import React, { useEffect, useState } from 'react';
import DeleteBtn from '../../../buttons/DeleteBtn';

const View = (props) => {
  const { user, options, parent, update, toggle } = props;

  const [value, setValue] = useState('viewer');

  useEffect(() => {
    let access = parent?.access;
    let found = access?.find(x => x.type==='user' && x.id===user?.id);
    if(found) setValue(found.role);
  }, [user, parent])

  const change = (e) => {
    e.target.blur();
    setValue(e.target.value);
    let arr = [...parent.access];
    let idx = parent.access.findIndex(x => x.type==='user' && x.id===user.id);
    if(idx > -1) {
      arr[idx].role = e.target.value;
      update(arr);
    }
  }

  const fromChild = (data) => {
    const { type } = data;
    // console.clear();
    if(type === 'delete confirmed') {
      // parent setter can either be array OR object (where we don't need to search)
      let filtered = parent.access?.filter(x => !(x.type==='user' && x.id===user.id));
      update(filtered);
    }
  }

  return (
    <div className={`d-flex ${toggle ? '' : 'mb-1'}`}>
      <p className="flex-grow-1 py-1 me-2 mb-0">{user.firstName} {user.lastName}</p>
      {!toggle && (
        <select
          style={{width: 150}}
          className="form-select form-select-sm me-2"
          value={value}
          onChange={change}>
          {options?.map((obj) => (
            <option key={`share-access-${obj.value}`} value={obj.value}>{obj.label}</option>
          ))}
        </select>
      )}
      <DeleteBtn toParent={fromChild} wrapper="flex-shrink-0 pt-1" styles="btn btn-xs me-1" size="16" />
    </div>
  )
}

export default View;
