import React, { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';
import dayjs from 'dayjs'

import { SiteContext } from '../../../../context/SiteContext';
import { UserContext } from '../../../../context/UserContext';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ToggleOn, ToggleOff } from 'react-bootstrap-icons';

import DeleteBtn  from '../../../../components/buttons/DeleteBtn';
import Generated  from '../../../../components/system/Generated';
import { emptyObj } from '../../../../utils/functions';
import { formObj } from '../../../../utils/schemas';

const View = (props) => {
  const { obj, pieces, toParent } = props;
  const { config } = useContext(SiteContext);
  const { userDetails, projects, setProjects } = useContext(UserContext);

  const schema = useMemo(() => ([
    { id: '1', type: 'col-6', label: '1', col1: [
      { id: '2', type: 'hidden', field: 'id', },
      { id: '5', type: 'hidden', field: 'status', },
      { id: '3', type: 'floating-input', inputType: 'text', label: 'Name', field: 'name', },
    ], 
    // col2: [
    //   { id: '4', type: 'floating-select', label: 'Status', field: 'publicStatus', options: [
    //     {value: 'Milestone 0'},
    //     {value: 'Milestone 1'},
    //     {value: 'Milestone 2'},
    //     {value: 'Milestone 3'},
    //     {value: 'Milestone 4'},
    //     {value: 'Milestone 5'},
    //     {value: 'Milestone 6'},
    //   ]},
    // ],
    col2: [
      { id: '3', type: 'floating-input', inputType: 'date', label: 'Due date', field: 'dueBy' },
    ]
  }]), [config?.terms?.phase])

  const { serverCall } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [schema])
  });

  // if you need to set the form value based on props.obj changing
  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [obj, schema, reset])

  const onSubmit = async (data) => {
    let res = await serverCall({ method: 'PATCH', data, url: `/mapping/phases/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert(`Error updating ${config?.terms?.phase?.[2] ?? 'Phase'}. Contact support.`); // lklklk
    if(toParent) toParent({ type: 'update', value: data });
  };

  const markActive = async() => {
    if(emptyObj(pieces?.project)) return;

    let projs = [...projects];
    let idx = projs.findIndex(x => x.appId === pieces.project.appId);
    projs[idx].phaseId = obj.id;
    setProjects(projs);

    await serverCall({
      method: 'PATCH',  url: `/mapping/projects/${pieces.project.appId}`,
      data: {
        id: pieces.project.id,
        phaseId: obj.id,
        updatedBy: userDetails.id,
        updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      },
      headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId },
      eamsAuth0: true
    });
  }

  const fromChild = (data) => {
    const { type } = data;
    if(type === 'delete confirmed') {
      setValue('status','D');
      handleSubmit(onSubmit)();
    } else {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className="d-flex">
      {pieces.project?.phaseId === obj.id && (
        <OverlayTrigger placement="left" overlay={(<Tooltip id={`phase-${obj.appId}-hover`}>{obj.name} is active</Tooltip>)}>
          <div className="d-flex pe-2 align-items-center">
            <ToggleOn size={32} className="text-success" />
          </div>
        </OverlayTrigger>
      )}

      {pieces.project?.phaseId !== obj.id && (
        <OverlayTrigger placement="left" overlay={(<Tooltip id={`phase-${obj.appId}-hover`}>Mark {obj.name} active</Tooltip>)}>
          <div className="d-flex pe-2 align-items-center hover" onClick={markActive}>
            {pieces.project?.phaseId !== obj.id && <ToggleOff size={32} className="text-muted" />}
          </div>
        </OverlayTrigger>
      )}

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="flex-grow-1">
        <Generated id={obj.appId} schema={schema} size="md" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />
      </form>
      <DeleteBtn toParent={fromChild} wrapper="d-flex float-end" styles="btn mx-1" size={18} />
    </div>
  )
}

export default View;
