import { Buffer } from 'buffer';
import { nanoid } from 'nanoid';

export const photoData = async ({ obj, file, task, project, activePlan, createdBy, createdAt }) => {
  let appId = nanoid();

  let data = {
    appId: appId,
    projectId: obj.projectId,
    phaseId: obj.phaseId,
    planId: obj.planId,
    taskId: task.id,
    plotAppId: obj.appId,
    plotId: obj.id,
    bucket: process.env.REACT_APP_S3_BUCKET,
    folder: `projects/${project.appId}/photos/`,
    filePrefix: `${project.appId}-${activePlan.appId}-${appId}`,
    // fileSource: 'SV8',
    createdBy,
    createdAt,
    status: 'A',
    dirty: 1
  }

  let newObj = await makeBase64({ file, data });
  return(newObj);
}

export const makeBase64 = ({ file, data }) => {
  return new Promise(resolve => {
    // this script:
    // opens a photo file
    // reads the image information
    // resizes it as needed
    // saves it locally as base64 with appropriate info
    // then tries to sync it if we have connectivity

    // Create an image and new file reader
    var img = document.createElement('img');
    var reader = new FileReader();

    // Set the image once loaded into file reader
    reader.onload = function(e) {
      img.src = e.target.result;

      img.onload = function () {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 2000;
        var MAX_HEIGHT = 2000;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        var ctx2 = canvas.getContext('2d');
        ctx2.drawImage(img, 0, 0, width, height);
        let base64 = canvas.toDataURL('image/jpeg');

        var currentTime = new Date().getTime();
        const buffer = Buffer.from(base64.substring(base64.indexOf(',') + 1));

        data.base64 = base64;
        data.size = buffer.length
        data.name = data.filePrefix+'-'+currentTime+'.jpeg';
        data.type = 'image/jpeg';

        resolve(data);
      } // end img onload
    } // end reader onload

    // Load files into file reader
    reader.readAsDataURL(file);
  })
};