const byStructure = ({ planGroups, plans, plots }) => {
  return new Promise(async(resolve) => {
    let arr = [];

    let groups = planGroups.filter(x => x.status==='A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
    for(const group of groups) {
      let groupPlans = plans.filter(x => x.planGroupId===group.id && x.status === 'A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
      for(const plan of groupPlans) {
        let planPlots = plots.filter(x => x.planId === plan.id);
        for(const plot of planPlots) {
          plot.planGroup = group;
          plot.plan = plan;
          arr.push(plot);
        }
      }
    }

    let soloPlans = plans.filter(x => !x.planGroupId && x.status === 'A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
    for(const plan of soloPlans) {
      let planPlots = plots.filter(x => x.planId === plan.id);
      for(const plot of planPlots) {
        plot.plan = plan;
        arr.push(plot);
      }
    }

    resolve(arr);
  })
};

export default byStructure;