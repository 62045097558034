import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom'

import { UserContext } from '../../../context/UserContext';

import { useAxios } from '../../../hooks/useAxios';
import { useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';

// you are using "generated" instead of FormBasic because you
// have to format the log details value depending on the form fields
import Generated from '../../../components/system/Generated';
// import EmailError from '../../../system/EmailError';

import { formObj } from '../../../utils/schemas';
import { nl2br } from '../../../utils/functions'
import parse from 'html-react-parser'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)

// lklklk future:
// move form to its own component
// add filters by type for the update types?
// move the updates into their own switch OR components

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'floating-select', label: 'Log Activity', field: 'type', options: [{value:'update',label:'Update'}] },
    { id: '3', type: 'floating-textarea', label: 'Update', field: 'update', required: 'Please enter an update', rows: 3 },
  ]},
]

const View = (props) => {
  const { userDetails } = useContext(UserContext);
  const { parentType, parentId, logs, setLogs } = useOutletContext();
  const formText = { submit: 'Add', pending: 'Adding', success: 'Added!', cancel: 'Cancel' };
  const errorCode = '8927'

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  useEffect(() => {
    setValue('type','update');
  }, [setValue])

  const onSubmit = async (data) => {
    data.appId = nanoid();
    data.parentType = parentType;
    data.parentIds = [String(parentId)];
    data.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.createdBy = userDetails.id;
    data.createdByUser = userDetails.byUser;
    data.status = 'A';

    if(data.type === 'update')
      data.details = {value: data.update}

    let res = await serverCall({ method: 'post', data, url: '/system/logs', eamsAuth0: true });
    if(res.status === 200) {
      let arr = [...logs];
      arr.unshift(res.data);
      setLogs(arr);
      reset();
      setValue('type','update');
    } else {
      // lklklk error
    }
  };

  const handleCancel = (e) => {
    e.target.blur();
    reset();
  }

  return (
    <div className="row">
      <div className="col-sm-8">
        { logs.length===0 && (
          <div className="mb-3 p-2 bg-white rounded">
            <p className="p-3 mb-0">No activity yet.</p>
          </div>
        )}

        <div className="bg-white">
          { logs.map((obj, idx) => {
            // console.log(obj);

            return (
              <div key={obj.appId} className={`d-flex align-items-start p-3 ${(idx+1 < logs.length) ? "border-bottom" : ""}`}>
                <div className="flex-grow-1">
                  <small className="float-end text-muted">{dayjs(obj.createdAt).fromNow()}</small>
                  {/*<a href={'mailto:'+obj.createdEmail}><strong>{obj.createdBy}</strong></a>*/}

                  {obj.type==='public status' && <p className="mb-1">The public status was changed to <b>{obj.details.value}</b></p>}
                  {obj.type==='due date' && obj.details.value && <p className="mb-1">The due date was changed to <b>{obj.details.value}</b></p>}
                  {obj.type==='due date' && !obj.details.value  && <p className="mb-1">The due date was removed</p>}
                  {obj.type==='update' && <p className="mb-1">{obj.details.value}</p>}
                  {/*
                  <a href={'mailto:'+obj.createdEmail}><strong>{obj.createdBy}</strong></a>
                  { obj.type==='public status' && (<> changed the status</>)}
                  { obj.type==='due date' && (<> changed the due date</>)}
                  { obj.type==='update' && (<> posted an update</>)}
                  { obj.type==='email' && (<> logged an email</>)}
                  { obj.type==='meeting' && (<> scheduled a meeting</>)}
                  { obj.type==='rfi' && (<> requested information</>)}
                  { obj.type==='convert' && (<> converted to a project</>)}
                  { obj.type==='decline' && (<> declined ticket</>)}
                  */}
                  <small className="text-muted">{dayjs(obj.createdAt).format('MMM D, YYYY h:mma')}</small>
                  <div className="text-sm text-muted mt-2">
                    { obj.activityType!=='email' && (
                      <>{parse(nl2br(obj.content))}</>
                    )}

                    { obj.activityType==='email' && (
                      <>
                      <b>From:</b> {obj.content[0]}<br />
                      <b>Email:</b> <a href={'mailto:'+obj.content[1]}>{obj.content[1]}</a><br />
                      <br />
                      {parse(nl2br(obj.content[2]))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="col-sm-4">
        <h5>Add Activity</h5>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Generated id="add-activity" schema={schema} size="md" pieces={{ register, control, setValue, watch, errors }} />
          <div className="mt-3">
            <button className={`btn btn-sm me-1 ${error ? 'btn-danger' : 'btn-success'}`} type="submit" disabled={pending}>
              { !pending && !error && (formText.submit)}
              { pending === true && (
                <Fragment>
                  <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  {formText.pending}
                </Fragment>
              )}
              { error && (`Error saving (${errorCode})`)}
              { pending ==='success' && formText.success}
            </button>

            <button className="btn btn-sm btn-outline-dark" type="button" onClick={handleCancel}>
              {formText.cancel}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default View;
