import { fabric } from 'fabric';

const shape = ({ fillColor, scale, rotation, details, textOpts }) => {

  let text = new fabric.Text('A    B',{
    ...textOpts,
    fontSize: 8*scale,
    textAlign: 'center',
    lineHeight: 0.85,
    fill: fillColor
  });

  let rect = new fabric.Rect({
    width: 9*scale,
    height: 3*scale,
    rx: 1*scale,
    fill: fillColor,
    stroke: fillColor,
    angle: 90
  });

  let c = new fabric.Group([ text, rect ], {
    ...details,
    hasControls: true,
    allowRotation: true,
    angle: rotation,
    cornerSize: 24,
    borderColor: 'red',
    cornerColor: 'red'
  });

  c.setControlsVisibility({
    bl: false,
    br: false,
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    tl: false,
    tr: false,
    mtr: true
  });

  return c;
}

export default shape;