import React, { createContext, useEffect, useState } from 'react';
import { useAxios } from '../hooks/useAxios';

import Collapse from 'react-bootstrap/Collapse';
import WorkerProgress from './components/WorkerProgress';
import { ChevronDown, ChevronUp, CloudDownload, CloudUpload, X } from 'react-bootstrap-icons'

import pdfWorker from './workers/pdf/init';
import excelWorker from './workers/excel/init';
import artWorker from './workers/artwork/init';
import ccPlanWorker from './workers/cc-plans/init';
import ccThumbsWorker from './workers/cc-thumbs/init';
import syncWorker from './workers/sync/init';
import zipWorker from './workers/zip/init';

const WorkerContext = createContext();

// future: if a plan is being downloaded, terminate the old worker

// this will be used for:
// pdf reports
// pdf location plans (and png?)
// artwork
// zip downloads of photos


const WorkerProvider = (props) => {
  const { serverCall } = useAxios();
  const [open, setOpen] = useState(true);
  const [workers, setWorkers] = useState({});

  const [syncWorkers, setSyncWorkers] = useState([]);
  const [downloads, setDownloads] = useState(0);
  const [uploads, setUploads] = useState(0);

  const startWorker = ({ type, pieces }) => {
    setOpen(true);

    switch (type) {
      case 'art': return artWorker({ pieces, setWorkers });
      case 'pdf': return pdfWorker({ pieces, setWorkers });
      case 'excel': return excelWorker({ pieces, setWorkers });
      case 'zip': return zipWorker({ pieces, setWorkers });
      case 'cc-plan': return ccPlanWorker({ pieces, setWorkers, serverCall });
      case 'cc-thumbs': return ccThumbsWorker({ pieces, setWorkers, serverCall });
      case 'sync': return syncWorker({ pieces, setSyncWorkers });
      default: return alert('Error starting worker.'); // lklklk
    }
  }

  const stopWorker = (id) => {
    workers[id].worker.terminate();
    setWorkers((prev) => {
      const updatedWorkers = {...prev};
      updatedWorkers[id].data = { status: 'stopped' };
      return updatedWorkers;
    });
  }

  const clearAll = () => {
    Object.values({...workers}).forEach((worker) => {
      worker.worker.terminate();
    });
    setWorkers({});
    setOpen(false);
  }

  useEffect(() => {
    setDownloads(syncWorkers.filter(x => x.direction === 'download').length);
    setUploads(syncWorkers.filter(x => x.direction === 'upload').length);
  }, [syncWorkers])

  return (
    <WorkerContext.Provider value={{ workers, startWorker, stopWorker }}>
      {props.children}

      {Object.keys(workers).length > 0 && (
        <div className="position-fixed bg-white border rounded shadow" style={{zIndex:1999, width:300, bottom:12, right:12}}>
          <div className={`bg-dark p-2 text-end ${open ? 'rounded-top' : 'rounded'}`}>
            {  open && ( <ChevronDown size={24} className="pointer text-white" onClick={() => setOpen(false)} /> )}
            { !open && ( <ChevronUp size={24} className="pointer text-white" onClick={() => setOpen(true)} /> )}
            <X size={28} className="pointer text-white ms-2" onClick={clearAll} />
          </div>

          <Collapse in={open}>
            <div>
              { Object.keys(workers).map((key, idx) => <WorkerProgress key={idx} id={key} obj={workers[key]} stopWorker={stopWorker} /> )}
            </div>
          </Collapse>
        </div>
      )}

      {(downloads > 0 || uploads > 0) && (
        <div className="position-fixed d-flex bg-dark text-white p-2 rounded shadow" style={{zIndex:1999, top:12, left:'45%'}}>
          <div className="spinner-border spinner-border-sm mx-1" role="status"><span className="visually-hidden">Loading...</span></div>
          { downloads > 0 && (
            <div className="mx-1"><CloudDownload size={20} /> <span className="badge rounded-pill bg-secondary">{downloads}</span></div>
          )}
          { uploads > 0 && (
            <div className="mx-1"><CloudUpload size={20} /> <span className="badge rounded-pill bg-secondary">{uploads}</span></div>
          )}
        </div>
      )}
    </WorkerContext.Provider>
  );
}

export { WorkerContext, WorkerProvider };
