import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { matchSorter } from 'match-sorter';

import { UserContext } from '../../../../context/UserContext';

import { Filter as FilterIcon, XLg } from 'react-bootstrap-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Collapsed from '../../../../components/system/filters/Collapsed';
import FilterCheckboxes from '../../../../components/system/filters/Checkboxes';

import { emptyObj } from '../../../../utils/functions';
import { organizeTypes } from '../../../../utils/types';
import { sortPlots } from '../utils/plots';

// note: removed filtering by activePlan so we can see quantities
// for other plans, even when we are within a single plan
// the other views (list, map) manually filter by the current plan when needed

const Group = {
  TYPE: 'typeId',
  VARIATION: 'variationId',
  ACTION: 'installMode',
  STATUS: 'status',
  PUBLICSTATUS: 'publicStatus',
  PHASE: 'phase',
  GROUP: 'planGroup',
  PLAN: 'plan',
  TAG: 'tag'
}

const isShownByStatus = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.STATUS);
  // if no filters exist, default to show active plots only
  if (!filtered.length) filtered = [{name: 'status', group: 'status', fnc: (x) => x.status === 'A'}]
  return filtered.some((filter) => filter.fnc(item));
}

const isShownByAction = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.ACTION);
  if (!filtered.length) return true;
  return filtered.some((filter) => filter.fnc(item));
}

const isShownByPublicStatus = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.PUBLICSTATUS);
  if (!filtered.length) return true;
  return filtered.some((filter) => filter.fnc(item));
}

const isActivePhase = (item, phase) => {
  if(emptyObj(phase)) return true;
  return (item.phaseId === phase.id) ? true : false;
}

const isShownByTypeId = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.TYPE);
  if (!filtered.length) return true;
  return filtered.some((filter) => filter.fnc(item));
}

// we do want to keep this in case they want to filter
// the list/reports by specific plans
const isShownByPlanId = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.PLAN);
  if (!filtered.length) return true;
  return filtered.some((filter) => filter.fnc(item));
}

const isShownByTagId = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.TAG);
  if (!filtered.length) return true;
  return filtered.some((filter) => filter.fnc(item));
}

export const applyFilters = (activePhase, activePlan, planGroups, plans, data, filters, search, bounds, showAllPhases, sort, types, typeVars) => {
  if(!data) data = [];
  if(!planGroups) planGroups = [];
  if(!plans) plans = [];

  // filter to get active planGroups and plans
  let groupIds = planGroups.filter(x => x.status==='A').map(x => x.id);
  let planIds = plans.filter(x => x.status==='A' && (!x.planGroupId || groupIds.includes(x.planGroupId))).map(x => x.id);
  if(planIds.length > 0) {
    data = data.filter(x => planIds.includes(x.planId));
  }

  if(bounds && !emptyObj(bounds)) {
    data = data.filter(x => (
      x.styling.lat <= bounds._northEast.lat && 
      x.styling.lat >= bounds._southWest.lat &&
      x.styling.lng <= bounds._northEast.lng && 
      x.styling.lng >= bounds._southWest.lng
  ))}

  // apply filters
  let active = data.filter((plot) => {
    // if showAllPhases is true, do not filter out by activePhase -- innies/outies below
    const showByActivePhase = showAllPhases ? true : isActivePhase(plot, activePhase);

    // all others are default
    const showByStatus = isShownByStatus(plot, filters);
    const showByAction = isShownByAction(plot, filters);
    const showByPublicStatus = isShownByPublicStatus(plot, filters);
    const showByPlanId = isShownByPlanId(plot, filters);
    const showByTypeId = isShownByTypeId(plot, filters);
    const showByTagId  = isShownByTagId(plot, filters);
    return showByActivePhase && showByStatus && showByAction && showByPublicStatus && showByPlanId && showByTypeId && showByTagId;
  });

  // then apply any search terms
  if(search) {
    active = matchSorter(active, search, {keys: [
      {threshold: matchSorter.rankings.CONTAINS, key: 'name'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf1'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf2'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf3'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf4'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf5'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf6'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf7'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf8'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf9'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf10'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf11'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf12'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf13'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf14'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf15'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'arr1'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'arr2'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'arr3'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'arr4'},
    ]});
  }

  // if projectId exists, split between visible and active
  if(showAllPhases && !emptyObj(activePhase)) {
    // console.clear();
    // pull out the ones that are active/currently in this phase
    // then filter the outies and sort desc so we see the most recent ones (in theory)
    // if this starts to slow down consider reduce/some:
    // https://stackoverflow.com/a/45440277/983017

    // also possible future editing if it gets slow:
    // remove active phase (innies)
    // sort by id desc
    // reduce by appId
    // recombine

    let innies = active.filter(x => x.phaseId === activePhase.id);
    let outies = active.filter(x => x.phaseId !== activePhase.id).sort((a,b) => (b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0));
    let appIds = innies.map(x => x.appId);
    for(const plot of outies) {
      if(appIds.includes(plot.appId)) continue;
      plot.disabled = true;
      innies.push(plot);
      appIds.push(plot.appId);
    }

    active = innies;
  }

  // then sort
  if(plans?.length > 0) {
    if(sort==='type')
      active = sortPlots(active, plans, planGroups, types, typeVars, true);
    else
      active = sortPlots(active, plans, planGroups);
  } else {
    active.sort((a, b) => ((a || {}).name || '').localeCompare((b || {}).name || '', undefined, { numeric: true, sensitivity: 'base' }));
  }

  // active.sort((a, b)=> (a.name.localeCompare(b.name, 'en', { numeric: true })))
  // active.sort((a, b) => ((a || {}).name || '').localeCompare((b || {}).name || '', undefined, { numeric: true, sensitivity: 'base' }));
  // active.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
  // active.sort((a,b) => (b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0))
  return active;
}

const View = (props) => {
  const { mini, project, libraries, publicStatuses, types, activeQtys, planGroups, plans, filters, setFilters, search, setSearch, sort, setSort } = props;
  const { schemas } = useContext(UserContext);

  const defaultsRef = useRef([]);
  const [statuses, setStatuses] = useState([]);
  const [actions, setActions] = useState([]);
  const [organized, setOrganized] = useState([]);
  const [foundQty, setFoundQty] = useState(0);

  const resetFilters = () => {
    setFilters(defaultsRef.current);
  }

  useEffect(() => {
    const findSchema = name => schemas.find(x => x.name === name);
    defaultsRef.current = [];

    const foundStatuses = findSchema('statuses');
    if (foundStatuses?.schema) {
      setStatuses(foundStatuses.schema);
      const activeDefaults = foundStatuses.schema
        .filter(obj => obj.active)
        .map(obj => ({ name: obj.value, group: 'status', fnc: (x) => x.status === obj.value }));

      defaultsRef.current.push(...activeDefaults);
    }

    const foundActions = findSchema('plot-actions');
    if (foundActions) setActions(foundActions.schema);

    // const foundPriority = findSchema('project-priority');
    // if(foundPriority) setPriority(foundPriority.schema);

    if (JSON.stringify(filters) === '[]') setFilters(defaultsRef.current);
  }, [schemas, filters, setFilters])

  useEffect(() => {
    // organize by type
    let organized = organizeTypes({ project, libraries, types, activeQtys });
    (organized.length > 0) ? setOrganized(organized) : setOrganized([{name: 'By Type', types}]);

    // then determine the number of found plots
    let qty = activeQtys.find(x => x.typeId==='total');
    (qty?.quantity) ? setFoundQty(qty.quantity) : setFoundQty(0);
  }, [project, libraries, types, activeQtys])

  return (
    <Fragment>
      { filters?.length > 0 && JSON.stringify(filters) !== JSON.stringify(defaultsRef.current) && (
        <div className="float-end">
          <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-reset`}>Clear filters</Tooltip>}>
            <button className="btn border bg-white shadow-sm" type="button" onClick={resetFilters}>
              <XLg size={16} />
            </button>
          </OverlayTrigger>
        </div>
      )}


      <div className="dropdown d-inline-block float-end">
        <button className="btn border bg-white shadow-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
          { mini && (<FilterIcon size={20} className="my-1 me-2" />) }
          {!mini && <span className="me-1">Filters</span>}
          { JSON.stringify(filters) !== JSON.stringify(defaultsRef.current) && <span className="me-2">({filters.length})</span>}
        </button>

        <ul className="dropdown-menu dropdown-menu-end" style={{width:350, maxHeight:'85vh', overflow: 'scroll'}}>
          { mini && (
            <li className="p-2">
              <form className="mb-2">
                <input className="form-control" type="search" defaultValue={search} placeholder="Search" aria-label="Search" onChange={(e) => setSearch(e.target.value)} />
              </form>
              { search && <p className="mb-1 text-muted small">Found plots: {foundQty}</p> }
            </li>
          )}

          {/*<FilterCheckboxes arr={priority} group={Group.PRIORITY} field="priority" title="Priority" filters={filters} setFilters={setFilters} />
          <li><hr className="dropdown-divider" /></li>
          <FilterCheckboxes arr={scale} group={Group.SCALE} field="udf1" title="Scale" filters={filters} setFilters={setFilters} />
          <li><hr className="dropdown-divider" /></li>*/}
          <li className="p-2">
            <div className="btn-group" role="group" aria-label="Sort Toggle">
              <button type="button" className={`btn btn-sm btn-outline-dark ${sort==='plan' && 'active'}`} onClick={() => setSort('plan')}>Sort by Plan</button>
              <button type="button" className={`btn btn-sm btn-outline-dark ${sort==='type' && 'active'}`} onClick={() => setSort('type')}>Sort by Type</button>
            </div>
          </li>
          <li><hr className="dropdown-divider" /></li>
          <Collapsed title="By Action" classes="px-3">
            <div className="mt-1">
              <FilterCheckboxes arr={actions} group={Group.ACTION} field="installMode" filters={filters} setFilters={setFilters} />
            </div>
          </Collapsed>
          <Collapsed title="By Status" classes="px-3">
            <div className="mt-1">
              <FilterCheckboxes arr={publicStatuses} group={Group.PUBLICSTATUS} field="publicStatus" filters={filters} setFilters={setFilters} />
            </div>
          </Collapsed>
          <Collapsed title="By Type" classes="px-3">
            <div className="mt-1">
              { organized.map(obj => (
                <Collapsed key={`types-${obj.uid}`} appId={obj.uid} title={obj.name} detail={obj.quantity} arr={obj.types} field="typeId" filters={filters} setFilters={setFilters} classes="px-3">
                  <div className="mt-0">
                    <FilterCheckboxes arr={obj.types} group={Group.TYPE} field="typeId" filters={filters} setFilters={setFilters} />
                  </div>
                </Collapsed>
              ))}
              <FilterCheckboxes arr={[{ value: null, label: 'TBD' }]} group={Group.TYPE} field="typeId" filters={filters} setFilters={setFilters} />
            </div>
          </Collapsed>       
          <Collapsed title="By Plan" classes="px-3">
            <div className="mt-1">
            { planGroups.filter(x => x.status==='A').map(obj => (
                <Collapsed key={`planGroups-${obj.appId}`} appId={obj.appId} title={obj.name} arr={plans.filter(x => x.planGroupId===obj.id && x.status==='A')} field="planId" filters={filters} setFilters={setFilters} classes="px-3">
                  <div className="mt-0">
                    <FilterCheckboxes arr={plans.filter(x => x.planGroupId===obj.id && x.status==='A')} group={Group.PLAN} field="planId" filters={filters} setFilters={setFilters} />
                  </div>
                </Collapsed>
              ))}
              <FilterCheckboxes arr={plans.filter(x => !x.planGroupId && x.status==='A')} group={Group.PLAN} field="planId" filters={filters} setFilters={setFilters} />
            </div>
          </Collapsed>          
          <li><hr className="dropdown-divider" /></li>
          <Collapsed title="Active/Deleted" classes="px-3">
            <div className="mt-0">
              <FilterCheckboxes arr={statuses} group={Group.STATUS} field="status" filters={filters} setFilters={setFilters} />
            </div>
          </Collapsed>
        </ul>
      </div>

      { !mini && (
        <form className="d-inline-block mb-2 me-2 float-end" style={{width:150}} onSubmit={e => { e.preventDefault() }}>
          <input style={{height:34}} className="form-control shadow-sm" type="search" defaultValue={search} placeholder="Search" aria-label="Search" onChange={(e) => setSearch(e.target.value)} />
        </form>
      )}
    </Fragment>
  )
}

export default View;
