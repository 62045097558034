import React, { Fragment, useEffect, useState } from 'react';
// import { WorkerContext } from '../../context/WorkerContext';
import { Download } from 'react-bootstrap-icons';

import { blobToBase64, downloadFile } from '../../utils/functions';
import { getSvg } from '../artwork/_utils/functions';
import { makeSvg } from '../artwork/_utils/generate-svg.js';

import JSZip from 'jszip';
import dayjs from 'dayjs'

// future: 
// production vs client review etc.
// pull the dropdown from one of the others

const View = (props) => {
  // const { startWorker } = useContext(WorkerContext);
  const { project, planGroups, plans, phase, symbols, types, typeVars, plots } = props;
  const wrapper = props.wrapper ? props.wrapper : 'shadow-sm me-2';
  const classes = props.classes ? props.classes : 'btn btn-outline-primary';
  const text = props.text ? props.text : '';
  const [loading, setLoading] = useState(false);
  const [artIds, setArtIds] = useState([]);

  useEffect(() => {
    // check if any types/typeVars have artwork to hide button
    let plotTypeIds = plots.map(x => x.typeId);
    plotTypeIds = [...new Set([...plotTypeIds])];

    let typeIds   = types.filter(x => plotTypeIds.includes(x.id) && (x.prodAurl || x.prodBurl)).map(x => x.id);
    let parentIds = typeVars.filter(x => plotTypeIds.includes(x.typeId) && (x.prodAurl || x.prodBurl)).map(x => x.typeId);
    let unique = [...new Set([...typeIds ,...parentIds])];
    setArtIds(unique);
  }, [types, typeVars])

  const download = async (artType) => {
    console.clear();
    setLoading(true);

    let sortedTypes = [];

    if(artType==='production') {
      sortedTypes = types.filter(x => artIds.includes(x.id));
    }

    const zip = new JSZip();

    for(const type of sortedTypes) {
      console.log(type.label);
      let plotVarIds = plots.filter(x => x.typeId===type.id && x.variationId).map(x => x.variationId);
      plotVarIds = [...new Set([...plotVarIds])];

      if(type.prodAurl) type.prodAblob = await getSvg(type.prodAurl);
      if(type.prodBurl) type.prodBblob = await getSvg(type.prodBurl);

      let filteredVars = typeVars.filter(x => plotVarIds.includes(x.id));

      for(const typeVar of filteredVars) {
        if(typeVar.prodAurl) typeVar.prodAblob = await getSvg(typeVar.prodAurl);
        if(typeVar.prodBurl) typeVar.prodBblob = await getSvg(typeVar.prodBurl);
      }

      let filtered = plots.filter(x => x.typeId===type.id);

      for(const plot of filtered) {
        let plan = plans.find(x => x.id === plot.planId);
        plot.projectName = project.name;
        plot.phaseName = phase.name;
        plot.planGroupName = plan.planGroupId ? planGroups.find(x => x.id === plan.planGroupId) : null;
        plot.planName = plan?.name;
  
        let typeVar = filteredVars.find(x => x.id === plot.variationId);
        // lklklk: may want to refine this instead of clobbering the type
        let display = typeVar ? {...type, ...typeVar} : {...type};
  
        let filename = `${plot.display.textLabel} - ${plot.projectName}`;
        if(plot.phaseName !== 'Phase 1') filename = filename + ` ${plot.phaseName}`;
        if(plot.planGroupName) filename = filename + ` - ${plot.planGroupName}`;
        filename = filename + ` - ${plot.planName}`;
        filename = filename + ` Loc ${String(plot.name).padStart(3, '0')}`;
        filename = filename + `-${plot.id}`;
  
        try {
          if(display.prodAblob) {
            let svg = await makeSvg({ template: display.prodAblob, message: plot.arr1, plot, type: display, symbols, format: 'blob' });
            let sideA = display.prodBblob ? filename+'-sideA' : filename;
            zip.folder(type.label).file(sideA+'.svg', svg);
          }

          if(display.prodBblob) {
            let svg = await makeSvg({ template: display.prodBblob, message: plot.arr1, plot, type: display, symbols, format: 'blob' });
            zip.folder(type.label).file(filename+'-sideB.svg', svg);
          }
        } catch (error) {
          console.log('There was an error', error);
          continue;
        }
      }
    }

    // let timestamp = dayjs().format(schema?.schema.dateFormat ? schema?.schema.dateFormat : 'YYYY-MM-DD');
    let timestamp = dayjs().format('YYYY-MM-DD');
    // let name = `${project.name} - ${phase.name} - Artwork (Zip)`;
    let zipName = `${project.name} - ${phase.name} - Artwork ${timestamp}`;
  
    zip.generateAsync({type:"blob"}).then(async(data) => {
      const blob = new Blob([data], { type: 'application/zip' });
      let dataUrl = await blobToBase64(blob);
      downloadFile(dataUrl, `${zipName}.zip`, false);
    });

    console.log('already done');
    // startWorker({ type: 'art', pieces: { name, filename, content: { typeIds, types: sortedTypes, typeVars: someTypeVars, plots: somePlots} } });
    setLoading(false);
  }

  if(artIds?.length === 0) return null;

  return (
    <div className={`btn-group position-static ${wrapper}`}>
      <button type="button" className={classes} onClick={() => download('production')} disabled={loading}>
        { !loading && (
          <Fragment>
            <Download size={16} />
            { text && <span className="d-inline-block ms-2">{text}</span>}
          </Fragment>
        )}

        { loading && (
          <Fragment>
            <div className="spinner-border spinner-border-sm me-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            Generating
          </Fragment>
        )}        
      </button>
    </div>
  )
}

export default View;
