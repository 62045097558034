import React, { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';
import { UserContext } from '../../../../context/UserContext';
import dayjs from 'dayjs'

import Generated  from '../../../../components/system/Generated';
import ToggleStatusBtn from '../../../../components/buttons/ToggleStatus';
// import EmailError from '../system/EmailError';

import { formObj } from '../../../../utils/schemas';

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'id' },
    { id: '3', type: 'hidden', field: 'appId' },
    { id: '4', type: 'hidden', field: 'status' },
    { id: '8', type: 'floating-input', field: 'name', label: 'Name', required: 'Please enter a library name'},
  ]}
]

const View = (props) => {
  const debug = false;
  const { obj, setLibraries, setTypes } = props;
  const { userDetails } = useContext(UserContext);

  const { serverCall } = useAxios();
  const { control, register, handleSubmit, formState: { errors, isDirty }, reset, watch, setValue, getValues } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });
  const toggleStatus = watch('status');

  // if you need to set the form value based on props.obj changing
  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [obj, reset])

  const onSubmit = async (data) => {
    if(debug) console.log(data);

    data.updatedBy = userDetails.id;
    data.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');

    setLibraries((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.id === data.id);
      arr[idx] = {...arr[idx], ...data};
      return arr;
    })

    let res = await serverCall({ method: 'PATCH', data, url: `/library/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating library. Contact support.'); // lklklk
    let filled = formObj(schema, data);
    reset(filled);
  }

  const fromChild = (data) => {
    if(debug) console.log(data);
    const { type, value, autosave } = data;
    if(type==='toggle status') {
      setValue('status', value);
      handleSubmit(onSubmit)();
    } else if(isDirty || autosave)
      handleSubmit(onSubmit)();
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mb-3">
        <Generated id={`library-row-${obj.appId}`} schema={schema} size="sm" pieces={{ register, control, setValue, getValues, watch, errors, toParent: fromChild }} />
      </form>
      <ToggleStatusBtn toParent={fromChild} status={toggleStatus} textA="Delete Library" textD="Reactivate Library" />
    </div>
  )
}

export default View;
