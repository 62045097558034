import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom'

import { useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs'

import { UserContext } from '../../context/UserContext';
import { useAxios }    from '../../hooks/useAxios';
import Generated  from './Generated';
// import EmailError from './EmailError';

import { formObj } from '../../utils/schemas';

import Modal from 'react-bootstrap/Modal';

// note: this form assumes we are logged in/authenticated somehow
// if you need to submit an anonymous form, this script should be adjusted

// note 2:
// the response includes value = form values, manually entered
// and response = server-returned values, i.e. includes id of a new record

// HUGE NOTE: This modal doesn't unmount when closing the modal

// how to use:
// <FormModal debug={true} method="PATCH" url={`/system/schemas/${params.projectId}`} obj={active}
// <FormModal debug={true} method="POST" url="/mapping/projects" schema={projSchema}
//   vals={{
//     createdBy: userDetails.id,
//     udf1: scale[0]?.value,
//     udf2: priority[0]?.value,
//     visible: 'Y',
//     publicStatus: projStatus[0]?.value
//   }}
//   vars={{ 'project-scale': scale, 'all-users': allUsers }}
//   createForm={true} updateForm={false} toParent={fromForm}
//   modalText={{ button: 'Create Project', title: 'Create Project' }}
//   formText={{ submit: 'Create', pending: 'Creating', success: 'Created', cancel: 'Cancel' }}
//   xxsubmitType={{ type: 'redirect-new', path: '/project' }}
//   submitType={{ type: 'get-record' }}
// />

const View = (props) => {
  let { debug, obj, schema, vals, vars, method, url, createForm, updateForm, errorCode, formText, modalText, toParent, submitType } = props;
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const backdrop = props.backdrop ? props.backdrop : 'static';
  const keyboard = props.keyboard ? props.keyboard : false;
  const centered = props.centered ? props.centered : false;
  const classes = props.classes ? props.classes : 'btn btn-sm btn-outline-dark mx-1';
  modalText = modalText ? modalText : { button: 'Button', title: 'Something' }
  formText = formText ? formText : { submit: 'Save', pending: 'Saving', success: 'Saved!', cancel: 'Cancel' };

  const [show, setShow] = useState(false);
  const handleCancel = () => {
    setShow(false);
    reset();
  }

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [schema])
  });

  useEffect(() => {
    // hide modal when unmounting?
    return () => setShow(false);
  }, [])

  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [schema, obj, reset])

  const openModal = (e) => {
    e.target.blur();
    setShow(true);
  }

  const fromChild = (data) => {
    if(toParent) toParent(data);
  }

  const onSubmit = async (data) => {
    if(debug) console.log(data);
    let uuid = nanoid();

    if(createForm) {
      data.appId = uuid;
      data.createdAt = data.createdAt ? data.createdAt : dayjs().format('YYYY-MM-DD HH:mm:ss');
      data.createdByUser = userDetails.byUser;
      data.status = 'A';

      // if the form has already provided createdBy, do not override it
      // used in the cases where someone is filing a ticket for another person
      data.createdBy = data.createdBy ? data.createdBy : userDetails.id;
    }

    if(updateForm) {
      data.updatedBy = userDetails.id;
      data.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
      data.updatedByUser = userDetails.byUser;
    }

    if(debug) console.log(data);

    let res = await serverCall({
      method, data, url,
      headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId },
      eamsAuth0: true
    });
    if(debug) console.log(res);

    if(toParent) toParent({type: 'submitted', status: res.status, value: data, response: res.data});

    if(submitType.type === 'redirect-new') {
      navigate(`${submitType.path}/${uuid}`);
    } else if(submitType.type === 'close-modal') {
      handleCancel();
    }
  };

  return (
    <Fragment>
      <button className={classes} onClick={openModal}>
        {modalText.button}
      </button>

      <Modal show={show} onHide={handleCancel} backdrop={backdrop} keyboard={keyboard} centered={centered} scrollable>
          <Modal.Header closeButton>
            <Modal.Title>{modalText.title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Generated id="form-modal" schema={schema} size="md" pieces={{ vals, vars, register, control, setValue, watch, errors, toParent: fromChild }} />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline-danger me-1" type="button" onClick={handleCancel}>
              {formText.cancel}
            </button>

            <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'}`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
              { !pending && !error && (formText.submit)}
              { pending === true && (
                <Fragment>
                  <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  {formText.pending}
                </Fragment>
              )}
              { error && (`Error saving (${errorCode})`)}
              { pending ==='success' && formText.success}
            </button>
          </Modal.Footer>

      </Modal>
    </Fragment>
  )
}

export default View;
