import { fabric } from 'fabric'
// import { getSelected, setSelected } from "./selection";
import { addToArray } from '../../../../utils/functions';


// lklklk think about adding left/right arrows to go 
// to the next record in the sequence
// also maybe up/down so they can navigate tables faster
// check this with eams PHP version, you already do this



// export const copyPlot = (type, canvas, clipboardRef, setCopied, phase) => {
//   // clipboardRef.current.copyType = type; // not enabling atm
//   // clear out and/or create array
//   clipboardRef.current.ogPhase = phase;
//   clipboardRef.current.toCopy = [];
//   let items = canvas.getActiveObject();
//
//   if(items) {
//     if(items.appId) {
//       clipboardRef.current.toCopy.push(items.appId);
//
//     } else {
//       items._objects.forEach((obj) => {
//         clipboardRef.current.toCopy.push(obj.appId);
//       });
//     }
//   }
//
//   let numCopied = clipboardRef.current.toCopy.length;
//   setCopied(numCopied);
//
//   setTimeout(() => {
//     canvas.discardActiveObject();
//     let selected = [];
//     clipboardRef.current.toCopy.forEach((appId) => {
//       canvas.getObjects().forEach((obj) => {
//         if(obj.appId === appId)
//           selected.push(obj);
//       });
//     });
//
//     var sel = new fabric.ActiveSelection(selected, { canvas: canvas });
//     canvas.setActiveObject(sel).requestRenderAll();
//   }, 300)
// }
//
// const pastePlot = (canvas, clipboardRef, interaction) => {
//   interaction({ type: 'paste' });
// }

export const handleKeyDown = ({ e, debug, canvas, interaction, setSelected, toParent }) => {
  if(debug) console.log('key down', e.keyCode);
  if(!canvas) return console.log('canvas missing');

  if (e.keyCode === 16) { // shift key
    interaction.bkgdLocked = true;
    toParent({ type: 'shift key', value: true });

  } else if (e.keyCode === 8) { // delete key
    if(document.activeElement !== document.body) return;

    let obj = canvas.getActiveObject();
    if(!obj) return;
    let deleteArr = [];
    if('appId' in obj) {
      deleteArr = addToArray(deleteArr, { id: obj.id, appId: obj.appId, phaseId: obj.phaseId });
    } else {
      for (const item of obj._objects) {
        if('appId' in item) {
          deleteArr = addToArray(deleteArr, { id: item.id, appId: item.appId, phaseId: item.phaseId });
        }
      }
    }
    if(deleteArr.length===0) return;
    toParent({ type: 'delete plots', value: deleteArr });    

  // } else if (e.keyCode === 13) { // enter to end rotation?

  } else if (e.keyCode === 82) { // r key for rotate?
  //   if(interactionRef.current.objRotatable) {
      let obj = canvas.getActiveObject();
      if(!obj?.allowRotation) return;

      // rotate with r key and shift combo
      let shift = interaction.bkgdLocked;
      let currentAngle = obj.angle ? obj.angle : 0;
      switch (currentAngle) {
        case 0:  obj.angle  = !shift ? 45  : 315; break;
        case 45: obj.angle  = !shift ? 90  : 0; break;
        case 90: obj.angle  = !shift ? 135 : 45; break;
        case 135: obj.angle = !shift ? 180 : 90; break;
        case 180: obj.angle = !shift ? 225 : 135; break;
        case 225: obj.angle = !shift ? 270 : 180; break;
        case 270: obj.angle = !shift ? 315 : 225; break;
        case 315: obj.angle = !shift ? 0   : 270; break;
        default:  obj.angle  = 0;
      }

      obj.eamsRotated = true;
      if(!obj.eamsOgAngle) obj.eamsOgAngle = currentAngle;
      canvas.requestRenderAll();

  // } else if (e.keyCode === 67 && (e.metaKey || e.ctrlKey)) { // ctrl c
  // //   // copyPlot('copy', canvas, clipboardRef, setCopied);

  // } else if (e.keyCode === 88 && (e.metaKey || e.ctrlKey)) { // ctrl x
  // //   // you aren't enabling cut at the moment
  // //   // just in case they have photos, etc.
  // //   // copyPlot('cut', canvas, clipboardRef);

  // } else if (e.keyCode === 86 && (e.metaKey || e.ctrlKey)) { // ctrl v
  // //   // pastePlot(canvas, clipboardRef, interaction);

  } else if (e.keyCode === 65 && (e.metaKey || e.ctrlKey)) { // ctrl a
    if(document.activeElement !== document.body) return;

    // remove any active text selections
    setTimeout(() => {
      document.getSelection().removeAllRanges();
    }, 50)

    let active = canvas.getObjects();
    let selected = active.filter(x => x.piece==='locator' || x.piece==='label').map(x => ({ appId: x.appId, id: x.id, phaseId: x.phaseId, piece: x.piece }));
    interaction.selected = selected;
    setSelected(selected);
  }
}

export const handleKeyUp = ({ e, debug, canvas, interaction, setSelected, toParent }) => {
  if(debug) console.log('key up', e.keyCode);
  if(!canvas) return console.log('canvas missing');

  if (e.keyCode === 16) { // shift key
    interaction.bkgdLocked = false;
    toParent({ type: 'shift key', value: false });

  } else if(e.keyCode === 27) { // escape key
    let obj = canvas.getActiveObject();
    if(obj?.eamsOgAngle) {
      obj.angle = obj.eamsOgAngle;
      canvas.requestRenderAll();
    }
    setSelected([]);
  } 
}
