import { nanoid } from 'nanoid';
import { saveAs } from 'file-saver';
import { base64toBlob } from '../../../utils/functions'

const init = ({ pieces, setWorkers }) => {
  if(pieces?.report?.version === 2) {
    const worker = new Worker(new URL('./worker-v2.js', import.meta.url));
    let newId = nanoid();
  
    worker.postMessage({ data: pieces });
    worker.onerror = (err) => err;
    worker.onmessage = async (e) => {
      const { status, dataUrl, pdfBytes } = e.data;
  
      setWorkers((prev) => {
        const updatedWorkers = {...prev};
        updatedWorkers[newId].data = e.data;
        return updatedWorkers;
      });
  
      if(status === 'finished') {
        if(dataUrl) {
          let blob = await base64toBlob(dataUrl);
          saveAs(blob, `${pieces.filename}.pdf`);
        } else if(pdfBytes) {
          let blob = new Blob([pdfBytes], { type: 'application/pdf' });
          saveAs(blob, `${pieces.filename}.pdf`);
          // window.open(URL.createObjectURL(blob));
          // setWorkers([]);
        }
        
        worker.terminate();
      }
    };
    
    setWorkers((prev) => ({ ...prev, 
      [newId]: { 
        worker, 
        type: 'pdf', 
        name: pieces.name,
        filename: pieces.filename, 
        data: { status: 'starting', progress: 0 }
      }
    }));

  } else {
    const worker = new Worker(new URL('./worker.js', import.meta.url));
    let newId = nanoid();
  
    worker.postMessage({ data: pieces.content });
    worker.onerror = (err) => err;
    worker.onmessage = async (e) => {
      const { status, dataUrl, pdfBytes } = e.data;
  
      setWorkers((prev) => {
        const updatedWorkers = {...prev};
        updatedWorkers[newId].data = e.data;
        return updatedWorkers;
      });
  
      if(status === 'finished') {
        if(dataUrl) {
          let blob = await base64toBlob(dataUrl);
          saveAs(blob, `${pieces.filename}.pdf`);
        } else if(pdfBytes) {
          let blob = new Blob([pdfBytes], { type: 'application/pdf' });
          saveAs(blob, `${pieces.filename}.pdf`);
        }
        
        worker.terminate();
      }
    };
    
    setWorkers((prev) => ({ ...prev, 
      [newId]: { 
        worker, 
        type: 'pdf', 
        name: pieces.name,
        filename: pieces.filename, 
        data: { status: 'starting', progress: 0 }
      }
    })); 
  }
}

export default init;