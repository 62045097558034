import { fabric } from 'fabric';
import { emptyObj } from '../../../../utils/functions';

export const getMarkup = ({ img }) => {
  return new Promise(async(resolve) => {
    // if there are no markups, just send back false
    if(!img || emptyObj(img.markups)) return resolve(false);

    const bkgd = new Image();
    bkgd.crossOrigin = 'Anonymous';
    bkgd.src = img.url;
    bkgd.onload = () => {
      const el = document.createElement('canvas');
      const canvas = new fabric.Canvas(el);
      fabric.Object.prototype.originX = fabric.Object.prototype.originY = 'center';
      canvas.loadFromJSON(img.markups);
      canvas.setWidth(bkgd.width);
      canvas.setHeight(bkgd.height);

      const bgOpts = {
        opacity: 1,
        backgroundColor: 'white',
        backgroundImageStretch: false,
        originX: 'center',
        originY: 'center',
        left: bkgd.width/2,
        top: bkgd.height/2,
        crossOrigin: 'Anonymous',
        // this angle works but the resize thing doesnt account for it
        // angle: 90
      }

      canvas.setBackgroundImage(bkgd.src, () => {
        canvas.renderAll();
        try {
          let dataUrl = canvas.toDataURL({ format: 'jpeg', quality: 1 });
          resolve(dataUrl);
        } catch (err) {
          console.error('Failed to create dataURL:', err);
          // Handle error accordingly, for instance, by setting dataUrl to a placeholder image or error message
          resolve(false);
        }
      }, bgOpts);
    }

    bkgd.onerror = (err) => {
      console.error('Image load error:', err);
      // Handle error, for example by setting dataUrl to a placeholder image or error message
      resolve(false);
    };
  })
};