import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useAxios } from '../../../../hooks/useAxios';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';

// if task is labeled as "In Review", perhaps we auto-show the table?
// if it's in progress, do we show the modal instructions? (text below)

// future: you may need to move the "Add to project" into history
// and in that case we'd copy from a specific one instead of the last one

import { SiteContext } from '../../../../context/SiteContext';
import { UserContext } from '../../../../context/UserContext';

import { plans } from '../../../../components/_products/sfo/plans';

import Loading from '../../../../components/system/Loading';
import Header  from '../../../../components/system/HeaderTask';
import Filters, { applyFilters } from '../../../mapping/plots/components/Filters';
import Offcanvas from 'react-bootstrap/Offcanvas';

import GisMap from '../../../../components/mapping/GisMap';
import PlotHistory from '../../../mapping/plots/History';
import PlotEdit from '../../../mapping/plots/components/PlotEdit';
import PlotNew  from '../../../mapping/plots/components/New';
import Review from './ApplicationReview';

import { emptyObj, isNumeric } from '../../../../utils/functions';
import { getPlotDisplay } from '../../../../utils/plots';
import { plotPhotos, typeQtys, newPlot as addNewPlot, rotatePlot, movePlots } from '../../../mapping/plots/utils/plots';

const View = (props) => {
  const params = useParams();
  const { activePhase } = props;
  // temporarily taking phaseOut and setting it in the props above
  // the updating wasn't happening in a timefly fashion...
  const { allLoaded, setAllLoaded, project, projTasks, phases, symbols, types, setTypes, typeVars, setTypeVars, planGroups, activePlan, setActivePlan, plots, setPlots, photos, setPhotos, publicStatuses, search, setSearch, filters, setFilters, sort, setSort } = props.context;
  const { config } = useContext(SiteContext);
  const { userDetails } = useContext(UserContext);
  
  const { serverCall, pending } = useAxios();
  const closePanel = () => {
    setNewPlot({});
    setActiveAppId();
    setActivePlot({});
    setPlotHistory([]);
  }

  const [task, setTask] = useState({});
  const [libraries, setLibraries] = useState([]);

  // lklklk this should be set within the project...?
  const [mapStart, setMapStart] = useState({ zoom: 20, center: [37.61797713168521, -122.3867189884186] });
  const planRef = useRef({});
  const [newPlot, setNewPlot] = useState({});
  const [activeAppId, setActiveAppId] = useState();
  const [activePlot, setActivePlot] = useState({});
  const [plotHistory, setPlotHistory] = useState([]);
  const [appIdPhotos, setAppIdPhotos] = useState([]);

  const [bounds, setBounds] = useState([]);

  // this is the toggle to have applyFilters show ALL including in other phases
  const showAll = true;

  const activePlots = useMemo(() => applyFilters(activePhase, activePlan, planGroups, plans, plots, filters, search, bounds, showAll, sort), [activePhase, activePlan, planGroups, plans, plots, filters, search, sort, bounds, showAll]);
  const activePhotos = useMemo(() => plotPhotos(photos, null, activePlots), [photos, activePlots]);
  const activeQtys  = useMemo(() => typeQtys(types, typeVars, activePlots), [types, typeVars, activePlots]);

  useEffect(() => {
    let found = projTasks.find(x => x.appId === params.taskId);
    if(!found) return; // lklklk
    setTask(found);
  }, [params.taskId, projTasks])

  useEffect(() => {
    let plan = plans.find(x => x.id === 2);
    if(!plan) return;
    planRef.current = plan;
    setActivePlan(plan);
  }, [setActivePlan])

  useEffect(() => {
    if(allLoaded || emptyObj(userDetails)) return;
    (async () => {
      let res = await serverCall({
        method: 'GET', url: `/mapping/plots/all`, eamsAuth0: true,
        headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId }
      });
      if(res.status!==200) return alert('Error getting all plots. Contact support.'); // lklklk
      if(res.data.libraries) setLibraries(res.data.libraries);
      if(res.data.types) setTypes(res.data.types);
      if(res.data.typeVars) setTypeVars(res.data.typeVars);
      if(res.data.photos) setPhotos(res.data.photos);

      // spin through to format the plot display and store within each individual plot
      let formatted = [];
      for (const plot of res.data.plots) {
        let newPlot = getPlotDisplay({ types: res.data.types, typeVars: res.data.typeVars, plot });
        formatted.push(newPlot);
      }
      setPlots(formatted);
      setAllLoaded(true);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLoaded, userDetails])

  useEffect(() => {
    if(emptyObj(activeAppId)) {
      setActivePlot({});
      setPlotHistory([]);
    } else {
      let found = plots.find(x => x.appId === activeAppId && x.projectId === project.id && x.phaseId === activePhase.id);
      (found) ? setActivePlot(found) : setActivePlot({});

      let history = plots.filter(x => x.status==='A' && x.appId === activeAppId && (x.projectId !== project.id || x.phaseId !== activePhase.id)).sort((a,b) => (b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0));
      setPlotHistory(history);

      let filtered = photos.filter(x => x.plotAppId === activeAppId);
      setAppIdPhotos(filtered);
    }
  }, [activeAppId, activePhase.id, project.id, plots, photos])

  const fromChild = async(data) => {
    const { type, value } = data;
    if(type==='plan change') {
      let plan = plans.find(x => x.appId === value);
      if(!plan) return;
      planRef.current = plan;
      setActivePlan(plan);

    } else if(type==='view plot') {
      setNewPlot({});
      setActiveAppId(value.appId);

    } else if(type==='add plot') {
      let nextPlot = Math.max(...plots.map(o => isNumeric(o.name) ? o.name : 0), 0) + 1;
      let plot = addNewPlot({ project, plans, types, planRef, activePhase, pieces: { latLng: value, nextPlot }, userDetails });
      setNewPlot(plot)

    } else if(type==='plots moved') {
      let updates = await movePlots({ setPlots, plots: value, userDetails });
      let res = await serverCall({ method: 'PATCH', data: updates, url: '/mapping/plots/bulk', eamsAuth0: true });
      if(res.status!==200) return alert('Error moving plot(s). Contact support.'); // lklklk

    } else if(type === 'rotated') {
      let update = await rotatePlot({ setPlots, plot: value, userDetails });
      let res = await serverCall({ method: 'PATCH', data: update, url: `/mapping/plots/${value.appId}`, eamsAuth0: true });
      if(res.status!==200) return alert('Error rotating plot. Contact support.'); // lklklk
    }
  }

  const addToProject = async () => {
    console.log('adding');
    let data = {...plotHistory[0]};
    delete data.id;
    delete data.updatedAt;
    delete data.updatedBy;
    data.projectId = project.id;
    data.phaseId = activePhase.id;
    data.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.createdBy = userDetails.id;
    data.status = 'A';

    let returned = await serverCall({ method: 'POST', data, url: `/mapping/plots`, eamsAuth0: true });
    if(returned.status!==200) return alert('Error getting adding to project. Contact support.'); // lklklk

    // format new plot and add to appropriate states
    // lklklk you may need to switch getPlotDisplay to be a promise, 
    // this isn't coming back correctly right now
    let formatted = getPlotDisplay({ types, typeVars, plot: returned.data });

    await setPlots((prev) => {
      let arr = [...prev];
      arr.push(formatted);
      return arr;
    })

    setActivePlot(formatted);
  }

  if(!allLoaded) return <Loading />;

  return (
    <Fragment>
      <Helmet>
        <title>Application | {config.title}</title>
        <style>{`html, body, div#root { height: 100%; }`}</style>
      </Helmet>
      <Header task={task} />
      { !params.mode && (
        <Fragment>
          <div className="position-fixed" style={{ top:64, right: 12, zIndex: 999 }}>
            <Filters project={project} activeQtys={activeQtys} libraries={libraries} planGroups={planGroups} plans={plans} types={types} search={search} setSearch={setSearch} filters={filters} setFilters={setFilters} sort={sort} setSort={setSort} />
          </div>
          <div className="position-fixed w-100 h-100" style={{top:0, left:0, marginTop:53}}>
            <GisMap zoom={mapStart.zoom} center={mapStart.center} draggable={true} plans={plans} plots={activePlots.filter(x => x.planId===activePlan.id)} publicStatuses={publicStatuses} setBounds={setBounds} toParent={fromChild} />
          </div>
        </Fragment>
      )}
      { params.mode === 'review' && (
        <Review task={task} project={project} activePhase={activePhase} types={types} plots={plots} setPlots={setPlots} photos={photos} setPhotos={setPhotos} />
      )}

      <Offcanvas show={!emptyObj(activePlot) || plotHistory.length > 0} onHide={closePanel} placement="end" scroll={true} keyboard={false} backdrop={false} className="shadow">
        { emptyObj(activePlot) && (
          <Fragment>
            <Offcanvas.Header className="bg-body" closeButton>
              <Offcanvas.Title>Viewing {plotHistory[0]?.name ? plotHistory[0].name : ''}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="bg-body pt-0">
            <button onClick={addToProject} className="btn btn-primary d-block mb-2" disabled={pending}>
              { !pending && 'Add to this Project' }
              {  pending && 'Please wait...' }
            </button>
            <PlotHistory plots={plotHistory} photos={appIdPhotos} libraries={libraries} types={types} />
            </Offcanvas.Body>
          </Fragment>
        )}

        { !emptyObj(activePlot) && (
          <PlotEdit project={project} phases={phases} activePhase={activePhase} plots={plots} activePhotos={activePhotos} setPhotos={setPhotos} publicStatuses={publicStatuses} obj={activePlot} types={types} typeVars={typeVars} symbols={symbols} setPlots={setPlots} handleClose={closePanel} toParent={fromChild} />
        )}
      </Offcanvas>

      <Offcanvas show={!emptyObj(newPlot)} onHide={closePanel} placement="end" scroll={true} backdrop={false} className="shadow">
        <Offcanvas.Header className="bg-body" closeButton>
          <Offcanvas.Title>Add Plot</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="bg-body pt-0">
          <PlotNew obj={newPlot} project={project} activePhase={activePhase} types={types} setPlots={setPlots} photos={photos} setPhotos={setPhotos} handleClose={closePanel} toParent={fromChild} />
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  )
}

export default View;


// xs modal instructions:
// Sign placement and mounting
// Select and place relevant sign types based on circulation and decision point planning.