import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { WorkerContext } from '../../../../context/WorkerContext';
import { useAxios } from '../../../../hooks/useAxios';

import { formatBytes } from '../../../../utils/functions';
import Dropzone from '../../../../components/system/dropzone/Dropzone';
import dayjs from 'dayjs'

import Modal from 'react-bootstrap/Modal';
import Edit from './Edit';

// future:
// if user is an admin for this project/task, show edit options
// also modal to update/swap out the file

// future: also... this gets the files every time the tab is clicked
// could we set something at the project level if they've already been downloaded once
// and if so, have a button to refresh if needed but otherwise don't get each time

const View = (props) => {
  const { parentType, parentId, fileTypes, files, setFiles, filesLog, setFilesLog, actions, thumbnails, convert, toParent } = props;
  const title = props.title ? props.title : 'Upload file(s)';
  const raster = useRef(convert);

  const { startWorker } = useContext(WorkerContext);
  const { serverCall } = useAxios();

  const [filtered, setFiltered] = useState([]);
  const [active, setActive] = useState({});
  const [activeLog, setActiveLog] = useState([]);
  const [show, setShow] = useState(false);
  const handleCancel = () => {
    setShow(false);
    setActive({});
    setActiveLog([]);
  }

  useEffect(() => {
    raster.current = convert;
  }, [convert])

  useEffect(() => {
    // currently sending null location
    if(!parentType || !parentId) return;
    (async () => {
      let res = await serverCall({ method: 'GET', url: `/system/files/${parentType}/${parentId}`, eamsAuth0: true });
      if(res.status!==200) return alert('Error getting files.'); // lklklk
      console.log('overriding setFiles/setFilesLog from within File Manager');
      setFiles(res.data.files);
      setFilesLog(res.data.filesLog);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentType, parentId])

  useEffect(() => {
    if(!fileTypes || fileTypes.length === 0) {
      let active = files.filter(x => x.status === 'A');
      setFiltered(active);
    } else {
      let filtered = files.filter(x => fileTypes.includes(x.type) && x.status === 'A');
      setFiltered(filtered);
    }
  }, [files, fileTypes])

  const clicked = (appId) => {
    console.log(appId);
    let found = files.find(x => x.appId === appId);
    if(!found) return;
    setActive(found);

    // then find the associated logs with this one
    let logs = filesLog.filter(x => x.parentId === found.id);
    setActiveLog(logs);
    setShow(true);
  }

  const fromChild = async (data) => {
    let files = [data.value];
    if(toParent) toParent(data);

    let res = await serverCall({ method: 'POST', data: files, url: `/system/files/${parentType}/${parentId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error saving uploaded files.'); // lklklk

    setFiles((prev) => [...prev, ...res.data.parents]);
    setFilesLog((prev) => [...prev, ...res.data.children]);

    let worker = { convert: raster.current, files: res.data.children };
    let conv = await serverCall({ method: 'POST', data: worker, url: '/services/cc-thumbs/start', eamsAuth0: true });
    for(const job of conv.data) {
      if(!job.id || !job.links.self) continue;
      startWorker({ type: 'cc-thumbs', pieces: { subtype: 'thumbnail', name: `${job.eams.originalName} processing`, filename: job.eams.source, url: job.links.self, value: job.eams, setFilesLog } });
    }

    if(toParent)
      toParent({ type: 'file saved', value: { parents: res.data.parents, children: res.data.children }})
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-8">
          <div className="mb-3 p-3 bg-white rounded">
            { filtered.length === 0 && <p className="p-3 mb-0">No files yet.</p> }
            { (filtered.length > 0 && thumbnails) && filtered.map((obj) => {
              let found = filesLog.find(x => x.id === obj.activeId);
              let url = found.urlThumb ? found.urlThumb : found.url;
              return (
                <div key={obj.appId} onClick={() => clicked(obj.appId)} className="d-inline-block border p-1 me-2 mb-2 pointer">
                  <figure className="overflow-hidden bg-light mb-2" style={{width:150,height:93}}>
                    <img src={url} alt={obj.name} className="img-fluid" style={{width:'100%',height:'100%',objectFit:'cover'}} loading="lazy" />
                  </figure>
                  <p className="small text-muted mb-0">{obj.name}</p>
                </div>
              )
            })}

            { (filtered.length > 0 && !thumbnails) && (
              <table className="table table-border table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Size</th>
                    <th>Created At</th>
                    <th className="text-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  { filtered.map((obj) => {
                    let found = filesLog.find(x => x.id === obj.activeId);
                    return (
                      <tr key={obj.appId}>
                        <td>{found?.url ? <a href={found.url} target="_blank" rel="noreferrer" download={found.source}>{obj.name}</a> : obj.name}</td>
                        <td>{found?.type}</td>
                        <td>{found?.size ? formatBytes(found.size) : ''}</td>
                        <td>{found?.createdAt ? dayjs(found.createdAt).format('YYYY-MM-DD HH:mma') : ''}</td>
                        <td className="text-end">
                          { actions?.map((act, idx) => (
                            <button key={`action-${idx}`} className="btn btn-sm mb-1 btn-outline-primary" onClick={() => { if(toParent) toParent({ type: act.type, value: obj})}}>{act.text}</button>  
                          ))}
                          <button className="btn btn-sm mb-1 ms-2 btn-outline-primary" onClick={() => clicked(obj.appId)}>Edit</button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="col-sm-4">
          <h5>{title}</h5>
          <Dropzone
            folder="uploads/files/"
            prefix="file-upload"
            toParent={fromChild}
          />
        </div>
      </div>

      <Modal show={show} onHide={handleCancel} size='lg' backdrop='static' keyboard={false} centered scrollable>
        <Edit convert={convert} obj={active} log={activeLog} setActiveLog={setActiveLog} handleCancel={handleCancel} setFiles={setFiles} setFilesLog={setFilesLog} />
      </Modal>
    </Fragment>
  )
}

export default View;
