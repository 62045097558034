
export const styles = {
  base: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  focused: {
    borderColor: '#2196f3'
  },
  accept: {
    borderColor: '#00e676'
  },
  reject: {
    borderColor: '#ff1744'
  }
}
