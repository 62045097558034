import { nanoid } from 'nanoid';

const init = ({ pieces, setWorkers, serverCall }) => {
  const worker = new Worker(new URL('./worker.js', import.meta.url));
  let newId = nanoid();

  worker.postMessage({ url: pieces.url, value: pieces.value });
  worker.onerror = (err) => err;
  worker.onmessage = async (e) => {
    const { status, value, thumbnail, converted } = e.data;

    setWorkers((prev) => {
      const updatedWorkers = {...prev};
      updatedWorkers[newId].data = e.data;
      return updatedWorkers;
    });

    if(status === 'finished') {
      console.log(value);
      console.log(thumbnail);
      console.log(converted);
      let update = {
        id: value.id,
        appId: value.appId,
        thumbnail: thumbnail.filename,
        raster: converted
      }

      let res = await serverCall({ method: 'PATCH', data: update, url: `/system/files/log/${value.appId}`, eamsAuth0: true });
      // lklklk should we send back urlThumb here?
      // and raster stuff too?
      pieces.setFilesLog((prev) => {
        let arr = [...prev];
        let idx = arr.findIndex(x => x.id === value.id);
        arr[idx].thumbnail = thumbnail.filename;
        return arr;
      });

      worker.terminate();
    }
  };
  
  setWorkers((prev) => ({ ...prev, 
    [newId]: { 
      worker, 
      type: 'thumbnails', 
      name: pieces.name,
      data: { status: 'starting', progress: 0 }
    }
  }));

}

export default init;