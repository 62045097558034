import React from 'react';
import parse from 'html-react-parser';

const View = (props) => {
  const { item } = props;
  if(!item.content) return null;
  return parse(item.content);
}

export default View;
