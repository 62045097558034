import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { HexColorPicker, HexColorInput } from "react-colorful";

import useClickOutside from '../../../../hooks/useClickOutside';

const View = ({ name, value, toParent }) => {
  const [color, setColor] = useState();
  const popover = useRef();

  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((s) => !s);

  const close = useCallback(() => {
    setOpen(false);
    if(toParent) toParent({ type: 'color-picker', name, value: color });
  }, [color, name, toParent]);
  useClickOutside(popover, close);

  useEffect(() => {
    setColor(value);
  }, [value])

  return (
    <div className="position-relative my-2">
      <div className="border rounded pointer mx-1" style={{backgroundColor: color, height:20}} onClick={toggleOpen} />
      { open && (
        <Fragment>
          <div ref={popover} className="position-absolute border rounded-3 bg-white p-2 shadow-sm" style={{top:22, left:0, zIndex: 500}}>
            <HexColorPicker color={color} onChange={setColor} />
            <label className="d-block mt-2">Hex Color:</label>
            <HexColorInput className="form-control" color={color} onChange={setColor} />
            <label className="d-block mt-2">Quick Picks:</label>
            <button className="btn btn-sm" onClick={() => setColor('transparent')}>Transparent</button>
          </div>
          <div className="position-fixed" style={{top:0, left: 0, width:'100vw',height:'100vh', zIndex: 499}}></div>
        </Fragment>
      )}
    </div>
  )
}

export default View;
