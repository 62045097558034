import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import Manager from './components/View';

// future:
// if user is an admin for this project/task, show edit options
// also modal to update/swap out the file

// future: also... this gets the files every time the tab is clicked
// could we set something at the project level if they've already been downloaded once
// and if so, have a button to refresh if needed but otherwise don't get each time

const View = (props) => {
  const { parentType, title, fileTypes, actions, thumbnails, convert } = props;
  const params = useParams();
  const { files, setFiles, filesLog, setFilesLog } = useOutletContext();

  return <Manager
      parentType={parentType}
      parentId={params.projectId}
      files={files}
      setFiles={setFiles}
      title={title}
      fileTypes={fileTypes}
      filesLog={filesLog}
      setFilesLog={setFilesLog}
      actions={actions}
      thumbnails={thumbnails}
      convert={convert}
    />
}

export default View;
