import { nanoid } from 'nanoid';
import dayjs from 'dayjs';

import { emptyObj, sumByTwoKeys } from '../../../../utils/functions';
import { getPlotDisplay } from '../../../../utils/plots';
import { organizeTypes } from '../../../../utils/types';

export const newPlot = ({ typeId, project, plans, types, typeVars, planRef, activePhase, pieces, userDetails }) => {
  // determine location for gis vs non
  let styling = {locatorX: pieces.left, locatorY: pieces.top};
  if(pieces.latLng) styling = { lat: pieces.latLng.lat, lng: pieces.latLng.lng, rotation: 0 };

  let plot = {
    eamsNew: true,
    appId: nanoid(),
    projectId: project.id,
    planId: planRef?.current?.id,
    phaseId: activePhase.id,
    name: String(pieces.nextPlot),
    installMode: pieces.installMode ? pieces.installMode : 'New',
    styling,
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    createdBy: userDetails.id,
    status: 'A'
  }

  if(typeId) plot.typeId = typeId;

  plot = getPlotDisplay({ project, plans, types, typeVars, plot });
  return plot;
}

export const newPlotTask = ({ projectId, plot, task, userDetails }) => {
  let styling = {};
  if(plot.piece==='locator') {
    styling.locatorX = plot.left;
    styling.locatorY = plot.top;
  } else {
    styling.labelX = plot.left;
    styling.labelY = plot.top;
  }     

  let plotTask = {
    appId: nanoid(),
    projectId,
    phaseId: plot.phaseId,
    taskId: task?.id,
    plotAppId: plot.appId,
    styling,
    publicStatus: null,
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    createdBy: userDetails.id,
    status: 'A'
  }

  return plotTask;
}

export const copyPlot = ({ project, plans, types, typeVars, planRef, activePhase, ogPlot, userDetails }) => {
  let plot = {...ogPlot};
  plot.styling = {...ogPlot.styling};

  // delete conflicting fields
  delete plot.id;
  delete plot.appId;
  delete plot.updatedAt;
  delete plot.updatedBy;

  // generate new values
  plot.appId = nanoid();
  plot.planId = planRef.current.id;
  plot.phaseId = activePhase.id;
  plot.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
  plot.createdBy = userDetails.id;
  plot.status = 'A';

  // if we are pasting to the same plan, we need to offset the coords
  if(plot.planId === ogPlot.planId) {
    plot.styling.locatorX = plot.styling.locatorX + 25;
    plot.styling.locatorY = plot.styling.locatorY + 25;
    if(plot.styling.labelX) plot.styling.labelX = plot.styling.labelX + 25;
    if(plot.styling.labelY) plot.styling.labelY = plot.styling.labelY + 25;
  }

  // reformat plot display in case the plan has changed
  plot = getPlotDisplay({ project, plans, types, typeVars, plot });
  return plot;
}

export const addPlot = ({ plotsRef, setPlots, plot }) => {
  return new Promise(async(resolve) => {
    await setPlots((prev) => {
      let arr = [...prev];
      arr.push(plot);
      plotsRef = arr;
      return arr;
    })
    resolve();
  })
};

export const replacePlot = ({ plotsRef, setPlots, plot }) => {
  return new Promise(async(resolve) => {
    await setPlots((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId===plot.appId && x.phaseId===plot.phaseId);
      arr[idx] = plot;
      plotsRef = arr;
      return arr;
    })
    resolve();
  })
};

export const updatePlot = ({ project, plans, types, typeVars, setPlots, plot }) => {
  return new Promise(async(resolve) => {
    let obj;
    await setPlots((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId===plot.appId && x.phaseId===plot.phaseId);
      for (const [key, value] of Object.entries(plot)) {
        arr[idx][key] = value;
      }
      arr[idx] = getPlotDisplay({ project, plans, types, typeVars, plot: arr[idx] });
      obj = arr[idx];
      return arr;
    })
    resolve(obj);
  })
};

export const rotatePlot = ({ setPlots, plot, userDetails }) => {
  return new Promise(async(resolve) => {
    let styling;
    await setPlots((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId===plot.appId && x.phaseId===plot.phaseId);
      if(plot.rotation===0)
        delete arr[idx].styling?.rotation;
      else
        arr[idx].styling = {...arr[idx].styling, rotation: plot.rotation};

      styling = arr[idx].styling;
      return arr;
    })
    resolve({
      appId: plot.appId,
      phaseId: plot.phaseId,
      styling,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedBy: userDetails.id
    });
  })
};

export const updatePoints = ({ setPlots, plot, userDetails }) => {
  return new Promise(async(resolve) => {
    let styling;
    await setPlots((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId===plot.appId && x.phaseId===plot.phaseId);
      styling = {...arr[idx].styling};
      styling.points = plot.points;
      if(plot.piece==='locator') {
        styling.locatorX = plot.left;
        styling.locatorY = plot.top;
      } else {
        styling.labelX = plot.left;
        styling.labelY = plot.top;
      }      
      arr[idx].styling = styling;
      styling = arr[idx].styling;
      return arr;
    })
    resolve({
      appId: plot.appId,
      phaseId: plot.phaseId,
      styling,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedBy: userDetails.id
    });
  })
};

export const movePlots = ({ plots, setPlots, userDetails }) => {
  return new Promise(async(resolve) => {
    let updates = [];
    await setPlots((prev) => {
      let arr = [...prev];
      for(const plot of plots) {
        let found = arr.find(x => x.appId===plot.appId && x.phaseId===plot.phaseId);
        if(!found) continue;
        if(plot.lat || plot.lng) {
          found.styling.lat = plot.lat;
          found.styling.lng = plot.lng;
        } else if(plot.piece==='locator') {
          found.styling.locatorX = plot.left;
          found.styling.locatorY = plot.top;
        } else {
          found.styling.labelX = plot.left;
          found.styling.labelY = plot.top;
        }
        // check if this update already exists, otherwise add
        let idx = updates.findIndex(x => x.appId===found.appId && x.phaseId===found.phaseId);
        if(idx > -1)
          updates[idx].styling = found.styling;
        else
          updates.push({ id: found.id, appId: found.appId, phaseId: found.phaseId, styling: found.styling });
      }
      return arr;
    })

    resolve({
      updates,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedBy: userDetails.id
    });
  })
};

export const movePlotTasks = ({ projectId, task, plots, setPlotTasks, userDetails }) => {
  return new Promise(async(resolve) => {
    let updates = [];
    await setPlotTasks((prev) => {
      let arr = [...prev];
      for(const plot of plots) {
        let update = {};
        let idx = arr.findIndex(x => x.plotAppId===plot.appId && x.taskId===task.id);
        if(idx > -1) {
          let styles = {...arr[idx].styling};

          if(plot.lat || plot.lng) {
            styles.lat = plot.lat;
            styles.lng = plot.lng;
          } else if(plot.piece==='locator') {
            styles.locatorX = plot.left;
            styles.locatorY = plot.top;
          } else {
            styles.labelX = plot.left;
            styles.labelY = plot.top;
          }

          arr[idx].styling = {...styles};
          update = { styling: styles, plotAppId: plot.appId, taskId: task.id };

        } else {
          // it doesn't exist, make a new one
          update = newPlotTask({ projectId, plot, task, userDetails });
          arr.push(update);
        }

        // check if this update already exists, otherwise add
        let updIdx = updates.findIndex(x => x.plotAppId===plot.appId && x.taskId===task.id);
        if(updIdx > -1)
          updates[updIdx].styling = {...updates[updIdx].styling, ...update.styling};
        else
          updates.push(update);
      }
      return arr;
    })

    resolve({
      updates,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedBy: userDetails.id
    });
  })
};

export const cutPlots = ({ plotIds, setPlots, phaseId, planId, userDetails }) => {
  return new Promise(async(resolve) => {
    let updates = [];
    let existing = [];
    await setPlots((prev) => {
      let arr = [...prev];
      for(const id of plotIds) {
        let found = arr.find(x => x.id===id);
        if(!found) continue;

        // need to check if this appId already exists in this floor/phase
        let twin = arr.find(x => x.appId===found.appId && x.phaseId===phaseId && x.planId===planId);
        if(twin) {
          // a twin exists, don't add to updates
          existing.push(twin);
          continue;
        }

        found.planId = planId;
        found.phaseId = phaseId;

        // check if this update already exists, otherwise add
        let idx = updates.findIndex(x => x.id===found.id);
        if(idx > -1) {
          updates[idx].planId = planId;
          updates[idx].phaseId = phaseId;
        } else {
          updates.push({ id, phaseId, planId });
        } 
      }
      return arr;
    })

    resolve({
      existing,
      updates,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedBy: userDetails.id
    });
  })
};

export const toggleStatus = ({ setPlots, plotIds, userDetails, status }) => {
  return new Promise(async(resolve) => {
    let updates = []
    await setPlots((prev) => {
      let arr = [...prev];
      for(const id of plotIds) {
        let found = arr.find(x => x.id === id);
        if(found) found.status = status;
        updates.push({ id: found.id, appId: found.appId, phaseId: found.phaseId, status });
      }
      return arr;
    })

    resolve({
      updates,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedBy: userDetails.id
    });
  })
};

export const reorderPlots = ({ project, plans, types, typeVars, setPlots, changed, userDetails }) => {
  return new Promise(async(resolve) => {
    let updates = [];
    await setPlots((prev) => {
      let arr = [...prev];
      for(const update of changed) {
        let idx = arr.findIndex(x => x.id===update.id);
        if(idx > -1) {
          arr[idx].name = update.name;
          arr[idx] = getPlotDisplay({ project, plans, types, typeVars, plot: arr[idx] });
          updates.push({ id: update.id, appId: arr[idx].appId, phaseId: arr[idx].phaseId, name: update.name });
        }
      }
      return arr;
    })

    resolve({
      updates,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedBy: userDetails.id
    });
  })
};

export const bulkReorderPlots = ({ project, libraries, plans, types, typeVars, activePlots, activeQtys, setPlots, reorder, userDetails }) => {
  return new Promise(async(resolve) => {
    let updates = [];

    await setPlots((prev) => {
      let arr = [...prev];
      let sortedActive = [...activePlots].filter(x => x.planId === reorder.planId);

      if(reorder.sort==='type') {
        let typeIds = {};
        let sortedTypes = organizeTypes({ project, libraries, types, activeQtys, excludeZero: true });

        let idx = 0;
        for(const category of sortedTypes) {
          for(const type of category.types) {
            typeIds[String(type.value)] = idx;
            idx++;
          }
        }

        sortedActive.sort( function(a, b) {
          return (typeIds[a.typeId] - typeIds[b.typeId]) || ((a || {}).name || '').localeCompare((b || {}).name || '', undefined, { numeric: true, sensitivity: 'base' });
        });
      }

      let type;
      let n = reorder.start;
      for (const plot of sortedActive) {
        if(reorder.sort==='type' && type!==plot.typeId)
          n = reorder.start;

        let idx = arr.findIndex(x => x.id === plot.id);
        arr[idx].name = String(n);
        arr[idx] = getPlotDisplay({ project, plans, types, typeVars, plot: arr[idx] });
        updates.push({ id: arr[idx].id, appId: arr[idx].appId, phaseId: arr[idx].phaseId, name: String(n) });
        
        n = n+reorder.skip+1;
        type = plot.typeId;
      }
      return arr;
    })

    resolve({
      updates,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedBy: userDetails.id
    });
  })
};

export const plotPhotos = (photos, phase, plots) => {
  // this function filters by status, plot appIds, and optional phase
  if(plots.length === 0) return [];

  let filtered = [];
  let plotAppIds = plots.map(x => x.appId);
  if(!emptyObj(phase)) {
    // if a phase was sent, filter with that
    filtered = photos.filter(x => x.status==='A' && plotAppIds.includes(x.plotAppId) && x.phaseId===phase.id);
  } else {
    // if a phase wasn't sent, just sent active ones back
    filtered = photos.filter(x => x.status==='A' && plotAppIds.includes(x.plotAppId));
  }

  // let orphans = photos.filter(x => x.status==='A' && !plotAppIds.includes(x.plotAppId));
  // let orphanPlans = [...new Set(orphans.map(x => x.planId))];

  // manually remove duplicates...?
  filtered.filter((value, index, self) => self.findIndex(v => v.name === value.name) === index);

  filtered.sort((a,b) => (b.sortOrder > a.sortOrder) ? 1 : ((a.sortOrder > b.sortOrder) ? -1 : 0) || (b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0));
  return filtered;
}

export const typeQtys = (types, typeVars, plots) => {
  if(plots.length === 0) return [];

  // const start = performance.now();
  let summary = [];
  const qtys = sumByTwoKeys(plots,'typeId','variationId');

  for (const qty of qtys) {
    if(!qty.typeId) {
      qty.code = 'TBD';
      qty.description = 'TBD';
      qty.fillColor = '#ccc';
      qty.textColor = '#000';
      qty.labelId = 1;
    } else {
      let type = types.find(x => x.id === Number(qty.typeId));
      if(!type) {
        qty.code = 'TBD';
        qty.description = 'Missing type';
        qty.fillColor = '#f00';
        qty.textColor = '#fff';
        qty.labelId = 1;
        qty.textId = 1;
      } else {
        qty.code = type.code;
        qty.description = type.description;
        qty.fillColor = type.styling.fillColor ? type.styling.fillColor : '#ccc';
        qty.textColor = type.styling.textFill;
        qty.labelId = type.styling.labelId;
        qty.textId = type.styling.textId;

        if(type.multiplier > 1) qty.quantity = Number(qty.quantity) * Number(type.multiplier);
      }

      // check for variation override
      if(qty.variationId) {
        let variation = typeVars.find(x => x.id === qty.variationId);
        if(variation) {
          if(variation.code) qty.code = qty.code + variation.code;
          if(variation.description) qty.description = qty.description + ' - ' + variation.description;
          if(variation.styling.fillColor) qty.fillColor = variation.styling.fillColor;
          if(variation.styling.textColor) qty.textColor = variation.styling.textColor;
          if(variation.styling.labelId) qty.labelId = variation.styling.labelId;
          if(variation.styling.textId) qty.textId = variation.styling.textId;
        }
      }
    }
    summary.push(qty);
  }

  // sort by code, then description
  summary.sort((a, b)=> (
    a.code?.localeCompare(b.code, 'en', { numeric: true }) ||
    a.description?.localeCompare(b.description, 'en', { numeric: true })
  ));

  // then get a total and append to the bottom
  let total = summary.reduce((total, obj) => obj.quantity + total,0);
  summary.push({ typeId: 'total', quantity: total});

  // const duration = performance.now() - start;
  // console.log(duration);
  return summary;
}

const compareTypes = (typeA, typeB, typeVarLookup) => {
  // Handle possible null or empty values
  const typeACode = typeA.code || '';
  const typeBCode = typeB.code || '';
  
  if (typeACode !== typeBCode) {
    return typeACode.localeCompare(typeBCode, undefined, { numeric: true, sensitivity: 'base' });
  }

  // Type var comparison logic
  const typeVarACode = typeVarLookup[typeA.id] ? typeVarLookup[typeA.id].code || '' : '';
  const typeVarBCode = typeVarLookup[typeB.id] ? typeVarLookup[typeB.id].code || '' : '';

  if (typeVarACode !== typeVarBCode) {
    return typeVarACode.localeCompare(typeVarBCode, undefined, { numeric: true, sensitivity: 'base' });
  }

  // Fallback to description comparison
  const typeADesc = typeA.description || '';
  const typeBDesc = typeB.description || '';
  return typeADesc.localeCompare(typeBDesc);
};

const comparePlots = (a, b, sortByType, planLookup, planGroupLookup, typeLookup, typeVarLookup) => {
  if (sortByType) {
    // Handle cases where typeId is null or empty
    if (!a.typeId && b.typeId) return -1;  // a comes first if its typeId is null/empty but b's isn't
    if (a.typeId && !b.typeId) return 1;   // b comes first if its typeId is null/empty but a's isn't
  
    const typeA = a.typeId ? typeLookup[a.typeId] : null;
    const typeB = b.typeId ? typeLookup[b.typeId] : null;
  
    // If both plots have a typeId, compare them
    if (typeA && typeB) {
      const typeComparison = compareTypes(typeA, typeB, typeVarLookup);
      if (typeComparison !== 0) {
        return typeComparison;
      }
    }
  }  

  const planA = planLookup[a.planId];
  const planB = planLookup[b.planId];

  // If one plot is associated with a planGroup and the other isn't
  if (planA.planGroupId && !planB.planGroupId) return -1;
  if (!planA.planGroupId && planB.planGroupId) return 1;

  // If both plots are associated with planGroups
  if (planA.planGroupId && planB.planGroupId) {
    const groupA = planGroupLookup[planA.planGroupId];
    const groupB = planGroupLookup[planB.planGroupId];
    if (groupA.name !== groupB.name) {
      return groupA.name.localeCompare(groupB.name);
    }
    if (planA.sortOrder !== planB.sortOrder) {
      return planA.sortOrder - planB.sortOrder;
    }
  }

  // If both plots are not associated with planGroups
  if (!planA.planGroupId && !planB.planGroupId) {
    if (planA.sortOrder !== planB.sortOrder) {
      return planA.sortOrder - planB.sortOrder;
    }
  }

  // Lastly, compare by plot name
  const aName = a.name || ''; 
  const bName = b.name || '';
  
  return aName.localeCompare(bName, undefined, {
    numeric: true,
    sensitivity: 'base'
  });
};

export const sortPlots = (plots, plans, planGroups, types, typeVars, sortByType = false) => {
  const planLookup = plans.reduce((acc, plan) => ({ ...acc, [plan.id]: plan }), {});
  const planGroupLookup = planGroups.reduce((acc, group) => ({ ...acc, [group.id]: group }), {});
  const typeLookup = types ? types.reduce((acc, type) => ({ ...acc, [type.id]: type }), {}) : {};
  const typeVarLookup = typeVars ? typeVars.reduce((acc, typeVar) => ({ ...acc, [typeVar.id]: typeVar }), {}) : {};
  return plots.sort((a, b) => comparePlots(a, b, sortByType, planLookup, planGroupLookup, typeLookup, typeVarLookup));
};

// const sortedPlots = sortPlots(plots, plans, planGroups, types, typeVars, true); // if you want to sort by type
// const sortedPlots = sortPlots(plots, plans, planGroups); // if you don't want to sort by type


