const makeValue = ({ plot, project, phase, showExt }) => {
  return new Promise(async(resolve) => {
    let filename = `${plot.display.textLabel} - ${project.name}`;
    if(plot.phaseName !== 'Phase 1') filename = filename + ` ${phase.name}`;
    if(plot.planGroup) filename = filename + ` - ${plot.planGroup.name}`;
    filename = filename + ` - ${plot.plan.name}`;
    filename = filename + ` Loc ${String(plot.name).padStart(3, '0')}`;
    filename = filename + `-${plot.id}`;
    if(showExt) filename = filename+'.svg';
    resolve(filename);
  })
};

export default makeValue;