import React, { Fragment } from 'react';
import dayjs from 'dayjs'

import GeneratedDisplay from '../../../components/system/GeneratedDisplay';

const View = (props) => {
  const { photos, types, plots } = props; // also receiving libraries

  return (
    <Fragment>
      { plots.length === 0 && <p>No earlier history.</p>}
      { plots.map((obj) => {
        let found = types.find(x => x.id === obj.typeId);
        if(!found) found = { description: 'TBD' };
        let schema = found?.schema?.length > 0 ? found.schema : [];
        let library = found.libraryId===2 ? 'Legacy' : 'WGS';
        let plotPics = photos.filter(x => x.phaseId === obj.phaseId && x.status === 'A');
        let date = obj.updatedAt ? obj.updatedAt : obj.createdAt;
        date = dayjs(date).format('MMM DD, YYYY'); // h:mma

        return (
          <div key={obj.id+'-'+obj.appId} className="card mb-2">
            <div className="card-body">
              <h5 className="card-title">{library}: {found.description}</h5>
              <h6 className="card-subtitle mb-2 text-muted">Updated {date}</h6>
              { plotPics && (
                <div className="horizontal-scroll mb-3">
                  { plotPics.map(img => (
                    <a key={img.appId} href={img.url} target="_blank" rel="noreferrer">
                      <img src={img.url} className="horizontal-thumb border" alt="Site Photo" crossOrigin="anonymous" />
                    </a>
                  ))}
                </div>
              )}
              
              <GeneratedDisplay schema={schema} data={obj} tag="h6" />
              {/* <a href="#" className="card-link">Card link</a>
              <a href="#" className="card-link">Another link</a> */}
            </div>
          </div>
        )
      })}
    </Fragment>
  )
}

export default View;
