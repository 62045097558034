export const resizeHandler = ({ wrapperRef, canvas, bkgd }) => {
  if(!wrapperRef.current || !canvas || bkgd?.length!==4) return;

  let width = wrapperRef.current.offsetWidth;
  let height = wrapperRef.current.offsetHeight;      
  let scale = 1;
  let offsetX = 0;
  let offsetY = 0;

  if (bkgd) {
    const widthScale = width / bkgd[0];
    const heightScale = height / bkgd[1];
    scale = Math.min(widthScale, heightScale);
    offsetX = (bkgd[2] * scale)/2;
    offsetY = (bkgd[3] * scale)/2;
  }
  
  canvas.setWidth(bkgd ? bkgd[0] * scale : width);
  canvas.setHeight(bkgd ? bkgd[1] * scale : height);
  canvas.setZoom(scale);

  // let viewport = localStorage.getItem('viewport');
  // if(viewport && map) {
  //   viewport = JSON.parse(viewport);
  //   if(map.projectId === viewport.projectId)
  //     canvas.setViewportTransform(viewport.coords);
  // } else {
    canvas.setViewportTransform([scale,0,0,scale,offsetX,offsetY]);
  // }

  // canvas.renderAll();
}