import { emptyObj, getDifference } from '../../../../utils/functions';
import { textLabelIds, importComboIds } from '../../../../utils/plots';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs'

let typeHeaders = [
  { header: 'Category', column: 'category', ifEmpty: 'New Category' },
  { header: 'Type Code', column: 'code'},
  { header: 'Type Description', column: 'description'},
  { header: 'Fill Color', column: 'styling', child: 'fillColor' },
  { header: 'Text Label', map: 'text label' },
  { header: 'Display Type', map: 'display type' },
  { header: 'Width', column: 'udf1' },
  { header: 'Height', column: 'udf2' },
  { header: 'Depth', column: 'udf3' },
  { header: 'Overhead', column: 'udf4' },
  { header: 'Weight', column: 'udf5' },
  { header: 'Area (sq ft)', column: 'udf6' },
  { header: 'General Notes', column: 'udf9' },
  { header: 'Manufacture Notes', column: 'udf10' },
  { header: 'Installation Notes', column: 'udf11' },
  { header: 'Unit Cost', column: 'udf7' },
  { header: 'Unit Price', column: 'udf8' },
]

export const checkHeaders = (arr) => {
  let typeTemplate = JSON.stringify(typeHeaders.map(x => x.header));
  let imported = JSON.stringify(arr);

  if(typeTemplate===imported) {
    return 'type';
  } else {
    return 'error';
  }
}

// export const checkTypes = ({ arr, project }) => {
//   return new Promise(async(resolve) => {
//     console.clear();
//     let newArr = [...arr];
//     let newCategories = [];

//     console.log(arr);
//     console.log(project);
//     for(const obj of arr) {
//       // check for existing category
//       console.log(obj.category);
//       let idx = project.library.findIndex(x => x.name===obj.category);
//       if(idx < 0) {
//         // this is a new category
//         newCategories.push(obj.category);

//       } else {

//       }
//       console.log(idx);
//     }
//     resolve('yooo')
//   })
// };

export const reviewTypes = ({ sheet, parentType, parentId, library, userDetails }) => {
  return new Promise(async(resolve) => {
    let objs = [];
    for(const row of sheet.rows) {
      let obj = { styling: {} };
      for (const [idx, col] of row.entries()) {
        let header = typeHeaders[idx];
        if(!header?.ifEmpty && !col) continue;

        if(header.ifEmpty) {
          obj[header.column] = col ? col : header.ifEmpty;

        } else if(header.map==='display type') {
          let found = importComboIds.find(x => x.label===col);
          if(found && found.label!=='Circle') obj.styling = {...obj.styling, ...found.value};

        } else if(header.map==='text label') {
          let found = textLabelIds.find(x => x.label===col);
          if(found && found.value!==1) obj.styling.textId = found.value;

        } else if(header.column && header.child) {  
          obj[header.column][header.child] = col;
        } else if(header.column) {
          obj[header.column] = col;
        }
      }

      // add other required columns for types
      obj.appId = nanoid();
      if(parentType==='project') obj.projectId = parentId;
      if(parentType==='global') obj.libraryId = parentId;
      obj.schema = [];
      obj.createdBy = userDetails.id;
      obj.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
      if(!emptyObj(obj)) objs.push(obj);
    }

    // then check to see if the categories already exist
    let parentCats = library?.map(x => x.name);
    let typeCats = objs.map(x => x.category);
    typeCats = [...new Set(typeCats)];
    let diff = getDifference(typeCats, parentCats);
    diff.sort();

    // then sort by categories and code
    objs.sort((a, b)=> a.category.localeCompare(b.category, 'en', { numeric: true }) || a.code.localeCompare(b.code, 'en', { numeric: true }))

    resolve({
      objs,
      newCategories: diff
    });
  })
};