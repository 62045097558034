import { fabric } from 'fabric';

const shape = ({ fillColor, scale, rotation, details }) => {

  let rect = new fabric.Rect({
    width: 14*scale,
    height: 6*scale,
    rx: 3*scale,
    fill: fillColor,
    stroke: fillColor,
  });

  let c = new fabric.Group([ rect ], {
    ...details,
    hasControls: true,
    allowRotation: true,
    angle: rotation,
    cornerSize: 24,
    borderColor: 'red',
    cornerColor: 'red'
  });

  c.setControlsVisibility({
    bl: false,
    br: false,
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    tl: false,
    tr: false,
    mtr: true
  });

  return c;
}

export default shape;