import axios from 'axios';

// https://github.com/axios/axios#request-config
// other onUploadProgress events: loaded, total, progress, bytes, estimated, rate, upload = true

export const uploadFile = ({ appId, url, file, setUploads }) => {
  return new Promise(async(resolve) => {
    axios.put(url, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: async (e) => {
        const progress = Math.round((e.loaded * 100) / e.total);

        setUploads((prev) => {
          const updated = { ...prev };
          updated[appId].progress = progress;
          return updated;
        });

        if(e.loaded===e.total)
          resolve({ status: 'complete', appId });
      }
    }).then(res => {
      if(res.status !== 200) {
        // lklklk error
        alert('Error uploading file. Contact support.')
        resolve({ status: 'error' })
      }
    }).catch(error => {
      console.log(error)
      // lklklk error
      alert('Error uploading file. Contact support.')
      resolve({ status: 'error', error })
    });
  })
};
