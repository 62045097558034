import { fabric } from 'fabric';

const shape = ({ fillColor, scale, rotation, details }) => {

  let triangle = new fabric.Triangle({
    top: 4*scale,
    width: 10*scale,
    height: 5*scale,
    fill: fillColor,
    stroke: fillColor,
  });
  
  let rect = new fabric.Rect({
    width: 12*scale,
    height: 3*scale,
    rx: 1*scale,
    fill: fillColor,
    stroke: fillColor,
  });

  let hiddenRect = new fabric.Rect({
    top: 8*scale,
    width: 4*scale,
    height: 6*scale,
    rx: 1*scale,
    fill: 'rgba(255,255,255,0)',
    stroke: 'rgba(255,255,255,0)'
  });

  let c = new fabric.Group([ hiddenRect, triangle, rect ], {
    ...details,
    hasControls: true,
    allowRotation: true,
    angle: rotation,
    cornerSize: 24,
    borderColor: 'red',
    cornerColor: 'red'
  });

  c.setControlsVisibility({
    bl: false,
    br: false,
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    tl: false,
    tr: false,
    mtr: true
  });

  return c;
}

export default shape;