import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../hooks/useAxios';
import { SiteContext } from '../../../context/SiteContext';
import { UserContext } from '../../../context/UserContext';

// multiple forms:
// https://stackoverflow.com/questions/60276510/how-to-make-react-hook-form-work-with-multiple-forms-in-one-page


// plot-edit in schema should always be default, that's like name/Id etc.
// but also system specific

// then the type schema with setup Id in this case would also be in the details tab
// these would be the permanent/setup information




import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Generated  from '../../../components/system/Generated';
// import GeneratedDisplay from '../../../components/system/GeneratedDisplay';
import ToggleStatusBtn from '../../../components/buttons/ToggleStatus';
// import EmailError from '../system/EmailError';

import dayjs from 'dayjs'
import { nanoid } from 'nanoid';
import { emptyObj } from '../../../utils/functions';
import { findSchema, formObj, removeArrs } from '../../../utils/schemas';
import { getPlotDisplay } from '../../../utils/plots';

import { photoData } from '../utils/images';
import { Camera, Images } from 'react-bootstrap-icons'

// LKLKLK DON'T FORGET THE RECALL FUNCTION!!!!
// ArrowCounterclockwise


// also... NEED TO CHECK IF ANYTHING HAS CHANGED
// if they haven't, then don't need to mark dirty...?


// future: tap on photo to enlarge (maybe zoom?) and also delete, add notes etc
// also maybe a synced icon?

// also need to handle the server photos, and possibly past tasks??

// consider adding a task option where we can do details/info in tabs or stacked?

import db from '../../../utils/dexie-provider.js'
// import { useLiveQuery } from 'dexie-react-hooks'

// keeping these as the required defaults that get sent with
// a new plot on mobile. You don't need this on desktop bc
// you are just adding a plot and then opening to edit;
// here we need to save the defaults somehow...
const defaults = [
  { id: 'default1', type: 'col-12', col: [
    { id: 'default2', type: 'hidden', field: 'eamsNew' },
    { id: 'default5', type: 'hidden', field: 'display' },
    { id: 'default6', type: 'hidden', field: 'projectId' },
    { id: 'default7', type: 'hidden', field: 'planId' },
    { id: 'default8', type: 'hidden', field: 'styling' },
    { id: 'default9', type: 'hidden', field: 'status' },
  ]},
]

const ptDefaults = [
  { id: 'ptDefault1', type: 'col-12', col: [
    { id: 'ptDefault2', type: 'hidden', field: 'ptNew' },
    { id: 'ptDefault3', type: 'hidden', field: 'appId' },
    { id: 'ptDefault4', type: 'hidden', field: 'projectId' },
    { id: 'ptDefault5', type: 'hidden', field: 'phaseId' },
    { id: 'ptDefault6', type: 'hidden', field: 'planId' },
    { id: 'ptDefault7', type: 'hidden', field: 'taskId' },
    { id: 'ptDefault8', type: 'hidden', field: 'plotAppId' },
    { id: 'ptDefault9', type: 'hidden', field: 'status' },
  ]},
]

const View = (props) => {
  const { userDetails, schemas } = useContext(UserContext);
  const { online, config } = useContext(SiteContext);
  // const { startWorker } = useContext(WorkerContext);
  // const { getAccessTokenSilently } = useAuth0();

  const { obj, task, checklist, typeLists, project, activePhase, activePlan, plans, symbols, types, typeVars, setPlots, publicStatuses, viewServer, handleClose } = props;
  const formText = obj?.eamsNew? { submit: `Add`, pending: 'Adding', success: 'Added!', cancel: 'Cancel' } : { submit: `Update`, pending: 'Updating', success: 'Updated!', cancel: 'Cancel' };
  const errorCode = '1987';

  const [border, setBorder] = useState('#ccc');
  const [plot, setPlot] = useState({});
  const [photos, setPhotos] = useState([]);
  const [serverPhotos, setServerPhotos] = useState([]);

  // plotSchema are the default system fields, like type/qty etc
  // typeSchema is the message A/B etc  override if any exists
  // listSchema is the type + checklist join, not shown in this view
  const [plotSchema, setPlotSchema] = useState([]); // default system fields from plot-edit + config
  const [typeSchema, setTypeSchema] = useState([]); // permanent plot data, always visible in desktop
  const [listSchema, setListSchema] = useState([]); // varies by checklist + type combo, stored in plotTasks
  const [actions, setActions] = useState([]);

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue, getValues } = useForm({
    defaultValues: useMemo(() => formObj(plotSchema), [plotSchema])
  });

  // are we getting rid of this...?
  const { control: control2, register: register2, formState: { errors: errors2 }, reset: reset2, watch: watch2, setValue: setValue2, getValues: getValues2 } = useForm({
    defaultValues: useMemo(() => formObj(listSchema), [listSchema])
  });
  const typeId = watch('typeId');
  const toggleStatus = watch('status');

  useEffect(() => {
    if(emptyObj(obj)) return;
    let plot = {...obj};
    let plotData = getValues();
    if(!emptyObj(plotData)) {
      plot = removeArrs(plotData);
    }

    plot = getPlotDisplay({ project, plans, types, typeVars, plot });
    setPlot(plot);
  }, [obj, project, plans, types, typeVars, getValues])

  useEffect(() => {
    let found = schemas.find(x => x.name==='plot-actions');
    if(found) setActions(found.schema);
    let schema = [];
    found = schemas.filter(x => x.name==='plot-edit');
    found = findSchema(found, project); // this one could have multiple schemas
    if(found?.schema) schema = found.schema;
    setPlotSchema(schema);
  }, [schemas, project])

  useEffect(() => {
    if(emptyObj(checklist) || emptyObj(plot)) return;

    // first determine the default plotSchema for new/editing
    let combo = [...plotSchema];
    if(plot.eamsNew)
      combo = [...plotSchema, ...defaults];

    // add type schema if we can view or edit details
    if(checklist.config?.details)
      combo = [...combo, ...typeSchema];

    let filled = formObj(combo, plot);
    reset(filled);

    (async () => {
      let objTask = await db.plotTasks.where({ taskId: task.id, plotAppId: plot.appId, }).first();
      if(!objTask) {
        objTask = {
          ptNew: true,
          appId: nanoid(),
          projectId: plot.projectId,
          phaseId: plot.phaseId,
          planId: plot.planId,
          taskId: task.id,
          plotAppId: plot.appId,
          status: 'A'
        };
      }

      // manually combining the plotTask defaults since we don't have a config for that now
      // to make consisten we could add that in to emulate the hidden defaults (plot-edit)?
      let combo = [...listSchema, ...ptDefaults];
      let filled = formObj(combo, objTask);
      reset2(filled);
    })();

  }, [checklist, plotSchema, typeSchema, listSchema, task?.id, plot, reset, reset2])

  useEffect(() => {
    let newBorder = '#ccc';
    let newTypeSchema = [];

    // find the type, check if it has its own schema
    let type = types.find(x => x.id === typeId);
    if(type) {
      newBorder = type.styling.fillColor;
      newTypeSchema = type.schema;
    }

    // no type detail schema, look for default
    if(newTypeSchema.length===0 && 'schemaIds' in config && 'plot-message' in config.schemaIds) {
      let schemaId = config.schemaIds['plot-message'];
      let found = schemas.find(x => x.id===schemaId);
      if(found?.schema) newTypeSchema = found.schema;
    }

    setBorder(newBorder);
    setTypeSchema(newTypeSchema);
  }, [config, schemas, typeId, types])

  useEffect(() => {
    if(emptyObj(checklist)) return;
    if(!typeId) return setListSchema([]);
    let newListSchema = [];

    // check if a typeList combo exists for this typeId/checklistId
    let typeList = typeLists.find(x => x.typeId===typeId && x.checklistId===checklist.id);
    if(typeList) newListSchema = typeList.schema;

    // no list schema, look for default in config
    if(newListSchema.length===0 && 'default' in checklist.config && checklist.config.default?.length > 0) {
      newListSchema = checklist.config.default;
    }

    setListSchema(newListSchema);
  }, [schemas, typeId, checklist, typeLists])

  useEffect(() => {
    if(emptyObj(obj)) return;
    (async () => {
      let photos = await db.photos.where({ plotAppId: obj.appId, phaseId: activePhase.id}).toArray();
      setPhotos(photos);

      if(online && viewServer) {
        let res = await serverCall({ method: 'GET', url: `/mapping/plots/photos/${obj.appId}`, eamsAuth0: true });
        setServerPhotos(res.data);
      }
    })();
  }, [obj, online, activePhase, viewServer, serverCall])

  const fromChild = async (data) => {
    const { type, name, value } = data;
    if(type==='autosuggest' && name==='typeId' && obj.typeId !== value) {
      setPlot(prev => {
        let currentValues = getValues();
        let newObj = {...prev, ...currentValues};
        newObj.typeId = value;
        return newObj;
      })
    } else if(type==='toggle status') {
      setValue('status', value);
      handleSubmit(onSubmit)();
    }
  }

  const onSubmit = async () => {
    console.clear();
    console.log('onSubmit');
    let plotData = getValues();
    let listData = getValues2();

    // required to clean up dynamic RHF arrays, yes needed
    let plotCleaned = removeArrs(plotData); 
    let listCleaned = removeArrs(listData);
    // console.log(listData);
    // console.log(listCleaned);

    if(plotCleaned.eamsNew) {
      plotCleaned.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
      plotCleaned.createdBy = userDetails.id;
    }
    delete plotCleaned.eamsNew;

    plotCleaned.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    plotCleaned.updatedBy = userDetails.id;
    plotCleaned.dirty = 1;

    // also copy the publicStatus into the list so we have that history
    if(listCleaned.ptNew) {
      listCleaned.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
      listCleaned.createdBy = userDetails.id;
    }
    delete listCleaned.ptNew;
    listCleaned.publicStatus = plotCleaned.publicStatus;
    listCleaned.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    listCleaned.updatedBy = userDetails.id;
    listCleaned.dirty = 1;

    // console.log(plotCleaned);
    // console.log(listCleaned);
    // return;
    // console.log(listSchema);
    // console.log(listCleaned);
    console.log('everything cleaned');

    if(listSchema.length > 0) {
      console.log('yes would be adding/editing plotTask');
      await db.plotTasks.put(listCleaned);
    }

    await setPlots((prev) => {
      console.log('setting plots');
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId === obj.appId && x.phaseId === activePhase.id);
      if(idx > -1) {
        arr[idx] = {...arr[idx], ...plotCleaned};
        db.plots.update([obj.appId, activePhase.id], plotCleaned);
      } else {
        arr.push(plotCleaned);
        db.plots.put(plotCleaned);
      }
      return arr;
    })

    // the below works; do we want to try to push for everything
    // or just do a batch update when we've hit X records?

    // or... just start a simple worker?
    // disabling this for now so it's not slow for them in the field
    // we DO WANT TO START A WORKER
    // otherwise it makes them wait for the upload



    // if(online) {
    //   let update = (listSchema.length > 0) ? { plot: plotCleaned, plotTask: listCleaned } : { plot: plotCleaned };
    //   let res = await serverCall({ method: 'PATCH', data: update, url: `/mapping/plots/task/${obj.appId}`, eamsAuth0: true });
    //   if(res.status===200) {
    //     // update or replace plot(s)
    //     if(res.data.plots?.length > 0) {
    //       for(const plot of res.data.plots) {
    //         await db.plots.put(plot);
    //       }
    //     } else {
    //       await db.plots.update([obj.appId, activePhase.id], { dirty: null });
    //     }

    //     // update or replace plotTask(s)
    //     if(res.data.plotTasks?.length > 0) {
    //       for(const plotTask of res.data.plotTasks) {
    //         await db.plotTasks.put(plotTask);
    //       }
    //     } else {
    //       await db.plotTasks.update([obj.appId, task.id], { dirty: null });
    //     }
    //   }
    // }

    handleClose();
  }

  const savePhoto = async (e) => {
    console.log('saving photo');
    document.activeElement.blur();
    let files = e.target.files;
    for(const file of files) {
      let data = await photoData({ obj, file, task, project, activePlan, createdBy: userDetails.id, createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss') });
      console.log('new photo:', data.appId);
      await db.photos.put(data);
      setPhotos((prev) => {
        let arr = [...prev];
        arr.push(data);
        return arr;
      })

      // if(online) {
      //   let token = await getAccessTokenSilently();
      //   startWorker({ type: 'sync', pieces: { direction: 'upload', type: 'photo', appId: data.appId, token } })
      // }
    }
  }

  const getServerPics = async () => {
    if(!online) return alert('Connect to wifi/cell service to get server photos.');
    let res = await serverCall({ method: 'GET', url: `/mapping/plots/photos/${obj.appId}`, eamsAuth0: true });
    setServerPhotos(res.data);
  }

  return (
    <div className="d-flex flex-column h-100 overflow-hidden" style={{borderLeft:`6px solid ${border}`}}>
      <Modal.Header closeButton>
        <Modal.Title>{ plot?.eamsNew ? `Add Plot ${plot.name}` : plot?.display?.textLabel}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-2">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mb-3">
        <Tabs defaultActiveKey="details" id="edit-plot" className="mb-3">
          {/* <Tab eventKey="punchlist" title="Punchlist">
            { obj && (
              <Fragment>
                { obj.display.textLabel }<br />
                { obj.display.typeLabel }
              </Fragment>
            )} */}
            
              {/* <Generated id="plot-edit2" schema={tempSchema} size="md" pieces={{ vars: { 'plot-actions': actions, types }, register, control, setValue, watch, errors, toParent: fromChild }} /> */}
              {/* <Generated id="plot-detail" schema={plotSchema} size="md" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} /> */}

              {/* and then here... should be the message by the type? */}
              {/* <Generated id="type-checklist" schema={listSchema} size="md" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} /> */}
              
          {/* </Tab> */}
          <Tab eventKey="details" title="Details">
            { photos?.length > 0 && (
              <div className="col-sm-12">
                <div className="horizontal-scroll mb-3">
                  { photos.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt))).map((obj, index) => (
                      <img key={'opt'+index} src={obj.base64} className="horizontal-thumb border" alt="Upload" />
                  ))}
                </div>
              </div>
            )}
              {/* 
                technically the plot-edit form should have disabled={checklist?.config?.details!=='edit'}
                but we are omitting since you don't have a different survey schema...
                may also want to pass specific publicStatuses...?
              */}
              <Generated id="plot-edit" schema={plotSchema} size="md" pieces={{ vars: { 'plot-actions': actions, 'plot-status': publicStatuses, types, typeVars }, register, control, setValue, watch, errors, toParent: fromChild }} />

              { checklist?.config?.details && (
                <Generated disabled={checklist?.config?.details!=='edit'} id="plot-detail" schema={typeSchema} size="md" pieces={{ vars: {'symbols': symbols}, register, control, setValue, watch, errors, toParent: fromChild }} />
              )}

              {/* <GeneratedDisplay schema={typeSchema} data={plot} tag="h6" /> */}

              {/* and then here... should be the message by the type? */}
              <Generated id="type-checklist" schema={listSchema} size="md" pieces={{ register: register2, control: control2, setValue: setValue2, watch: watch2, errors: errors2, toParent: fromChild }} />
          </Tab>
          <Tab eventKey="photos" title={ (photos.length + serverPhotos.length) > 0 ? `Photos (${photos.length + serverPhotos.length})` : 'Photos'}>
            { photos.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt))).map((obj, idx) => (
              <img key={idx+obj.appId} src={obj.base64} className="img-fluid border" alt="Upload" />
            ))}
            { online && (
              <div className="d-grid gap-2">
                <button className="btn btn-outline-dark mt-3" type="button" onClick={getServerPics}>View Server Photos</button>
              </div>
            )}
            { online && serverPhotos.length > 0 && (
              <Fragment>
                <hr />
                { serverPhotos.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt))).map((obj, idx) => (
                  <img key={idx+obj.appId} src={obj.url} className="img-fluid border mb-3" alt="Upload" />
                ))}
              </Fragment>
            )}
          </Tab>
        </Tabs>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <ToggleStatusBtn toParent={fromChild} status={toggleStatus} wrapper="float-end" styles="btn btn-outline-danger ms-2" textA="Delete" textD="Reactivate" />
          <button type="button" className="file-container btn btn-outline-dark float-end ms-2">
            <Images className="pointer" size={18} />
            <input type="file" accept="image/*" onChange={savePhoto} />
          </button>
          <button type="button" className="file-container btn btn-outline-dark float-end">
            <Camera className="pointer" size={18} />
            <input type="file" accept="image/*" capture="environment" onChange={savePhoto} />
          </button>
        </div>
        
        { userDetails.supe && <p className="float-end ms-auto mt-2 mb-0 small text-muted">{obj?.appId} {obj?.id}</p>}
        
        <button className="btn btn-outline-dark me-1 float-end" type="button" onClick={handleClose}>
          {formText.cancel}
        </button>

        <button className={`btn ${error ? 'btn-danger' : 'btn-success'} float-end`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
          { !pending && !error && (formText.submit)}
          { pending === true && (
            <Fragment>
              <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              {formText.pending}
            </Fragment>
          )}
          { error && (`Error saving (${errorCode})`)}
          { pending ==='success' && formText.success}
        </button>
      </Modal.Footer>
    </div>
  )
}

export default View;
