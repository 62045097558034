import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useAxios } from '../../../../hooks/useAxios';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';

// if task is labeled as "In Review", perhaps we auto-show the table?
// if it's in progress, do we show the modal instructions? (text below)

// future: you may need to move the "Add to project" into history
// and in that case we'd copy from a specific one instead of the last one

import { SiteContext } from '../../../../context/SiteContext';
import { UserContext } from '../../../../context/UserContext';

import Header  from '../../../../components/system/HeaderTask';
import Filters from '../../../mapping/plots/components/Filters';
import Table from '../../../../components/system/Table';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { typeQtys } from '../../../mapping/plots/utils/plots';

const cols = [
  { Header: 'Plan Group/Plan', accessor: 'plan', width: 20 },
  { Header: 'ID', accessor: 'name', width: 20 },
  { Header: 'Type', accessor: 'type' },
  { Header: 'Action', accessor: 'action' },
  { Header: 'Status', accessor: 'status' },
]

const View = (props) => {
  const params = useParams();
  const { activePhase, context } = props;
  // temporarily taking phaseOut and setting it in the props above
  // the updating wasn't happening in a timefly fashion...
  const { project, projTasks, planGroups, plans } = context;
  const { config } = useContext(SiteContext);
  const { userDetails } = useContext(UserContext);
  
  const { serverCall, pending } = useAxios();
  const closeModal = () => setActivePlot({});

  const [task, setTask] = useState({});
  const [activePlot, setActivePlot] = useState({});
  const [data, setData] = useState([]);

  const rowClick = (e) => setActivePlot(e.original);

  useEffect(() => {
    let found = projTasks.find(x => x.appId === params.taskId);
    if(!found) return; // lklklk
    setTask(found);
  }, [params.taskId, projTasks])

  useEffect(() => {
    if(context?.activePlots?.length===0) return;
    let arr = [];
    for(const plot of context.activePlots) {
      console.log(plot);
      let group;
      let plan = plans?.find(x => x.id === plot.planId);
      if(plan?.planGroupId) {
        group = planGroups?.find(x => x.id === plan.planGroupId);
      }

      let label = group ? group.name+' '+plan.name : plan.name;
      arr.push({
        plan: label,
        name: plot.display.textLabel,
        type: plot.display.typeLabel,
        action: plot.installMode,
        status: plot.publicStatus
      });

      setData(arr);
    }
  }, [context.activePlots])

  return (
    <Fragment>
      <Helmet>
        <title>Fabrication | {config.title}</title>
        <style>{`html, body, div#root { height: 100%; }`}</style>
      </Helmet>
      <Header task={task} />
      <div className="row">
        <div className="col-sm-7">
          <h3>Fabrication: {project.name}</h3>
        </div>
        <div className="col-sm-5">
          <Filters project={project} publicStatuses={context.publicStatuses} activeQtys={context.activeQtys} libraries={context.libraries} planGroups={planGroups} plans={plans} types={context.types} search={context.search} setSearch={context.setSearch} filters={context.filters} setFilters={context.setFilters} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-8">
          <div className="mb-3 p-2 bg-white rounded">
            <Table columns={cols} data={data} onRowClicked={rowClick} classes="table table-hover my-0" rowClasses="pointer" />
          </div>
        </div>
        <div className="col-sm-4">
          {/* <Discussion 
            parentType="task" 
            parentId={task.id} 
            threads={threads} 
            setThreads={setThreads} 
            comments={comments} 
            setComments={setComments}
          /> */}
        </div>
      </div>

    </Fragment>
  )
}

export default View;


// xs modal instructions:
// Sign placement and mounting
// Select and place relevant sign types based on circulation and decision point planning.