import { sumByKey } from './functions';

export const organizeTypes = ({ project, libraries, types, activeQtys, excludeZero }) => {
  let list = [];

  // sum keys so we combine variation qtys
  let qtys = sumByKey(activeQtys, 'typeId');

  if(project?.library) {
    for(const library of project.library) {
      let listQty = 0;
      let typeList = [];
      for(const typeId of library.ids) {
        let found = types.find(x => x.id===typeId);
        if(found) {
          let type = {...found};
          let qty = qtys.find(x => x.typeId===typeId);
          type.quantity = qty?.quantity ? qty.quantity : 0;
          listQty = listQty + type.quantity;
  
          if(!excludeZero || qty?.quantity > 0)
            typeList.push(type);
        }
      }
  
      if(!excludeZero || listQty > 0) {
        list.push({
          uid: library.uid,
          name: library.name,
          quantity: listQty,
          types: typeList
        })
      }
    }
  }

  for(const library of libraries) {
    for(const schema of library.schema) {
      let listQty = 0;
      let typeList = [];
      for(const typeId of schema.ids) {
        let found = types.find(x => x.id===typeId);
        if(found) {
          let type = {...found};
          let qty = qtys.find(x => x.typeId===typeId);
          type.quantity = qty?.quantity ? qty.quantity : 0;
          listQty = listQty + type.quantity;
          if(!excludeZero || qty?.quantity > 0)
            typeList.push(type);
        }
      }

      if(!excludeZero || listQty > 0) {
        list.push({
          uid: schema.uid,
          name: library.name+': '+schema.name,
          quantity: listQty,
          types: typeList
        })
      }
    }
  }

  return list;
}