import React, { Fragment } from 'react';

// lklklk:
// split up images and files so we can see thumbnails and list pdfs beneath
// also need to add functionality here to delete/remove something that they didn't want attached
// this needs to receive toParent from Dropzone to do that, then we will handle
// the logic at the form or script level

const View = (props) => {
  const { files } = props;
  // const [images, setImages] = useState([]);
  // const [others, setOthers] = useState([]);

  // useEffect(() => {
  //   let images = files.filter(x => x.fileType.includes('image'));
  //   let others = files.filter(x => !x.fileType.includes('image'));
  //   console.log(images, others);
  // }, [files])

  return (
    <Fragment>
      { files.length > 0 && (
        <Fragment>
          <h5 className="mt-2">Uploaded</h5>
          { files.map((obj, idx) => (
            <div key={'thumbnail-'+obj.appId} className="d-flex">
              <p className="mb-0 flex-grow-1">{obj.originalName}</p>
              <p className="mb-0 text-end">{obj.progress}%</p>
            </div>
          ))}
          <hr />
        </Fragment>
      )}
    </Fragment>
  )
}

export default View;
