import React, { Fragment } from 'react';
import { resizeHandler } from '../../../../hooks/fabric/resize';
import { AspectRatio } from 'react-bootstrap-icons';

const View = ({ wrapperRef, canvas, bkgd }) => {
  const resize = () => resizeHandler({ wrapperRef, canvas, bkgd });

  return (
    <Fragment>
      <button className="btn btn-sm" onClick={resize}>
        <AspectRatio size={16} />
      </button>
    </Fragment>
  )
}

export default View;
