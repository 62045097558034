import { fabric } from 'fabric';

const shape = ({ fillColor, scale, details, icon }) => {
  return new Promise(async(resolve) => {
    // determine icon file type
    const filename = new URL(icon).pathname;
    let ext = filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
    
    if(ext === 'svg') {
      fabric.loadSVGFromURL(icon, function(svg) {
        for(const item of svg) {
          if(!item.fill) continue;
          item.set({ fill: fillColor });
        }
        svg = fabric.util.groupSVGElements(svg);
        svg = svg.scale(20*scale / svg.width);
        let c = new fabric.Group([ svg ], details);
        resolve(c);
      });
    } else {
      fabric.Image.fromURL(icon, function(img) {
        img = img.scale(20*scale / img.width);
        let c = new fabric.Group([ img ], details);
        resolve(c);
      });
    }
  })
};

export default shape;