
export const handleObjCleared = ({ e, canvas, fromMap }) => {
  // fromMap({ type: 'object cleared', value: e.target })
}

export const handleObjMoving = ({ e, debug, interaction }) => {
  // if(debug) console.log('object:moving,', e);
  interaction.objMoved = true;
  clearTimeout(interaction.lockTimer);
  let obj = e.target;

  // check if we are moving one or multiple
  if(obj.piece && obj.eamsLine) {
    let left = Math.round(obj.left);
    let top  = Math.round(obj.top);
    moveLine(obj, left, top);
  } else if(obj._objects) {
    for (const item of obj._objects) {
      if(item.piece && item.eamsLine) {
        let left = Math.round(item.left + obj.left);
        let top  = Math.round(item.top + obj.top);
        moveLine(item, left, top);
      }
    }
  }
}

const moveLine = (obj, left, top) => {
  let coords = [...obj.eamsLine.points];

  if(obj.piece === 'locator') {
    coords[0] = { x: left, y: top };
  } else if(obj.piece === 'label') {
    let idx = obj.eamsLine.points.length-1;
    coords[idx] = { x: left, y: top };
  }

  // update points to immediately show on map & setCoords to
  // ensure they don't disappear on zoom - yes you need both!
  obj.eamsLine.points = coords;
  obj.eamsLine.setCoords(coords);
}

export const handleObjRotating = ({ e, debug, interaction }) => {
  // if(debug) console.log('object:moving,', e);
  interaction.bkgdLocked = true;
}

export const handleObjModified = ({ e, debug, interaction, setPlotsLocked, toParent }) => {
  if(debug) console.log('object:modified,', e.action);
  let obj = e.target;

  // if editable or touch, restart the timer to lock
  if(interaction.editable==='suggest' || interaction.touch)
    interaction.lockTimer = setTimeout(() => setPlotsLocked(true), interaction.lockAfter);

  if(e.action === 'drag') {
    let movedArr = [];
    if('appId' in obj) {
      // single item
      movedArr.push({
        id: obj.id,
        appId: obj.appId,
        phaseId: obj.phaseId,
        left: (Math.round(obj.left)/interaction.offset.scale-interaction.offset.x),
        top: (Math.round(obj.top)/interaction.offset.scale-interaction.offset.y),
        piece: obj.piece
      });

    } else {
      // multiple items, note that we calculate the offset here (different from above)
      for (const item of obj._objects) {
        if('appId' in item) {
          movedArr.push({
            id: item.id,
            appId: item.appId,
            phaseId: item.phaseId,
            left: (Math.round(item.left + obj.left)/interaction.offset.scale-interaction.offset.x),
            top: (Math.round(item.top + obj.top)/interaction.offset.scale-interaction.offset.y),
            piece: item.piece
          });
        }
      }
    }

    // then send to parent
    if(toParent) toParent({ type: 'plots moved', value: movedArr });
  } else if(e.action === 'scale' || e.action === 'scaleX' || e.action === 'scaleY') {
    if(!obj.appId) return;
    let sizes = {
      id: obj.id,
      appId: obj.appId,
      phaseId: obj.phaseId,
      left: Math.round(obj.left),
      top: Math.round(obj.top),
      width: Math.round(obj.width*obj.scaleX),
      height: Math.round(obj.height*obj.scaleY),
      radius: obj.radius ? Math.round(obj.radius*obj.scaleX) : null
    }

    if(toParent) toParent({ type: 'plot sized', value: sizes });
  }
}
