
// https://codesandbox.io/s/c1gtn?file=/src/App.jsx
// https://codesandbox.io/s/github/Venkanna77/dndkit-sortable-image-grid/tree/main/

import { arrayMove } from "@dnd-kit/sortable";

export const removeAtIndex = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const insertAtIndex = (array, index, item) => {
  return [...array.slice(0, index), item, ...array.slice(index)];
};

export const moveObjects = async (setStructure, activeContainer, overContainer, activeIndex, overIndex) => {
  setStructure((prev) => {
    let obj = {...prev};
    let item = {...obj[activeContainer][activeIndex]};
    obj[activeContainer] = removeAtIndex(obj[activeContainer], activeIndex);
    obj[overContainer] = insertAtIndex(obj[overContainer], overIndex, item);
    return obj;
  });
}

export const handleDragOver = ({ e, setStructure }) => {
  const { over, active } = e;
  if(!over) return;

  const activeContainer = active.data.current.sortable.containerId;
  const overContainer = over.data.current?.sortable.containerId || over.id;
  const activeIndex = active.data.current.sortable.index;
  const overIndex = over.data.current?.sortable.index || 0;
  if(!overContainer) return;

  if (activeContainer !== overContainer) {
    moveObjects(setStructure, activeContainer, overContainer, activeIndex, overIndex);
  }
};

export const handleDragEnd = ({ e, setStructure }) => {
  const { over, active } = e;
  if(!over) return;

  if (active.id !== over.id) {
    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current?.sortable.containerId || over.id;
    const activeIndex = active.data.current.sortable.index;
    const overIndex = over.data.current?.sortable.index || 0;
    // console.log(activeContainer, overContainer, activeIndex, overIndex);

    if(activeContainer === overContainer) {
      setStructure((prev) => {
        let obj = {...prev};
        obj[activeContainer] = arrayMove(obj[activeContainer], activeIndex, overIndex);
        return obj;
      })
    } else {
      moveObjects(setStructure, activeContainer, overContainer, activeIndex, overIndex);
    }
  }
};

export const handleDragEndArray = ({ e, arr, setArr, caption, toParent }) => {
  const { over, active } = e;
  if(!over) return;

  if (active.id !== over.id) {
    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current?.sortable.containerId || over.id;
    const activeIndex = active.data.current.sortable.index;
    const overIndex = over.data.current?.sortable.index || 0;

    if(activeContainer === overContainer) {
      let updated = [...arr];
      let array = arrayMove(updated, activeIndex, overIndex);

      let updates = [];
      setArr((prev) => {
        let arr = [...prev];
        for(const [idx, obj] of array.entries()) {
          let sort = idx+1;
          let index = arr.findIndex(x => x.appId === obj.appId);

          // only include in the update if the sort has changed
          if(arr[index].sortOrder!==sort) {
            updates.push({
              id: obj.id,
              appId: obj.appId,
              sortOrder: sort
            })
          }
          arr[index].sortOrder = sort;
        }
        return arr;
      });

      if(toParent) toParent({ type: caption, value: updates });

    } else {
      alert('Error moving array. Contact support');
    }
  }
};