import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';

import Generated  from '../../../../components/system/Generated';
// import EmailError from '../system/EmailError';

import { formObj } from '../../../../utils/schemas';
import { updatePlot } from '../utils/plots';

// lklklk future:
// need to be able to switch between type color and status color
// also considering revising to be within an editable table
// so that they could sort/filter the columns as desired

const View = (props) => {
  const debug = false;
  const { schema, obj, project, plans, types, typeVars, setPlots, selected, setSelected, setZoomTo, toParent } = props;
  const [border, setBorder] = useState('#ccc');

  const { serverCall } = useAxios();
  const { control, register, handleSubmit, formState: { errors, isDirty }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [schema])
  });

  // if you need to set the form value based on props.obj changing
  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);

    if(!obj.display) return;
    let color = obj.display.labelFill ? obj.display.labelFill : obj.display.locatorFill;
    setBorder(color);
  }, [schema, obj, reset])

  const onSubmit = async (data) => {
    if(debug) console.log(data);
    let plot = await updatePlot({ project, plans, types, setPlots, plot: data });
    let res = await serverCall({ method: 'PATCH', data, url: `/mapping/plots/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating plot. Contact support.'); // lklklk
    // update the form with new default values so the bg color goes away
    let filled = formObj(schema, plot);
    reset(filled);

    if(!plot.display) return;
    let color = plot.display.labelFill ? plot.display.labelFill : plot.display.locatorFill;
    setBorder(color);
  }

  const fromChild = (data) => {
    if(debug) console.log(data);
    const { type, value, autosave } = data;
    if(type==='view plot') {
      toParent({ type: 'view plot detail', value: obj.id, appId: obj.appId });

    } else if(type==='find plot') {
      setSelected([
        { appId: obj.appId, id: obj.id, phaseId: obj.phaseId, piece: 'locator' },
        { appId: obj.appId, id: obj.id, phaseId: obj.phaseId, piece: 'label' }
      ]);
      if(obj.styling?.locatorX && obj.styling.locatorY)
        setZoomTo({ id: obj.id, left: obj.styling.locatorX, top: obj.styling.locatorY, timestamp: Date.now() });
    } else if(type==='checkbox-select') {
      setSelected((prev) => {
        let arr = [...prev];
        if(value.checked) {
          arr.push({...value.value, ...{ piece: 'locator' }});
          arr.push({...value.value, ...{ piece: 'label' }});
        } else {
          arr = arr.filter(x => x.id !== value.value.id);
        }
        return arr;
      })
    } else if(isDirty || autosave)
      handleSubmit(onSubmit)();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="ps-2" style={{borderLeft:`6px solid ${border}`, backgroundColor: isDirty ? '#D3CBC5' : ''}}>
      <Generated id={obj.appId} layout="horizontal" schema={schema} size="sm" pieces={{ vars: { value: { appId: obj.appId, phaseId: obj.phaseId, id: obj.id }, types, typeVars, selected }, register, control, setValue, watch, errors, toParent: fromChild }} />
    </form>
  )
}

export default View;
