import React, { Fragment, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Generated  from '../../../../components/system/Generated';
import DeleteBtn from '../../../../components/buttons/DeleteBtn';
// import EmailError from '../system/EmailError';

import SchemaAuth from '../../../../components/system/schemas/SchemaAuth';
import Dropzone from '../../../../components/system/dropzone/Dropzone';
import ViewLists from '../../checklists/components/View';
import dayjs from 'dayjs'

import { formObj } from '../../../../utils/schemas';

const View = (props) => {
  const debug = true;
  const { userDetails, schema, obj, setTypes, handleClose, toParent } = props;
  const formText = { submit: `Update`, pending: 'Updating', success: 'Updated!', cancel: 'Cancel' };
  const errorCode = '5512'

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [schema])
  });

  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    // manually set these for color picker:
    if(filled.styling?.fillColor) filled.fillColor = filled.styling.fillColor;
    if(filled.styling?.borderColor) filled.borderColor = filled.styling.borderColor;

    reset(filled);
  }, [schema, obj, reset])

  const onSubmit = async (data) => {
    if(debug) console.log(data);

    setTypes((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.id === obj.id);
      arr[idx] = {...arr[idx], ...data}
      return arr;
    })

    data.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.updatedBy = userDetails.id;

    let res = await serverCall({ method: 'PATCH', data, url: `/library/types/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating type. Contact support.') // lklklk
    handleClose();
  };

  const fromChild = async (data) => {
    console.log(data);
    const { type, value } = data;
    if(type==='schema update') {
      setValue('schema', value);
    } else if(type==='delete confirmed') {
      if(toParent) toParent({ type: 'remove types', value: [obj.id] });
      handleClose();
    }
  }

  return (
    <div className="d-flex flex-column h-100 overflow-hidden">
      <Offcanvas.Header className="bg-body pb-2" closeButton>
        <Offcanvas.Title>{obj?.label}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="bg-body flex-grow-1 p-2 pt-0 overflow-scroll">
        <Tab.Container defaultActiveKey="details" id="edit-type" className="mb-3">
          <Nav className="nav-tabs bg-body sticky-top mb-3">
            <Nav.Item>
              <Nav.Link eventKey="details">Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fields">Fields</Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link eventKey="thumbnails">Thumbnails</Nav.Link>
            </Nav.Item> */}
            <Nav.Item>
              <Nav.Link eventKey="artwork">Artwork</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="checklists">Checklists</Nav.Link>
            </Nav.Item>
          </Nav>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mb-3">
            <Tab.Content>
              <Tab.Pane eventKey="details">
                <Generated id="type-edit" schema={schema} size="md" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />
              </Tab.Pane>
              <Tab.Pane eventKey="thumbnails">
                <h5>Side A</h5>
                <Dropzone
                  folder="uploads/"
                  prefix="thumbnail-side-a"
                  toParent={fromChild}
                />
                <h5 className="mt-5">Side B</h5>
                <Dropzone
                  folder="uploads/"
                  prefix="thumbnail-side-a"
                  toParent={fromChild}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="artwork">
                <h5>Preview</h5>
                <h6>Side A</h6>
                <Dropzone
                  folder="uploads/"
                  prefix="thumbnail-side-a"
                  toParent={fromChild}
                />
                <h6 className="mt-3">Side B</h6>
                <Dropzone
                  folder="uploads/"
                  prefix="thumbnail-side-a"
                  toParent={fromChild}
                />

                <h5 className="mt-5">Production</h5>
                <h6>Side A</h6>
                <Dropzone
                  folder="uploads/"
                  prefix="thumbnail-side-a"
                  toParent={fromChild}
                />
                <h6 className="mt-3">Side B</h6>
                <Dropzone
                  folder="uploads/"
                  prefix="thumbnail-side-a"
                  toParent={fromChild}
                />
              </Tab.Pane>
            </Tab.Content>
          </form>
          <Tab.Pane eventKey="fields" mountOnEnter>
            <SchemaAuth table="plots" arr={obj?.schema} toParent={fromChild} />
          </Tab.Pane>
          <Tab.Pane eventKey="checklists" title="Checklists" mountOnEnter>
            <ViewLists obj={obj} />
          </Tab.Pane>
        </Tab.Container>
      </Offcanvas.Body>
      <div className="bg-body p-3">
        <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'} float-end`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
          { !pending && !error && (formText.submit)}
          { pending === true && (
            <Fragment>
              <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              {formText.pending}
            </Fragment>
          )}
          { error && (`Error saving (${errorCode})`)}
          { pending ==='success' && formText.success}
        </button>

        <button className="btn btn-sm btn-outline-dark ms-auto me-1 float-end" type="button" onClick={handleClose}>
          {formText.cancel}
        </button>

        <DeleteBtn toParent={fromChild} text={`Delete Type`} wrapper="float-start" />
        { userDetails.supe && <p className="float-start ms-2 mt-2 mb-0 small text-muted">{obj.id}</p>}
      </div>
    </div>
  )
}

export default View;
