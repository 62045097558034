import React, { useContext } from 'react';
import { SiteContext } from '../../context/SiteContext';

import WFIHeader from '../_products/wfi/TaskHeader';
import WGSHeader from '../_products/sfo/TaskHeader';
import ICPHeader from '../_products/icp/TaskHeader';

const View = (props) => {
  const { task } = props;
  const { hostname } = useContext(SiteContext);

  if(hostname.includes('wayfindit'))
    return <WFIHeader task={task} />
  else if(hostname==='wgs.flysfo.io')
    return <WGSHeader task={task} />
  else if(hostname==='icp.jblapp.com')
    return <ICPHeader task={task} />
  else
    return null;
}

export default View;
