import React, { useState } from 'react';
import { Download, XCircleFill, CheckCircleFill } from 'react-bootstrap-icons'

const View = ({ id, obj, stopWorker }) => {
  const [showStop, setShowStop] = useState(false);

  const handleOver = (e) => {
    let state = (e.type === 'mouseover') ? true : false;
    setShowStop(state);
  }

  return (
    <div className="d-flex border-bottom p-3">
      <p className="flex-grow-1 lh-sm mb-0 me-2">
        {obj.name}
      </p>

      { obj.data.progress && (
        <p className="lh-sm mb-0 mx-2">{obj.data.progress}%</p>
      )}

      { obj.data.status === 'finishing' && (
        <p className="lh-sm mb-0 mx-2">Finishing...</p>
      )}

      { (obj.data.status !== 'finished' && obj.data.status !== 'stopped') && (
        <div onMouseOver={handleOver} onMouseOut={handleOver}>
          {showStop && (
            <XCircleFill size={18} className="text-muted" onClick={() => stopWorker(id)} />
          )}

          {!showStop && (
            <div className="spinner-border spinner-border-sm text-muted" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
      )}

      { obj.type==='pdf' && obj.data.status === 'finished' && (
        <a href={obj.data.dataUrl} download={obj.filename} className="d-block"><Download /></a>
      )}

      { (obj.type==='plan upload' || obj.type==='thumbnails') && obj.data.status === 'finished' && (
        <CheckCircleFill size={30} className="text-success" />
      )}

      { obj.data.status === 'stopped' && (
        <p className="lh-sm mb-0 mx-2 text-muted">Cancelled</p>
      )}
    </div>
  )
}

export default View;
