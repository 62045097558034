import { fabric } from 'fabric';

const shape = ({ textLabel, textOpts, fillColor, borderColor, scale, details }) => {
  let text = new fabric.Text(textLabel, textOpts);
  let width = text.width ? text.width + 12 : scale*22;
  let height = text.height ? text.height + 6 : scale*22;

  let rect = new fabric.Rect({
    width,
    height,
    fill: fillColor,
    strokeWidth: 1*scale,
    stroke: borderColor
  });

  let c = new fabric.Group([ rect, text ], details);
  return c;
}

export default shape;