import React, { Fragment, useEffect, useState } from 'react';
import Artwork from '../../artwork/Artwork';
import { emptyObj } from '../../../utils/functions'

// lklklk future: need a way to differentiate between which ones we want to view?

const View = (props) => {
  const { appId, types, typeVars, symbols, plots } = props;
  const [plot, setPlot] = useState({});
  const [display, setDisplay] = useState({})
  const [toShow, setToShow] = useState();

  useEffect(() => {
    if(!appId) return;

    let found = plots.find(x => x.appId === appId);
    found ? setPlot(found) : setPlot({});

    if(!found || !found.typeId) return setDisplay({});

    let type = types.find(x => x.id === found.typeId);
    let typeVar = typeVars.find(x => x.id === found.variationId);
    if(!type) return setDisplay({});

    // lklklk: may want to refine this instead of clobbering the type
    let display = typeVar ? {...type, ...typeVar} : {...type};
    setDisplay(display);

    // determine if this type has art to show
    if(display.prevAurl || display.prevBurl)
      setToShow('preview');
    else if(display.prodAurl || display.prodBurl)
      setToShow('production');
    else if(display.thumbAurl || display.thumbBurl)
      setToShow('thumbnail');
  }, [appId, plots])


  if(!appId) return null;

  return (
    <div className="bg-white border shadow-sm p-2" style={{width:250}}>
      <h4>{plot.display?.textLabel}</h4>
      <p className="text-muted lh-sm mb-1">{plot.udf1}</p>
      <p className="text-muted mb-2 lh-sm">{plot.display?.typeLabel}</p>
      {toShow==='preview' && (
        <Fragment>
          <Artwork url={display.prevAurl} display={display} content={plot.arr1} plot={plot} type={display} symbols={symbols} />
          <Artwork url={display.prevBurl} display={display} content={plot.arr1} plot={plot} type={display} symbols={symbols} />
        </Fragment>
      )}
      {toShow==='production' && (
        <Fragment>
          <Artwork url={display.prodAurl} display={display} content={plot.arr1} plot={plot} type={display} symbols={symbols} />
          <Artwork url={display.prodBurl} display={display} content={plot.arr1} plot={plot} type={display} symbols={symbols} />
        </Fragment>
      )}
      {toShow==='thumbnail' && display.thumbAurl && <img src={display.thumbAurl} className="img-fluid border" alt="Sign Preview" />}
      {toShow==='thumbnail' && display.thumbBurl && <img src={display.thumbBurl} className="img-fluid border" alt="Sign Preview Side B" />}
    </div>
  )
}

export default View;


{/* 
<img id="hover-thumbA" className="img-fluid border" alt="Thumbnail Side A" />
<img id="hover-thumbB" className="img-fluid border mt-2" alt="Thumbnail Side B" />
*/}