import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

// lklklk update teh bg color slightlight when hovering edit so they know what will be edited?

import EditBtn from '../edit/Html';
import DirectionBtns from '../../../../buttons/DirectionBtns';
import { EyeSlashFill } from 'react-bootstrap-icons';

const View = ({ display, siblingCount, objIdx, obj: ogObj, colIdx, colName, toParent }) => {
  const [obj, setObj] = useState({});
  const [show, setShow] = useState(false);
  const toggle = () => setShow(s => !s);

  useEffect(() => {
    let obj = {...ogObj};
    setObj(obj);
  }, [ogObj])

  // onMouseOver={hover}
  // const hover = () => {
  //   console.log('yoooo')
  // }

  const fromChild = ({ type, value}) => {
    if(type==='direction' && toParent) {
      toParent({ type: 'move block', direction: value, colIdx, colName, objIdx });
    }
  }

  if(!display && !show) return (
    <div className="d-grid">
      <button className="btn btn-warning p-0 pt-1 mt-1 ms-4" onClick={toggle} />
    </div>
  )

  return (
    <div className="d-flex">
      <DirectionBtns idx={objIdx} count={siblingCount} toParent={fromChild} styles={{paddingTop:13}} />
      <div className="flex-grow-1">
        { obj.content && parse(obj.content)}
      </div>
      { show && (
        <button className="align-self-start btn btn-xs btn-warning mt-2 mx-1" onClick={toggle}>
          <EyeSlashFill size={16} />
        </button>
      )}
      <div>
        <EditBtn obj={obj} colIdx={colIdx} colName={colName} toParent={toParent} />
      </div>
    </div>
  )
}

export default View;
