import { fabric } from 'fabric';

const shape = ({ borderColor, scale, details, thumbA, thumbB }) => {
  return new Promise(async(resolve) => {
    fabric.Image.fromURL(thumbA, function(img) {
      img = img.set({ strokeWidth: 1*scale, stroke: borderColor });
      img = img.scale(100*scale / img.width);

      if(!thumbB) {
        let c = new fabric.Group([ img ], details);
        resolve(c);
      } else {
        fabric.Image.fromURL(thumbB, function(imgB) {
          img = img.set({ left: -50*scale });
          imgB = imgB.set({ left: 50*scale, strokeWidth: 1*scale, stroke: borderColor });
          imgB = imgB.scale(100*scale / img.width);
          let c = new fabric.Group([ img, imgB ], details);
          resolve(c);
        });
      }
    });
  })
};

export default shape;