import React, { useState } from 'react';
import parse from 'html-react-parser';

import EditBtn from '../edit/Input';
import DirectionBtns from '../../../../buttons/DirectionBtns';
import { EyeSlashFill } from 'react-bootstrap-icons';

import Input from '../../../forms/Input'
import Textarea from '../../../forms/Textarea'
import Checkbox from '../../../forms/Checkbox'
import FloatingInput from '../../../forms/FloatingInput'
import FloatingTextarea from '../../../forms/FloatingTextarea'

const View = ({ display, siblingCount, objIdx, obj, colIdx, colName, avail, pieces, toParent }) => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(s => !s);

  const fromChild = ({ type, value}) => {
    if(type==='direction' && toParent) {
      toParent({ type: 'move block', direction: value, colIdx, colName, objIdx });
    }
  }

  if(!display && !show) return (
    <div className="d-grid">
      <button className="btn btn-warning p-0 pt-1 mt-1 ms-4" onClick={toggle} />
    </div>
  )

  return (
    <div className="d-flex">
      <DirectionBtns idx={objIdx} count={siblingCount} toParent={fromChild} styles={{paddingTop:13}} />
      <div className="flex-grow-1">
        { !obj.type==='checkbox' && !obj.type.includes('floating') && obj.label && parse(obj.label)}
        { obj.type==='input' && <Input item={obj} pieces={pieces} />}
        { obj.type==='checkbox' && <Checkbox item={obj} pieces={pieces} />}
        { obj.type==='textarea' && <Textarea item={obj} pieces={pieces} />}
        { obj.type==='floating-input' && <FloatingInput item={obj} pieces={pieces} />}
        { obj.type==='floating-textarea' && <FloatingTextarea item={obj} pieces={pieces} />}
      </div>
      { show && (
        <button className="align-self-start btn btn-xs btn-warning mt-2 mx-1" onClick={toggle}>
          <EyeSlashFill size={16} />
        </button>
      )}
      <div className="d-flex flex-column">
        <EditBtn obj={obj} objIdx={objIdx} colIdx={colIdx} colName={colName} pieces={pieces} avail={avail} toParent={toParent} />
        <p className="small text-muted">{obj.field}</p>
      </div>
    </div>
  )
}

export default View;
