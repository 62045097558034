import { nanoid } from 'nanoid';
import { downloadFile } from '../../../utils/functions';

const init = ({ pieces, setWorkers }) => {
  const worker = new Worker(new URL('./worker.js', import.meta.url));
  let newId = nanoid();

  worker.postMessage({ data: pieces.files });
  worker.onerror = (err) => err;
  worker.onmessage = (e) => {
    const { status, dataUrl } = e.data;

    setWorkers((prev) => {
      const updatedWorkers = {...prev};
      updatedWorkers[newId].data = e.data;
      return updatedWorkers;
    });

    if(status === 'finished') {
      downloadFile(dataUrl, `${pieces.filename}.zip`, false);
      worker.terminate();
    }
  };
  
  setWorkers((prev) => ({ ...prev, 
    [newId]: { 
      worker, 
      type: 'zip', 
      name: pieces.name,
      filename: pieces.filename, 
      data: { status: 'starting', progress: 0 }
    }
  }));

}

export default init;