import React, { useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'

import { CaretUp, CaretDown } from 'react-bootstrap-icons';

// how to use:
// <Table columns={projectColumns} data={projects} onRowClicked={projectRowClick} noDataMsg="No active projects" classes="table my-0" rowClasses="pointer" />

// accessing different props:
// https://stackoverflow.com/questions/55504805/react-table-using-multiple-accessors-as-props-in-a-custom-component

// hidden columns:
// https://stackoverflow.com/questions/67925681/react-table-hide-custom-accessor-but-include-in-global-filtering


const Table = ({ classes, rowClasses, cellClasses, columns, data, noDataMsg, onRowClicked, onCellClicked }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  // const firstPageRows = rows.slice(0, 5000)

  return (
    <>
      <table {...getTableProps()} className={classes}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth }
                  })}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <CaretDown className="ms-1" />
                        : <CaretUp className="ms-1" />
                      : ''}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(rows.length > 0 && rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps({
                    className: rowClasses,
                    onClick: e => onRowClicked && onRowClicked(row, e)
                  })}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps({
                          className: cellClasses,
                          onClick: e => onCellClicked && onCellClicked(row, e)
                        })}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )}
          )) || <tr><td colSpan={columns.length} align="center" className="text-muted py-3">{noDataMsg}</td></tr>}
        </tbody>
      </table>
      {/*<p>Showing the first 20 results of {rows.length} rows</p>*/}
    </>
  )
}

const Component = (props) => {
  const columns = useMemo(() => props.columns, [props.columns]);
  const data    = useMemo(() => props.data, [props.data]);

  if(data.length > 0 && columns.length===0) return (
    <div className="mt-5 p-2 text-center bg-danger text-white">Missing table headers.</div>
  );

  return (
    <Table
      columns={columns}
      data={data}
      noDataMsg={props.noDataMsg}
      classes={props.classes}
      rowClasses={props.rowClasses}
      cellClasses={props.cellClasses}
      onRowClicked={props.onRowClicked}
      onCellClicked={props.onCellClicked}
    />
  )
}

export default Component;
