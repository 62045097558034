import React, { forwardRef, useEffect, useState } from 'react'

import Autosuggest from './Autosuggest'

// future: add a 'variation label' too for the second group?

const Field = forwardRef(({ item, pieces, ...rest }, ref) => {
  const horizontal = item.horizontal ? true : false;
  const typeOpts = pieces.vars?.types ? pieces.vars.types : [];
  const [typeVars, setTypeVars] = useState([]);

  const appId = pieces.watch('appId')
  const value = pieces.watch('typeId');
  const varVal = pieces.watch('variationId');

  useEffect(() => {
    // if appId is null, plot hasn't loaded yet so return
    if(!appId) return;
    let varOpts  = pieces.vars?.typeVars ? pieces.vars.typeVars : [];

    let filtered = varOpts.filter(x => x.typeId===value);
    let filteredIds = filtered.map(x => x.id);
    setTypeVars(filtered);

    if(varVal && !filteredIds.includes(varVal)) {
      pieces.setValue('variationId', null);
    }
  }, [appId, value, varVal, pieces])

  return (
    <div className="mb-1">
      {item.label && (<label htmlFor={item.field} className="mt-2 mb-1">{item.label}</label>)}
      <div className={horizontal ? `d-flex` : `d-flex flex-column`}>
        <div className="flex-fill"><Autosuggest item={{ field: 'typeId', placeholder: 'Select Type', options: typeOpts, autosave: item.autosave, disabled: item.disabled }} pieces={{...pieces, ...{ size: 'sm' }}} /></div>
        { typeVars.length > 0 && (
          <div className={horizontal ? `flex-fill ms-2` : `mt-1`}>
            <Autosuggest item={{ field: 'variationId', placeholder: 'Select Variation', options: typeVars, autosave: item.autosave, disabled: item.disabled }} pieces={{...pieces, ...{ size: 'sm' }}} />
          </div>
        )}
      </div>
    </div>
  )
});

export default Field;
