import React, { useCallback, useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context/UserContext';
import { useAxios } from '../../../hooks/useAxios';

import GroupAdd  from './components/GroupAdd';
import GroupEdit from './components/GroupEdit';
import UserAdd from './components/UserAdd';
import UserEdit from './components/UserEdit';

let groupOpts = [
  { value: 'owner', label: 'Owner' },
  { value: 'admin', label: 'Tier 4 (Admin)', alt: 'Tier 4', toggle: true },
  { value: 'editor', label: 'Tier 3 (Editor)', alt: 'Tier 3', toggle: true },
  { value: 'contributor', label: 'Tier 2 (Contributor)', alt: 'Tier 2', toggle: true },
  { value: 'viewer', label: 'Tier 1 (Viewer)', alt: 'Tier 1', toggle: true },
]

let userOpts = [
  { value: 'admin', label: 'Tier 4 (Admin)' },
  { value: 'editor', label: 'Tier 3 (Editor)' },
  { value: 'contributor', label: 'Tier 2 (Contributor)' },
  { value: 'viewer', label: 'Tier 1 (Viewer)' },
]

const View = (props) => {
  const { url, arr, parent, setParent, setParents, setAccessUsers, vertical, toggle, getUsers, toParent } = props;
  const { userDetails, allUsers, allGroups } = useContext(UserContext);
  const { serverCall } = useAxios();
  
  const [access, setAccess] = useState([]);
  // const [accessUsers, setAccessUsers] = useState([]);
  const [sharedGroups, setSharedGroups] = useState([]);
  const [otherGroups, setOtherGroups] = useState([]);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [otherUsers, setOtherUsers] = useState([]);
  
  const sendUpdate = useCallback(async(arr) => {
    if(!toParent) return;
    let updateToSend = { type: 'access', value: arr };
    updateToSend.ownerQty = arr.filter(x => x.role==='owner').length;
    updateToSend.groupQty = arr.filter(x => x.type==='group' && x.role!=='owner').length;
    updateToSend.userQty = arr.filter(x => x.type==='user').length;
    toParent(updateToSend);

    if(setAccessUsers) {
      let includedUsers = [];

      for(const user of allUsers) {
        // check if user id exists as type: "user"
        let found = arr.find(x => x.type==='user' && x.id===user.id);
        if(found) {
          includedUsers.push(user);
          continue;
        }

        // if not, check if they exist in the selected groups
        const findMatchingObjects = (array1, array2) => {
          return array1.filter(obj1 => {
            return array2.some(obj2 => obj1.type === obj2.type && obj1.id === obj2.id && obj1.role === obj2.role);
          });
        }

        const groupTest = findMatchingObjects(user.groupArr, arr);
        if(groupTest?.length > 0) includedUsers.push(user);
      }

      includedUsers.sort((a, b) => {
        const result = a.firstName.localeCompare(b.firstName);
        return result !== 0 ? result : a.lastName.localeCompare(b.lastName);
      })

      setAccessUsers(includedUsers);
    }

  }, [allUsers, getUsers, toParent])

  useEffect(() => {
    if(!arr) return;
    sendUpdate(arr);

    let userIds  = arr.filter(x => x.type === 'user').map(x => x.id);
    let groupIds = arr.filter(x => x.type === 'group').map(x => x.id);

    // filter groups that currently have access
    let groupAccess = allGroups.filter(x => groupIds.includes(x.id));
    let otherGroups = allGroups.filter(x => !groupIds.includes(x.id));
    setSharedGroups(groupAccess);
    setOtherGroups(otherGroups);

    // filter users to just groups that have access to this project(?)
    let filtered = allUsers.filter(x => {
      let groups = x.groupArr.filter(x => groupIds.includes(x.id));
      return (userIds.includes(x.id) || groups.length > 0) ? true : false;
    })
    // setAccessUsers(filtered);

    // get the opposite in case they want to share with someone
    let other = allUsers.filter(x => !filtered.includes(x));
    setOtherUsers(other);

    // then filter the share-only users
    let shared = allUsers.filter(x => userIds.includes(x.id));
    setSharedUsers(shared);
  }, [arr, allUsers, allGroups, sendUpdate])

  const update = async (newAccess) => {
    setAccess(newAccess);
    sendUpdate(newAccess);

    if(setParents) {
      setParents(prev => {
        let arr = [...prev];
        let idx = arr.findIndex(x => x.id === parent.id);
        if(idx > -1) arr[idx].access = newAccess;
        return arr;
      })
    }
    
    if(setParent)
      setParent(prev => ({...prev, access: newAccess}));

    // let url = type==='project' ? `/mapping/projects/${parent.appId}` : `/system/tasks/${parent.appId}`;
    if(url) {
      console.log(url);
      let res = await serverCall({
        method: 'PATCH', data: { id: parent.id, access: newAccess }, url,
        headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId }, eamsAuth0: true
      });
      if(!res.status===200) alert('Error removing access. Contact support. (UA981)');
    }
  }

  return (
    <div className="row">
      <div className={ vertical ? 'col-12 mb-3' : 'col-xl-6 mb-3'}>
        <h5>Groups</h5>
        <GroupAdd update={update} groups={otherGroups} parent={parent} setObj={setParent} setArr={setParents} />        

        { toggle && access.filter(x => x.type==='group').length > 0 && (
          <div className="d-flex justify-content-end">
            <div className="small text-center" style={{width:45}}>All</div>
            { groupOpts.filter(x => x.toggle).map((x, i) => (
              <div key={`col-${i}`} className="small text-center" style={{width:45}}>
                {x.alt ? x.alt : x.label}
              </div>
            ))}
            <div style={{width: 30}} />
          </div>
        )}

        { sharedGroups.sort((a, b) => a.label.localeCompare(b.label)).map(x => {
          return <GroupEdit key={`group-${x.value}`} obj={x} update={update} parent={parent} options={groupOpts} toggle={toggle} />;
        })}
        
      </div>
      <div className={ vertical ? 'col-12' : 'col-xl-6'}>
        <h5>People</h5>
        <UserAdd update={update} users={otherUsers} parent={parent} setObj={setParent} setArr={setParents} />        
        { sharedUsers.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)).map(x => {
          return <UserEdit key={`user-${x.id}`} user={x} update={update} parent={parent} options={userOpts} toggle={toggle} />;
        })}
      </div>
    </div>
  )
}

export default View;
