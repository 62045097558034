import React, { forwardRef, Fragment } from 'react'
import { Controller } from 'react-hook-form'

// how to use:
// <ButtonGroup key={item.id} {...register(item.field)} item={item} pieces={{ control, errors, toParent, size }} />

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  if(!item.options) item.options = [];
  if(!pieces.vars) pieces.vars = {};
  if(!pieces.vars[item.optionVar]) pieces.vars[item.optionVar] = [];
  let options = item.options.concat(pieces.vars[item.optionVar]);

  return (
    <Controller
      name={item.field}
      control={pieces.control}
      rules={{ required: item.required }}
      render={({ field }) => {
        if(!field.value) field.value = '';

        return (
          <div className={ pieces.size === 'sm' ? 'my-0' : 'my-3'}>
            {item.label && (<label className="d-block mb-1" htmlFor={item.field}>{item.label}</label>)}
            {item.note && (<p className="mb-1 text-muted fst-italic small">{item.note}</p>)}
            <div className="btn-group" role="group">
              { options.length===0 && <div className="alert alert-warning p-1 text-center small">No options available</div>}
              { options.map((opt, idx) => {
                // these options are hard-coded into the form schema
                // leaving this here in case you want to append TBD/null etc options
                if(!opt.label) opt.label = opt.value;
                return (
                  <Fragment key={`${item.field}-select-arr-${idx}`}>
                    <input {...field}
                      ref={field.ref}
                      type="radio"
                      name={item.field}
                      value={opt.value}
                      id={`${pieces.formId}-${item.id}-${item.field}-opt-${idx}`}
                      checked={opt.value === field.value}
                      className="btn-check"
                      onChange={() => {
                        pieces.setValue(`${item.field}`, opt.value);
                        if(pieces.toParent) pieces.toParent({ type: 'button-group', name: item.field, value: opt.value });
                      }}
                      disabled={item.disabled} />
                    {/* need to include id for label clickability */}
                    <label htmlFor={`${pieces.formId}-${item.id}-${item.field}-opt-${idx}`}
                      className={`btn btn-outline-primary ${(pieces.size) ? "btn-"+pieces.size : ""}`}>
                      {opt.label}
                    </label>
                  </Fragment>
                )
              })}
            </div>
            {pieces.errors[item.field] && <p className="mt-0 error">{item.errorMsg}</p>}
          </div>
        )
      }}
    />
  )
});

export default Field;
