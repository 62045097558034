import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-rotatedmarker';

import { emptyObj } from '../../../../utils/functions'

// lklklk: need to implement rotatable marker

// lklklk: also need to implement the wider dimensions for directionals


// lKLKLK INSTEAD OF AN ENTIRELY DIFFERENT ICON
// can we just adjust the border color

// const rotatingIcon  = new L.divIcon({
//   iconSize: [10, 10],
//   html: `<div class="divIcon" style="
//   transform: translate(-50%, -50%);
//   width: `+mkrWidth+`px;
//   height: `+mkrHeight+`px;
//   background-color: red;
//   border: 2.5px solid white;
//   border-radius: `+mkrRadius+`px;"></div>`
// });

const View = (props) => {
  const { statuses, mode, plot, zoom, toParent } = props;
  const draggable = plot.disabled ? false : props.draggable;
  const opacity   = plot.disabled ? 0.3 : 1;
  const [position, setPosition] = useState([plot.styling.lat, plot.styling.lng])
  const [styling, setStyling] = useState({ width: 8, height: 8, radius: 9, bgColor: '#000', border: '2.5px solid #000'});

  const markerRef = useRef(null); // actual marker reference for setRotationAngle
  const mouseRef = useRef(); // stores relative mouse Y change
  const angleRef = useRef(); // stores the computed angle based on mouse Y change
  const shiftRef = useRef(false); // used for counter-cl rotation
  const [rotating, setRotating] = useState(false); // used to trigger marker color change

  const icon = new L.divIcon({
    iconSize: [10, 10],
    html: `<div class="divIcon" style="
    transform: translate(-50%, -50%);
    width: `+styling.width+`px;
    height: `+styling.height+`px;
    background-color: `+styling.bgColor+`;
    border: `+styling.border+`;
    border-radius: `+styling.radius+`px;"></div>`
  });

  const eventHandlers = useMemo(() => ({
    click() {
      blur();
      if(toParent) toParent({ type: 'view plot', value: { id: plot.id, appId: plot.appId, phaseId: plot.phaseId }, appId: plot.appId });
    },
    contextmenu() {
      if(!draggable) return;
      startRotation();
    },
    dragend() {
      if(markerRef.current !== null) {
        let latLng = markerRef.current.getLatLng(); 
        setPosition(latLng);
        if(toParent) toParent({ type: 'plots moved', value: [{ id: plot.id, appId: plot.appId, phaseId: plot.phaseId, lat: latLng.lat, lng: latLng.lng }] });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [plot, draggable, toParent])

  useEffect(() => {
    if(!markerRef.current) return;
    let rotation = plot.styling.rotation ? plot.styling.rotation : 0;
    angleRef.current = rotation;
    markerRef.current.setRotationAngle(rotation);
  }, [plot])

  useEffect(() => {
    setStyling((prev) => {
      let style;
      if(zoom>=23) 
        style = { ...prev, width: 30, height: 15, radius: 15 };
      else if(zoom===22) 
        style = { ...prev, width: 20, height: 9, radius: 9 };
      else if(zoom===21)
        style = { ...prev, width: 15, height: 8, radius: 9 };
      else
        style = { ...prev, width: 8, height: 8, radius: 9 };
      return style;
    })
  }, [zoom])

  useEffect(() => {
    if(emptyObj(plot.display)) return;
    if(plot.disabled && mode===2) return;

    let fillColor = plot.display.locatorFill ? plot.display.locatorFill : '#000';
    let borderColor = plot.display.borderColor ? plot.display.borderColor : '#000';

    if(mode===2 && statuses?.length > 0) {
      let status = statuses.find(x => x.value===plot.publicStatus);
      fillColor = status ? status.fillColor : '#ff0';
      borderColor = status ? status.fillColor : '#ff0';
    } else if(rotating) {
      fillColor = '#f00';
      borderColor = '#f00';
    }

    setStyling((prev) => {
      let style = { ...prev};
      style.bgColor = fillColor;
      style.border = `2px solid ${borderColor}`;
      return style;
    })
  }, [mode, statuses, plot, rotating])

  const blur = () => {
    // unselect so it functions better on mobile
    if (document.activeElement !== document.body)
        document.activeElement.blur();
  }

  const startRotation = () => {
    setRotating(true);
    window.addEventListener('click',handleClick);
    window.addEventListener('mousemove',handleMouse);
    window.addEventListener('keydown',handleKeyDown);
    window.addEventListener('keyup',handleKeyUp);
  }

  const stopRotation = () => {
    blur();
    setRotating(false);
    window.removeEventListener('click',handleClick);
    window.removeEventListener('mousemove',handleMouse);
    window.removeEventListener('keydown',handleKeyDown);
    window.removeEventListener('keyup',handleKeyUp);
  }

  const handleMouse = (e) => {
    if(!mouseRef.current) mouseRef.current = e.clientY;
    let newAngle = angleRef.current + (e.clientY - mouseRef.current);
    markerRef.current.setRotationAngle(newAngle);
  }

  const handleClick = (e) => {
    stopRotation();
    let rotation = Math.round(markerRef.current.options.rotationAngle);
    if(toParent) toParent({ type: 'rotated', value: { id: plot.id, appId: plot.appId, phaseId: plot.phaseId, rotation }});
  }

  const handleKeyDown = (e) => {
    blur();
    if(e.keyCode===16) {
      shiftRef.current = true;
    } else if(e.keyCode===27) {
      stopRotation();
      markerRef.current.setRotationAngle(angleRef.current);
    } else if(e.keyCode===82) {
      // rotate with r key and shift combo
      let shift = shiftRef.current;
      switch (angleRef.current) {
        case 0:  angleRef.current  = !shift ? 45  : 315; break;
        case 45: angleRef.current  = !shift ? 90  : 0; break;
        case 90: angleRef.current  = !shift ? 135 : 45; break;
        case 135: angleRef.current = !shift ? 180 : 90; break;
        case 180: angleRef.current = !shift ? 225 : 135; break;
        case 225: angleRef.current = !shift ? 270 : 180; break;
        case 270: angleRef.current = !shift ? 315 : 225; break;
        case 315: angleRef.current = !shift ? 0   : 270; break;
        default: angleRef.current  = 0;
      }
      markerRef.current.setRotationAngle(angleRef.current);
    }
  }

  const handleKeyUp = (e) => {
    if(e.keyCode===16) {
      shiftRef.current = false;
    }
  }

  // hide disabled ones if we are viewing status
  if(plot.disabled && mode===2) return;

  return (
    <Marker
      appId={plot.appId}
      phaseId={plot.phaseId}
      draggable={draggable}
      opacity={opacity}
      eventHandlers={eventHandlers}
      icon={icon}
      rotationOrigin="left top"
      position={position}
      ref={markerRef}
    >
    <Tooltip direction="top">{plot.display.textLabel}</Tooltip>
  </Marker>
  );
}

export default View;