import React, { Fragment, useEffect, useState } from 'react';
import EditBtn from '../edit/Options';
import DirectionBtns from '../../../../buttons/DirectionBtns';
import { EyeSlashFill } from 'react-bootstrap-icons';

import Checkboxes     from '../../../forms/Checkboxes'
import ButtonGroup    from '../../../forms/ButtonGroup'
import FloatingSelect from '../../../forms/FloatingSelect'
import Select         from '../../../forms/Select'
import Autosuggest    from '../../../forms/Autosuggest'

const View = ({ display, siblingCount, objIdx, obj: ogObj, colIdx, colName, pieces, avail, toParent }) => {
  const [obj, setObj] = useState({});
  const [show, setShow] = useState(false);
  const toggle = () => setShow(s => !s);

  const componentMap = {
    'checkboxes': Checkboxes,
    'button-group': ButtonGroup,
    'floating-select': FloatingSelect,
    'select': Select,
    'autosuggest': Autosuggest,
  };

  useEffect(() => {
    let obj = {...ogObj};
    if(obj.schema) {
      let found = pieces.schemas.find(x => x.appId === obj.schema.appId);
      if(found) {
        let opts = found.schema;
        if(obj.schema.label && obj.schema.label !== 'label') {
          // override/select label if needed
          opts = found.schema.map(x => ({ value: x.value, label: x[obj.schema.label] }));
        }
        obj.options = opts;
      }
    }

    setObj(obj);
  }, [ogObj, pieces.schemas])

  const fromChild = ({ type, value }) => {
    if(type==='direction' && toParent) {
      toParent({ type: 'move block', direction: value, colIdx, colName, objIdx });
    }
  }

  const Component = componentMap[obj.type] || (() => <div className="alert alert-danger p-1 text-center small">Not found: {obj.type}</div>);

  if(!display && !show) return (
    <div className="d-grid">
      <button className="btn btn-warning p-0 pt-1 mt-1 ms-4" onClick={toggle} />
    </div>
  )

  return (
    <Fragment>
      <div className="d-flex">
        <DirectionBtns idx={objIdx} count={siblingCount} toParent={fromChild} styles={{paddingTop:13}} />
        <div className="flex-grow-1">
          <Component item={obj} pieces={pieces} />
        </div>
        { show && (
          <button className="align-self-start btn btn-xs btn-warning mt-2 mx-1" onClick={toggle}>
            <EyeSlashFill size={16} />
          </button>
        )}
        <div className="d-flex flex-column">
          <EditBtn obj={ogObj} objIdx={objIdx} colIdx={colIdx} colName={colName} pieces={pieces} avail={avail} toParent={toParent} />
          <p className="small text-muted">{obj.field}</p>
        </div>
      </div>
    </Fragment>
  )
}

export default View;
