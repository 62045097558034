import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { useAxios } from '../../../../hooks/useAxios';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Arrow90degRight } from 'react-bootstrap-icons';
import dayjs from 'dayjs'

// note: you stringify the received appIds so it doesn't
// rerender every single time; it seemed like the only way

import { emptyObj, getDifference } from '../../../../utils/functions';

const View = (props) => {
  const { phase, phases, plots, appIdStr, toParent } = props;
  const hideToggle = props.hideToggle ? props.hideToggle : false;
  const { userDetails } = useContext(UserContext);
  const [show, setShow] = useState(hideToggle);
  const toggle = () => setShow((s) => !s);

  const copyRef = useRef({});
  const [appIds, setAppIds] = useState([]);
  const [availPhases, setAvailPhases] = useState([]);
  const [phaseId, setPhaseId] = useState();
  const [existing, setExisting] = useState([]);
  const [clobber, setClobber] = useState(false);
  
  const { serverCall, pending, error } = useAxios();
  const errorCode = '7661';

  useEffect(() => {
    if(emptyObj(phase) || !appIdStr || phases?.length===0 || plots?.length===0) return;
    copyRef.current = {};
    let appIdArr = JSON.parse(appIdStr);
    let unique = [...new Map(appIdArr?.map(item => [item.id, item])).values()];
    setAppIds(unique);

    let phaseIds = phases.map(x => x.id);

    for(const plot of appIdArr) {
      let filtered = plots.filter(x => x.appId === plot.appId);
      let plotPhaseIds = filtered.map(x => x.phaseId);
      let difference = getDifference(phaseIds, plotPhaseIds);
      // console.log('--------');
      // console.log(plot.appId + ' - ' +plot.id);
      // console.log('already in:')
      // console.log(plotPhaseIds);
      // console.log('can be copied into:')
      // console.log(difference);

      // create the phaseId key and push plot id to it
      for(const phaseId of difference) {
        if(phase.id===phaseId) continue;
        if(!copyRef.current[phaseId]) copyRef.current[phaseId] = { copy: [], exists: [] };
        if(!copyRef.current[phaseId].copy.includes(plot.id))
          copyRef.current[phaseId].copy.push(plot.id);
      }

      for(const phaseId of plotPhaseIds) {
        if(phase.id===phaseId) continue;
        if(!copyRef.current[phaseId]) copyRef.current[phaseId] = { copy: [], exists: [] };
        if(!copyRef.current[phaseId].exists.includes(plot.id))
          copyRef.current[phaseId].exists.push(plot.id);
      }
    }
  }, [hideToggle, phase, phases, plots, appIdStr])

  useEffect(() => {
    if(emptyObj(phase) || phases?.length===0) return;
    let avail = [];
    for (const [key] of Object.entries(copyRef.current)) {
      if(copyRef.current[key].copy.length === 0 && copyRef.current[key].exists.length === 0) continue;
      let found = phases.find(x => x.id === Number(key));
      if(found) avail.push(found);
    }
    setPhaseId(avail[0]?.value ? avail[0].value : null);
    setAvailPhases(avail);
  }, [phase, phases])

  useEffect(() => {
    if(!phaseId || plots?.length===0) return;
    let active = copyRef.current[phaseId];

    let existing = [];
    for(const id of active.exists) {
      let found = plots.find(x => x.id === id);
      let label = found.display.textLabel.includes(found.name) ? found.display.textLabel : found.display.textLabel+` (${found.name})`;
      if(found) existing.push(label);
    }

    setExisting(existing);
    setClobber(false);
  }, [phaseId, plots])

  const change = (e) => {
    e.target.blur();
    setPhaseId(Number(e.target.value));
  }

  const confirmed = async () => {
    if(!phaseId || (copyRef.current[phaseId].copy.length === 0 && copyRef.current[phaseId].exists.length === 0)) return;
    let ids = [...copyRef.current[phaseId].copy, ...copyRef.current[phaseId].exists];

    let res = await serverCall({ method: 'POST', data: { 
      phaseId, 
      ids, 
      clobber,
      createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      createdBy: userDetails.id
    }, url: '/mapping/plots/copy-to-phase', eamsAuth0: true });
    if(res.status!==200) return alert('Error copying to phase. Contact support. (7661)');

    // update the local store
    if(toParent) toParent({ type: 'copied to phase', value: res.data });
  }

  if(phases?.length < 2) return null; // don't show if there's only one phase
  if(availPhases.length === 0) return null; // don't show if there are no available phases to link into

  return (
    <Fragment>
      { !show && (
        <div className="float-end ms-3">
          <OverlayTrigger placement="left" overlay={<Tooltip id={`copy-to-phase`}>Copy to Another Phase</Tooltip>}>
            <button className="btn bg-white border rounded" onClick={toggle}>
              <Arrow90degRight size={16} className="my-1" />
            </button>
          </OverlayTrigger>
        </div>
      )}

      { show && (
        <div className="clearfix my-3">
          <h5>Copy to Another Phase</h5>
          <p className="mb-1">This action will do the following:</p>
          <ol>
            <li>Copy {appIds.length === 1 ? 'this plot' : 'these plots'} to the selected phase</li>
            <li>Update the new {appIds.length === 1 ? 'plot\'s action' : 'plots\' actions'} to TBD</li>
            <li>Update the new {appIds.length === 1 ? 'plot\'s status' : 'plots\' statuses'} to Pending</li>
            <li>Change {appIds.length === 1 ? 'this plot\'s status' : 'these plots\' statuses'} to "Moved to Another Phase"</li>
          </ol>
          <p className="mb-2">Select the desired phase in the dropdown below and press <b>confirm</b> to copy {appIds.length === 1 ? 'plot' : 'plots'}. If the phase is not listed below, {appIds.length === 1 ? 'this plot already exists' : 'these plots already exist'} within that phase.</p>
          { existing.length > 0 && (
            <Fragment>
              <p className="text-danger"><b>Note:</b> {existing.length === 1 ? `this plot exists` : `${existing.length} of these plots exist`} in the selected phase. Please choose if you want to <i>reactivate</i> (un-delete) OR <i>replace</i> (clobber) the existing {existing.length===1 ? `plot` : `plots`}.</p>
              { existing.length > 1 && (
                <p className="text-danger">Existing plots: {existing.join(', ')}</p>
              )}
            </Fragment>
          )}
          <div className="d-flex align-items-center">
            <select className="form-select form-select-sm d-block border" 
              value={phaseId} onChange={change}>
              {availPhases.map((obj) => (
                <option key={`group-${obj.id}`} value={obj.id}>{obj.label}</option>
              ))}
            </select>
            { existing.length > 0 && (
              <div className="btn-group ms-2" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" className="btn-check" id="btnReplace" autoComplete="off" checked={clobber} onChange={() => setClobber(true) } />
                <label className="btn btn-sm btn-outline-primary" htmlFor="btnReplace">Replace</label>
                <input type="radio" className="btn-check" id="btnReactivate" autoComplete="off" checked={!clobber} onChange={() => setClobber(false) } />
                <label className="btn btn-sm btn-outline-primary" htmlFor="btnReactivate">Reactivate</label>
              </div>
            )}
            { !hideToggle && (
              <button className="btn btn-sm btn-outline-dark ms-2" onClick={toggle}>
                Cancel
              </button>
            )}
            <button className={`btn btn-sm ms-2 ${error ? 'btn-danger' : 'btn-success'}`} type="button" disabled={pending} onClick={confirmed}>
            { !pending && !error && 'Confirm'}
            { pending === true && (
              <Fragment>
                <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                Copying...
              </Fragment>
            )}
            { error && (`Error saving (${errorCode})`)}
            { pending ==='success' && 'Copied!'}
          </button>
          </div>
          {!hideToggle && <hr />}
        </div>
      )}
    </Fragment>
  )
}

export default View;
