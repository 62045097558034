import React, { forwardRef, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

// how to use:
// <ButtonGroup key={item.id} {...register(item.field)} item={item} pieces={{ control, errors, toParent, size }} />

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  if(!item.options) item.options = [];
  if(!pieces.vars) pieces.vars = {};
  if(!pieces.vars[item.optionVar]) pieces.vars[item.optionVar] = [];
  let options = item.options.concat(pieces.vars[item.optionVar]);
  let columns = item.options.length < 4 ? 1 : (item.columns ? item.columns : 1);

  item.toggleAll = true;

  const value = pieces.watch(item.field);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    let selected = value ? value.filter(Boolean).length : 0;
    if(selected === options.length)
      setAllChecked(true);
    else
      setAllChecked(false);

    if(item.toggleAll) {
      var checkbox = document.getElementById(`${item.field}-toggleAll`);
      if(checkbox) {
        if(selected > 0 && selected < options.length)
          checkbox.indeterminate = true;
        else
          checkbox.indeterminate = false;
      }
    }
  }, [value, options.length, item.toggleAll, item.field])

  const toggleChecked = (e) => {
    e.target.blur();
    if(e.target.checked) {
      const values = [...new Set(options.map(x => x.value))];
      pieces.setValue(`${item.field}`, values);

      // manually set the first one to 'on' in case it's TBD
      if(options[0].value==='') pieces.setValue(`${item.field}[0]`,true);
    } else {
      // lklklk should the "false" below actually be null?
      const initial = Array.from({ length: options.length }, () => (false))
      pieces.setValue(`${item.field}`, initial);
    }
  }

  const toggle = (e) => {
    e.target.blur();
    let items = value ? [...value] : [];
    if(e.target.checked)
      items.push(e.target.value);
    else {
      let idx = items.indexOf(e.target.value);
      items.splice(idx, 1);
    }

    pieces.setValue(`${item.field}`, items);
    if(pieces.toParent) pieces.toParent({ type: 'checkboxes', name: item.field, value: items });
  }

  return (
    <Controller
      name={item.field}
      control={pieces.control}
      rules={{ required: item.required }}
      render={({ field }) => {
        if(!field.value) field.value = [];

        return (
          <div className={ pieces.size === 'sm' ? 'my-0' : 'my-3'}>
            {item.label && (<label className="d-block mb-1" htmlFor={item.field}>{item.label}</label>)}
            {item.note && (<p className="mb-1 text-muted fst-italic small">{item.note}</p>)}
            {!item.disabled && item.toggleAll && options.length > 1 && (
              <div className="form-check mt-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="toggleAll"
                  id={`${item.field}-toggleAll`}
                  checked={allChecked}
                  onChange={toggleChecked}
                  />
                <label className="form-check-label" htmlFor={`${item.field}-toggleAll`}>Select all</label>
              </div>
            )}

            <div className="mt-2" style={{columns}}>
              { options.length===0 && <div className="alert alert-warning p-1 text-center small">No options available</div>}
              { options.map((opt, idx) => {
                // these options are hard-coded into the form schema
                // leaving this here in case you want to append TBD/null etc options
                if(!opt.label) opt.label = opt.value;

                return (
                  <div key={`${item.field}-select-arr-${idx}`} className="clearfix">
                    <div className="mb-2 form-check">
                      <input {...field.rest}
                        ref={field.ref}
                        type="checkbox"
                        value={opt.value}
                        id={`${pieces.formId}-${item.id}-${item.field}-opt-${idx}`}
                        defaultChecked={value?.includes(opt.value)}
                        className="border form-check-input"
                        onClick={toggle}
                        disabled={item.disabled} />
                      {opt.label && (<label className="form-check-label" htmlFor={`${pieces.formId}-${item.id}-${item.field}-opt-${idx}`}>{opt.label}</label>)}
                    </div>
                  </div>
                )
              })}
            </div>
            {item.note && (<p className="mb-1 text-muted fst-italic small">{item.note}</p>)}
            {pieces.errors[item.field] && <p className="mt-0 error">{item.errorMsg}</p>}
          </div>
        )
      }}
    />
  )
});

export default Field;
