import React, { Fragment, useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useOutletContext } from 'react-router-dom'
import { useAxios } from '../../../hooks/useAxios';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs'
import { ArrowsCollapse, ArrowsExpand, BoxArrowInUpRight, ArrowDownUp } from 'react-bootstrap-icons'

import { UserContext } from '../../../context/UserContext';

import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import LibraryEdit from './components/LibraryEdit';
import Category from './components/Category';
import BulkEdit from './components/BulkEdit';
import TypeEdit from './components/TypeEdit';

import Collapsed from '../../../components/system/Collapsed';
import { emptyObj, removeMultiple } from '../../../utils/functions';

const View = (props) => {
  const navigate = useNavigate();
  const { parentType, parentId, parentAppId, library, project, schema, setLibraries, types, setTypes } = props;
  const { userDetails, setProjects, schemas, libraries } = useContext(UserContext);
  const { serverCall } = useAxios();

  const [bulkSchema, setBulkSchema] = useState([]);
  const [editSchema, setEditSchema] = useState([]);
  const [activeType, setActiveType] = useState({});
  const [selected, setSelected] = useState([]);

  // category toggles
  const [open, setOpen] = useState(true);
  const toggleOpen = () => setOpen((s) => !s);

  // modal + offcanvas controls
  const [show, setShow] = useState(false);
  const handleClose = () => setShow();
  const closePanel = () => setActiveType({});

  useEffect(() => {
    let found = schemas.find(x => x.name === 'type-edit');
    if(found) setEditSchema(found.schema);

    found = schemas.find(x => x.name === 'type-bulk-edit');
    if(found) setBulkSchema(found.schema);
  }, [schemas])

  const addCategory = () => {
    let arr = [...schema];
    arr.push({ name: 'New Category', uid: nanoid(), ids: [] });
    (parentType === 'project') ? updateProject('schema', arr) : updateGlobal('schema', arr);
  }

  const linkLibrary = async (e, appId, id) => {
    let linked = [...project.linked];
    if(e.target.checked && !linked.includes(id)) {
      linked.push(id);

      // then get types...
      let res = await serverCall({
        method: 'GET', url: `/library/${appId}/types`, eamsAuth0: true,
        headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId }
      });
      if(res.status===200) {
        // combine but only keep unique by id
        setTypes(prev => {
          let arr = [...prev, ...res.data.types];
          let merged = arr.reduce((unique, o) => {
            if(!unique.some(obj => obj.id === o.id))
              unique.push(o);
            return unique;
          },[]);

          // then resort
          merged = merged.sort((a, b) => ((a || {}).label || '').localeCompare((b || {}).label || '', undefined, { numeric: true, sensitivity: 'base' }));
          return merged;
        })
      }

    } else if(!e.target.checked) {
      // false, try to remove
      let idx = linked.indexOf(id);
      if(idx > -1) linked.splice(idx, 1);

      // then remove types...
      setTypes(prev => {
        let arr = [...prev];
        return arr.filter(x => x.libraryId!==id);
      })
    }

    updateProject('linked', linked)
  }

  const updateProject = async (type, data) => {
    setProjects((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId === parentAppId);
      if(type==='schema') arr[idx].library = data;
      if(type==='linked') arr[idx].linked = data;
      return arr;
    })

    let update = { 
      id: parentId,
      updatedBy: userDetails.id,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss')
    };
    if(type==='schema') update.library = data;
    if(type==='linked') update.linked = data;
    await serverCall({ method: 'PATCH', data: update, url: `/mapping/projects/${parentAppId}`, eamsAuth0: true });
  }

  const updateGlobal = async (type, data) => {
    setLibraries((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId === parentAppId);
      if(type==='schema') arr[idx].schema = data;
      if(type==='status') arr[idx].status = data;
      return arr;
    })

    let update = { 
      id: parentId, 
      updatedBy: userDetails.id,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss')
    };
    if(type==='schema') update.schema = data;
    await serverCall({ method: 'PATCH', data: update, url: `/library/${parentAppId}`, eamsAuth0: true });
  }

  const fromChild = (data) => {
    const { type, value } = data;

    if(type==='edit type') {
      let found = types.find(x => x.id === value);
      if(found) setActiveType(found);

    } else {
      let arr = [...schema];
      if(type==='category update') {
        let idx = arr.findIndex(x => x.uid === value.uid);
        arr[idx].name = value.name;
  
      } else if(type==='category deleted') {
        let idx = arr.findIndex(x => x.uid === value);
        arr.splice(idx, 1);
  
      } else if(type==='add types') {
        let idx = arr.findIndex(x => x.uid === value.uid);
        arr[idx].ids = [...arr[idx].ids, ...value.ids];
  
      } else if(type==='remove types') {
        // check if we are manually passing the id or deleting selected from bulk
        let remove = value ? value : selected;
        for(const category of arr) {
          category.ids = removeMultiple(category.ids, remove);
        }
      }
  
      if(parentType==='project')
        updateProject('schema', arr);
      else if(parentType==='global')
        updateGlobal('schema', arr);
    }
  }

  return (
    <Fragment>
      <div className="row h-100 overflow-hidden">
        <div className="col-sm-9 h-100 overflow-scroll">
          { schema.length === 0 && (
            <div className="mb-3 p-2 bg-white rounded">
              <p className="p-3 mb-0">This looks like a new library. Add a category to get started!</p>
            </div>
          )}
          { schema.sort((a, b) => ((a || {}).name || '').localeCompare((b || {}).name || '', undefined, { numeric: true, sensitivity: 'base' })).map(obj => {
            let filtered = types.filter(x => obj.ids.includes(x.id));
            return <Category key={obj.uid} obj={obj} open={open} parentType={parentType} parentId={parentId} toParent={fromChild} types={filtered} setTypes={setTypes} selected={selected} setSelected={setSelected} />;
          })}
        </div>
        <div className="col-sm-3 h-100 overflow-scroll">
          <h5>Actions</h5>
          <button className="btn btn-outline-dark me-2 mb-2" onClick={toggleOpen}>
            { open && (
              <Fragment><ArrowsCollapse size={18} /> Collapse</Fragment>
            )}
            { !open && (
              <Fragment><ArrowsExpand size={18} /> Expand</Fragment>
            )}
          </button>
          <br />
          <button className="btn btn-outline-dark me-2 mb-1" onClick={addCategory}>Add Category</button>
          <button className="btn btn-outline-dark me-2 mb-1" onClick={() => navigate('./import')}>
            <ArrowDownUp className="me-1" /> Bulk Import
          </button>
          <br />
          { selected.length > 1 && (
            <button className="btn btn-outline-dark me-2 mb-2" onClick={() => setShow(true)}>Edit {selected.length} Types</button>
          )}

          { (parentType==='project' && libraries.length > 0) && (
            <Fragment>
              <hr />
              <Collapsed title={`Linked Libraries ${project.linked?.length > 0 ? `(${project.linked.length})` : ''}`}>
                <div className="mt-2">
                  { libraries.filter(x => !x.type).map(obj => (
                    <div key={obj.appId} className="form-check">
                      <input className="form-check-input" type="checkbox" id={obj.appId} defaultChecked={project.linked?.includes(obj.id)} onChange={(e) => linkLibrary(e, obj.appId, obj.id)} />
                      <label className="form-check-label" htmlFor={obj.appId}>
                        {obj.name}
                      </label>
                      <NavLink to={`/libraries/library/${obj.appId}`} className="d-inline-block ms-2">View <BoxArrowInUpRight/></NavLink>
                    </div>
                  ))}
                </div>
              </Collapsed>
            </Fragment>
          )}
          { parentType==='global' && (
            <Fragment>
              <hr />
              <Collapsed title="Advanced">
                <div className="mt-2">
                  <LibraryEdit obj={library} setLibraries={setLibraries} setTypes={setTypes} toParent={fromChild} />
                </div>
              </Collapsed>
            </Fragment>
          )}
        </div>
      </div>

      <Modal size="md" show={show} onHide={handleClose} centered scrollable>
        <BulkEdit userDetails={userDetails} schema={bulkSchema} selected={selected} setTypes={setTypes} handleClose={handleClose} toParent={fromChild} />
      </Modal>

      <Offcanvas show={!emptyObj(activeType)} style={{width:500}} onHide={closePanel} placement="end" scroll={true} keyboard={false} backdrop={false} className="shadow">
        <TypeEdit userDetails={userDetails} obj={activeType} schema={editSchema} setTypes={setTypes} handleClose={closePanel} toParent={fromChild} />
      </Offcanvas>
    </Fragment>
  )
}

export const LibraryOutlet = (props) => {
  const { parentType } = props;
  const { library, setLibraries, project, types, setTypes } = useOutletContext();

  if(emptyObj(library) && emptyObj(project))
    return <div>Error.</div>;
  if(parentType==='global')
    return <View parentType="global" library={library} parentId={library.id} parentAppId={library.appId} schema={library.schema} status={library.status} setLibraries={setLibraries} types={types} setTypes={setTypes} />
  else
    return <View parentType="project" project={project} parentId={project.id} parentAppId={project.appId} schema={project.library} types={types} setTypes={setTypes} />
}
