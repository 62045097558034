import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useAxios } from '../../../../hooks/useAxios';

import { emptyObj } from '../../../../utils/functions';
import { getMarkup } from '../../../../components/system/markups/utils/render';
import DeleteBtn from '../../../../components/buttons/DeleteBtn';
import EditBtn from './Edit';

const View = (props) => {
  const { img, mini, setPhotos } = props;
  const imgClasses = props.imgClasses ? props.imgClasses : 'img-fluid border mb-1';
  const figClasses = props.figClasses ? props.figClasses : 'text-muted small';
  const { serverCall } = useAxios();

  const [src, setSrc] = useState(null);

  useEffect(() => {
    if(emptyObj(img) || !img.url) return;
    if(!emptyObj(img.markups)) {
      (async () => {
        let render = await getMarkup({ img });
        if(render) setSrc(render);
      })();
    } else {
      setSrc(img.url);
    }
  }, [img])

  const fromChild = async (data) => {
    const { type } = data;

    if(type === 'delete confirmed') {
      let res = await serverCall({ method: 'PATCH', data: { appId: img.appId, id: img.id, status: 'D' }, url: `/mapping/photos/${img.appId}`, eamsAuth0: true });
      if(!res.status===200) return alert('Error deleting photo, contact support. (ER761)');
      
      if(setPhotos) {
        setPhotos(prev => {
          let arr = [...prev];
          let idx = arr.findIndex(x => x.id === img.id);
          arr[idx].status = 'D';
          return arr;
        })
      }
    }
  }

  if(!src) {
    let width = mini ? 115 : '100%';
    let height = mini ? 150 : 250;
    return (
      <div className="d-flex justify-content-center align-items-center border" style={{width, height}}>
        <div className="spinner-border spinner-border-sm text-muted" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  if(mini && src) {
    return (
      <a href={img.url} target="_blank" rel="noopener noreferrer">
        <img src={src} className="horizontal-thumb border" alt="Reference" loading="lazy" crossOrigin="anonymous" />
      </a>
    )
  }

  return (
    <Fragment>
      <Helmet>
        <style>{`
          .overlay {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0);
            transition: background 0.5s ease;
          }

          .button { 
            top: 12px;
            right: 12px;
            opacity: 0; 
            transition: opacity .35s ease;
          }

          .img-wrapper:hover .overlay {
            display: block;
            background: rgba(0, 0, 0, .2);
          }

          .img-wrapper:hover .button { 
            opacity: 1;
          }
        `}</style>
      </Helmet>
      <figure className="d-inline-block">
        <div className="img-wrapper position-relative">
          <img src={src} className={imgClasses} alt="Reference" loading="lazy" crossOrigin="anonymous" />
          <a href={img.url} target="_blank" rel="noreferrer">
            <div className="overlay position-absolute"></div>
          </a>
          <div className="button position-absolute">
            <EditBtn key={`photo-${img.id}`} obj={img} setPhotos={setPhotos} />
            <DeleteBtn toParent={fromChild} wrapper="d-inline-block" styles="btn btn-sm bg-white" size="16" />
          </div>
        </div>
        <figcaption className={figClasses}>{img.createdAt}</figcaption>
      </figure>

    </Fragment>
  )
}

export default View;