import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react';
import { WorkerContext } from '../../../../context/WorkerContext';
import { useAxios } from '../../../../hooks/useAxios';
import { useForm } from 'react-hook-form';

import Modal from 'react-bootstrap/Modal';
import Generated  from '../../../../components/system/Generated';
import DeleteBtn from '../../../../components/buttons/DeleteBtn';
// import EmailError from '../system/EmailError';

import { formatBytes } from '../../../../utils/functions';
import Dropzone from '../../../../components/system/dropzone/Dropzone';
import dayjs from 'dayjs'

import { formObj } from '../../../../utils/schemas';

// next steps:
// 2. add a dropzone to replace, add to log
// 3. ability to create share link?

// future:
// should find all of the file logs to see if you need to replace it with another file
// also add something where you can press a button to make one of them active, similar to ... checklists?

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'id', },
    { id: '3', type: 'hidden', field: 'status', },
    { id: '4', type: 'floating-input', label: 'File name', field: 'name', },
  ]},
]

const View = (props) => {
  const { debug, convert, obj, log, setActiveLog, setFiles, setFilesLog, handleCancel } = props;
  const formText = { submit: 'Save', pending: 'Saving', success: 'Saved!', cancel: 'Cancel' };
  const errorCode = '7223'

  const { startWorker } = useContext(WorkerContext);
  const raster = useRef(convert);

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  useEffect(() => {
    raster.current = convert;
  }, [convert])

  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [obj, reset])

  const onSubmit = async (data) => {
    if(debug) console.log(data);
    let res = await serverCall({ method: 'PATCH', data, url: `/system/files/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating file. Contact support.') // lklklk

    setFiles((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.id === obj.id);
      arr[idx].name = data.name;
      arr[idx].status = data.status;
      return arr;
    })

    handleCancel();
  };

  const fromChild = async (data) => {
    const { type, value } = data;
    console.log(data);
    if(type==='file uploaded') {
      let update = { id: obj.id, file: value };
      let res = await serverCall({ method: 'PATCH', data: update, url: `/system/files/replace/${obj.appId}`, eamsAuth0: true });
      if(res.status!==200) return alert('Error replacing file.'); // lklklk
      console.log(res.data);

      // add to the log and filtered log
      setFilesLog((prev) => {
        let arr = [...prev];
        arr.push(res.data);
        return arr;
      })

      setActiveLog((prev) => {
        let arr = [...prev];
        arr.push(res.data);
        return arr;
      })

      // then update the parent for the current record
      // artificially adding a timeout bc it seemed like
      // s3 was showing a missing link if we did it too fast
      setTimeout(() => {
        setFiles((prev) => {
          let arr = [...prev];
          let idx = arr.findIndex(x => x.id === obj.id);
          arr[idx].activeId = res.data.id;
          // in case we allow to change file types:
          arr[idx].tpe = res.data.type;
          return arr;
        })
      }, 500)

      let worker = { convert: raster.current, files: [res.data] };
      let conv = await serverCall({ method: 'POST', data: worker, url: '/services/cc-thumbs/start', eamsAuth0: true });
      for(const job of conv.data) {
        if(!job.id || !job.links.self) continue;
        startWorker({ type: 'cc-thumbs', pieces: { subtype: 'thumbnail', name: `${job.eams.originalName} processing`, filename: job.eams.source, url: job.links.self, value: job.eams, setFilesLog } });
      }

    } else if(type==='delete confirmed') {
      setValue('status','D');
      handleSubmit(onSubmit)();
    }
  }

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Edit File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mb-3">
          <Generated id="edit-file" schema={schema} size="md" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />
        </form>

        <h5>Replace File</h5>
        <Dropzone
            folder="uploads/files/"
            prefix="file-upload"
            toParent={fromChild}
          />

        <h5 className="mt-4">Versions</h5>
        { log.length > 0 && (
          <table className="table table-border table-hover mb-4">
            <thead>
              <tr>
                <th width={100}>Version</th>
                <th>File Name</th>
                <th width={110}>Size</th>
                <th>Created At</th>
                {/* <th className="text-end">Actions</th> */}
              </tr>
            </thead>
            <tbody>
              { log.map((log, idx) => {
                return (
                  <tr key={log.appId}>
                    <td><a href={log.url} target="_blank" rel="noreferrer" download={log.source}>Version {idx + 1}</a></td>
                    <td>{log.originalName}</td>
                    <td>{log.size ? formatBytes(log.size) : ''}</td>
                    <td>{log.createdAt ? dayjs(log.createdAt).format('YYYY-MM-DD HH:mma') : ''}</td>
                    {/* <td className="text-end">
                      <button className="btn btn-sm btn-outline-primary" onClick={() => clicked(obj.appId)}>Edit</button>
                    </td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <DeleteBtn toParent={fromChild} text="Delete File" />

        <button className="btn btn-sm btn-outline-dark ms-auto me-1" type="button" onClick={handleCancel}>
          {formText.cancel}
        </button>

        <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'}`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
          { !pending && !error && (formText.submit)}
          { pending === true && (
            <Fragment>
              <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              {formText.pending}
            </Fragment>
          )}
          { error && (`Error saving (${errorCode})`)}
          { pending ==='success' && formText.success}
        </button>
      </Modal.Footer>
    </Fragment>
  )
}

export default View;
