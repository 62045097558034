
// NOTES
// the pauseSel from interaction temporarily disables these selection actions
// we need this since we are updating the selection from the EditRow checkboxes too
// you have to clear the canvas selection to get the boundary box to work properly,
// so if we don't pause these it clears out the selection

export const handleSelectionCreated = ({ e, debug, canvas, interaction, setSelected }) => {
  if(interaction.pauseSel) return;
  if(debug) console.log('selection created', e);

  // get active objects but filter out ones without an id (ie archlines)
  // let active = canvas.getActiveObjects().filter(x => x.id);
  // original was above; lines aren't selectable so filtering by id may not be needed?
  let active = canvas.getActiveObjects();
  let selected = active.map(x => ({ appId: x.appId, id: x.id, phaseId: x.phaseId, piece: x.piece }));
  interaction.selected = selected;
  if(setSelected) setSelected(selected);
}

export const handleSelectionUpdated = ({ e, debug, canvas, interaction, setSelected }) => {
  if(interaction.pauseSel) return;
  if(debug) console.log('selection updated', e);

  // get active objects but filter out ones without an id (ie archlines)
  // let active = canvas.getActiveObjects().filter(x => x.id);
  // same note as above
  let active = canvas.getActiveObjects();
  let selected = active.map(x => ({ appId: x.appId, id: x.id, phaseId: x.phaseId, piece: x.piece }));
  interaction.selected = selected;
  if(setSelected) setSelected(selected);
}

export const handleSelectionCleared = ({ e, debug, interaction, setSelected }) => {
  if(debug) console.log('selection cleared', e);
  if(interaction.pauseSel) return;
  interaction.selected = [];
  interaction.objMoved = false;
  if(setSelected) setSelected([]);
}

export const handleBeforeSelectionCleared = ({ e, debug, toParent }) => {
  if(debug) console.log('before selection cleared', e);
  if(e.target?.eamsRotated) {
    let item = e.target;
    if(toParent) toParent({ type: 'rotated', value: {
      id: item.id,
      appId: item.appId,
      phaseId: item.phaseId,
      rotation: item.angle
    }});
  }
}