import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { SiteContext } from '../../context/SiteContext';

// future:
// consider adding parse to link to documentation/help
// display as larger error codes?
// possibly send an error to the log to show who, what url, etc

const Component = (props) => {
  const { config } = useContext(SiteContext);
  const msg = (props.msg) ? props.msg : 'You are not authorized to view this page.';
  return (
    <>
      <Helmet>
        <title>{config.title}</title>
        <style>{` body { padding:0 } `}</style>
      </Helmet>
      <div style={{height: '100vh'}} className="d-flex align-items-center justify-content-center">
        {msg}
      </div>
    </>
  );
}

export default Component;
