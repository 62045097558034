import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom'
import { UserContext } from '../../../context/UserContext';
import { useAxios } from '../../../hooks/useAxios';
import dayjs from 'dayjs'
import  { nanoid } from 'nanoid';

import Dropzone from '../../../components/system/dropzone/Dropzone'
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ExcelJS from 'exceljs';

import { emptyObj } from '../../../utils/functions'
import { checkHeaders, reviewTypes } from './utils/types'

const View = (props) => {
  const { parentType } = props;
  const navigate = useNavigate();
  const { serverCall } = useAxios();
  const { project, library, setLibraries, setTypes } = useOutletContext();
  const { userDetails, setProjects } = useContext(UserContext);

  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const [error, setError] = useState(false);
  const closeModal = () => navigate(-1);

  useEffect(() => {
    if(data?.length===0) return;

    (async () => {
      let summaries = [];
      for(const sheet of data) {
        let summary = {};
        if(sheet.type==='type')
          summary = await reviewTypes({ 
            sheet, 
            parentType, 
            parentId: parentType==='project' ? project.id : library.id, 
            library: parentType==='project' ? project.library : library.schema, 
            userDetails
          });

        if(emptyObj(summary)) continue;
        summary = {...sheet, ...summary};
        summaries.push(summary);
        console.log(summary);
      }

      setSummary(summaries);
    })();
  }, [data])

  const fromChild = async ({ files }) => {
    if(files.length === 0) return;

    var reader = new FileReader();
    reader.onload =  async () => {
      let data = reader.result;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(data);

      let sheets = [];
      workbook.eachSheet(sheet => {
        let rows = [];
        let headers = [];

        sheet.eachRow(row => {
          let values = [];
          let model = row.model;
          if (model.number === 1) {
            row.eachCell({includeEmpty: true}, (c, i) => {
              i && headers.push(c.value);
            });
          };

          if (model.number > 1) {
            row.eachCell({includeEmpty: true}, (c, i) => {
              values.push(c.value);
            });

            if(headers.length !== values.length) {
              let diff = headers.length - values.length;
              let empties = Array.apply(null, {length: diff})
              values = values.concat(empties);
            }
          };

          rows.push(values);
        });

        let reportType = checkHeaders(headers);
        if(reportType!=='error') {
          if(reportType==='type') {
            // type code is index 1, so only return ones that have it
            rows = rows.filter(x => x[1]);
          }
          sheets.push({ uid: nanoid(), type: reportType, name: sheet.name, headers, rows });
        } else {
          setError(true);
        }
      });

      setData(sheets);
    }

    reader.readAsArrayBuffer(files[0]);
  }

  const finishImport = async (obj) => {
    if(obj.type==='type') {
      let res = await serverCall({ method: 'POST', data: obj.objs, url: `/library/types/bulk`, eamsAuth0: true });
      if(res.status!==200) return alert('Error importing types. Contact support.') // lklklk
      let newTypes = res.data;
      setTypes(prev => [...prev, ...newTypes]);

      let schema = (parentType==='project') ? [...project.library] : [...library.schema];
      for(const category of schema) {
        let ids = newTypes.filter(x => x.category === category.name).map(x => x.id);
        category.ids = category.ids.concat(ids);
      }

      for(const sheet of summary) {
        for(const category of sheet.newCategories) {
          let ids = newTypes.filter(x => x.category === category).map(x => x.id);
          schema.push({ name: category, uid: nanoid(), ids });
        }
      }

      (parentType === 'project') ? updateProject(schema) : updateGlobal(schema);
    }
  }

  const updateProject = async (data) => {
    setProjects((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId === project.appId);
      arr[idx].library = data;
      return arr;
    })

    let update = { 
      id: project.id,
      library: data,
      updatedBy: userDetails.id,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss')
    };
    await serverCall({ method: 'PATCH', data: update, url: `/mapping/projects/${project.appId}`, eamsAuth0: true });
    closeModal();
  }

  const updateGlobal = async (data) => {
    setLibraries((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId === library.appId);
      arr[idx].schema = data;
      return arr;
    })

    let update = { 
      id: library.id, 
      schema: data,
      updatedBy: userDetails.id,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss')
    };
    await serverCall({ method: 'PATCH', data: update, url: `/library/${library.appId}`, eamsAuth0: true });
    closeModal();
  }

  return (
    <Fragment>
      <Modal show={true} onHide={closeModal} fullscreen={true} backdrop={true} keyboard={true} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Bulk Import: {parentType==='project' ? 'Project' : 'Global Library'} Plot Types</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-4">
            <p>Use this tool to import and export data using Excel spreadsheets.</p>
            <Dropzone
              msg="Drag & drop your formatted Excel sheet or click to select file"
              excludeSave={true}
              fileTypes={{
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
              }}
              toParent={fromChild}
            />
          </div>
          <div className="col-sm-8">
            { error && (
              <Fragment>
                <h4 className="text-danger">Error importing</h4>
                <p className="text-danger">There was an issue with your import.<br />Please check that the columns match the templates and try again.</p>
              </Fragment>
            )}
            { (!error && summary.length > 0) && (
              <Tabs defaultActiveKey={data[0].uid}>
                { summary.map(obj => (
                  <Tab key={obj.uid} eventKey={obj.uid} title={obj.name}>
                    <div className="table-responsive mb-5" style={{maxHeight:295}}>
                      <table className="table">
                        <thead>
                          <tr>{obj.headers.map((col, idx) => <th key={`th-${idx}`} style={{minWidth:100}}>{col}</th>)}</tr>
                        </thead>
                        <tbody>
                          {obj.rows.map((col, idx) => (
                            <tr key={`row-${idx}`}>
                              {col.map((cell, idx) => <td key={`cell-${idx}`}>{cell}</td>)}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <h4>Summary</h4>
                    <table className="table" style={{width:300}}>
                      <tbody>
                        <tr>
                          <td>Categories Added</td>
                          <td>{obj.newCategories.length}</td>
                        </tr>
                        <tr>
                          <td>Types Added</td>
                          <td>{obj.objs.length}</td>
                        </tr>
                      </tbody>
                    </table>
                    <button className="btn btn-outline-success" role="button" onClick={() => finishImport(obj)}>Finish Import</button>
                  </Tab>
                ))}
              </Tabs>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-dark ms-auto me-1" type="button" onClick={closeModal}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
    </Fragment>
  )
}

export default View;
