import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const View = () => {
  const location = useLocation();
  const [email, setEmail] = useState(true);
  const [show, setShow] = useState(false);
  const toggle = () => setShow((s) => !s);

  useEffect(() => {
    (location.pathname==='/' || location.pathname.includes('/wgs')) ? setEmail(false) : setEmail(true);
  }, [location])

  return (
    <Fragment>
      { email && (
        <a href="mailto:wayfinding@flysfo.com" className="btn btn-secondary shadow-sm position-fixed" style={{bottom:24, right:12}} tabIndex="-1" role="button" aria-disabled="true">
          Need Help?
        </a>
      )}
      {!email && (
        <button className="btn btn-secondary shadow-sm position-fixed" style={{bottom:24, right:12}} onClick={toggle}>
          Need Help?
        </button>
      )}

      <Modal show={show} onHide={toggle} centered={true} backdrop={true} keyboard={true} scrollable>
        <Modal.Body>
          <h4>Need help?</h4>
          { location.pathname === '/' && (
            <Fragment>
              <h2>How to log a ticket</h2>
              <div className="my-3" style={{padding:'51.20% 0 0 0', position:'relative'}}><iframe src="https://player.vimeo.com/video/821458202?h=038888e6c0&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}></iframe></div>
              <p>The above video explains the homepage of the Asset Management Platform and the different components, features, and actions that the user has access to.</p>
              <p><b>Note:</b> the contents of the hompage differs depending on the level of access and privileges the user has.</p>
            </Fragment>
          )}
          { location.pathname.includes('/wgs') && (
            <Fragment>
              <h2>Using the guidance</h2>
              <div className="my-3" style={{padding:'51.20% 0 0 0', position:'relative'}}><iframe src="https://player.vimeo.com/video/837006441?h=8091185cb3&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}></iframe></div>
              <p>The above video explains how to navigate and use the guidance section of the Asset Management Platform. It also covers how to access resource and specification files.</p>
              <p><b>Note:</b> access to resource and specification files differs depending on the level of access and privileges the user has.</p>
            </Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <a href="mailto:wayfinding@flysfo.com" className="btn btn-outline-dark ms-auto" tabIndex="-1" role="button" aria-disabled="true">Request more information</a>
          <button className="btn btn-outline-danger ms-1" type="button" onClick={toggle}>
            Dismiss
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

export default View;
