import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom'
import { useAxios }    from '../../../hooks/useAxios';

import FormBasic from '../../../components/system/FormBasic'
import Comment from './Comment';

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    // { id: '2', type: 'access', label: 'Set access levels', field: 'access' },
    { id: '6', type: 'redactor', label: 'Add to the discussion', field: 'content', hide: ['image','embed','quote','pre','table'], maxHeight: '400px', required: 'Please enter a comment'},
  ]},
]

const View = (props) => {
  const { parentType, parentId, needsLoading, vertical, comments, setComments, setThreads } = props;
  const { serverCall } = useAxios();

  useEffect(() => {
    if(!needsLoading || !parentType || !parentId) return;
    (async function () {
      let res = await serverCall({ method: 'GET', url: `/system/discussion/${parentType}/${parentId}`, eamsAuth0: true });
      if(res.data.threads) setThreads(res.data.threads);
      if(res.data.comments) setComments(res.data.comments);
		})();
  }, [needsLoading, parentType, parentId, serverCall, setComments, setThreads ])

  const fromForm = (data) => {
    const { type, response } = data;
    if(type === 'submitted') {
      setComments((prev) => {
        let all = [...prev];
        all.unshift(response);
        return all;
      })
    }
  }

  return (
    <div className="row h-100 overflow-hidden">
      <div className={`${vertical ? 'col-12 order-2' : 'col-sm-8 h-100 overflow-scroll'}`}>
        <div className="mb-3 p-2 bg-white rounded">
          {(!comments || comments.length === 0) && <p className="p-3 mb-0">No discussion yet.</p>}
          { comments?.map((obj, idx) => {
            return <Comment key={obj.appId} idx={idx} length={comments.length} obj={obj} />
          })}
        </div>
      </div>
      <div className={`${vertical ? 'col-12 order-1 mb-4' : 'col-sm-4'}`}>
        {!vertical && (<h5 className="mb-1">Add a Comment</h5>)}
        <FormBasic debug={false} method="POST" url="/system/discussion/comments" clearSubmit={true}
          vals={{  parentType, parentId: `${parentId}`, threadId: 22 }} createForm={true} toParent={fromForm} schema={schema}
          formText={{ submit: 'Add', pending: 'Adding', success: 'Added!', cancel: 'Cancel' }}
        />
      </div>
    </div>
  )
}

export const Discussion = (props) => {
  const { parentType, parentId, needsLoading, vertical, threads, setThreads, comments, setComments } = props;
  return <View parentType={parentType} parentId={parentId} needsLoading={needsLoading} vertical={vertical} threads={threads} setThreads={setThreads} comments={comments} setComments={setComments} />
}

export const DiscussionOutlet = (props) => {
  const { parentType, parentId, needsLoading, vertical, threads, setThreads, comments, setComments } = useOutletContext();
  return <View parentType={parentType} parentId={parentId} needsLoading={needsLoading} vertical={vertical} threads={threads} setThreads={setThreads} comments={comments} setComments={setComments} />
}

// could also do DiscussionParam which would grab the object from the server...?
