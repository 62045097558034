import React, { useContext } from 'react';
import { SiteContext } from '../../context/SiteContext';

import WFIHeader from '../_products/wfi/Header';
import WGSHeader from '../_products/sfo/Header';
import DSMHeader from '../_products/dsm/Header';
import ICPHeader from '../_products/icp/Header';

const View = (props) => {
  const { hostname } = useContext(SiteContext);

  if(hostname.includes('wayfindit'))
    return <WFIHeader />
  else if(hostname==='admin.mstdsqr.com')
    return <DSMHeader />
  else if(hostname==='wgs.flysfo.io')
    return <WGSHeader />
  else if(hostname==='icp.jblapp.com' || hostname.includes('inspect'))
    return <ICPHeader />
  else
    return null;
}

export default View;
