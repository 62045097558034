import React, { forwardRef, useRef } from 'react'
import { Controller } from 'react-hook-form'
import Dropzone from '../dropzone/Dropzone';

// future: will need to differentiate between an attachment for a single file, ie thumbnail
// vs a list of files, like the attachments that we already see the list for

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  const files = useRef([]);
  const prefix = item.prefix ? item.prefix : 'file-upload';

  const fromChild = (data) => {
    data.name = item.field;
    if(pieces.toParent) pieces.toParent(data);

    files.current.push(data.value);
    pieces.setValue(`${item.field}`, files.current);
  }

  return (
    <Controller
      render={({ field }) => {
        if(!field.value) field.value = '';
        return (
          <div className="my-3">
            {item.label && (<label htmlFor={item.field}>{item.label}</label>)}
            <Dropzone
              folder="uploads/"
              prefix={prefix}
              toParent={fromChild}
            />
          </div>
        );
      }}
      name={item.field}
      control={pieces.control}
      rules={{ required: item.required }}
      defaultValue=""
    />
  )
});

export default Field;
