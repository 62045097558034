import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom'
import { SiteContext } from '../../../context/SiteContext';
import { WorkerContext } from '../../../context/WorkerContext';
import { findPhasePlan } from '../../../utils/phases';

import { ArrowClockwise, CheckLg } from 'react-bootstrap-icons';
// import dayjs from 'dayjs'

// lklklk: will want to differentiate between phase plans that are loaded
// right now the below would show as marked off if they downloaded it in a prior ... phase

// future: maybe grey/gray out plans that haven't been downloaded if they are offlien??


// also maybe don't show 'last synced'... here? since data is pulled with task and NOT with the plan downloads?

const View = (props) => {
  const { obj, phases, phasePlans, phase, plotQty } = props;
  const navigate = useNavigate();
  const { online } = useContext(SiteContext);
  const { startWorker } = useContext(WorkerContext);
  const [downloaded, setDownloaded] = useState(false);

  useEffect(() => {
    // find phasePlan for plan + phase combo
    let filtered = phasePlans.filter(x => x.planId === obj?.id);
    let phasePlan = findPhasePlan(phases, phase, filtered);
    setDownloaded(phasePlan?.base64);
  }, [obj, phasePlans, phase])

  const downloadPlan = (e) => {
    e.target.blur();
    startWorker({ type: 'sync', pieces: { direction: 'download', type: 'plan', phases, phasePlans, phase, id: obj.id } })
  }

  const checkPlan = (e) => {
    if(downloaded)
      navigate(`./${obj.appId}`);
    else
      downloadPlan(e);
  }

  return (
    <div className="card mb-2">
      <div className="row g-0">
        <div className="col pointer" onClick={checkPlan}>
          <div className="card-body d-flex">
            <h5 className="card-title flex-grow-1 my-1">{obj.name}</h5>
            {/* { (obj.lastSync && obj.lastSync!=='downloading') && <p className="card-text"><small className="text-muted">Last synced {dayjs(obj.lastSync).format('MM-DD-YY HH:mma')}</small></p> } */}
            { plotQty > 0 && (
              <p className="flex-shrink-0 text-muted ms-auto mt-1 mb-0 me-2">{plotQty} Plots</p>
            )}

            { phase && (
              <Fragment>
                { obj.lastSync==='downloading' && (
                  <div className="flex-shrink-0 spinner-border spinner-border-sm text-warning mt-1" role="status"><span className="visually-hidden">Loading...</span></div>
                )}
                { obj.lastSync!=='downloading' && (
                  <div className="flex-shrink-0">
                    { downloaded && <CheckLg className="text-success me-2" size={26} />}
                    { online && downloaded && <button className="btn btn-sm btn-outline-dark"><ArrowClockwise size={16} onClick={downloadPlan} /></button> }
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default View;
