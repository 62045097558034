import React, { forwardRef, Fragment } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CheckLg, ClockHistory, ExclamationLg, Hourglass, Pause } from 'react-bootstrap-icons';

// lklklk switch this to be controlled like the other button-group module?

// to use:
// <StatusBtnGroup item={{ field: 'publicStatus', options: statuses }} pieces={{ vars: { id: obj.id, value: status, classes: 'flex-shrink-0 ms-2 btn-group' }, toParent: fromChild }} />

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  const val = pieces.vals?.publicStatus;
  if(!item.options) item.options = [];
  if(!pieces.vars) pieces.vars = {};
  if(!pieces.vars[item.optionVar]) pieces.vars[item.optionVar] = [];
  let options = item.options.concat(pieces.vars[item.optionVar]);

  return (
    <div className={ pieces.classes ? pieces.classes : ''}>
      {item.label && (<label className="d-block mb-1" htmlFor={item.field}>{item.label}</label>)}
      {item.note && (<p className="mb-1 text-muted fst-italic small">{item.note}</p>)}
      <div className="btn-group" role="group">
        { options.map((opt, idx) => {
          return (
            <Fragment key={`review-arr-${idx}`}>
              <input
                type="radio"
                id={`${pieces.vars.id}-review-arr-opt-${idx}`}
                autoComplete="off"
                value={opt.value}
                checked={opt.value === val}
                className="btn-check"
                onChange={() => {
                  if(pieces.setValue) pieces.setValue(`${item.field}`, opt.value);
                  if(pieces.toParent) pieces.toParent({ type: 'status-button-group', name: item.field, value: opt.value });
                }}
              />
              {/* need to include id for label clickability */}
              <OverlayTrigger placement="top" overlay={<Tooltip id={`${pieces.vars.id}-review-arr-tooltip-${idx}`}>Mark as {opt.label}</Tooltip>}>
                <label htmlFor={`${pieces.vars.id}-review-arr-opt-${idx}`}
                  className={`btn ${item.size==='sm' ? 'btn-sm': ''} btn-outline-${(opt.class) ? opt.class : 'dark'}`}>
                  {!opt.icon && opt.label}
                  {opt.icon === 'check' && <CheckLg size={18} />}
                  {opt.icon === 'clock' && <ClockHistory size={16} />}
                  {opt.icon === 'exclamation' && <ExclamationLg size={18} />}
                  {opt.icon === 'hourglass' && <Hourglass size={16} />}
                  {opt.icon === 'pause' && <Pause size={18} />}
                </label>
              </OverlayTrigger>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
});

export default Field;

