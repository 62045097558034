import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

const Component = (props) => {
  const { msg } = props;

  return (
    <Fragment>
      <Helmet>
        <style>{` body { padding:0 } `}</style>
      </Helmet>
      <div style={{top:0, left: 0, height: '100vh',width:'100vw',zIndex:1999}} className="position-fixed bg-light d-flex flex-column align-items-center justify-content-center">
        <div className="spinner-border text-muted" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        { msg && <p className="mt-3">{msg}</p>}
      </div>
    </Fragment>
  );
}

export default Component;
