import React from 'react';
import Manager from './components/View';

// future:
// if user is an admin for this project/task, show edit options
// also modal to update/swap out the file

// future: also... this gets the files every time the tab is clicked
// could we set something at the project level if they've already been downloaded once
// and if so, have a button to refresh if needed but otherwise don't get each time

const View = (props) => {
  const { parentType, parentId, files, setFiles, filesLog, setFilesLog, title, fileTypes, actions, thumbnails, convert, toParent } = props;

  return <Manager
      parentType={parentType}
      parentId={parentId}
      files={files}
      setFiles={setFiles}
      filesLog={filesLog}
      title={title}
      fileTypes={fileTypes}
      setFilesLog={setFilesLog}
      actions={actions}
      thumbnails={thumbnails}
      convert={convert}
      toParent={toParent}
    />
}

export default View;
