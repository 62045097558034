import React, { createContext, useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

export const SocketContext  = createContext({});

export const SocketProvider = ({ children }) => {
  const socketRef = useRef(null);
  const connErrRef = useRef(false);
  const [socket, setSocket] = useState(null);
  const [socketMsg, setSocketMsg] = useState();

  useEffect(() => {
    if(!socketRef.current) socketRef.current = io.connect();
    socketRef.current.on('connect', () => {
      console.log('Connected to server');

      // if we've reconnected after an error, refresh the page
      if(connErrRef.current === true) window.location.reload(false);

      // otherwise, figure out which rooms to join etc?
      // socketRef.current.emit('join', {roomId: locationId.current, deviceType: 'tablet', deviceId: 'role_id_here?' });
    })

    socketRef.current.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`);
      console.log(err)
      connErrRef.current = true;

      // set interval to refresh the page if we regain internet
      // do we want to do this??
      // setInterval(() => {
      //   let internet = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      //   if (internet) window.location.reload(false);
      // }, (1000 * 60 * 5));
    });

    // default/standard sysmsgs should go here
    // function-specific ones should sit within the views
    socketRef.current.on('sysmsg', data => {
      // also passes value
      const { type } = data;
      if(type === 'refresh') window.location.reload(false);
      setSocketMsg(data);
    });

    setSocket(socketRef.current);

    return () => {
      console.log('unmounting')
      socketRef.current.disconnect();
      socketRef.current = null;
      setSocket(null);
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket, socketMsg }}>
      {children}
    </SocketContext.Provider>
  );
};
