import React from 'react';
import { ArrowUpShort, ArrowDownShort } from 'react-bootstrap-icons';

const View = ({ idx, count, toParent, styles }) => {
  return (
    <div className="d-flex flex-column" style={styles}>
      {idx > 0 && (
        <button type="button" className="btn btn-sm p-0 pe-1" onClick={() => toParent({ type: 'direction', value: 'up', idx })}>
          <ArrowUpShort size={20} className="text-muted" />
        </button>
      )}
      {idx < count-1 && (
        <button type="button" className="btn btn-sm p-0 pe-1" onClick={() => toParent({ type: 'direction', value: 'down', idx })}>
          <ArrowDownShort size={20} className="text-muted" />
        </button>
      )}
    </div>
  )
}

export default View;
