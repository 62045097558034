import { getPlotDisplay } from '../../../../utils/plots';

// we are leaving this in in case the types change color in a library
// the plots update themselves whenever their type changes,
// but nothing updates them otherwise...

const formatPlots = (project, plans, types, typeVars, plots) => {
  if(!plots) return [];
  let formatted = [];
  for (const plot of plots) {
    let newPlot = getPlotDisplay({ project, plans, types, typeVars, plot });
    formatted.push(newPlot);
  }
  return formatted;
}

export default formatPlots;