import { bracketToDot } from './functions';

// right now this assumes we aren't nesting multiple cols within each other
// not sure if that will matter at any point...?

// could/should we add a prefix for tasks etc
// so we can still have udfs etc but within the same form?
// or would it just be better to have two form objects (plot + plotTask)?

// how to use:
// let obj = formObj(schema); <-- when you want an empty obj for blank forms
// let obj = formObj(schema, data); <-- when you want to be able to reset the form with data values

export const findSchema = (schemas, parent) => {
  let owner = parent.access?.find(x => x.role==='owner');
  if(owner?.id) {
    // filter to see if there's one that includes the parent ID
    // if not, just send back the first one
    let ownerSchema = schemas.find(x => x.groupArr.includes(String(owner.id)));
    let schema = ownerSchema ? ownerSchema : schemas[0];
    return schema;
  } else {
    // fallback if we just need default
    return schemas[0];
  }
}

export const getFields = (arr, udfs) => {
  const fields = new Set();

  const processObj = (obj) => {
    ['col', 'col1', 'col2', 'col3', 'col4'].forEach(key => {
      if(key in obj) {
        getFields(obj[key], udfs).forEach(field => {
          const dotField = bracketToDot(field);
          addField(dotField, udfs, fields);
        });
      }
    });

    if('field' in obj) {
      const dotField = bracketToDot(obj.field);
      addField(dotField, udfs, fields);
    }
  };

  // If field matches "arrX.Y" pattern, add "arrX" to the set
  // If udfs is true and field matches "udfX" or "arrX" pattern, add it to the set
  // If neither condition is true, add the original field
  const addField = (field, udfs, fieldsSet) => {
    const arrMatch = field.match(/(arr\d+)\.\d+/);
    if (arrMatch) {
      fieldsSet.add(arrMatch[1]);
    } else if (udfs) {
      const udfArrMatch = field.match(/(udf\d+|arr\d+)/);
      if (udfArrMatch) fieldsSet.add(udfArrMatch[1]);
    } else {
      fieldsSet.add(field);
    }
  };

  arr.forEach(processObj);
  return [...fields];
};

export const cleanFields = (arr, obj, udfs) => {
  const fields = getFields(arr, udfs);
  let cleanObj = {};
  fields.forEach(field => {
    const match = field.match(/^(arr\d+)\.\d+$/);
    const baseField = match ? match[1] : field;
    if (!(baseField in cleanObj) && baseField in obj) {
      cleanObj[baseField] = obj[baseField];
    }
  });
  return cleanObj;
};

export const formatRepeats = (template, N, offset, parentName) => {
  let result = [];
  let count = 0+offset;

  for(let i = 0; i < N; i++) {
    let newItem = JSON.parse(JSON.stringify(template[0])); // deep clone the template item
    if(!newItem.id) newItem.id = `col-${i}`;

    ['col','col1', 'col2','col3','col4'].forEach(col => {
      if(newItem.hasOwnProperty(col)) {
        newItem[col].forEach(item => {
          item.name = `${parentName}.${item.field}`;
          item.count = count++;
          item.id = item.count;
          if(item.label?.includes('[X]')) item.label = item.label.replace('[X]', item.count+1);
        });
      }
    });
    result.push(newItem);
  }
  return result;
};

// col1: [{ id: 'repeat2', type: 'floating-input', field: 'field', label: `Field ${idx+idxOffset}` }], 
// col2: [{ id: 'repeat3', type: 'floating-input', field: 'value', label: 'Value' }], 

export const formObj = (schema, data) => {
  let obj = {};
  if(!data) data = {};

  let iterate = (items) => {
    for(const item of items) {
      if(!item.field) continue;
      if(item.array) {
        if(!data[item.array]) data[item.array] = [];
        obj[item.field] = data[item.array][item.index] ? data[item.array][item.index] : '';
      } else
        obj[item.field] = data[item.field] ? data[item.field] : null;
    }
  }

  for(const item of schema) {
    if('col' in item) iterate(item.col);
    if('col1' in item) iterate(item.col1);
    if('col2' in item) iterate(item.col2);
    if('col3' in item) iterate(item.col3);
    if('col4' in item) iterate(item.col4);
  }

  return obj;
}

export const schemaObjArr = (schema, data) => {
  let arr = [];
  if(!data) data = {};

  let iterate = (items) => {
    for(const item of items) {
      if(!item.field) continue;
      let value;
      if(item.array) {
        if(!data[item.array]) data[item.array] = [];
        value = data[item.array][item.index] ? data[item.array][item.index] : '';
      } else {
        value = data[item.field] ? data[item.field] : null;
      }

      // if the item is a checkbox/dropdown, but the value isn't one of the options, clear value
      if(item.options?.length > 0 && value) {
        let found = item.options.find(x => x.value===value);
        if(!found) value = null;
      } else if(item.type==='checkbox' && item.value!==value)
        value = null;

      // lklklk should we do an 'omitEmpty' thing here to NOT add to the array?
      arr.push({ label: item.label, value, field: item.field });
    }
  }

  for(const item of schema) {
    if('col' in item) iterate(item.col);
    if('col1' in item) iterate(item.col1);
    if('col2' in item) iterate(item.col2);
    if('col3' in item) iterate(item.col3);
    if('col4' in item) iterate(item.col4);
  }

  return arr;
}

// this tests for square brackets and removes the values
// used to clean up react-hook-form dynamic arrays
export const removeArrs = (data) => {
  // let regex = /\(|\)|\[|\]/g;
  let regex = /(\[[\0-9+]*\])/;
  for (const [key] of Object.entries(data)) {
    if(regex.test(key)) {
      delete data[`${key}`];
    }
  }
  return data;
}
