import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAxios } from '../../../hooks/useAxios';
import axios from 'axios';

import ExcelJS from 'exceljs';

import Modal from 'react-bootstrap/Modal';
import Collapse from 'react-bootstrap/Collapse';

// FUTURE:
// add a debug toggle that would actually print EVERYTHING
// state that goes to the ... out... looks


import { SiteContext } from '../../../context/SiteContext';
import { UserContext } from '../../../context/UserContext';
import { WorkerContext } from '../../../context/WorkerContext';
import { deleteUnsynced, deleteDb } from '../utils/sync';
import { formatBytes } from '../../../utils/functions';

import db from '../../../utils/dexie-provider.js'
import { Bug } from 'react-bootstrap-icons';

// future: display if their connection is slow/bad
// could also show the connection strength?

// future: auto-check to sync stuff if they reactivated?
// future: hide the sync button if there's nothing to sync?

// https://stackoverflow.com/questions/63695129/retrieving-console-errors-to-html

const View = (props) => {
  const { redirect, dirtyPlots, dirtyPlotTasks, dirtyPhotos, handleCancel } = props;
  const { online } = useContext(SiteContext);
  const { userDetails } = useContext(UserContext);
  const { startWorker } = useContext(WorkerContext);
  const { getAccessTokenSilently } = useAuth0();
  const { serverCall } = useAxios();
  
  const [debug, setDebug] = useState(false);
  const toggleDebug  = () => setDebug((s) => !s);
  
  const [storage, setStorage] = useState();
  const [sum, setSum] = useState(0);
  const [plots, setPlots] = useState([]);
  const [photos, setPhotos] = useState([]);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const toggleDelete = () => setConfirmDelete((s) => !s);

  const [confirmForce, setConfirmForce] = useState(false);
  const toggleForce = () => setConfirmForce((s) => !s);

  const [confirmSingle, setConfirmSingle] = useState(false);
  const toggleSingle = () => setConfirmSingle((s) => !s);

  const [plotProgress, setPlotProgress] = useState();
  const [confirmTriage, setConfirmTriage] = useState(false);
  const toggleTriage = () => setConfirmTriage((s) => !s);
  
  useEffect(() => {
    if(!debug) return;
    if(typeof navigator.storage.estimate === 'function') {
      navigator.storage.estimate().then((estimate) => {
        let used = (estimate.usage / estimate.quota * 100).toFixed(2);
        let avail = 1-used;
        setStorage({ used, avail, usage: formatBytes(estimate.usage), quota: formatBytes(estimate.quota) });
      });
    }
      (async () => {
        let plots = await db.plots.where({ dirty: 1 }).toArray();
        setPlots(plots);
        
        let photos = await db.photos.toArray();
        let filtered = photos.filter(x => x.dirty===1);
        setPhotos(filtered);

        let size = photos.reduce((n, {size}) => n + size, 0);
        setSum(formatBytes(size));
      })();
  }, [debug])

  const sync = async (e) => {
    e.target.blur();
    let token = await getAccessTokenSilently();
    startWorker({ type: 'sync', pieces: { direction: 'upload', type: 'sync', token } })
  }

  const syncSingle = async (e) => {
    e.target.blur();
    let token = await getAccessTokenSilently();
    startWorker({ type: 'sync', pieces: { direction: 'upload', type: 'single photo', token } })
    setConfirmSingle(false);
  }

  const confirmUnsyncDelete = async () => {
    await deleteUnsynced();
    setConfirmForce(false);
    setPhotos([]);
    setPlots([]);
  }

  const packagePlots = async () => {
    console.clear();
    setPlotProgress(1);
    let plots  = await db.plots.where({ dirty: 1 }).toArray();
    let plotTasks = await db.plotTasks.where({ dirty: 1 }).toArray();
    let photos = await db.photos.where({ dirty: 1 }).toArray();
    setPlotProgress(2);

    const workbook = new ExcelJS.Workbook();
    if(plots.length > 0) {
      let worksheet = workbook.addWorksheet('Plots');
      let columns = Object.keys(plots[0]);
      columns = columns.map(key => ({ header: key, id: key, width: 100 }));
      worksheet.columns = columns;
      for(const plot of plots) {
        const vals = Object.values(plot);
        let arr = [];
        for(const val of vals) {
          if(!val) arr.push('');
          else arr.push(JSON.stringify(val));
        }
        worksheet.addRow(arr);
      }
    }
    setPlotProgress(3);

    if(plotTasks.length > 0) {
      let worksheet = workbook.addWorksheet('Plot Tasks');
      let columns = Object.keys(plotTasks[0]);
      columns = columns.map(key => ({ header: key, id: key, width: 100 }));
      worksheet.columns = columns;
      for(const plotTask of plotTasks) {
        const vals = Object.values(plotTask);
        let arr = [];
        for(const val of vals) {
          if(!val) arr.push('');
          else arr.push(JSON.stringify(val));
        }
        worksheet.addRow(arr);
      }
    }
    setPlotProgress(4);

    if(photos.length > 0) {
      let worksheet = workbook.addWorksheet('Photos');
      let columns = Object.keys(photos[0]);
      columns = columns.map(key => ({ header: key, id: key, width: 100 }));
      let base64Idx = columns.findIndex(x => x.id === 'base64');

      worksheet.columns = columns;
      for(const photo of photos) {
        const vals = Object.values(photo);
        let arr = [];
        for (const [idx, val] of vals.entries()) {
          if(!val || idx===base64Idx) arr.push('');
          else arr.push(JSON.stringify(val));
        }
        worksheet.addRow(arr);
      }
    }
    setPlotProgress(5);

    workbook.xlsx.writeBuffer().then(async (data) => {
      let excelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
      setPlotProgress(6);
      const blob = new Blob([data], { type: excelType });
      let filename = userDetails.id+'-'+Date.now()+'.xlsx';
      let url  = await serverCall({ method: 'POST', data: { bucket: 'wayfinditv8', folder: 'triage/', name: filename, type: excelType  }, url: '/system/files/presign/upload', eamsAuth0: true });
      console.log(url);
      if(url.status!==200) return setPlotProgress('presign fail');

      let upload = await axios.put(url.data, blob, {
        headers: { 'Content-Type': excelType },
        // below works! maybe send back details for status...?
        onUploadProgress: async (e) => {
          const progress = Math.round((e.loaded * 100) / e.total);
          console.log(progress);
          setPlotProgress('uploading: ', progress);
  
          if(e.loaded===e.total) {
            console.log('doneeee')
          }
        }
      });

      if(upload.status!==200) return setPlotProgress('upload fail');
      setPlotProgress('success');
    });
  }

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Sync</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { !confirmTriage && (
          <>
            <p>The table below shows any data that has not been synced from your device.</p>
            <div className="d-grid gap-2">
              { online && <button className="btn btn-outline-success" type="button" onClick={sync}>Sync Data Now</button> }
              {!online && <button className="btn btn-outline-secondary" type="button" disabled>You are offline - reconnect to sync</button> }
            </div>
            <table className="table my-4">
              <thead>
                <tr>
                  <th>Type</th>
                  <th width="100" className="text-end">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Plots</td>
                  <td className="text-end">{dirtyPlots}</td>
                </tr>
                <tr>
                  <td>Task/Survey Data</td>
                  <td className="text-end">{dirtyPlotTasks}</td>
                </tr>
                <tr>
                  <td>Photos</td>
                  <td className="text-end">{dirtyPhotos}</td>
                </tr>
              </tbody>
            </table>

            <h4 className="mt-5">Clear Device Data</h4>
            <p className="fw-bold text-danger mb-1">If you delete data without syncing, this information will be lost. This cannot be undone.</p>
            <p className="text-muted small">Note: this only deletes data on this device, not data on the server.</p>
            { !confirmDelete && (
              <div className="d-grid gap-2">
                <button className="btn btn-outline-danger" type="button" onClick={toggleDelete}>
                  Delete Device (Local) Data
                </button>
              </div>
            )}
            { confirmDelete && (
              <div className="row">
                <div className="col-12">
                  <p className="text-danger mb-2">Are you sure you want to clear your local data? This cannot be undone.</p>
                </div>
                <div className="col d-grid gap-2">
                  <button className="btn btn-outline-danger" type="button" onClick={() => deleteDb(redirect)}>Yes</button>
                </div>
                <div className="col d-grid gap-2">
                  <button className="btn btn-outline-danger" type="button" onClick={toggleDelete}>Cancel</button>
                </div>
              </div>
            )}

            <Collapse in={debug}>
              <div>
                <h4 className="mt-5">Debug</h4>
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th colSpan="3">Storage Details/Estimates</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Approx. Storage</td>
                          <td>{ sum }</td>
                        </tr>

                      { storage && (
                        <Fragment>
                          <tr>
                            <td>Available</td>
                            <td>{ storage.avail }%</td>
                          </tr>
                          <tr>
                            <td>Usage</td>
                            <td>{ storage.usage }</td>
                          </tr>
                          <tr>
                            <td>Quota</td>
                            <td>{ storage.quota }</td>
                          </tr>
                        </Fragment>
                      )}
                      </tbody>
                    </table>
                    {!confirmSingle && (
                      <div className="d-grid gap-2 mb-3">
                        <button className="btn btn-outline-success" type="button" onClick={toggleSingle}>Single Photo Sync</button>
                      </div>
                    )}
                    { confirmSingle && (
                      <div className="row mb-3">
                        <div className="col d-grid gap-2">
                          <button className="btn btn-outline-success" type="button" onClick={syncSingle}>Yes</button>
                        </div>
                        <div className="col d-grid gap-2">
                          <button className="btn btn-outline-danger" type="button" onClick={toggleSingle}>Cancel</button>
                        </div>
                      </div>
                    )}
                    { !confirmForce && (
                      <div className="d-grid gap-2 mb-3">
                        <button className="btn btn-outline-danger" type="button" onClick={toggleForce}>
                          Force Delete Unsynced Items
                        </button>
                      </div>
                    )}
                    { confirmForce && (
                      <div className="row mb-3">
                        <div className="col d-grid gap-2">
                          <button className="btn btn-outline-danger" type="button" onClick={confirmUnsyncDelete}>Yes</button>
                        </div>
                        <div className="col d-grid gap-2">
                          <button className="btn btn-outline-danger" type="button" onClick={toggleForce}>Cancel</button>
                        </div>
                      </div>
                    )}
                    <div className="d-grid gap-2 mb-3">
                      <button className="btn btn-outline-danger" type="button" onClick={toggleTriage}>Open Triage</button>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    { plots.map(obj => <p key={obj.appId+obj.phaseId}>{obj.appId} - {obj.name}</p>)}
                    <div className="clearfix">
                      { photos.map(obj => <img key={obj.appId+obj.plotAppId} src={obj.base64} className="w-25 float-start me-1 mb-1" alt="Survey Pic" />)}
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </>
        )}
        { confirmTriage && (
            <>
              <p>Use these tools if you have large amounts of data but you are unable to sync them from your device.</p>
              <h5>Plots/Data</h5>
              { !plotProgress && (
                <Fragment>
                  <p>Press the button below to package your unsynced data into a spreadsheet. It will be sent to the support team for importing.</p>
                  <div className="d-grid gap-2 mb-3">
                    <button className="btn btn-outline-primary" type="button" onClick={packagePlots} disabled={plotProgress}>Package Plot Data</button>
                  </div>
                </Fragment>
              )}
              { plotProgress && (
                <p>Packaging data: {plotProgress}</p>
              )}

              <div className="d-grid gap-2 mt-5 mb-3">
                <button className="btn btn-outline-danger" type="button" onClick={toggleTriage}>Close Triage</button>
              </div>
            </>
          )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-light me-auto" type="button" onClick={toggleDebug}>
          <Bug size={18} className="text-muted" />
        </button>
        <button className="btn btn-sm btn-outline-dark ms-auto" type="button" onClick={handleCancel}>
          Close
        </button>
      </Modal.Footer>
    </Fragment>
  )
}

export default View;
