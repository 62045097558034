import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useAxios } from '../../../hooks/useAxios';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs'

import { UserContext } from '../../../context/UserContext';
import { WorkerContext } from '../../../context/WorkerContext';
import PlanList from '../components/PlanList';
import { ArrowClockwise, Download } from 'react-bootstrap-icons';

import db from '../../../utils/dexie-provider.js'
import Generated  from '../../../components/system/Generated';
import { emptyObj } from '../../../utils/functions';
import { formObj } from '../../../utils/schemas';

// sfo hardcode plans
import { plans as gisPlans } from '../../../components/_products/sfo/plans';
// lklklk may need to do a different GIS structure option
// we probably wouldn't want them clicking directly on plans...
// but also need to figure out a way for them to be linked and/or refresh
// the downloads...? and zoom to...?


// lklklk future:
// make the resync thing a split button
// allow them to download all plans in one go
// and/or download the photos...?

const View = (props) => {
  const { gis } = props;
  const navigate = useNavigate();
  const { online, getTask, checklist, task, project, phases, phasePlans, activePhase, activePlots, planGroups, activePlans } = useOutletContext();
  const { startWorker } = useContext(WorkerContext);
  const { userDetails } = useContext(UserContext);

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(checklist?.config?.intro?.length > 0 ? checklist.config.intro : []), [checklist])
  });

  useEffect(() => {
    if(emptyObj(checklist) || emptyObj(task)) return;
    if(!checklist.config?.intro) return;
    
    let filled = formObj(checklist.config.intro, task);
    console.log(task);
    console.log(filled);
    reset(filled);
  }, [checklist, task, reset])

  const downloadPlan = (e, ids) => {
    e.target.blur();
    for(const id of ids) {
      let filtered = phasePlans.filter(x => x.planId === id);
      startWorker({ type: 'sync', pieces: { direction: 'download', type: 'plan', phases, phasePlans: filtered, phase: activePhase, id } })
    }
  }

  const onSubmit = async (data) => {
    data.id = task.id;
    data.appId = task.appId;
    data.userBy = userDetails.id;
    data.userAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    console.log(data);
    // data.dirty = 1;
    await db.tasks.update(task.appId, data);


    // or... just start a simple worker?
    // disabling this for now so it's not slow for them in the field
    // we DO WANT TO START A WORKER
    // otherwise it makes them wait for the upload



    // if(online) {
    //   let update = (listSchema.length > 0) ? { plot: plotCleaned, plotTask: listCleaned } : { plot: plotCleaned };
    //   let res = await serverCall({ method: 'PATCH', data: update, url: `/mapping/plots/task/${obj.appId}`, eamsAuth0: true });
    //   if(res.status===200) {
    //     // update or replace plot(s)
    //     if(res.data.plots?.length > 0) {
    //       for(const plot of res.data.plots) {
    //         await db.plots.put(plot);
    //       }
    //     } else {
    //       await db.plots.update([obj.appId, activePhase.id], { dirty: null });
    //     }

    //     // update or replace plotTask(s)
    //     if(res.data.plotTasks?.length > 0) {
    //       for(const plotTask of res.data.plotTasks) {
    //         await db.plotTasks.put(plotTask);
    //       }
    //     } else {
    //       await db.plotTasks.update([obj.appId, task.id], { dirty: null });
    //     }
    //   }
    // }

    // handleClose();



  }

  const handleCancel = () => {
    reset(task);
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-7">
            <h5 className="mb-1">{project.name}{phases?.length > 1 && `: ${activePhase.name}`}</h5>
            <h2>{checklist.name}</h2>
            
            { task.lastSync && <p className="text-muted">Last synced {dayjs(task.lastSync).format('MM-DD-YY HH:mm:ssa')}</p> }
          </div>
          <div className="col-5 text-end">
            { online && (
              <button className="btn btn-outline-dark mb-3" onClick={getTask}>
                <ArrowClockwise size={16} />
              </button>
            )}
          </div>
        </div>
        { checklist.config?.intro?.length > 0 && (
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Generated id="form-modal" schema={checklist.config.intro} size="md" pieces={{ register, control, setValue, watch, errors }} />
            <div>
              <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'}`} type="submit" disabled={pending}>
                { !pending && !error && 'Save'}
                { pending === true && (
                  <Fragment>
                    <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    Saving...
                  </Fragment>
                )}
                { error && (`Error saving (TE332)`)}
                { pending ==='success' && 'Saved!'}
              </button>
              
              <button className="btn btn-sm btn-outline-danger ms-1" type="button" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </form>
        )}
        { checklist?.config?.noPlans!==true && (
          <div className="row">
            <div className="col">
              { planGroups.filter(x => x.projectId===project.id).sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0)).map((obj) => {
                let groupPlans = activePlans.filter(x => x.projectId===project.id && x.planGroupId===obj.id).sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
                let planIds = groupPlans.map(x => x.id);

                return (
                  <div key={obj.appId} className="mb-5">
                    <div className="d-flex justify-content-between align-items-end mb-2">
                      <h4 className="mb-0">{obj.name}</h4>
                      { online && (
                        <button className="d-block btn btn-outline-dark" style={{marginRight:16}} onClick={(e) => downloadPlan(e, planIds)}>
                          <Download size={16} />
                        </button>
                      )}
                    </div>
                    
                    { groupPlans.map(plan => {
                      let filtered = phasePlans?.filter(x => x.planId===plan.id);
                      let plotQty = activePlots?.filter(x => x.planId === plan.id)?.length;
                      return (
                        <PlanList key={plotQty+plan.appId} obj={plan} phase={activePhase} phases={phases} phasePlans={filtered} plotQty={plotQty} />
                      )
                    })}
                  </div>
                )
              })}

              {(online && activePlans.filter(x => x.projectId===project.id && !x.planGroupId).length > 0) && (
                <div className="text-end mb-2">
                  <button className="btn btn-sm btn-outline-dark" style={{marginRight:16}} onClick={(e) => downloadPlan(e, activePlans.filter(x => x.projectId===project.id && !x.planGroupId).map(x => x.id) )}>
                    <Download size={16} className="me-1" /> Download All
                  </button>
                </div>
              )}

              { activePlans.filter(x => x.projectId===project.id && !x.planGroupId).sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0)).map(plan => {
                let filtered = phasePlans?.filter(x => x.planId===plan.id);
                let plotQty = activePlots?.filter(x => x.planId === plan.id)?.length;
                return (
                  <PlanList key={plan.appId} obj={plan} phase={activePhase} phases={phases} phasePlans={filtered} plotQty={plotQty} />
                )
              })}

              { gis && gisPlans.map(plan => (
                <div key={plan.appId} className="card mb-2 pointer" onClick={() => navigate(`./${plan.appId}`)}>
                  <div className="card-body">
                    <h5 className="card-title">{plan.name}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div style={{height:100}} />
    </Fragment>
  )
}

export default View;
