import React from 'react';

const View = (props) => {
  const { phases, activePhase, setActivePhase } = props;

  const changePhase = (e) => {
    e.target.blur();
    let found = phases.find(x => x.id === parseInt(e.target.value));
    if(found) setActivePhase(found);
  }

  // if there's only one phase, don't show the dropdown
  if(phases.filter(x => x.status==='A').length < 2) return null;

  return (
    <select
      style={{width:200}}
      className="form-select form-select-sm d-inline-block border shadow-sm float-end me-2"
      value={activePhase.id}
      onChange={changePhase}>
      {phases.filter(x => x.status === 'A')
        .sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0))
        .map((phase, idx) => (
        <option key={`phase-${phase.id}`} value={phase.id}>{phase.name}</option>
      ))}
    </select>
  )
}

export default View;
