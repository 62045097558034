import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { TableVirtuoso } from 'react-virtuoso'
import { useTable, useSortBy } from 'react-table'

import { CaretUp, CaretDown } from 'react-bootstrap-icons';

// how to use:
// <TableLarge columns={projectColumns} data={projects} onRowClicked={projectRowClick} classes="table my-0" rowClasses="pointer" />

// accessing different props:
// https://stackoverflow.com/questions/55504805/react-table-using-multiple-accessors-as-props-in-a-custom-component

// hidden columns:
// https://stackoverflow.com/questions/67925681/react-table-hide-custom-accessor-but-include-in-global-filtering

// change column width:
// https://stackoverflow.com/questions/62152372/changing-column-width-in-react-table

const Table = ({ height, classes, rowClasses, cellClasses, columns, data, noDataMsg, onRowClicked, onCellClicked }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  // const firstPageRows = rows.slice(0, 5000)

  return (
    <>
    <TableVirtuoso
      style={{ height: height }}
      totalCount={rows.length}
      components={{
        Table: ({ style, ...props }) => <table {...getTableProps()} {...props} style={{ ...style, width:'100%', tableLayout: 'fixed' }} className={classes} />,
        TableBody: React.forwardRef(({ style, ...props }, ref) => <tbody {...getTableBodyProps()} {...props} ref={ref} />),
        TableRow: (props) => {
          const index = props['data-index']
          const row = rows[index]
          return <tr {...props} {...row.getRowProps({
              className: rowClasses,
              onClick: e => onRowClicked && onRowClicked(row, e)
            })} />
        },
      }}
      fixedHeaderContent={() => {
        return headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th className="body-bg" {...column.getHeaderProps({
                  style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth }
                })}>
                <div {...column.getSortByToggleProps()}>
                  {column.render('Header')}
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <CaretDown className="ms-1" />
                      : <CaretUp className="ms-1" />
                    : ''}
                </div>
              </th>
            ))}
          </tr>
        ))
      }}
      itemContent={(index, user) => {
        const row = rows[index];
        prepareRow(row);
        return row.cells.map((cell) => {
          return (
            <td {...cell.getCellProps({
                className: cellClasses,
                onClick: e => onCellClicked && onCellClicked(row, e)
              })}>{cell.render('Cell')}</td>
          )
        })
      }}
    />

      {/*<p>Showing the first 20 results of {rows.length} rows</p>*/}
    </>
  )
}

const Component = (props) => {
  const ref = useRef(null);
  const columns = useMemo(() => props.columns, [props.columns]);
  const data    = useMemo(() => props.data, [props.data]);
  const [height, setHeight] = useState(400);

  useLayoutEffect(() => {
    const updateHeight = () => {
      setHeight(ref.current.parentElement.offsetHeight - 10)
    }
    window.addEventListener('resize', updateHeight);
    updateHeight();
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  if(data.length > 0 && columns.length===0) return (
    <div className="mt-5 p-2 text-center bg-danger text-white">Missing table headers.</div>
  );

  return (
    <div ref={ref}>
      <Table
        height={height}
        columns={columns}
        data={data}
        noDataMsg={props.noDataMsg}
        classes={props.classes}
        rowClasses={props.rowClasses}
        cellClasses={props.cellClasses}
        onRowClicked={props.onRowClicked}
        onCellClicked={props.onCellClicked}
      />
    </div>
  )
}

export default Component;
