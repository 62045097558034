import React, { Fragment, useContext, useState } from 'react';
import dayjs from 'dayjs'

import { WorkerContext } from '../../context/WorkerContext';
import { Download } from 'react-bootstrap-icons';
import { findPhasePlan } from '../../utils/phases';
import { drawMap } from '../mapping/utils/reports';
import { downloadFile } from '../../utils/functions';

// letter: 792 pt x 612 pt
// tabloid: 1224 pt x 792 pt
// arch E: 48 in x 36 in, 3023 pt x 2160 pt

const View = (props) => {
  const { startWorker } = useContext(WorkerContext);
  const { project, planGroups, plans, phasePlans, phases, phase, publicStatuses, plots } = props;
  const wrapper = props.wrapper ? props.wrapper : 'shadow-sm me-2';
  const classes = props.classes ? props.classes : 'btn btn-outline-primary';
  const text = props.text ? props.text : '';
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState();
  const [qty, setQty] = useState(0);
  
  const download = async ({ format, size, fit, hideBg }) => {
    console.clear();
    setLoading(true);

    let timestamp = dayjs().format('YYYY-MM-DD');
    let planName = plans.length > 1 ? 'Plans' : plans[0].name;
    let name = `${project.name} - ${phase.name} - ${planName} (${format.toUpperCase()})`;
    let filename = `${project.name} - ${phase.name} - ${planName} ${timestamp}.${format}`;

    let activePlans = [];
    let groups = planGroups.filter(x => x.status === 'A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
    for (const group of groups) {
      let groupPlans = plans.filter(x => x.planGroupId===group.id && x.status === 'A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
      activePlans = activePlans.concat(groupPlans);
    }

    let loosePlans = plans.filter(x => !x.planGroupId && x.status === 'A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
    activePlans = activePlans.concat(loosePlans);
    setQty(activePlans.length);

    let pages = [];
    for (const [idx, plan] of activePlans.entries()) {
      setCurrent(idx+1);
      let filtered = phasePlans.filter(x => x.planId === plan.id);
      let phasePlan = findPhasePlan(phases, phase, filtered);
      if(!phasePlan) continue;

      let image = await drawMap({ phasePlan, publicStatuses, plots, hideBg });
      console.log(`${plan.name} - ${phasePlan.folder}${phasePlan.name} downloaded`);

      if(format==='pdf')
        pages.push({ image, fit });
      else {
        filename = `${project.name} - ${phase.name} - ${plan.name} ${timestamp}.${format}`;
        downloadFile(image, filename, false);
      }
    }

    if(format==='pdf') {
      let content = { pageSize: size, pageMargins: [18, 18, 18, 18], content: pages }
      startWorker({ type: 'pdf', pieces: { name, filename, content } });
    }

    setLoading(false);
  }

  return (
    <div className={`btn-group position-static ${wrapper}`}>
      <button type="button" className={classes} onClick={() => download({format: 'pdf', size: {width: 1224, height: 792}, fit: [1188, 756]})} disabled={loading}>
        { !loading && (
          <Fragment>
            <Download size={16} />
            { text && <span className="d-inline-block ms-2">{text}</span>}
          </Fragment>
        )}

        { loading && (
          <Fragment>
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            { plans.length>1 && <span className="d-inline-block ms-2">{current} / {qty}</span>}
          </Fragment>
        )}        
      </button>
      <button type="button" className={`dropdown-toggle dropdown-toggle-split ${classes}`} data-bs-toggle="dropdown" aria-expanded="false" disabled={loading}>
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className="dropdown-menu dropdown-menu-end">
        <li><h6 className="dropdown-header">PDF</h6></li>
        <li className="dropdown-item pointer" onClick={() => download({format: 'pdf', size: {width: 1224, height: 792}, fit: [1188, 756]})}>Tabloid Landscape</li>
        <li className="dropdown-item pointer" onClick={() => download({format: 'pdf', size: {width: 792, height: 1224}, fit: [756, 1188]})}>Tabloid Portrait</li>
        <li><hr className="dropdown-divider" /></li>
        <li><h6 className="dropdown-header">PNG</h6></li>
        <li className="dropdown-item pointer" onClick={() => download({ format: 'png' })}>Plots & Background</li>
        <li className="dropdown-item pointer" onClick={() => download({ format: 'png', hideBg: true })}>Plots Only</li>
      </ul>
    </div>
  )
}

export default View;
