import React, { useRef } from 'react';
import { useMapEvents } from 'react-leaflet'

const View = (props) => {
	const longPress = useRef();
	const longPressTimer = useRef();
	const { toParent } = props;

	const map = useMapEvents({
		click: (e) => {
			// console.log(e)
      // console.log(e.sourceTarget)
			// let targetLayer = e.sourceTarget;
			// if (targetLayer instanceof L.Rectangle) {
			//      // Do something
			// 		 console.log('rect')
			//   } else if (targetLayer instanceof L.Polygon) {
			//      // Do something
			// 		 console.log('poly')
			//   } else if (targetLayer instanceof L.Polyline) {
			//      // Do something
			// 		 console.log('polyline')
			//   } else {
			//      // Do something
			// 		 console.log('other')
			//   }

			// console.log(e.latlng, map.getZoom())
			// console.log(map.getCenter())
			// console.log(map.getBounds())
			console.log('['+e.latlng.lat+', '+e.latlng.lng+']')
		},
		mousedown: (e) => {
			// if clicking on a divIcon, exit
			if(e.originalEvent?.target?.classList.contains('divIcon')) return;
			let latLng = e.latlng;
			longPress.current = Date.now();
			longPressTimer.current = setTimeout(() => {
				toParent({ type: 'add plot', value: latLng })
			}, 500);
		},
		dragstart: (e) => {
			clearInterval(longPressTimer.current);
		},
		mouseup: (e) => {
			clearInterval(longPressTimer.current);
		},
		dragging: (e) => {
			clearInterval(longPressTimer.current);
		},
		dragend: (e) => {
			toParent({ type: 'drag change' });
		},
		zoomend: (e) => {
			toParent({ type: 'zoom change', value: map.getZoom() });
		},
    baselayerchange: (e) => {
			toParent({ type: 'plan change', value: e.layer.options.appId });
    }
	});
	return null;
};

export default View;