import React, { Fragment, useEffect, useState } from 'react';
// import { useAxios }    from '../../../../hooks/useAxios';

import Modal from 'react-bootstrap/Modal';
import PlotEdit from '../../../mapping/plots/components/Edit';
// import ChangeStatus from '../../../../components/dropdowns/ChangeStatus';
// import { Discussion } from '../../../system/discussion/Discussion';
import MessageSchedule from '../../../../components/reports/MessageSchedule';
import Table from '../../../../components/system/Table';
import { emptyObj } from '../../../../utils/functions'

const cols = [
  { Header: 'ID', accessor: 'name', width: 20 },
  { Header: 'Product Type', accessor: 'type' },
  { Header: 'Status', accessor: 'publicStatus', width: 70 },
]

const View = (props) => {
  const { task, project, activePhase, types, plots, setPlots, photos, setPhotos } = props;
  // const { serverCall } = useAxios();

  const [activePlot, setActivePlot] = useState({});
  const [activePlots, setActivePlots] = useState([]);
  // const [threads, setThreads] = useState([]);
  // const [comments, setComments] = useState([]);

  const closeModal = () => setActivePlot({});
  const rowClick = (e) => setActivePlot(e.original);

  useEffect(() => {
    let filtered = plots.filter(x => x.projectId === project.id && x.phaseId === activePhase.id)
    for (const plot of filtered) {
      let type = types.find(x => x.id === plot.typeId);
      plot.type = type ? type.label : 'TBD';
      plot.typeObj = type ? type : {};
      if(!plot.publicStatus) plot.publicStatus = 'Pending';
    }
    setActivePlots(filtered);
  }, [plots, project.id, activePhase.id, types])

  const fromChild = (data) => {
    console.log(data)
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-7">
          <h3>Review</h3>
        </div>
        <div className="col-sm-5 text-end">
          <MessageSchedule project={project} plots={activePlots} photos={photos} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-8">
          <div className="mb-3 p-2 bg-white rounded">
            <Table columns={cols} data={activePlots} onRowClicked={rowClick} classes="table table-hover my-0" rowClasses="pointer" />
          </div>
        </div>
        <div className="col-sm-4">
          {/* <Discussion 
            parentType="task" 
            parentId={task.id} 
            threads={threads} 
            setThreads={setThreads} 
            comments={comments} 
            setComments={setComments}
          /> */}
        </div>
      </div>

      <Modal show={!emptyObj(activePlot)} onHide={closeModal} size='lg' backdrop='static' keyboard={false} centered scrollable>
        <Modal.Header closeButton>
          <Modal.Title>{activePlot?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-8">
              <PlotEdit obj={activePlot} setActivePlot={setActivePlot} project={project} activePhase={activePhase} types={types} setPlots={setPlots} photos={photos} setPhotos={setPhotos} handleClose={closeModal} toParent={fromChild} />
            </div>
            {/* <div className="col-sm-4 pt-1">
              <ChangeStatus value={activePlot.publicStatus} toParent={fromChild} />
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default View;