import { fabric } from 'fabric';

const shape = ({ fillColor, strokeColor, scale, rotation, details }) => {

  let circle = new fabric.Circle({
    radius: 5*scale,
    fill: fillColor,
    stroke: strokeColor ? strokeColor : fillColor,
  });

  let c = new fabric.Group([ circle ], {
    hasControls: true,
    allowRotation: true,
    angle: rotation,
    cornerSize: 24,
    borderColor: 'red',
    cornerColor: 'red',
    ...details
  });

  c.setControlsVisibility({
    bl: false,
    br: false,
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    tl: false,
    tr: false,
    mtr: true
  });

  return c;
}

export default shape;