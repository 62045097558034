import Dexie from 'dexie';

const db = new Dexie('eamsv8');
const eams = process.env.REACT_APP_EAMS_PRODUCT;
const dbVersion = 2;

// lklklk important note:
// you will need to start incrementing the version number any time you change these things!
// otherwise the local databases will throw errors

// general db notes...
// any time pages are updated we should update the presentation updatedAt...
// that way we can tell it if stuff should be synced down or not...?

let tables = {
  system: 'name',
  files: 'id, appId, parentType, parentId, name, type, status, updatedAt',
  filesLog: 'id, appId, parentId, name, type, status, updatedAt',
  schemas: 'id, appId, type, name, status',
  tasks: 'appId, id, projectId, phaseId, assignToArr, status, dirty',
  checklists: 'id, appId, status',
  typeChecklists: '[checklistId+typeId], id, appId, active, status'
}

if(eams==='dsm') {
  let dsmTables = {
    dsmDevices: 'appId',
    dsmPresos: 'id,appId,status,updatedAt',
    dsmPages: 'id,appId,presentationId,parentAppId,status,updatedAt',
    dsmSchedules: 'id,appId,updatedAt'
  };
  tables = {...tables, ...dsmTables}
}

let mapTables = {
  libraries: 'appId, id, status, dirty',
  symbols: 'appId, id, libraryId, status, dirty',
  types: 'appId, id, libraryId, status, dirty',
  variations: 'appId, id, libraryId, status, dirty',
  projects: 'appId, id, status, dirty',
  phases: 'appId, id, projectId, status, dirty',
  planGroups: 'appId, id, projectId, status, [projectId+status], dirty',
  plans: 'appId, id, projectId, [projectId+status], planGroupId, status, dirty',
  phasePlans: 'id, [planId+phaseId], status, dirty',
  plots: '[appId+phaseId], appId, id, projectId, planId, [planId+phaseId], status, dirty',
  plotTasks: '[plotAppId+taskId], appId, id, taskId, planId, [planId+taskId], status, dirty',
  photos: 'appId, id, projectId, planId, phaseId, plotAppId, [phaseId+plotAppId], status, dirty',
}

tables = {...tables, ...mapTables}

db.version(dbVersion).stores(tables);

db.open().catch(Dexie.SchemaError, (e) => {
  console.log('schema error: '+e.message);
})

export default db;

// for upgrading/changing in the future:
// https://github.com/dexie/Dexie.js/issues/349
