import React, { Fragment, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';

import Modal from 'react-bootstrap/Modal';
import Generated  from '../../../../components/system/Generated';
import DeleteBtn from '../../../../components/buttons/DeleteBtn';
// import EmailError from '../system/EmailError';

import dayjs from 'dayjs'

import { formObj } from '../../../../utils/schemas';
import { emptyObj } from '../../../../utils/functions';

const View = (props) => {
  const debug = true;
  const { userDetails, schema, obj, selected, setTypes, handleClose, toParent } = props;
  const formText = { submit: `Update ${selected.length} Types`, pending: 'Updating', success: 'Updated!', cancel: 'Cancel' };
  const errorCode = '5512'

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [schema])
  });

  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [schema, obj, reset])

  const onSubmit = async (data) => {
    if(debug) console.log(data);
    // create the update object, only with non-null values
    // this could mean they could do a space and it would clear values
    let update = {};
    for (const [key, value] of Object.entries(data)) {
      if(value===null) continue;
      update[key] = value;
    }

    let styleUpdates = [];

    setTypes((prev) => {
      let arr = [...prev];
      for(const id of selected) {
        let idx = arr.findIndex(x => x.id === id);
        // if we aren't updating styles, clobber existing values
        if(emptyObj(update.styling)) {
          arr[idx] = {...arr[idx], ...update}
        } else {
          let styling = {...arr[idx].styling, ...update.styling};
          styleUpdates.push({ id: arr[idx].id, styling })
          arr[idx] = {...arr[idx], ...update}
          arr[idx].styling = styling;
        }
      }
      return arr;
    })

    update.ids = selected;
    update.styleUpdates = styleUpdates;
    update.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    update.updatedBy = userDetails.id;

    let res = await serverCall({ method: 'PATCH', data: update, url: `/library/types/bulk`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating types. Contact support.') // lklklk
    handleClose();
  };

  const fromChild = async (data) => {
    const { type } = data;
    // console.log(data);
    if(type==='delete confirmed') {
      if(toParent) toParent({ type: 'remove types' });
    }
  }

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Edit {selected.length} Types</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mb-3">
          <Generated id="bulk-edit-types" schema={schema} size="md" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />
        </form>        
      </Modal.Body>
      <Modal.Footer>
        <DeleteBtn toParent={fromChild} text={`Delete ${selected.length} Types`} />

        <button className="btn btn-sm btn-outline-dark ms-auto me-1" type="button" onClick={handleClose}>
          {formText.cancel}
        </button>

        <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'}`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
          { !pending && !error && (formText.submit)}
          { pending === true && (
            <Fragment>
              <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              {formText.pending}
            </Fragment>
          )}
          { error && (`Error saving (${errorCode})`)}
          { pending ==='success' && formText.success}
        </button>
      </Modal.Footer>
    </Fragment>
  )
}

export default View;
