import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const View = (props) => {
  const { newNotices } = props;
  let navigate = useNavigate();
  let location = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if(location?.pathname!=='/notifications' && newNotices > 0) 
      setShow(true);
    else
      setShow(false);
  }, [location, newNotices])

  const viewNotices = () => {
    navigate('/notifications');
    setShow(false);
  }

  return (
    <Modal show={show} size="sm" onHide={() => setShow(false)} centered={true} backdrop={true} keyboard={true} scrollable>
      <Modal.Body>
        <h4>New Notifications</h4>
        <p>There are {newNotices} new notifications for you to view.</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-danger me-1 ms-auto" type="button" onClick={() => setShow(false)}>
          Dismiss
        </button>
        <button className="btn btn-outline-dark" onClick={viewNotices}>View</button>
      </Modal.Footer>
    </Modal>
  )
}

export default View;
