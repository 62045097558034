import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useAxios } from '../../../hooks/useAxios';
import { UserContext } from '../../../context/UserContext';
import dayjs from 'dayjs'

import NewPhase from './components/New';
import Sortable from '../../../components/system/sortable/Sortable'
import SortItem from './components/Phase';

const View = (props) => {
  const { project, phases, setPhases } = props;
  const { userDetails } = useContext(UserContext);
  const { serverCall } = useAxios();
  const [active, setActive] = useState([]);

  useEffect(() => {
    setActive((prev) => {
      let arr = [...phases];
      arr = arr.filter(x => x.status === 'A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
      return arr;
    })
  }, [phases]) 

  const fromChild = async (data) => {
    const { type, value } = data;

    if(type==='update') {
      setPhases((prev) => {
        let arr = [...prev];
        let idx = arr.findIndex(x => x.id === value.id);
        arr[idx] = {...arr[idx], ...value};
        return arr;
      })

    } else if(type==='sorted') {
      let update = value.map(obj => ({
        appId: obj.appId,
        id: obj.id,
        sortOrder: obj.sortOrder,
        updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        updatedBy: userDetails.id
      }));

      let res = await serverCall({ method: 'POST', data: update, url: `/mapping/phases/sort-order`, eamsAuth0: true });
      if(res.status!==200) return alert('Error sorting. Contact support.') // lklklk
    }
  }

  return (
    <Fragment>
      <Sortable arr={active} setArr={setPhases} pieces={{ project }} handle="end" child={SortItem} toParent={fromChild} />
      <br />
      <NewPhase project={project} phases={phases} setPhases={setPhases} />
    </Fragment>
  )
}

export default View;
