import { blobToBase64 } from '../../../utils/functions';
import { makeSvg } from './generate-svg';

export const getGenerated = (url, type, plot, message, symbols) => {
  return new Promise( async (resolve)=>{
    let template = await getSvg(url);
    let img = await makeSvg({ template, message, symbols, type, plot });
    resolve(img);
  });
}

export const getSvg = (url, parse) => {
  return new Promise( async (resolve)=>{
    try {
      let blob = await fetch(url, { mode: 'cors' }).then(res => res.blob());
      let reader = new FileReader();
      reader.readAsText(blob);
  
      reader.onload = async() => {
        if(parse) {
          let svg = new DOMParser().parseFromString(reader.result, "text/html").querySelector('svg');
          resolve(svg);
        } else
          resolve(reader.result);
      }
    
      reader.onerror = function() {
        console.log(reader.error);
        resolve(null);
      };
    } catch (error) {
      console.log('There was an error', error);
      resolve(null);
    }
  });
}

export const dimensions = (svg) => {
  return new Promise( async (resolve)=>{
    if(!svg) resolve(null);

    let dimensions = svg.getAttribute('viewBox').split(' ');
    const width = Number(dimensions[2]);
    const height = Number(dimensions[3]);
    resolve({ width, height });
  });
}

export const getRect = (rect) => {
  return new Promise( async (resolve)=>{
    let x = rect.getAttribute('x');
    let y = rect.getAttribute('y');
    let w = rect.getAttribute('width');
    let h = rect.getAttribute('height');
    let iconSet = rect.getAttribute('iconSet');
    resolve({ x, y, w, h, iconSet });
  });
}

export const setRect = ({ x, y, w, h, icon }) => {
  return new Promise( async (resolve)=>{
    icon.setAttribute('x',x);
    icon.setAttribute('y',y);
    icon.setAttribute('width',w);
    icon.setAttribute('height',h);
    resolve(icon);
  });
}

export const fonts = ({ styles, symbols }) => {
  return new Promise( async (resolve)=>{
    let regexp = /font-family:['"]([\S]+)['"]/g;
    let matches = (styles[0].innerHTML.match(regexp) || []).map(e => e.replace(regexp, '$1'));
    for(const match of matches) {
      let found = symbols.filter(x => x.category==='font').find(x => x.name===match);
      if(!found || !found.url1) continue;
      let blob = await fetch(found.url1, { mode: 'cors' }).then(res => res.blob());
      let dataUrl = await blobToBase64(blob);
      styles[0].appendChild(document.createTextNode("@font-face { font-family: '"+match+"'; src: url('" + dataUrl + "') format('truetype');}"));
    }
    resolve();
  });
}

export const cleanMsg = (msg) => {
  return new Promise( async (resolve)=>{
    let cleanMsg = document.createElement('div');
    cleanMsg.innerText = cleanMsg.textContent = msg;
    let newMsg = cleanMsg.innerHTML;
    newMsg = newMsg.replace("'", "&#8217;");
    cleanMsg.remove();
    resolve(newMsg);
  });
}

export const repeatRows = ({ svgTags, rows, content, symbols }) => {
  return new Promise( async (resolve)=>{
    // console.clear();
    let debug = false;
    let message = [...content];
    let rowTemplate = '';
    let rowParent = '';

    for(const row of rows) {
      let repeatNo = row.getAttribute('row-repeat');
      let coords = row.getAttribute('transform');
      let rowHeight = row.getAttribute('row-height');
      let indexStart = row.getAttribute('index-start');
      if(!indexStart) indexStart = 0;
      let sectionIdx = row.getAttribute('section-index');
      let sectionVal = row.getAttribute('section-value');
      let sectionSpacing = row.getAttribute('section-spacing');
      let sectionOffset = 0;
      let alphaSeparatorIdx = row.getAttribute('alpha-separator-index');
      let showAlphaSeparator = false;
      let currentAlpha = '';

      sectionVal = (sectionVal) ? sectionVal.split(',') : [];

      coords = coords.replace('translate(','');
      coords = coords.replace(')','');
      coords = coords.split(',');

      rowParent = row.parentNode;
      rowTemplate = row.cloneNode(true);
      row.parentNode.removeChild(row);
      let rowMsg = message.splice(indexStart, message.length);

      for(var i=0; i < repeatNo; i++) {
        if(debug) {
          console.log('-------- NEW ROW '+i+' ----------')
          console.log(rowMsg[0])
          console.log(rowMsg[1])
          console.log(rowMsg[2]);
        }

        // determine if this is a new section
        if(sectionIdx && sectionSpacing && rowMsg[sectionIdx] && sectionVal.includes(rowMsg[sectionIdx])) {
          sectionOffset = sectionOffset + Number(sectionSpacing);
        }

        let yCoord = sectionOffset + Number(coords[1]) + Number(rowHeight)*i;

        let newCoords = [coords[0],String(yCoord)];
        newCoords = 'translate('+newCoords[0]+','+newCoords[1]+')';

        let newRow = rowTemplate.cloneNode(true);
        newRow.setAttribute('transform',newCoords);

        // check if we are splitting/changing by alpha
        if(alphaSeparatorIdx > -1) {
          let newAlpha = rowMsg[alphaSeparatorIdx]?.charAt().toUpperCase();

          if(!currentAlpha || newAlpha === '') {
            let alphas = newRow.querySelectorAll('[alpha-separator]');
            alphas.forEach((tag, index) => {
              tag.parentNode.removeChild(tag);
            })
          }

          currentAlpha = newAlpha;
        }

        // check if anything conditional should be hidden/shown
        let conditionals = newRow.querySelectorAll('[row-cond-index],[row-excl-index]');
        conditionals.forEach((tag, index) => {
          let condIdx = tag.getAttribute('row-cond-index');
          let condVal = tag.getAttribute('row-cond-value');
          let exclIdx = tag.getAttribute('row-excl-index');
          let exclVal = tag.getAttribute('row-excl-value');
          // console.log(tag);
          // console.log('cond',condIdx,condVal,rowMsg[condIdx]);
          // console.log('excl',exclIdx,exclVal,rowMsg[exclIdx]);

          if(condVal) {
            let condVals = condVal.split(',');
            if(!condVals.includes(rowMsg[condIdx])) {
              tag.parentNode.removeChild(tag);
              // svg = readSvg.outerHTML;
            }
          } else if(
            (condIdx!==null && !rowMsg[condIdx]) ||
            (exclIdx!==undefined && rowMsg[exclIdx])
          ) {
            // no condValue, but this should show with ANY value
            // delete if any value exists for this index
            tag.parentNode.removeChild(tag);
            // svg = readSvg.outerHTML;
          }
        });

        let text = newRow.querySelectorAll('text');
        text.forEach((tag, index) => {
          let rowIdx  = tag.getAttribute('row-index');
          tag.innerHTML = (rowMsg[rowIdx]) ? rowMsg[rowIdx]: '';
        })

        let rect = newRow.querySelectorAll('rect');
        for(const tag of rect) {
          let rowIdx  = tag.getAttribute('row-index');
          let iconSet  = tag.getAttribute('icon-set');
          let x = tag.getAttribute('x');
          let y = tag.getAttribute('y');
          let w = tag.getAttribute('width');
          let h = tag.getAttribute('height');

          if(rowMsg[rowIdx]) {
            let found = symbols.find(x => x.id === Number(rowMsg[rowIdx]));
            if(found && found[`url${iconSet}`]) {
              let icon = await getSvg(found[`url${iconSet}`], true);
              icon = await setRect({ icon, x, y, w, h });
              tag.replaceWith(icon);
            } else {
              // remove box outline
              tag.parentNode.removeChild(tag);
            }
          }
        }

        // splice to get the next row message field
        rowMsg = rowMsg.splice(3, rowMsg.length);
        rowParent.appendChild(newRow);
      }
    }

    resolve();
  });
}


