import { emptyObj } from '../../../../utils/functions';

// storing this outside of react for the canvas animation abort function
// if you try to pass the interaction it doesn't update, so needs to live here
var hovering = false;

const changeOpacity = (obj, opacity, canvas) => {
  obj.animate('opacity', opacity, {
    onChange: canvas.renderAll.bind(canvas),
    duration: 100
  });
}

const animate = (canvas, obj, duration, opacity, scale) => {
  var newOpacity = (obj.opacity == 1) ? opacity : 1;
  var newScale = (obj.scaleX == 1) ? scale : 1;

  obj.animate({
    scaleX: newScale,
    scaleY: newScale,
    opacity: newOpacity
  }, {
    duration: duration,
    onChange: canvas.renderAll.bind(canvas),
    onComplete: () => {
      if(hovering) animate(canvas, obj, duration, opacity, scale);
    },
    abort: () => !hovering
  });
}

export const handleHover = ({ e, debug, touch, canvas, interaction, toParent }) => {
  if(debug) console.log('mouse:over', e);
  if(!e.target) return;

  // check if in mode 3, shift key pressed, and if hovered object has 2 items (indicates a locator/label)
  if(interaction.mode===3 && interaction.bkgdLocked && e.target._objects?.length === 2) {
    let obj = e.target;
    // first item is shape, then the text
    if(!obj.eamsNumbered) {
      // this isn't numbered yet, add 
      obj.eamsNumbered = true;
      obj._objects[0].fill = '#f00';
      obj._objects[0].width = interaction.reorderNo > 99 ? 70 : 60;
      obj._objects[1].text = String(interaction.reorderNo);
      if(obj.eamsLine && 'stroke' in obj.eamsLine) obj.eamsLine.stroke = '#f00';
      canvas.requestRenderAll();
      interaction.reorder.push({ id: obj.id, name: String(interaction.reorderNo) });
      interaction.reorderNo = interaction.reorderNo+interaction.reorderSkip+1;
    } else {
      // this is already numbered, un-highlight
      // but only if they are going backwards in the sequence
      let oldNo = Number(obj._objects[1].text);
      let lastNo = interaction.reorderNo-interaction.reorderSkip-1;
      if(oldNo!==lastNo) return;

      delete obj.eamsNumbered;
      obj._objects[0].fill = '#ffbf00';
      obj._objects[0].width = 36;
      obj._objects[1].text = '---';
      if(obj.eamsLine && 'stroke' in obj.eamsLine) obj.eamsLine.stroke = '#ffbf00';
      canvas.requestRenderAll();
      interaction.reorder.pop();
      interaction.reorderNo = interaction.reorderNo-interaction.reorderSkip-1;
    }
  } else {
    if(!touch && e.target && e.target.appId !== interaction.hover.appId) {
      // update the hoverRef location
      hovering = true;
      interaction.hover.appId = e.target.appId;
      interaction.hover.top = e.e.clientY + 10 + 'px';
      interaction.hover.left = e.e.clientX + 10 + 'px';
      if(toParent({ type: 'hover', value: interaction.hover }));

      // show original location if one exists
      if(!emptyObj(e.target.eamsMoved)) {
        let objs = canvas.getObjects();
        let filtered = objs?.filter(x => x.appId===e.target.appId && x.piece==='original location');
        for(const obj of filtered) {
          changeOpacity(obj, 1, canvas);
          animate(canvas, obj, 750, 0.75, 1.2);
        }
      }
    }
  }
}

export const handleOut = ({ e, touch, canvas, interaction, toParent }) => {
  if(touch) return;
  hovering = false;
  interaction.hover = {};
  if(toParent({ type: 'hover', value: interaction.hover }));

  if(e?.target?.eamsMoved) {
    let objs = canvas.getObjects();
    let filtered = objs?.filter(x => x.appId===e.target.appId && x.piece==='original location');
    for(const obj of filtered) {
      changeOpacity(obj, 0, canvas);
    }
  }
}