import React, { Fragment } from 'react';

import { filterExists, toggleFilter } from '../../../utils/filters'

const View = (props) => {
  const { arr, field, title, filters, setFilters, group } = props;

  return (
    <Fragment>
      { arr && (
        <Fragment>
          { title && (<li><h6 className="dropdown-header">{title}</h6></li>)}
          { arr.map((obj) => {
            let checkId = obj.value ? obj.value : field+'tbd';
            let border = '';
            if(group==='typeId') border = `6px solid  ${obj.styling?.fillColor}`;
            else if(group==='publicStatus') border = `6px solid  ${obj.fillColor}`;
            else border = `6px solid #eee`;

            return (
              <li key={'typeId-'+checkId} className="dropdown-item" style={{whiteSpace:'inherit', borderLeft: border, paddingLeft:18 }}>
                <div className="d-flex form-check">
                  <input className="flex-shrink-0 form-check-input" type="checkbox"
                    id={'status-'+checkId} checked={filterExists(filters, checkId, group)}
                    onChange={() => { toggleFilter(filters, setFilters, checkId, group, (x) => x[field] === obj.value) }}
                  />
                  <label className="w-100 flex-grow-1 d-flex align-items-center form-check-label" htmlFor={'status-'+checkId}>
                    <div className="flex-grow-1 ms-2 text-truncate">{obj.label ? obj.label : obj.value}</div>
                    <div className="flex-shrink-0 ms-1">{obj.quantity ? `(${obj.quantity})` : ''}</div>
                  </label>
                </div>
              </li>
            )
          })}
        </Fragment>
      )}
    </Fragment>
  )
}

export default View;
