import { fabric } from 'fabric';
import { handleDrawPlots } from './display/draw-plots';

export const drawMap = async ({ phasePlan, statuses, plots, hideBg, resolution }) => {
  let promise = new Promise((resolve, reject) => {
    if(!resolution) resolution = 1.5;
    let fadeBkgd = phasePlan.styling?.fadeBkgd ? phasePlan.styling.fadeBkgd : 1;

    let el = document.createElement('canvas');
    let canvas = new fabric.Canvas(el);
    fabric.Object.prototype.originX = fabric.Object.prototype.originY = 'center';

    let bkgd = new Image();
    bkgd.crossOrigin = 'Anonymous';
    bkgd.src = phasePlan.url;
    bkgd.onload = async () => {
      canvas.setViewportTransform([resolution,0,0,resolution,0,0]);
      canvas.setWidth(bkgd.width*resolution);
      canvas.setHeight(bkgd.height*resolution);
      if(!hideBg) {
        canvas.setBackgroundImage(phasePlan.url, canvas.renderAll.bind(canvas), {
          opacity: fadeBkgd,
          backgroundColor: 'white',
          backgroundImageStretch: false,
          originX: 'center',
          originY: 'center',
          left: bkgd.width/2,
          top: bkgd.height/2,
          crossOrigin: 'Anonymous',
        });
      }

      await handleDrawPlots({ canvas, map: phasePlan, statuses, plots });
      // canvas = await handleDrawPlots({ editable, sizing, offset, mode,  canvas: canvasRef.current, map, plots, interaction: interaction.current });

      setTimeout(() => {
        let imgData = canvas.toDataURL();
        resolve(imgData);
      }, 500)
    }
  })

  let result = await promise;
  return result;
}