import React, { Fragment } from 'react';

const View = (props) => {
  const { files } = props;

  return (
    <Fragment>
      { Object.keys(files).length > 0 && (
        <Fragment>
          <h5 className="mt-2">Uploading</h5>
          { Object.keys(files).map((key, idx) => (
            <div key={'accepted-'+files[key].appId} className="d-flex">
              <p className="mb-0 flex-grow-1">{files[key].originalName}</p>
              <p className="mb-0 text-end">{files[key].progress}%</p>
            </div>
          ))}
          <hr />
        </Fragment>
      )}
    </Fragment>
  )
}

export default View;
