import React, { Fragment } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';

import NewPreso from './presentations/New';

const View = (props) => {
  const navigate = useNavigate();
  const { presos, setPresos } = useOutletContext();
  
  return (
    <Fragment>
      <Helmet>
        <style>{`body { padding-top:4rem; }`}</style>
      </Helmet>
      <div className="float-end">
        <NewPreso setPresos={setPresos} />
      </div>
      { presos.filter(x => x.status==='A').map((obj) => (
        <button key={obj.appId} className="btn btn-outline-dark d-block mb-2" onClick={() => navigate(`./${obj.appId}`)}>{obj.name}</button>
      ))}
      
    </Fragment>
  )
}

export default View;
