import React, { useContext, useEffect, useState } from 'react';
import { SiteContext } from '../../../../context/SiteContext';
import FormModal from '../../../../components/system/FormModal';

const View = (props) => {
  const { project, phases, setPhases } = props;
  const { config } = useContext(SiteContext);
  const [newName, setNewName] = useState('');

  const schema = [
    { id: '1', type: 'col-12', label: '1', col: [
      { id: '2', type: 'hidden', field: 'projectId' },
      { id: '3', type: 'floating-input', inputType: 'text', label: `Create ${config?.terms?.phase?.[2] ?? 'Phase'}`, field: 'name', },
    ]},
  ]

  useEffect(() => {
    let num = phases?.filter(x => x.status==='A')?.length + 1;
    setNewName(`${config?.terms?.phase?.[2] ?? 'Phase'} #${num}`);
  }, [phases, config?.terms?.phase])

  const fromForm = (data) => {
    let { type, status, response } = data; // value = form values, response = response from server

    if(type === 'submitted' && status === 200) {
      if(!response) alert('Error.'); //lklklk

      setPhases((prev) => {
        let arr = [...prev];
        arr.push(response);
        return arr;
      })
    }
  }

  return (
    <FormModal debug={false} method="POST" url="/mapping/phases" schema={schema}
      createForm={true} updateForm={false} toParent={fromForm} keyboard={true} centered={true}
      vals={{ 
        projectId: project.id,
        name: newName
      }}
      modalText={{ button: `Create ${config?.terms?.phase?.[2] ?? 'Phase'}`, title: `Create ${config?.terms?.phase?.[2] ?? 'Phase'}` }}
      formText={{ submit: 'Create', pending: 'Creating', success: 'Created', cancel: 'Cancel' }}
      submitType={{ type: 'close-modal' }}
    />
  )
}

export default View;
