import React, { forwardRef, useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form'

import '../../../static/redactor/redactorx.css';
import RedactorX from '../../../static/redactor/redactorx.js';

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  const app = useRef();
  const editorId = `${pieces.formId}-${item.field}`;

  useEffect(() => {
    if(app.current) {
      app.current.destroy();
      app.current = '';
    }

    app.current = RedactorX(`#${editorId}`, {
      editor: { maxHeight: item.maxHeight },
      addbar: { hide: item.hide },
      placeholder: item.placeholder ? item.placeholder : '',
      subscribe: {
        'editor.change': () => {
          let content = app.current.editor.getContent();
          if(content === '<p></p>') content = '';
          pieces.setValue(`${item.field}`, content);
          if(pieces.toParent) pieces.toParent({ type: 'redactor', name: item.field, value: content }); 
        },
        'editor.blur': () => {
          // need to put this in blur so it doesn't kick them out of the editor
          let content = app.current.editor?.getContent();
          if(content === '<p></p>') content = '';
          if(content && pieces?.trigger) pieces.trigger(`${item.field}`);
        }
      }
    });
  }, [editorId, item, pieces])

  useEffect(() => {
    return () => {
      app.current.destroy();
      app.current = '';
    }    
  }, [])

  return (
    <Controller
      name={item.field}
      control={pieces.control}
      rules={{ required: item.required }}
      render={({ field }) => {
        if(!field.value) field.value = '<p></p>';

        return (
          <div className="my-2">
            {item.label && (<label htmlFor={item.field}>{item.label}</label>)}
            {item.note && (<p className="mb-1 text-muted fst-italic small">{item.note}</p>)}
            <textarea {...field}
              ref={field.ref}
              id={editorId}
              name={item.field}
              disabled={item.disabled} />
            {pieces.errors[item.field] && <p className="mt-0 error">{item.errorMsg}</p>}
          </div>
        )
      }}
    />
  )
});

export default Field;
