import React, { forwardRef, useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form'

// how to use:
// <Textarea key={item.id} {...register(item.field)} item={item} pieces={{ control, errors, toParent, size }} />

// schema example:
// { id: '__NEW_ID__', field: '__DB_FIELD__', type: 'textarea', rows: 5, label: 'ze label', error: 'u fd up', required: 'wow u fucked', note: 'some other note', placeholder='Wow how r u' },

const Field = forwardRef(({ item: ogItem, pieces, disabled, ...rest }, ref) => {
  let item = {...ogItem};
  if(!item.rows) item.rows = 3;
  let value = pieces.watch(item.field);
  const textareaRef = useRef(null);

  if(item.placeholder && !item.label) item.label = item.placeholder;

  const handleKeyDown = (e) => {
    const target = e.target;
    target.style.height = 'inherit'; // reset the height 
    target.style.height = `${Math.max(target.scrollHeight, item.rows * 16)}px`; // set new height
  }

  useEffect(() => {
    const element = textareaRef.current;
    if(element) {
      element.style.height = 'inherit'; // reset the height 
      let newHeight = Math.max(element.scrollHeight, item.rows * 16);
      element.style.height = `${newHeight}px`; // set new height
    }
  }, [item, value]);

  return (
    <Controller
      name={item.field}
      control={pieces.control}
      rules={{ required: item.required }}
      render={({ field }) => {
        if(!field.value) field.value = '';

        return (
          <div className="form-floating my-2">
            {item.note && (<p className="mb-1 text-muted fst-italic small">{item.note}</p>)}
            <textarea {...field}
              ref={textareaRef}
              name={item.field}
              placeholder={item.label}
              className={`form-control ${(pieces.size) ? "form-control-"+pieces.size : ""}`}
              rows={item.rows}
              onKeyDown={handleKeyDown}
              onBlur={(e) => {
                if(pieces.toParent) pieces.toParent({ type: 'textarea', name: item.field, value: e.target.value });
              }}
              disabled={item.disabled} />
            {item.label && (<label htmlFor={item.field}>{item.label}</label>)}
            {pieces.errors[item.field] && <p className="mt-0 error">{item.errorMsg}</p>}
          </div>
        )
      }}
    />
  )
});

export default Field;
