import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs';

import { SiteContext } from '../../context/SiteContext';
import { useAxios } from '../../hooks/useAxios';

import Generated  from '../../components/system/Generated';
// import EmailError from '../system/EmailError';

import { formObj } from '../../utils/schemas';

// lklklk improvements:
// add a checkbox with link to terms etc
// convert schema to be pulled from db

// future SFO-specific:
// check sub for ccsf oidb or email connections to auto-swap SFO department vs Exernal Company field
// may also consider allowing them to pre-register someone with email, and then link the two accounts
// you'd just need to figure out ... I dunno. how to link them I guess


const View = (props) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { config, siteSchemas } = useContext(SiteContext);
  const title = props.title ? props.title : 'Please register';
  const formText = { submit: 'Register', pending: 'Please wait...', success: 'Registered!', cancel: 'Cancel' };
  const errorCode = '005'

  const [schema, setSchema] = useState([]);

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [schema])
  });

  useEffect(() => {
    let found = siteSchemas.find(x => x.type==='form' && x.name==='registration');
    if(found) setSchema(found.schema);
  }, [siteSchemas])

  useEffect(() => {
    let data = props.obj ? props.obj : {};
    // if auth0 user provides email, manually lock the email field
    if(user.email) {
      data.email = user.email;
      let el = document.getElementsByName('email')[0];
      if(el) el.disabled = true;
    }

    let obj = formObj(schema, data);
    reset(obj);
  }, [user, schema, props.obj, reset])

  const onSubmit = async (data) => {
    data.findBy = 'email';
    data.auth0Id = user.sub;
    data.appId = user['https://mstdsqr.com/app_metadata']?.uuid;
    data.createdBy = 0; // self-registering, set id to 0
    data.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.status = 'A';

    // finally, check for any localStorage for a share redirect
    let shared = JSON.parse(localStorage.getItem('share_redirect'));
    if(shared?.key && shared?.access) {
      data.shareKey = shared.key;
      data.shareAccess = shared.access;
    }

    let res = await serverCall({ method: 'POST', data, url: `/system/users/register`, eamsAuth0: true });
    if(res.status===200)
      getAccessTokenSilently({ ignoreCache: true });
    else {
      console.log('error'); // lklklk
    }
  };

  const handleCancel = (e) => {
    e.target.blur();
    reset();
  }

  return (
    <Fragment>
      <Helmet>
        <title>Register | {config.title}</title>
        <style>{` body { padding:0 } `}</style>
      </Helmet>
      <div style={{height: '100vh'}} className="d-flex align-items-center justify-content-center">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" style={{width:320}}>
          <h1>{title}</h1>
          <Generated id="register-form" schema={schema} size="md" pieces={{ register, control, setValue, watch, errors }} />

          <div className="mt-3 text-center">
            <button className={`btn me-1 ${error ? 'btn-danger' : 'btn-success'}`} type="submit" disabled={pending}>
              { !pending && !error && (formText.submit)}
              { pending === true && (
                <Fragment>
                  <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  {formText.pending}
                </Fragment>
              )}
              { error && (`Error saving (${errorCode})`)}
              { pending ==='success' && formText.success}
            </button>

            <button className="btn btn-outline-dark" type="button" onClick={handleCancel}>
              {formText.cancel}
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  )
}

export default View;
