import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

// get user metadata
// https://community.auth0.com/t/how-do-i-allow-users-to-view-update-their-user-metadata/29957/3

// future security additions
// https://auth0.com/docs/secure/attack-protection/state-parameters?_ga=2.218349265.931994372.1647728613-1078320238.1647728613&_gl=1*v3mn35*rollup_ga*MTA3ODMyMDIzOC4xNjQ3NzI4NjEz*rollup_ga_F1G3E656YZ*MTY0NzcyNTM0Mi40Ni4xLjE2NDc3MzI5OTQuMzE.


const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  let navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;

// auth0 docs if we need them
// https://www.smashingmagazine.com/2020/11/authenticating-react-apps-auth0/
// https://community.auth0.com/t/id-token-and-refresh-token-from-in-react-app/63641/3
// https://community.auth0.com/t/auth0-react-wont-refresh-access-tokens-in-mobile-safari-with-refresh-tokens-enabled/58065
