import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';

import { UserContext } from '../../../../context/UserContext';
import Generated  from '../../../../components/system/Generated';
// import EmailError from '../system/EmailError';

import dayjs from 'dayjs'
import { findSchema, formObj } from '../../../../utils/schemas';

const View = (props) => {
  const debug = true;
  const { userDetails, schemas } = useContext(UserContext);
  const { obj, project, types, setPlots, handleClose, toParent } = props;
  const formText = { submit: `Add`, pending: 'Adding', success: 'Added!', cancel: 'Cancel' };
  const errorCode = '1986'

  const objRef = useRef({});
  const [editSchema, setEditSchema] = useState([]);
  const [actionSchema, setActionSchema] = useState([]);

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(editSchema), [editSchema])
  });

  useEffect(() => {
    let found = schemas.find(x => x.name === 'plot-actions');
    if(found) setActionSchema(found.schema);

    found = schemas.filter(x => x.name==='plot-edit');
    found = findSchema(found, project); // this one could have multiple schemas
    if(found?.schema) setEditSchema(found.schema);
  }, [schemas, project])

  useEffect(() => {
    if(!obj) return;
    objRef.current = obj;
    let filled = formObj(editSchema, obj);
    reset(filled);
  }, [editSchema, obj, reset])

  const onSubmit = async (data) => {
    if(debug) console.log(data);
    data.projectId = obj.projectId;
    data.planId = obj.planId;
    data.name = obj.name;
    data.styling = obj.styling;
    data.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.createdBy = userDetails.id;
    data.status = 'A';

    let res = await serverCall({ method: 'POST', data, url: `/mapping/plots`, eamsAuth0: true });
    if(res.status!==200) return alert('Error adding plot. Contact support.') // lklklk

    await setPlots((prev) => {
      let arr = [...prev];
      arr.push(res.data);
      return arr;
    });

    toParent({ type: 'view plot', value: { appId: obj.appId, phaseId: obj.phaseId } })
  };

  const fromChild = (data) => {
    console.log(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mb-3">
      <Generated id="plot-new" schema={editSchema} size="md" pieces={{ vars: { 'plot-actions': actionSchema, types }, register, control, setValue, watch, errors, toParent: fromChild }} />

      {/* and then here... should be the message by the type? */}

      <div className="pt-2">
        <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'}`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
          { !pending && !error && (formText.submit)}
          { pending === true && (
            <Fragment>
              <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              {formText.pending}
            </Fragment>
          )}
          { error && (`Error saving (${errorCode})`)}
          { pending ==='success' && formText.success}
        </button>

        <button className="btn btn-sm btn-outline-dark ms-2" type="button" onClick={handleClose}>
          {formText.cancel}
        </button>
      </div>
    </form>
  )
}

export default View;
