import { fabric } from 'fabric';

export const handleResize = ({wrapper, canvas, map, bkgd}) => {
  let width = wrapper.parentElement.offsetWidth;
  let height = wrapper.parentElement.offsetHeight;
  let widthScale  = width / bkgd[0];
  let heightScale = height / bkgd[1];

  // check to see which scale is smaller
  let scale;
  if(widthScale > heightScale) {
    scale = heightScale;
    canvas.setWidth(width);
    canvas.setHeight(bkgd[1] * scale);
  } else {
    scale = widthScale;
    canvas.setWidth(bkgd[0] * scale);
    canvas.setHeight(height);
  }
  canvas.setZoom(scale);

  let viewport = localStorage.getItem('viewport');
  if(viewport && map) {
    viewport = JSON.parse(viewport);
    if(map.projectId === viewport.projectId)
      canvas.setViewportTransform(viewport.coords);
  } else {
    let widthOffset  = (width - (bkgd[0]*scale)) / 2;
    let heightOffset = (height - (bkgd[1]*scale)) / 2;
    canvas.setViewportTransform([scale,0,0,scale,widthOffset,heightOffset]);
  }

  // this rotation code DOES WORK but:
  // THIS NEEDS TO BE MOVED TO A DIFFERENT SCRIPT
  // it continues to rotate on each resize
  // it also seems to rotate from the top left and not the center

  // rotation links:
  // http://jsfiddle.net/YH9yD/178/
  // https://jsfiddle.net/asturur/2Lrnockw/1/ (kinda shitty one)
  // https://github.com/fabricjs/fabric.js/issues/1030

  // let degrees = 90;
  // let canvasCenter = new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2) // center of canvas
  // let radians = fabric.util.degreesToRadians(degrees);
  // console.log(canvasCenter, radians);
  //
  // // canvas.backgroundImage.setAngle(degrees);
  //
  // console.log(canvas.backgroundImage);
  //
  // canvas.getObjects().forEach((obj) => {
  //     let objectOrigin = new fabric.Point(obj.left, obj.top)
  //     let new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, radians)
  //     obj.top = new_loc.y
  //     obj.left = new_loc.x
  //     obj.angle += degrees //rotate each object by the same angle
  //     obj.setCoords()
  // });
  //
  // canvas.renderAll();
}
