import React, { Fragment } from 'react';
import { useOutletContext } from 'react-router-dom'

import Photo from '../../system/photos/components/Photo';

const View = () => {
  const { plans, activePlots, activePhotos } = useOutletContext();

  return (
    <Fragment>
      { plans.filter(x => x.status === 'A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0)).map(plan => {
        let planPlots = activePlots.filter(x => x.planId===plan.id);
        let plotAppIds = planPlots.map(x => x.appId);
        let planPhotos = activePhotos.filter(x => plotAppIds.includes(x.plotAppId));
        if(planPhotos.length === 0) return null;

        return (
          <div key={plan.appId} className="mb-3 p-3 bg-white rounded">
            <h4 className="mb-4">{plan.name} - {planPhotos.length} photos</h4>
            { planPlots.map(plot => {
              let plotPhotos = activePhotos.filter(x => x.plotAppId===plot.appId);
              if(plotPhotos.length === 0) return null;
              return (
                <div key={plot.appId+plot.id} className="mb-3">
                  <h5>{plot.name} - {plot.display.textLabel} - {plot.display.typeLabel}</h5>
                  { plotPhotos.length > 0 && (
                    <div className="horizontal-scroll mb-3">
                      { plotPhotos.filter(x => x.url).map((img) => <Photo key={img.appId+img.id} img={img} mini />)}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )
      })}
    </Fragment>
  )
}

export default View;
