import { fabric } from 'fabric';

const shape = ({ textLabel, textOpts, fillColor, borderColor, scale, details }) => {
  let text = new fabric.Text(textLabel, textOpts);
  let circle = new fabric.Circle({
    radius: 13*scale,
    fill: fillColor,
    strokeWidth: 1*scale,
    stroke: borderColor,
  });

  let c = new fabric.Group([ circle, text ], details);
  return c;
}

export default shape;