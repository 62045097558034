import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';

import { wheelHandler } from './fabric/wheel';
import { resizeHandler } from './fabric/resize';
import { handleTouchDrag } from './fabric/touch'; // handleLongpress, handleTouchGesture

export const useCanvas = (canvasRef, wrapperRef, bgImg, angle = 0) => {
  const [canvas, setCanvas] = useState(null);
  const [bkgd, setBkgd] = useState([]);
  const [msg, setMsg] = useState(null);
  const [selected, setSelected] = useState([]);

  const interaction = useRef({
    selected: []
  });

  const wheel  = useCallback((e) => wheelHandler({ e, canvas }), [canvas]);
  const resize = useCallback(() => resizeHandler({ wrapperRef, canvas, bkgd }), [wrapperRef, canvas, bkgd]);
  
  // const touchGest = useCallback((e) => handleTouchGesture({ e, canvas, interaction }), [canvas]);

  const checkPan = useCallback((type, e) => {
    //   let item = e.target;
    //   // item.set('fill','green');
    //   // item.set('text','yoooo');

    if(type==='selection:created' || type==='selection:updated' || type==='selection:cleared') {
      if(e.selected?.length===0) return;
      let selected = canvas.getActiveObjects();
      interaction.current.selected = selected;
      setSelected(selected);

    } else if(type==='touch:drag' && interaction.current.selected.length === 0) {
      handleTouchDrag({ e, canvas, interaction });

    } else if(type==='mouse:down' || type==='mouse:up') {
      delete interaction.current.panX;
      delete interaction.current.panY;
    }
    
    // console.log(type, e);
    if(type!=='touch:drag' && type!=='mouse:down')
      setMsg({ type: 'canvas' });
  }, [canvas]);

  useEffect(() => {
    if(!canvasRef.current || canvas) return;
    let newCanvas = new fabric.Canvas(canvasRef.current);
    fabric.Object.prototype.originX = fabric.Object.prototype.originY = 'center';
    fabric.Object.prototype.borderColor = 'red';
    fabric.Object.prototype.cornerStrokeColor = 'red';
    fabric.Object.prototype.hasRotatingPoint = false;
    fabric.Object.prototype.perPixelTargetFind = false;
    fabric.Object.prototype.objectCaching = false;
    // fabric.Object.prototype.hasRotatingPoint = false;
    // fabric.Object.prototype.perPixelTargetFind = false;
    // fabric.Object.prototype.objectCaching = false;
    // canvas.selection = false; // canvas selectable only when bkgdLocked is true
    // fabric.Group.prototype.hasControls = false;
    // fabric.Group.prototype.lockScalingX = true;
    // fabric.Group.prototype.lockScalingY = true;
    // canvas.hoverCursor = 'pointer';
    // fabric.Object.prototype.selectable = true;
  
    // if(editable!=='suggest' && !touch) {
    //   fabric.Object.prototype.lockMovementX = false;
    //   fabric.Object.prototype.lockMovementY = false;
    //   fabric.Object.prototype.lockRotation = false;
    // } else {
    //   fabric.Object.prototype.lockMovementX = true;
    //   fabric.Object.prototype.lockMovementY = true;
    //   fabric.Object.prototype.lockRotation = true;
    // }


    // saving in case you want to do padding globally
    // fabric.IText.prototype._originalRender = fabric.IText.prototype._render;

    // fabric.IText.prototype.set({
    //   // _getNonTransformedDimensions() { // Object dimensions
    //   //   console.log(this.widith, this.height, this.padding);
    //   //   return new fabric.Point(this.width, (this.height*.8)).scalarAdd(this.padding);
    //   // },
    //   // _calculateCurrentDimensions() { // Controls dimensions
    //   //   return fabric.util.transformPoint(this._getTransformedDimensions(), this.getViewportTransform(), true);
    //   // }
    // });

    // var originalRender = fabric.IText.prototype._render;
    // fabric.IText.prototype._render = function(ctx) {
    //   originalRender.call(this, ctx);
    //   if (this.border && this.borderWidth) {
    //     ctx.strokeStyle = this.border;
    //     ctx.lineWidth = this.borderWidth;
    //     ctx.strokeRect(
    //       -this.width / 2, 
    //       -this.height / 2, 
    //       this.width, 
    //       this.height
    //     );
    //   }
    // };

    setCanvas(newCanvas);
    return () => canvas && canvas.dispose();
  }, [canvas, canvasRef]);

  useEffect(() => {
    if(!canvas) return;
    // console.clear();
    let img = new Image();
    img.src = bgImg;
    img.onload = () => {
      let radians = angle * (Math.PI / 180);
      let cosAngle = Math.abs(Math.cos(radians));
      let sinAngle = Math.abs(Math.sin(radians));
      let width = img.width * cosAngle + img.width * sinAngle;
      let height = img.width * sinAngle + img.height * cosAngle;

      let centerX = img.width / 2;
      let centerY = img.height / 2;
      let offsetX = width - img.width;
      let offsetY = height - img.height;

      setBkgd([width, height, offsetX, offsetY]);

      canvas.setBackgroundImage(bgImg, canvas.renderAll.bind(canvas), {
        opacity: 1,
        backgroundColor: 'white',
        backgroundImageStretch: false,
        originX: 'center',
        originY: 'center',
        left: img.width/2,
        top: img.height/2,
        crossOrigin: 'Anonymous',
        angle
      });

      // Calculate the new positions of the objects based on the rotation
      canvas.forEachObject(function (object) {
        var dx = object.left - centerX;
        var dy = object.top - centerY;

        // Rotate the object's position around the center point of the rectangle
        var rotatedX = centerX + dx * cosAngle - dy * sinAngle;
        var rotatedY = centerY + dx * sinAngle + dy * cosAngle;
        object.set({ angle, left: rotatedX, top: rotatedY });
      });

      // Finally, render the canvas
      canvas.renderAll();
    }

    window.addEventListener('wheel', wheel);
    // window.addEventListener('keydown', keyDown);
    // window.addEventListener('keyup', keyUp);

    const eventTypes = [
      // 'mouse:over', 'mouse:out', 'object:moving', 'before:selection:cleared',
      'selection:created', 'selection:updated', 'selection:cleared',
      'object:modified', 'object:moved', 'object:rotating', 
      'mouse:up', 'mouse:down', 'mouse:dblclick',
      'touch:drag', 'touch:gesture', 'touch:longpress',
      'text:changed'
    ];
  
    // Create a map of event types to handler functions
    const handlers = Object.fromEntries(
      eventTypes.map(type => [type, (e) => checkPan(type, e)])
    );

    canvas.on(handlers);

    return () => {
      // console.log('unmounting, clear out canvas')
      window.removeEventListener('wheel', wheel);
      // window.removeEventListener('keydown', keyDown);
      // window.removeEventListener('keyup', keyUp);
      canvas.off(handlers);
    }
  }, [canvas, bgImg, angle, wheel, checkPan])

  useLayoutEffect(() => {
    const debouncedUpdateSize = debounce(resize, 200); // 200ms delay
    debouncedUpdateSize(); // Initial update
    window.addEventListener('resize', debouncedUpdateSize);
    return () => window.removeEventListener('resize', debouncedUpdateSize);
  }, [canvas, bkgd, wrapperRef, resize]);

  return { canvas, setCanvas, bkgd, msg, selected };
};

// Debounce utility function
const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};
