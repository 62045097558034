import { fabric } from 'fabric';

// editable: whether plots can be moved;
// shift key: if the canvas should/shouldn't be panned


export const init = (id, editable, touch) => {
  let canvas = new fabric.Canvas(id);
  if(id==='map') {
    document.getElementById(id).fabric = canvas;
  }

  fabric.Object.prototype.originX = fabric.Object.prototype.originY = 'center';
  fabric.Object.prototype.borderColor = 'red';
  fabric.Object.prototype.hasRotatingPoint = false;
  fabric.Object.prototype.perPixelTargetFind = false;
  fabric.Object.prototype.objectCaching = false;
  canvas.selection = false; // canvas selectable only when bkgdLocked is true
  fabric.Group.prototype.hasControls = false;
  fabric.Group.prototype.lockScalingX = true;
  fabric.Group.prototype.lockScalingY = true;
  canvas.hoverCursor = 'pointer';
  fabric.Object.prototype.selectable = true;

  if(editable!=='suggest' && !touch) {
    fabric.Object.prototype.lockMovementX = false;
    fabric.Object.prototype.lockMovementY = false;
    fabric.Object.prototype.lockRotation = false;
  } else {
    fabric.Object.prototype.lockMovementX = true;
    fabric.Object.prototype.lockMovementY = true;
    fabric.Object.prototype.lockRotation = true;
  }

  return canvas;
}
