// import { getSelected, setSelected } from "./selection";

export const handleDblClick = ({ e, debug, canvas, interaction, toParent }) => {
  if(debug) console.log('dblclick');
  if(interaction.selected.length===0) {
    var pointer = canvas.getPointer(e.e);
    let posX = (Math.round(pointer.x)/interaction.offset.scale-interaction.offset.x);
    let posY = (Math.round(pointer.y)/interaction.offset.scale-interaction.offset.y);
    if(toParent) toParent({ type: 'dblclick', value: { left: posX, top: posY, nextPlot: interaction.nextPlot }});

  } else if(e.target?.eamsType==='polygon') {
    // console.log('YPPPP')

  }
}

export const handleMouseUp = ({ e, debug, canvas, interaction, setSelected, toParent }) => {
  if(debug) console.log('mouse up');
  let obj = e.target;
  let action = e.transform?.action;
  interaction.pinching = false; // this is set in touch gesture

  if(obj && interaction.selected.length === 1) {
    // if only one was moved, deselect after moving so we aren't opening just on a single move
    if(interaction.objMoved) {
      canvas.discardActiveObject().renderAll();
      interaction.selected = [];
    } else if(action === 'rotate') {
      interaction.bkgdLocked = false;
      if(toParent) toParent({ type: 'rotated', value: { id: obj.id, appId: obj.appId, phaseId: obj.phaseId, rotation: Math.round(obj.angle) }});
    } else if(action === 'modifyPolygon' && toParent) {
      toParent({ type: 'points moved', value: { id: obj.id, appId: obj.appId, phaseId: obj.phaseId, piece: obj.piece, points: obj.points, left: obj.left, top: obj.top }});
    } else if(!interaction.bkgdLocked && toParent) {
      toParent({ type: 'view plot', value: obj.id, appId: obj.appId });
    }
  }
  // reset interaction pieces
  interaction.objMoved = false;
  setTimeout(() => {
    interaction.inLongPress = false;
  }, 1000)
}

export const handleMouseDown = ({ e, debug, interaction, toParent }) => {
  if(debug) console.log('mouse down');
  // interaction.current.touching = true;
  // interactionRef.current.panning = true;

  interaction.hover = {};
  if(toParent) toParent({ type: 'hover', value: {}});

  if(toParent) toParent({ 
    type: 'mouse down', 
    value: [e.absolutePointer.x, e.absolutePointer.y],
    client: [e.e.clientX, e.e.clientY],
    absolute: [e.absolutePointer.x, e.absolutePointer.y],
    pointer: [e.pointer.x, e.pointer.y]
  });

  // if(!interactionRef.current.objSelected) interactionRef.current.panning = true;
  //
  // // check if we are in ocr mode
  // if(interactionRef.current.bkgdLocked && interactionRef.current.ocrMode === true) {
  //   interactionRef.current.ocrCoords.start = [e.pointer.x, e.pointer.y];
  //   interactionRef.current.ocrCoords.placement = [e.absolutePointer.x, e.absolutePointer.y];
  // }
  
  // restart the pan so we don't jump
  // but only if we aren't gesturing
  if(!interaction.pinching) {
    interaction.panX = e.absolutePointer.x;
    interaction.panY = e.absolutePointer.y;
  }
}

export const OLDhandleMouseUp = ({ e, debug, canvas, interactionRef, toParent }) => {
  if(debug) console.log('mouse up');
  let obj = e.target;

  // interactionRef.current.touching = false;
  // interactionRef.current.pinching = false;
  // interactionRef.current.panning = false;

  if(obj?.plotAllowRotation) {
    if(debug) console.log('rotate-able marker');
    interactionRef.current.objRotatable = true;
  }

  // check if they are clicking on an object
  if(interactionRef.current.objSelected && e.target) {
    let plot = e.target;

    // if shiftKey is not press, they are probably clicking to view a single one so just deselect
    if(!plot.plotAllowRotation && !interactionRef.current.bkgdLocked && !interactionRef.current.objDragged) {
      toParent({ type: 'view', appId: plot.appId });
      // canvas.discardActiveObject().renderAll();
    }
  }

  // check if object was just rotated
  if(interactionRef.current.objRotated) {
    toParent({ type: 'rotated', value: interactionRef.current.objRotated});
    delete interactionRef.current.objRotated;
  }

  // // otherwise check if we are in ocr mode
  // if(ocrMode.current === true) {
  //   ocrCoords.current.end = [e.pointer.x, e.pointer.y];
  //
  //   if(ocrCoords.current &&
  //     ocrCoords.current.start && ocrCoords.current.start[0] && ocrCoords.current.start[1] &&
  //     ocrCoords.current.end && ocrCoords.current.end[0] && ocrCoords.current.end[1]) {
  //
  //
  //     // determine which direction they are selecting from
  //     let left = ocrCoords.current.start[0] < ocrCoords.current.end[0] ? ocrCoords.current.start[0] : ocrCoords.current.end[0];
  //     let top  = ocrCoords.current.start[1] < ocrCoords.current.end[1] ? ocrCoords.current.start[1] : ocrCoords.current.end[1];
  //
  //     // determine dimensions of the image to be analyzed
  //     let width  = Math.abs(ocrCoords.current.end[0] - ocrCoords.current.start[0]);
  //     let height = Math.abs(ocrCoords.current.end[1] - ocrCoords.current.start[1]);
  //
  //     // get the position of where we should add the marker
  //     let posX = Math.round(ocrCoords.current.placement[0]);
  //     let posY  = Math.round(ocrCoords.current.placement[1]);
  //
  //     // below works if you want to center, but then it hides the value
  //     // let zoom = canvas.getZoom();
  //     // let posX = Math.round(ocrCoords.current.placement[0] + ((width/2) / zoom));
  //     // let posY  = Math.round(ocrCoords.current.placement[1] + ((height/2) / zoom));
  //
  //     let imgDataUrl = canvas.toDataURL({
  //       left: left,
  //       top: top,
  //       // originX: 'center',
  //       // originY: 'center',
  //       width: width,
  //       height: height,
  //       format: 'png'
  //     });
  //
  //     // may want to add size limitations here
  //     // in case there are performance issues?
  //     readImageText(imgDataUrl, posX, posY);
  //     // console.log(imgDataUrl)
  //   }
  // }
  //
  // // reset ocr
  // ocrCoords.current = {};

  // reset objDragged
  interactionRef.current.objDragged = false;
}
