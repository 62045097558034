import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { useAxios } from '../../../../hooks/useAxios';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';

import { SiteContext } from '../../../../context/SiteContext';
import { UserContext } from '../../../../context/UserContext';

import Header  from '../../../../components/system/HeaderTask';
import Filters, { applyFilters } from '../../../mapping/plots/components/Filters';
import Map from '../../../../components/mapping/MapV2';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import PlotEdit from '../../../mapping/plots/components/Edit';
// import Review from './ApplicationReview';

import { emptyObj } from '../../../../utils/functions';
import { findPhasePlan } from '../../../../utils/phases';
import { getPlotDisplay } from '../../../../utils/plots';

const View = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { config } = useContext(SiteContext);
  const { userDetails } = useContext(UserContext);
  const { 
    project, 
    phases, 
    planGroups,
    plans, 
    phasePlans, 
    activePlan, 
    setActivePlan, 
    activePhase, 
    setActivePhase, 
    types, 
    activePlots,
    plots, 
    setPlots,
    photos,
    setPhotos,
    search, 
    setSearch, 
    filters, 
    setFilters
  } = props.context;
  const { task, setTask } = props;
  
  const [mapPlan, setMapPlan] = useState({});
  const planRef = useRef({});

  const [actionSchema, setActionSchema] = useState([]);
  const [editSchema, setEditSchema] = useState([]);
  const [bulkSchema, setBulkSchema] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [copiedIds, setCopiedIds] = useState([]);
  const [zoomTo, setZoomTo] = useState({});

  const [activeAppId, setActiveAppId] = useState();
  const [activePlot, setActivePlot] = useState({});
  const [plotHistory, setPlotHistory] = useState([]);
  const [plotPhotos, setPlotPhotos] = useState([]);

  const { serverCall, pending } = useAxios();
  const closePanel = () => {
    setActiveAppId();
    setActivePlot({});
    setPlotHistory([]);
    setPlotPhotos([]);
  }

  useEffect(() => {
    // we are in a survey so we want to view the map automatically
    // check if params doesn't include planId, UNLESS we are in review
    let mode = params.mode;
    let planId = params.planId;
    if(!mode && !planId) {
      // first check for any planGroups by lowest sortOrder
      // otherwise default to the top ungrouped plan
      let activePlan = null;
      if(planGroups.length > 0) {
        let group = planGroups.filter(x => x.status === 'A').reduce((prev, curr) => prev.sortOrder < curr.sortOrder ? prev : curr);
        if(group) {
          let groupPlans = plans.filter(x => x.planGroupId===group.id).sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
          if(groupPlans.length > 0) activePlan = groupPlans[0];
        }
      }

      // if we still don't have an active plan, find the next ungrouped
      if(!activePlan) {
        let ungrouped = plans.filter(x => !x.planGroupId).sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        activePlan = ungrouped[0];
      }

      if(activePlan)
        navigate(`./map/${activePlan.appId}`);
    }
  }, [params])

  useEffect(() => {
    if(emptyObj(activePhase)) return;
    setSelected([]);
    let plan = plans.find(x => x.appId === params.planId);
    if(!plan) alert('Error finding plan. Contact support.'); // lklklk
    planRef.current = plan;
    setActivePlan(plan);

    let filtered = phasePlans.filter(x => x.planId === plan.id);
    let phasePlan = findPhasePlan(phases, activePhase, filtered);
    if(phasePlan) {
      // send planId, styling, and signed url to the map
      // planId is required so we filter the plots
      setMapPlan({ projectId: project.id, planId: plan.id, styling: phasePlan.styling, url: phasePlan.url });
    }
  }, [activePhase, phasePlans, phases, plans, setActivePlan, params])

  useEffect(() => {
    if(emptyObj(activeAppId)) {
      setActivePlot({});
      setPlotHistory([]);
      setPlotPhotos([]);
    } else {
      let found = plots.find(x => x.appId === activeAppId && x.projectId === project.id && x.phaseId === activePhase.id);
      (found) ? setActivePlot(found) : setActivePlot({});

      let history = plots.filter(x => x.status==='A' && x.appId === activeAppId && (x.projectId !== project.id || x.phaseId !== activePhase.id)).sort((a,b) => (b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0));
      setPlotHistory(history);

      let pics = photos.filter(x => x.plotAppId === activeAppId);
      setPlotPhotos(pics);
    }
  }, [activeAppId, activePhase.id, project.id, plots, photos])

  const fromChild = (data) => {
    const { type, value } = data;
    if(type==='view plot') {
      setActiveAppId(value.appId);
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>{task.name} | {config.title}</title>
        <style>{`html, body, div#root { height: 100%; }`}</style>
      </Helmet>
      <Header task={task} />
      { !params.mode && (
        <Fragment>
          <div className="position-fixed" style={{ top:64, right: 12, zIndex: 999 }}>
            <Filters search={search} setSearch={setSearch} filters={filters} setFilters={setFilters} />
          </div>
          <div className="position-fixed w-100 h-100 overflow-hidden" style={{top:0, left:0, marginTop:53}}>
            { !emptyObj(activePlan) && (
              <Map map={mapPlan} editable={true} plots={activePlots} selected={selected} setSelected={setSelected} zoomTo={zoomTo} toParent={fromChild} />
            )}
          </div>
        </Fragment>
      )}
      {/* { params.mode === 'review' && (
        <Review task={task} project={project} activePhase={activePhase} types={types} plots={plots} setPlots={setPlots} photos={photos} setPhotos={setPhotos} />
      )} */}
      <Offcanvas show={plotHistory.length > 0} onHide={closePanel} placement="end" scroll={true} backdrop={false} className="shadow">
        <Offcanvas.Header className="bg-body" closeButton>
          <Offcanvas.Title>{ emptyObj(activePlot) ? `Viewing ${plotHistory[0]?.name}` : `Editing ${activePlot.name}`} </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="bg-body pt-0">
          tbd
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  )
}

export default View;


// xs modal instructions:
// Sign placement and mounting
// Select and place relevant sign types based on circulation and decision point planning.