import React, { forwardRef, useContext, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { UserContext } from '../../../context/UserContext';

// how to use:
// <FloatingSelect key={item.id} {...register(item.field)} item={item} pieces={{ control, errors, toParent, size }} />

// schema example:
// { id: '__NEW_ID__', field: '__DB_FIELD__', type: 'floating-select', label: 'ze label', required: 'wow u fucked', options: [{label: 'Opt 1', value: 1}, {value: 2}]},

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  const { userGroup, allGroups } = useContext(UserContext);

  // useEffect(() => {
  //   if(allGroups.length === 0) return;
  //
  //   console.log(allGroups);
  //   // // set initial value to current user group OR
  //   // // to first group in the list
  //   // if(Object.keys(userGroup).length > 0)
  //   //   pieces.setValue(`${item.field}`, userGroup.value);
  //   // else
  //     // pieces.setValue(`${item.field}`, allGroups[0].value);
  //
  // }, [userGroup, allGroups, pieces, item.field])

  if(allGroups.length < 2) return null;

  return (
    <Controller
      name={item.field}
      control={pieces.control}
      rules={{ required: item.required }}
      render={({ field }) => {
        if(!field.value) field.value = '';

        return (
          <div className="form-floating my-2">
            <select {...field}
              ref={field.ref}
              name={item.field}
              placeholder={item.label}
              value={field.value}
              className={`form-select ${(pieces.size) ? "form-select-"+pieces.size : ""}`}
              onChange={(e) => {
                e.target.blur();
                pieces.setValue(`${item.field}`, e.target.value);
                if(pieces.toParent) pieces.toParent({ type: 'select', name: item.field, value: e.target.value });
              }}
              disabled={item.disabled}>
              { allGroups.map((opt, idx) => {
                if(!opt.label) opt.label = opt.value;
                return (
                  <option key={`${item.field}-select-${idx}`} value={opt.value}>{opt.label}</option>
                )
              })}
            </select>
            {item.label && (<label htmlFor={item.field}>{item.label}</label>)}
            {pieces.errors[item.field] && <p className="mt-0 error">{item.errorMsg}</p>}
          </div>
        )
      }}
    />
  )
});

export default Field;
