import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { EyeSlashFill } from 'react-bootstrap-icons';

import FindType from '../FindType';
import ColBtn from '../edit/Col';
import HtmlBtn from '../edit/Html';
import InputBtn from '../edit/Input';
import OptionBtn from '../edit/Options';
import DirectionBtns from '../../../../buttons/DirectionBtns';

const classesMap = {
  'col-12': ['col-12'],
  'col-336': ['col-3', 'col-3', 'col-6'],
  'col-363': ['col-3', 'col-6', 'col-3'],
  'col-4': ['col-4', 'col-4', 'col-4'],
  'col-6': ['col-6', 'col-6'],
  'col-633': ['col-6', 'col-3', 'col-3'],
  'col-48': ['col-4', 'col-8'],
  'col-84': ['col-8', 'col-4'],
};

const View = ({ display, siblingCount, objIdx, obj, colIdx, colName, avail, pieces, toParent }) => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(s => !s);

  const { colsData, colClasses } = useMemo(() => {
    const colsData = [];
    const colClasses = classesMap[obj.type] || ['col-12'];

    Object.keys(obj).forEach((key) => {
      if (key.startsWith('col')) {
        colsData.push({ name: key, data: obj[key] });
      }
    });

    return { colsData, colClasses };
  }, [obj]);

  const fromChild = ({ type, value}) => {
    if(type==='direction' && toParent) {
      toParent({ type: 'move block', direction: value, colIdx, colName, objIdx });
    }
  }

  if(!display && !show) return (
    <Fragment>
      <hr className="my-3" />
      <div className="d-grid">
        <button className="btn btn-warning p-0 pt-1" onClick={toggle} />
      </div>
    </Fragment>
  )

  return (
    <Fragment>
      { colIdx > 0 && <hr className="mt-3 mb-0" />}
      <div className="d-flex">
        <DirectionBtns idx={objIdx} count={siblingCount} toParent={fromChild} styles={{paddingTop:13}} />
        <div className="flex-grow-1">
          <div className="row g-1 mb-1">
            {colsData.map(({ name, data }, idx) => (
              <div key={idx} className={`${colClasses[idx] || 'col'}`}>
                {data.map((item, itemIdx) => (
                  <FindType key={item.id} colIdx={colIdx} colName={name} siblingCount={data?.length} objIdx={itemIdx} obj={item} avail={avail} pieces={pieces} toParent={toParent} />
                ))}
                <div className="d-flex">
                  {(avail?.udfs?.length > 0 || avail?.arrs?.length > 0) && (
                    <div className="dropdown flex-grow-1">
                      <button className="mt-2 btn btn-sm btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Add New
                      </button>
                      <ul className="dropdown-menu">
                        { avail?.udfs?.length > 0 && (
                          <Fragment>
                            <HtmlBtn colIdx={colIdx} colName={name} toParent={toParent} />
                            <InputBtn colIdx={colIdx} colName={name} avail={avail} toParent={toParent} />
                          </Fragment>
                        )}
                        { avail?.arrs?.length > 0 && (
                          <Fragment>
                            <OptionBtn colIdx={colIdx} colName={name} avail={avail} toParent={toParent} />
                          </Fragment>
                        )}
                      </ul>
                    </div>
                  )}
                  { show && (
                    <button className="align-self-start btn btn-xs btn-warning mt-2 mx-2" onClick={toggle}>
                      <EyeSlashFill size={16} />
                    </button>
                  )}
                  <ColBtn obj={obj} colIdx={colIdx} pieces={pieces} avail={avail} toParent={toParent} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  )
};

View.propTypes = {
  obj: PropTypes.object.isRequired,
  colIdx: PropTypes.number.isRequired,
  toParent: PropTypes.func.isRequired,
};

export default View;
