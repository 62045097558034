import React, { Fragment, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MapContainer, LayersControl } from 'react-leaflet';
import { DynamicMapLayer } from 'react-esri-leaflet';
import { Helmet } from 'react-helmet';

import MapEvents from './components/gis/GisMapEvents';
import GisPlots  from './components/gis/GisPlots';
import StatusButtons from './components/gis/StatusButtons';
import { ToggleOn, ToggleOff } from 'react-bootstrap-icons';

const View = (props) => {
  const params = useParams();
  const { publicStatuses, inSurvey, draggable, plans, plots, setBounds, toParent } = props;
  const startMap = props.inSurvey ? params.planId : 'kAMUxdCyHK2Fn9ePJeAcA';
  const startZoom = props.zoom ? props.zoom : 16;
  const wrapperRef = useRef(null);
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('95%');

  // map elements
  const mapRef = useRef();
  const [mode, setMode] = useState(1);
  const toggleMode = () => setMode((s) => (s===1) ? 2 : 1 );

  const [zoom, setZoom] = useState(startZoom);
	const mapCenter = (props.center) ? props.center : [37.6158565951731, -122.38767967763125]; // campus center

  useLayoutEffect(() => {
    const update = () => {
      let width = wrapperRef.current.parentElement.offsetWidth;
      let height = wrapperRef.current.parentElement.offsetHeight;
      setWidth(width);
      setHeight(height);
    }
    update();
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, []);

  const fromChild = (data) => {
    const { type, value } = data;
    if(type==='drag change') {
      let bounds = mapRef.current.getBounds();
      setBounds(bounds);
      mapRef.current.invalidateSize(true);

    } else if(type==='zoom change') {
      let bounds = mapRef.current.getBounds();
      setBounds(bounds);
      setZoom(value);
      toParent(data);

    } else {
      toParent(data);
    }
  }

  return (
    <Fragment>
      <Helmet>
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.3/dist/leaflet.css"
          integrity="sha256-kLaT2GOSpHechhsozzB+flnD+zUyjE2LlfWPgU04xyI="
          crossorigin=""/>
        {/*<link rel="stylesheet" href="https://unpkg.com/leaflet-draw@latest/dist/leaflet.draw-src.css" />
        <link href='https://api.tiles.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.css' rel='stylesheet' />*/}
      </Helmet>

      <div ref={wrapperRef} className="d-inline-block" style={{width:width, height:height}}>
        <MapContainer
          id="mapId"
          zoom={zoom}
          center={mapCenter}
          maxZoom={23}
          style={{height: inSurvey ? '100%' : 'calc(100% - 53px)', width:'100%'}}
          doubleClickZoom={false}
          whenReady={(e) => {
            mapRef.current = e.target;
            let bounds = mapRef.current.getBounds();
            setBounds(bounds);

            setTimeout(() => {
              mapRef.current.invalidateSize(true);
            }, 500)

            // not used/implemented yet:
            // mapMarkersRef.current = L.layerGroup().addTo(mapRef.current);
          }}
        >
          { inSurvey && <StatusButtons mapRef={mapRef} mode={mode} toggleMode={toggleMode} toParent={fromChild} /> } 
          <MapEvents toParent={fromChild} />
          <LayersControl position="topleft" collapsed={true}>
            { plans.map((obj, idx) => (
              <LayersControl.BaseLayer key={`${obj.appId}-${idx}`} name={obj.name} checked={obj.appId===startMap}>
                <DynamicMapLayer opacity="0.4" appId={obj.appId} url={obj.url} />
              </LayersControl.BaseLayer>
            ))}
            { zoom >= 19 && (
              <GisPlots mode={mode} statuses={publicStatuses} plots={plots} zoom={zoom} draggable={draggable} toParent={fromChild} />
            )}
          </LayersControl>
        </MapContainer>
      </div>

      { publicStatuses?.length > 0 && (
        <div className="position-fixed" style={{bottom:16, left:16 ,zIndex:999}}>
          <button className="btn bg-white rounded shadow-sm me-2" type="button" onClick={toggleMode}>
            { mode===1 && <ToggleOff size={20} />}
            { mode!==1 && <ToggleOn size={20} />}
          </button>
        </div>
      )}
    </Fragment>
  )
}

export default View;


// lklklk: DynamicMapLayer cacheLayers={true}?