import React, { Fragment, forwardRef, useEffect, useRef, useState } from 'react'

import { textLabelIds, locatorShapeIds, labelShapeIds, markerIds } from '../../../utils/plots';

import ColorPicker from './ColorPicker'
import Select from './Select'

// need to add something here to hide "Inherit" and show defaults for projects


// fillColor is intentional, we use it for quantity/list border colors
// if everything else is inherited

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  const value = pieces.watch(item.field);
  const display = pieces.watch('displayType');
  const styling = useRef({});

  useEffect(() => {
    if(!value) return;
    // reset values
    styling.current = value;

    // set display type based on what's selected
    if(styling.current.labelId)
      pieces.setValue('displayType', 'arch');
    else if(styling.current.locatorId)
      pieces.setValue('displayType', 'simple');
    else
      pieces.setValue('displayType', null);

    let labelId = styling.current.labelId ? styling.current.labelId : null;
    let locatorId = styling.current.locatorId ? styling.current.locatorId : null;
    let textId = styling.current.textId ? styling.current.textId : null;

    pieces.setValue('labelId', labelId);
    pieces.setValue('locatorId', locatorId);
    pieces.setValue('textId', textId);

  }, [value])

  const fromChild = (data) => {
    console.clear();
    console.log(data);
    const { name, value } = data;
    if(name==='displayType') {
      let styles = styling.current;

      if(value==='simple') {
        styles.locatorId = (styles.labelId && styles.labelId<=3) ? styles.labelId : 1;
        delete styles.labelId;
      } else if(value==='arch') {
        styles.labelId = styles.locatorId ? styles.locatorId : 1;
        styles.locatorId = 1;
      } else {
        delete styles.locatorId;
        delete styles.labelId;
      }

      styling.current = styles;

    } else if(name==='fillColor') {
      styling.current = {...styling.current, fillColor: value};

    } else if(name==='locatorId') {
      styling.current = {...styling.current, locatorId: parseInt(value)};

    } else if(name==='labelId') {
      styling.current = {...styling.current, labelId: parseInt(value)};

    } else if(name==='textId') {
      styling.current = {...styling.current, textId: parseInt(value)};
    }

    pieces.setValue('styling', styling.current);
  }

  return (
    <table className="table table-sm align-middle mb-4">
      <tbody>
        <tr>
          <td width={150}>Fill Color</td>
          <td><ColorPicker item={{ field: 'fillColor' }} pieces={{...pieces, toParent: fromChild}} /></td>
        </tr>
        {/* hiding border color until you implement/decide if it should be status
        <tr>
          <td width={150}>Border Color</td>
          <td><ColorPicker item={{ field: 'borderColor' }} pieces={{...pieces, toParent: fromChild}} /></td>
        </tr>
        */}
        <tr>
          <td>Text Label</td>
          <td><Select item={{ field: 'textId', options: [
            { value: '', label: 'Inherit'},
            ...textLabelIds
          ]}} pieces={{...pieces, toParent: fromChild}} /></td>
        </tr>
        <tr>
          <td>Display Type</td>
          <td><Select item={{ field: 'displayType', options: [
            { value: '', label: 'Inherit'},
            { value: 'simple', label: 'Simple'},
            { value: 'arch', label: 'Architectural'},
          ]}} pieces={{...pieces, toParent: fromChild}} /></td>
        </tr>
        { display==='simple' && (
          <tr>
            <td>Locator</td>
            <td><Select item={{ field: 'locatorId', options: locatorShapeIds }} pieces={{...pieces, toParent: fromChild}} /></td>
          </tr>
        )}

        { display==='arch' && (
          <Fragment>
            <tr>
              <td>Locator</td>
              <td><Select item={{ field: 'locatorId', options: markerIds }} pieces={{...pieces, toParent: fromChild}} /></td>
            </tr>
            <tr>
              <td>Label</td>
              <td><Select item={{ field: 'labelId', options: labelShapeIds }} pieces={{...pieces, toParent: fromChild}} /></td>
            </tr>
          </Fragment>
        )}


      </tbody>
    </table>
  )
});

export default Field;
