import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';

import DeleteBtn  from '../../../../components/buttons/DeleteBtn';
import Generated  from '../../../../components/system/Generated';
// import EmailError from '../system/EmailError';

import { formObj } from '../../../../utils/schemas';
import { addToArray, removeFromArray } from '../../../../utils/functions';

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'id' },
    { id: '3', type: 'hidden', field: 'appId' },
    { id: '4', width: 15, type: 'checkbox-select', field: 'selected' },
    { id: '5', width: 15, type: 'color-picker', field: 'fillColor' },
    { id: '6', width: 15, type: 'icon', icon: 'pencil', action: 'edit type' },
    { id: '7', width: 150, type: 'input', field: 'code', placeholder: 'Code', xxrequired: 'Please enter a short code'},
    { id: '8', type: 'input', field: 'description', placeholder: 'Description'},
  ]}
]

const View = (props) => {
  const debug = false;
  const { obj, types, setTypes, selected, setSelected, toParent } = props;

  const { serverCall } = useAxios();
  const { control, register, handleSubmit, formState: { errors, isDirty }, reset, watch, setValue, getValues } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  // if you need to set the form value based on props.obj changing
  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    filled.fillColor = obj.styling.fillColor ? obj.styling.fillColor : '#ccc';
    reset(filled);
  }, [obj, reset])

  const onSubmit = async (data) => {
    if(debug) console.log(data);
    // update the type label/value
    let code = data.code;
    let desc = data.description;
    data.label = (code) ? code + ' - ' + desc : desc;
    data.value = data.id;

    setTypes((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.id === data.id);
      arr[idx] = {...arr[idx], ...data};
      return arr;
    })

    let res = await serverCall({ method: 'PATCH', data, url: `/library/types/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating type. Contact support.'); // lklklk
    let filled = formObj(schema, data);
    reset(filled);
  }

  const fromChild = (data) => {
    console.log(data);
    if(debug) console.log(data);
    const { type, value, autosave } = data;
    if(type==='edit type') {
      if(toParent) toParent({ type: 'edit type', value: obj.id });

    } else if(type==='checkbox-select') {
      setSelected((prev) => {
        let arr = [...prev];
        if(value.checked)
          arr = addToArray(selected, value.value);
        else
          arr = removeFromArray(selected, value.value);
        return arr;
      })

    } else if(type==='color-picker') {
      let style = { ...obj.styling, fillColor: value };
      setValue('styling', style);
      handleSubmit(onSubmit)();

    } else if(type==='delete confirmed') {
      // send id as an array to match bulk edit
      if(toParent) toParent({ type: 'remove types', value: [obj.id] });

    } else if(isDirty || autosave)
      handleSubmit(onSubmit)();
  }

  return (
    <div className="d-flex align-items-center border-bottom">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="flex-grow-1">
        <Generated id={`type-row-${obj.appId}`} layout="horizontal" schema={schema} size="sm" pieces={{ vars: { value: obj.value, types, selected }, register, control, setValue, getValues, watch, errors, toParent: fromChild }} />
      </form>
      <DeleteBtn toParent={fromChild} styles="d-inline-block btn btn-xs ms-2" size={18} />
    </div>
  )
}

export default View;
