import { argbToHex, emptyObj } from '../../../../../utils/functions';
import { findPhasePlan } from '../../../../../utils/phases';
import { newPlot } from '../../../../mapping/plots/utils/plots';
import { nanoid } from 'nanoid';

const typeColIdx  = 1;
const planRowIdx  = 2;
const planNameIdx = 5;

const loopIdx = 1;
const plotNameIdx = 6;
const statusIdx   = 3;
const tagsIdx     = 6;
const qtyIdx      = 8; // also informs the index start for custom fields

export const formatSA = ({ workbook, project, phases, activePhase, plans, phasePlans, types, typeVars, pieces, userDetails }) => {
  return new Promise(async(resolve) => {
    try {
      let sheets = [];

      // first go through overview to get types, plans, and quantities
      // then loop through Grouped by Type to get all rows etc
      let { newTypes, typeMap, planMap } = await overview({ workbook, types, plans });
      // if(emptyObj(planMap)) throw('error-missing-plan');

      let rows = await byTypes({ workbook, typeMap, planMap, project, phases, activePhase, plans, types, phasePlans, typeVars, pieces, userDetails });

      sheets.push({ newTypes, newPlots: rows });
      // for(const row of rows) {
      //   console.log(row.typeCode, row.name);
      // }

      resolve(sheets);

    } catch(error) {
      console.log(error);
      resolve(error);
    }

    
    
    

    // // let headers = ['Sign Type','Plan','Name','Status','Tags','Quantity','Message A'];
    // let headers = [];
    // let sheets = [];



    // // this one is specifically to get the sign types and colors
    // // let sheet = workbook.getWorksheet('Overview');
    // let sheet = workbook.getWorksheet('Message Schedule');

    // // determine the header values and maps?
    // let row1 = sheet.getRow(1).values;
    // console.log(row1);


    // sheet.eachRow((row, idx) => {


    //   // right now we are manually mapping these values... 
    //   // could this be smarter where we attempt
    //   // to auto-match like how MailChimp does? 
    //   // could also try to determine the schema if the type doesn't exist
    //   let obj = {
    //     importTypeCode: row._cells?.[3]?.value ? row._cells[3].value : 'TBD',
    //     importPlanName: row._cells?.[4]?.value,
    //     name: row._cells?.[5]?.value,
    //     installMode: row._cells?.[2]?.value,
    //     tags: row._cells?.[6]?.value ? row._cells[6].value : [],
    //     quantity: row._cells?.[7]?.value ? row._cells[7].value : 1
    //   }

      


    //   // row.eachCell({includeEmpty: true}, (c, i) => {
    //   //   console.log(i, c.value);
    //   //   if(i===1) {

    //   //   }
    //     // if(i >= 9) {
    //     //   // obj.arr1.push(c.value);
    //     //   values[6].push(c.value);
    //     // }
    //   // })


    //   // this works for row arrays, v1
    //   // let values = [
    //   //   row._cells?.[3].value, // typeCode
    //   //   row._cells?.[4].value, // plan name
    //   //   row._cells?.[5].value, // plot name
    //   //   row._cells?.[2].value, // installMode
    //   //   row._cells?.[6]?.value ? row._cells[6].value : [], // tags
    //   //   row._cells?.[7]?.value ? row._cells[7].value : 1,  // quantity
    //   //   [] // message A
    //   // ]

    //   // row.eachCell({includeEmpty: true}, (c, i) => {
    //   //   console.log(i, c.value);
    //   //   if(i >= 9) {
    //   //     // obj.arr1.push(c.value);
    //   //     values[6].push(c.value);
    //   //   }
    //   // })

    //   // if(idx > 1) rows.push(values);



    // });

    // // sheets.push({ uid: nanoid(), type: 'sa', name: sheet.name, headers, rows });

  })
};

// const getTypes = () => {
//   // the below works fine to get the first column AND comment for sign types
//   row.eachCell({includeEmpty: true}, (c, i) => {
//     if(i===1 && c.value && c.value!=='Type') {
//       let typeCode = c.value;
//       let typeDesc;
//       let fillColor = c.fill?.bgColor?.argb?.slice(2);
//       let comment = c._comment?.note?.texts[0]?.text;
//       if(comment) {
//         let pieces = comment.split(`${typeCode} - `);
//         if(pieces?.length===2) {
//           typeDesc = pieces[1];
//         }
//       }

//       console.log(typeCode);
//       console.log(typeDesc);
//       console.log(fillColor);
//     }            
//   });
// }

const overview = ({ workbook, types, plans }) => {
  return new Promise(async(resolve) => {
    let newTypes = [];
    let typeMap = {};
    let planMap = {};

    let sheet = workbook.getWorksheet('Overview');
    let test1 = sheet.getCell('A2').value;
    let test2 = sheet.getCell('B1').value;
    if(test1!=='Type' || test2!=='Location') return resolve('error-sa-overview');

    let typeCodes = sheet.getColumn(typeColIdx);
    let typeCount = typeCodes['_worksheet']['_rows'].length;
    typeCodes.eachCell({ includeEmpty: true }, (cell, num) => {
      if(num < 3 || num===typeCount) return;
      let typeCode = cell.value;
      let typeDesc;
      let fillColor = cell.fill?.bgColor?.argb ? argbToHex(cell.fill.bgColor.argb) : '#ccc';
      let comment = cell._comment?.note?.texts[0]?.text;
      if(comment) {
        let pieces = comment.split(`${typeCode} - `);
        if(pieces?.length===2) typeDesc = pieces[1];
      }

      // lklklk should differentiate between one found and multiple, throw error
      let found = types.filter(x => x.code===typeCode);
      if(found?.length > 1) {
        // there are multiple types with the same name
        // need to either show error or indicate they can add category/select?
      } else if(found?.length===1) {
        typeMap[typeCode] = {...found[0]};
      } else {
        let newObj = {
          appId: nanoid(),
          // projectId: project.id,
          projectId: 7986,
          code: typeCode,
          status: 'A'
        };
        typeMap[typeCode] = {...newObj};
        newTypes.push(newObj)
      }
    });

    let planCodes = sheet.getRow(planRowIdx);
    let planCount = planCodes['_worksheet']['_rows'].length;
    planCodes.eachCell({ includeEmpty: true }, (cell, num) => {
      if(num===1 || num===planCount) return;
      let planCode = cell.value;
      let planName = cell.value;

      let comment = cell._comment?.note?.texts[0]?.text;
      if(comment) {
        let pieces = comment.split(`${planCode} - `);
        if(pieces?.length===2) planName = pieces[1];
      }

      // lklklk should differentiate between one found and multiple, throw error
      let found = plans.find(x => x.name===planCode);
      if(found) planMap[planCode] = found;
    })

    resolve({ newTypes, typeMap, planMap });
  })
};

const byTypes = ({ workbook, typeMap, planMap, project, phases, activePhase, plans, phasePlans, types, typeVars, userDetails }) => {
  return new Promise(async(resolve) => {
    let sheet = workbook.getWorksheet('Grouped by Type');

    // lklklk add error if sheet doesn't exist...
    // could also throw error if the first few col headers are out of order

    let rows = [];
    let currType = {};
    let currHead = [];
    let currPlot = {};
    let tagsFound = 0;
    // let schema = [];
    let currArr = 1;
    let pieces = { left: 200, top: 200 };

    sheet.eachRow({ includeEmpty: false }, (row, num) => {
      // let colI = row.getCell(9);
      // let commentx = colI._comment?.note?.texts ? 'yes' : '   ';
      // console.log(commentx, colI.value);
      

      let rowVals = row.values;
      rowVals.splice(0, qtyIdx+1);

      let colA    = row.getCell(loopIdx).value;
      let qty     = row.getCell(qtyIdx).value;
      let custom  = row.getCell(qtyIdx+1);
      let newSide = false;

      // check for side A/B etc. comment
      let comment = custom._comment?.note?.texts[0]?.text;
      if(comment) {
        let pieces = comment.split('Side ');
        currArr = Number(pieces[1]) > 0 ? Number(pieces[1]) : 1;
        newSide = true;
      } else if(currArr > 1) {
        currArr = Number(currArr)+1;
        newSide = true;
      } else {
        currArr = 1;
        newSide = false;
      }

      if(typeof colA==='string') {
        if(colA in typeMap) {
          console.log('-------------------')
          console.log('start new type ', typeMap[colA].code);
          currType = typeMap[colA];
        } else if(colA==='Link') {
          let headers = row.values;
          currHead = headers.splice(0, qtyIdx+1);
          // console.log(headers);
        }
      } else if(colA?.text==='View in browser') {
        if(!emptyObj(currPlot)) {
          let newObj = newPlot({ typeId: currPlot.typeId, project, activePhase, plans, types, typeVars, activePhase, pieces, userDetails })
          if(currPlot.planId) newObj.planId = currPlot.planId;
          currPlot = {...currPlot, ...newObj};
          rows.push(currPlot);
        }
        console.log('---',row.getCell(plotNameIdx).value,'---');
        // console.log(`Side`, currArr);
        pieces.nextPlot = row.getCell(plotNameIdx).value;
        pieces.left = 200;
        pieces.top = 200;

        let tagName = row.getCell(tagsIdx).value;
        let planName = row.getCell(planNameIdx).value;
        let planId = (planName in planMap) ? planMap[planName].id : null;
  
        currPlot = { 
          styling: {},
          planId,
          typeId: currType?.id,
          typeCode: currType?.code,
          // name: row.getCell(plotNameIdx).value,
          publicStatus: row.getCell(statusIdx).value,
          tags: row.getCell(tagsIdx).value ? [row.getCell(tagsIdx).value] : [],
          quantity: row.getCell(qtyIdx).value,
          arr1: rowVals,
          arr2: [],
          arr3: [],
          arr4: [],
          // not including from og:
          // typeCode
          // planName
        };

        // if there's a planId, filter phasePlans
        // otherwise... we'll need to try to find another way
        let filtered = planId ? phasePlans.filter(x => x.planId === planId) : [...phasePlans];
        // console.log(filtered);
        // will you need to do the phasePlan to confirm that tag is looking at the right plan...?
        // let coverPhase = findPhasePlan(phases, phase, filtered);
        if(filtered?.length > 0) {
          for(const phasePlan of filtered) {
            let foundTags = phasePlan.ocr?.words?.filter(x => x.text===tagName);
            if(foundTags?.length===1 && (!planId || planId===phasePlan.planId)) {
              // lklklk do something if there are multiple matches?
              // maybe a dropdown in the obj for... some options?
              tagsFound++;
              currPlot.tagFound = true;
              planId = phasePlan.planId; // need this in case planId was null
              pieces.left = foundTags[0].locatorX;
              pieces.top  = foundTags[0].locatorY;
              // pieces = { left: foundTags[0].locatorX, top: foundTags[0].locatorY }
              // .... like what are these lines below for
              // let parent = plans.find(x => x.id === planId);
              // console.log(parent);
              // if(parent) row[planIdx] = parent.label;
            }
          }
        }
      } else if(!qty) {
        // console.log(`Side`, currArr);
        if(currArr===1)
          currPlot.arr1 = currPlot.arr1.concat(rowVals);
        else if(currArr===2)
          currPlot.arr2 = currPlot.arr2.concat(rowVals);
        else if(currArr===3)
          currPlot.arr3 = currPlot.arr3.concat(rowVals);
        else if(currArr===4)
          currPlot.arr4 = currPlot.arr4.concat(rowVals);
      } else if(num === sheet.lastRow?._number) {
        console.log(num, sheet.lastRow._number);
        // lklklk can this be moved up...
        let newObj = newPlot({ typeId: currPlot.typeId, project, activePhase, plans, types, typeVars, activePhase, pieces, userDetails })
        if(currPlot.planId) newObj.planId = currPlot.planId;
        currPlot = {...currPlot, ...newObj};
        rows.push(currPlot);
      } else {
        // console.log('end type ', currType?.code);
        // if(num === sheet.lastRow?._number) {
        //   // last row, push currPlot
        //   rows.push(currPlot);
        // } else {
          currType = {};
          currArr = 1;
        // }
      }
    });

    resolve(rows);
  })
};