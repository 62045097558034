import React from 'react';
import { Square as Icon } from 'react-bootstrap-icons';
import { fabric } from 'fabric';

const View = ({ canvas, update }) => {

  const add = () => {
    const rect = new fabric.Rect({ 
      fill: '#000',
      stroke: '#ccc',
      strokeWidth: 6,
      left: 300, 
      top: 300,
      width: 100,
      height: 100
    });

    canvas.add(rect);
    canvas.setActiveObject(rect);
    update();
  }

  return (
    <button className="btn btn-sm" onClick={add}>
      <Icon size={16} />
    </button>
  )
}

export default View;
