
// if you need to research deep copying objects:
// https://stackoverflow.com/a/53771927/983017

// chunks = splits array into chunks
// emptyObj = checks if an object is empty
// deepCopy
// addToArray, removeFromArray = adds/removes from array and removes duplicates
// mergeArrays, removeMultiple = adds/removes arrays from arrays
// checkSubset = checks to see if a parent array contains an entire child array
// createFileName = sanitizes input to be a file name
// formatBytes = converts integer to kb, mb, gb, etc.
// titleCase = converts first word of string to title case
// nl2br = split linebreaks and add br tags
// nl2cols = split linebreaks into specified array length
// getDifference = get difference between two arrays
// regexpBucket
// regexpUrls
// downloadFile
// arrToObj = converts an array to an object based on the provided key
// userArray = returns a formatted list of user names
// accessArray = returns a formatted list of groups + roles
// isNumeric = checks if a string is a number
// pickTextColor = selects foreground color
// hex2rgba = converts 3 or 6 hex codes to rgba for Excel
// countByKey, countByTwoKeys = returns number of records
// sumByKey, sumByTwoKeys = returns sum of records
// blobToBase64 = returns data url
// base64toBlob = returns blob

// templates:
// promise resolve function

export function* chunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export const emptyObj = (obj) => {
  if(!obj) return true;
  return Object.keys(obj).length === 0;
}

export const bracketToDot = (str) => {
  // Convert bracket notation to dot notation: "arr1[0]" => "arr1.0"
  return str.replace(/\[(\d+)\]/g, '.$1');
}

export const deepCopy = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    // If obj is not an object or is null, return it as is
    return obj;
  }

  if (Array.isArray(obj)) {
    // If obj is an array, create a new array and deep copy its elements
    const newArray = [];
    for (let i = 0; i < obj.length; i++) {
      newArray[i] = deepCopy(obj[i]);
    }
    return newArray;
  }

  // If obj is an object, create a new object and deep copy its properties
  const newObj = {};
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      newObj[key] = deepCopy(obj[key]);
    }
  }

  return newObj;
}

export const addToArray = (arr, val) => {
  let newArr = [...arr];
  newArr.push(val);
  let unique = [...new Set(newArr)];
  return unique;
}

export const removeFromArray = (arr, val) => {
  let newArr = [...arr];
  let idx = newArr.indexOf(val);
  if (idx > -1) newArr.splice(idx, 1);
  return newArr;
}

export const mergeArrays = (arr1, arr2) => {
  let newArr = [...arr1, ...arr2];
  let unique = [...new Set(newArr)];
  return unique;
}

export const removeMultiple = (arr, vals) => {
  let newArr = [...arr];
  newArr = newArr.filter(x => !vals.includes(x));
  return newArr;
}

export const checkSubset = (parentArray, subsetArray) => {
  return subsetArray.every((el) => {
    return parentArray.includes(el)
  })
}

export const createFileName = (input) => {
  if(!input) return 'tbd';

  var illegalRe = /[<>\\:":]/g;
  // var controlRe = /[\x00-\x1f\x80-\x9f]/g;
  var reservedRe = /^\.+$/;
  var windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;

  var sanitized = input
    .replace(illegalRe, '')
    // .replace(controlRe, '')
    .replace(reservedRe, '')
    .replace(windowsReservedRe, '')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s+/g, '-')
    .split('').splice(0, 255).join('')
    .toLowerCase();

  return sanitized;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const titleCase = (str) => {
  if(!str) return;
  return str.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}

export const nl2br = (str, is_xhtml) => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export const nl2cols = (arr) => {
  // then create a new array by splitting for line breaks
  // this will flow the additional items into incremental keys
  if(!arr) arr = [];
  let newArr = [];
  for (var i = 0; i < arr.length; i++) {
    if(!Array.isArray(arr[i])) {
      if(!arr[i]) arr[i] = '';
      let breaks = arr[i].split(/\r?\n/g);
      newArr.push.apply(newArr,breaks);
    } else {
      // existing value is an array, append full array
      // if you want to make them individual values, switch to arr[i]
      newArr.push.apply(newArr,[arr[i]]);
    }
  }

  return newArr;
}

export const getDifference = (a, b) => {
  return a.filter(element => {
    return !b.includes(element);
  });
}

// split url to get bucket and key/filename info
// saving in case removing chars makes this not work /\bhttps?:\/\/([^\/]+)\.s3[^\/]+amazonaws\.com\/([^\s]*)\b/g;
export const regexpBucket = /\bhttps?:\/\/([^]+)\.s3[^]+amazonaws\.com\/([^\s]*)\b/g;

// search string for all included URLs (not limited to AWS)
export const regexpUrls = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/g;

export const downloadFile = (file, fileName, createUrl) => {
  // you haven't tested this with createUrl being true
  if(createUrl) return alert('NOT TESTED READ DOCUMENTATION');

  // Create a link and set the URL using `createObjectURL`
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = createUrl ? URL.createObjectURL(file) : file;
  link.download = fileName;

  // It needs to be added to the DOM so it can be clicked
  document.body.appendChild(link);
  link.click();

  // wait to remove so this works in FF
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 0);

  // another different example if the above doesn't work for some reason
  // link.href = `data:application/pdf;base64,${e.data.}`;
}

export const arrToObj = (array, key) =>
  array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item
    }),
    {}
  );

export const objToArr = (obj) => {
  return Object.keys(obj).map(function (key) {
    return obj[key];
  });
}

export const userArray = (allUsers, idArray) => {
  if(!idArray || idArray.length === 0) return '-';
  let list = [];
  for (const id of idArray) {
    let found = allUsers.find(x => x.id === Number(id));
    if(found) list.push(`${found.firstName} ${found.lastName ? found.lastName : ''}`);
  }

  return list.join(', ');
}

export const accessArray = (allGroups, rolesArray) => {
  // lklklk: may need to add projects to this at some point too?
  if(!rolesArray || rolesArray.length === 0) return '';
  let list = [];
  for (const role of rolesArray) {
    if(role.type !== 'group') continue;
    let group = allGroups.find(x => x.id === role.id);
    if(!group) continue;

    let str = '';
    if(allGroups.length > 1) {
      str = `${group.label}`;
      str += role.role ? ` (${role.role.charAt(0).toUpperCase() + role.role.slice(1)})` : '';
    } else if(role.role) {
      str = role.role.charAt(0).toUpperCase() + role.role.slice(1);
    }
    
    list.push(str);
  }

  return list.sort().join(', ');
}

// blob to array buffer to blob
// const buf = await blob.arrayBuffer();
// let objUrl = URL.createObjectURL( new Blob( [ buf ] ) );

// convert blob to base64 string
// const reader = new FileReader();
// reader.addEventListener('load', () => {
//   console.log(reader.result);
// });
// reader.readAsDataURL(blob);

export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const base64toBlob = (dataUrl) => {
  return new Promise((resolve, _) => {
    var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    let blob = new Blob([u8arr], {type:mime});
    resolve(blob);
  });
}

export const resizeImg = (src, maxWidth, maxHeight, resolution) => {
  return new Promise((resolve, reject) => {
    if(!resolution) resolution = 1;
    // Step 1: Create a new image element
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Attempt to bypass CORS issues for image URLs

    img.onload = () => {
      // Step 2: Create a canvas element and get the context
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Step 3: Calculate the proportional dimensions
      const aspectRatio = img.width / img.height;

      let newWidth;
      let newHeight;

      if (maxWidth && maxHeight) {
        if (aspectRatio > (maxWidth / maxHeight)) {
          newWidth = maxWidth;
          newHeight = newWidth / aspectRatio;
        } else {
          newHeight = maxHeight;
          newWidth = newHeight * aspectRatio;
        }
      } else if (maxWidth) {
        newWidth = maxWidth;
        newHeight = newWidth / aspectRatio;
      } else if (maxHeight) {
        newHeight = maxHeight;
        newWidth = newHeight * aspectRatio;
      } else {
        newWidth = img.width;
        newHeight = img.height;
      }

      // Step 4: Set the canvas dimensions based on the resolution parameter
      canvas.width = newWidth * resolution;
      canvas.height = newHeight * resolution;

      // Step 5: Draw the image onto the canvas with smoothing enabled
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Step 6: Convert the canvas to a base64 string and resolve the promise
      const dataURL = canvas.toDataURL('image/jpeg', 0.95);
      resolve(dataURL);
    };

    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };

    // Step 7: Set the src attribute of the image to start the loading process
    img.src = src;
  });
}

// gets dataUrl, converts to blob, opens in new window
// let dataUrl = await resizeImg(photos[0].url, 266, 366, 2);
// let blob = await fetch(dataUrl).then(res => res.blob());
// const objectURL = URL.createObjectURL(blob);
// window.open(objectURL, '_blank');




// custom sort ordering on object values
// var ordering = {}, // map for efficient lookup of sortIndex
//     sortOrder = ['D','A','B','C'];
// for (var i=0; i<sortOrder.length; i++)
//     ordering[sortOrder[i]] = i;
//
// let lktest = schema.sort( function(a, b) {
//     return (ordering[a.id] - ordering[b.id]) || a.id.localeCompare(b.id);
// });

// function to check equality between two objects
// function deepEqual(x, y) {
//   const ok = Object.keys, tx = typeof x, ty = typeof y;
//   return x && y && tx === 'object' && tx === ty ? (
//     ok(x).length === ok(y).length &&
//       ok(x).every(key => deepEqual(x[key], y[key]))
//   ) : (x === y);
// }

export const isNumeric = (str) => {
  if (typeof str === 'number') return true;
  if (typeof str !== 'string') return false; // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const pickTextColor = (bgColor, lightColor, darkColor) => {
  // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color

  if(!bgColor)    bgColor = '#f00';
  if(!lightColor) lightColor = '#fff';
  if(!darkColor)  darkColor = '#000';

  bgColor = bgColor.replace('#', '');

  if(bgColor.length === 3) {
  	bgColor = bgColor.split('').map(function (hex) {
  		return hex + hex;
  	}).join('');
  }

  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
  return (L > 0.179) ? darkColor : lightColor;
}

// the below does rgba, but you should be able to figure it out similarly...
// export const hex2argb = (hex, alpha = 1) => {
//   // ref if you need it:
//   // https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
//   const [r, g, b] = hex.match(hex.length < 4 ? /[0-9A-Fa-f]{1}/g : /[0-9A-Fa-f]{2}/g).map(x => parseInt(x, 16));
//   console.log(r, g, b);
//   // return `rgba(${r},${g},${b},${alpha})`;
// };

// // export const hex2rgba = (hex, alpha = 1) => { 
// //   const [r, g, b] = hex.match(hex.length<=4 ? /\w/g : /\w\w/g).map(x => parseInt(x.length<2?${x}${x}: x, 16)); 
// //   return rgba(${r},${g},${b},${alpha});
// // };

export const argbToHex = (argb) => {
  // Remove any leading "0x" if present and parse as hex number
  argb = argb.replace(/^0x/i, '');
  argb = parseInt(argb, 16);

  // Extract the red, green, and blue components from the ARGB value
  const red = (argb >> 16) & 0xFF;
  const green = (argb >> 8) & 0xFF;
  const blue = argb & 0xFF;

  // Convert each component to a two-digit hexadecimal string
  const redHex = red.toString(16).padStart(2, '0');
  const greenHex = green.toString(16).padStart(2, '0');
  const blueHex = blue.toString(16).padStart(2, '0');

  // Concatenate the components to form the hexadecimal color value
  const hexColor = `${redHex}${greenHex}${blueHex}`;
  return hexColor;
}


export const countByKey = (data, key) => {
  const res = data.reduce((acc, obj) => {
    const existingIndex = acc.findIndex(
      el => el[key] === obj[key]
    )
    if (existingIndex > -1) {
      acc[existingIndex].quantity += 1
    } else {
      let newObj = {};
      newObj[key] = obj[key];
      newObj['quantity'] = 1;
      acc.push(newObj);
    }
    return acc
  }, []);

  return res;
}

export const countByTwoKeys = (data, key1, key2) => {
  const res = data.reduce((acc, obj) => {
    const existingIndex = acc.findIndex(
      el => el[key1] === obj[key1] && el[key2] === obj[key2]
    )
    if (existingIndex > -1) {
      acc[existingIndex].quantity += 1
    } else {
      let newObj = {};
      newObj[key1] = obj[key1];
      newObj[key2] = obj[key2];
      newObj['quantity'] = 1;
      acc.push(newObj);
    }
    return acc
  }, []);

  return res;
}

export const sumByKey = (data, key) => {
  const res = data.reduce((acc, obj) => {
    // set quantity as 1 if obj.property doesn't exist/is null
    if(!obj.hasOwnProperty('quantity') || obj.quantity === null)
      obj.quantity = 1;

    const existingIndex = acc.findIndex(
      el => el[key] === obj[key]
    )
    if (existingIndex > -1) {
      acc[existingIndex].quantity = acc[existingIndex].quantity + obj.quantity;
    } else {
      let newObj = {};
      newObj[key] = obj[key];
      newObj['quantity'] = obj.quantity;
      acc.push(newObj);
    }
    return acc
  }, []);

  return res;
}

export const sumByTwoKeys = (data, key1, key2) => {
  const res = data.reduce((acc, obj) => {
    // set quantity as 1 if obj.property doesn't exist/is null
    if(!obj.hasOwnProperty('quantity') || obj.quantity === null)
      obj.quantity = 1;
    else
      obj.quantity = Number(obj.quantity);

    const existingIndex = acc.findIndex(
      el => el[key1] === obj[key1] && el[key2] === obj[key2]
    )
    if (existingIndex > -1) {
      acc[existingIndex].quantity = acc[existingIndex].quantity + obj.quantity;
    } else {
      let newObj = {};
      newObj[key1] = obj[key1];
      newObj[key2] = obj[key2];
      newObj['quantity'] = obj.quantity;
      acc.push(newObj);
    }
    return acc
  }, []);

  return res;
}

export const example1 = () => {
  return new Promise(async(resolve) => {
    resolve(true);
  })
};

// find overlaps between two arrays
export const example2 = (array1, array2) => {
  return array1.filter(obj1 => {
    return array2.some(obj2 => obj1.type === obj2.type && obj1.id === obj2.id && obj1.role === obj2.role);
  });
}
