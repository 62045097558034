import { fabric } from 'fabric';
import { viewportChanged } from './viewport'

// prevent default if we need it:
// https://stackoverflow.com/a/55673572/983017

export const wheelHandler = ({e, canvas, map}) => {
  // lklklk remember to set up to reverse based on the user preference
  // e.g. natural vs inverted scroll

  if(e.target.tagName === 'CANVAS') {
    // e.preventDefault();
    var delta = e.detail ? e.detail * (-120) : e.wheelDelta ? e.deltaY : e.deltaX ? e.deltaX : e.deltaY;
    var curZoom = canvas.getZoom();
    // lklklk this is where we could customize
    // and do natural vs. opposite for the scroll wheel
    // var newZoom = curZoom + delta / 1000;
    var newZoom = curZoom - delta / 1000;
    // console.log(delta, curZoom, newZoom);
    var x = e.offsetX;
    var y = e.offsetY;

    if (newZoom > 0.02 && newZoom < 8) {
      canvas.zoomToPoint({
        x: x,
        y: y
      }, newZoom);
    }
    // if (event != null) event.preventDefault();
    canvas.requestRenderAll();
    canvas.calcOffset();
    viewportChanged(canvas, map);
    return false;
  }
}
