import React, { Fragment, useEffect, useState } from 'react';

// this is NOT a duplciation/mistake from the other one in survey:
// we are doing activePlan, so we are searching by id
// the PlanOpt value is id here, is appId in the other
// the function here sets activePlan, manually changes URL in the other

const PlanOpts = (props) => {
  const { groupId, plans, plots } = props;
  const [list, setList] = useState([]);

  useEffect(() => {
    let filtered = [];
    if(groupId)
      filtered = plans.filter(x => x.planGroupId === groupId && x.status==='A');
    else
      filtered = plans.filter(x => !x.planGroupId && x.status==='A');

    filtered.sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
    setList(filtered);
  }, [groupId, plans])

  return (
    <Fragment>
    {list.map(plan => {
        let qty = plots?.filter(x => x.planId === plan.id)?.length;
        return (
          <option key={`plan-${plan.id}`} value={plan.id}>
            {plan.name}
            {qty > 0 && ` - ${qty} plots`}
          </option>
        )
      })}
    </Fragment>
  );
}

const View = (props) => {
  const { planGroups, plans, activePlan, setActivePlan, plots } = props;

  const changePlan = (e) => {
    e.target.blur();
    let found = plans.find(x => x.id === parseInt(e.target.value));
    if(found) setActivePlan(found);
  }

  return (
    <select
      style={{width:260}}
      className="form-select form-select-sm d-inline-block border shadow-sm float-end me-2"
      value={activePlan.id}
      onChange={changePlan}>
        {planGroups.filter(x => x.status === 'A')
          .sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0))
          .map(group => (
            <optgroup key={`group-${group.id}`} label={group.name}>
              <PlanOpts groupId={group.id} plans={plans} plots={plots} />
            </optgroup>
        ))}
        
        {/* only show the dashes if there are grouped plans */}
        {(planGroups.filter(x => x.status === 'A').length > 0 &&
          plans.filter(x => !x.planGroupId && x.status==='A').length > 0) && (
          <optgroup label="--">
            <PlanOpts plans={plans} plots={plots} />
          </optgroup>
        )}

        {/* otherwise just show them not in an opt group */}
        {planGroups.filter(x => x.status === 'A').length === 0 && (
          <PlanOpts plans={plans} plots={plots} />
        )}
    </select>
  )
}

export default View;