import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';

import { SiteContext } from '../../../../context/SiteContext';
import { UserContext } from '../../../../context/UserContext';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Generated  from '../../../../components/system/Generated';
import ToggleStatusBtn from '../../../../components/buttons/ToggleStatus';
// import EmailError from '../system/EmailError';
import CopyToPhase from './CopyToPhase';

import dayjs from 'dayjs'
import { emptyObj } from '../../../../utils/functions';
import { findSchema, formObj, removeArrs } from '../../../../utils/schemas';

// import DownloadQr from '../../../../components/buttons/DownloadQr';
import Photos from '../../../system/photos/Photos';
import Photo from '../../../system/photos/components/Photo';

// font documentation for when you need it:
// https://pdfmake.github.io/docs/0.1/fonts/custom-fonts-client-side/vfs/


const View = (props) => {
  const debug = true;
  const { hostname, config } = useContext(SiteContext);
  const { userDetails, schemas, allGroups } = useContext(UserContext);
  const { project, planGroups, plans, phases, activePhase, plots, activePhotos, setPhotos, publicStatuses, obj, symbols, types, typeVars, setPlots, handleClose, toParent } = props;
  const formText = { submit: `Update`, pending: 'Updating', success: 'Updated!', cancel: 'Cancel' };
  const errorCode = '1984'
  const [border, setBorder] = useState('#ccc');
  const [plot, setPlot] = useState({});
  
  const [assignGroups, setAssignGroups] = useState([]);
  const [plotPhotos, setPlotPhotos] = useState([]);
  const [phasePhotos, setPhasePhotos] = useState([]);

  // plotSchema are the default system fields, like type/qty etc
  // typeSchema is the message A/B etc  override if any exists
  // listSchema is the type + checklist join, not shown in this view
  const [plotSchema, setPlotSchema] = useState([]); // default system fields from plot-edit + config
  const [typeSchema, setTypeSchema] = useState([]); // permanent plot data, always visible in desktop
  const [detailSchema, setDetailSchema] = useState([]); // default system fields from plot-defaults + config (append)
  
  // listSchema is only visible within ... the lists, omitting here
  // LKLKLK consider making a received task/list etc optional...
  // then we could just continue using this one script

  const [actions, setActions] = useState([]);

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue, getValues } = useForm({
    defaultValues: useMemo(() => formObj(plotSchema), [])
  });
  const typeId = watch('typeId');
  const toggleStatus = watch('status');

  useEffect(() => {
    setPlot(obj);
  }, [obj])

  useEffect(() => {
    let filtered = activePhotos?.filter(x => x.plotAppId===obj.appId);
    let phaseFilter = filtered.filter(x => x.phaseId===activePhase.id);
    setPlotPhotos(filtered);
    setPhasePhotos(phaseFilter);
  }, [obj.appId, activePhotos])

  useEffect(() => {
    let found = schemas.find(x => x.name==='plot-actions');
    if(found) setActions(found.schema);

    found = schemas.filter(x => x.name==='plot-edit');
    found = findSchema(found, project); // this one could have multiple schemas
    if(found?.schema) setPlotSchema(found.schema);

    found = schemas.filter(x => x.name==='plot-detail');
    found = findSchema(found, project); // this one could have multiple schemas
    if(found?.schema) setDetailSchema(found.schema);
  }, [schemas])

  useEffect(() => {
    let whatever = [...allGroups];
    whatever.unshift({ value: null, label: 'Select One' });
    setAssignGroups(whatever);
  }, [allGroups])

  useEffect(() => {
    if(emptyObj(plot)) return;
    let combo = [...plotSchema, ...detailSchema];
    if(typeSchema.length > 0)
      combo = [...combo, ...typeSchema];

    let defaults = formObj(combo, plot);
    reset(defaults);
  }, [plotSchema, typeSchema, detailSchema, plot, reset])

  useEffect(() => {
    let newBorder = '#ccc';
    let newTypeSchema = [];

    let type = types.find(x => x.id === typeId);
    if(type) {
      newBorder = type.styling.fillColor;
      newTypeSchema = type.schema?.length > 0 ? type.schema : [];
    }

    if(newTypeSchema?.length===0 && 'schemaIds' in config && 'plot-message' in config.schemaIds) {
      let schemaId = config.schemaIds['plot-message'];
      let found = schemas.find(x => x.id===schemaId);
      if(found?.schema) newTypeSchema = found.schema;
    }

    setBorder(newBorder);
    setTypeSchema(newTypeSchema);
  }, [config, schemas, typeId, types])

  const onSubmit = async (data) => {
    if(debug) console.log(data);
    delete data.ogTypeId;
    let cleaned = removeArrs(data);

    await setPlots((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.id === obj.id);
      arr[idx] = {...arr[idx], ...cleaned}
      return arr;
    })

    data.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.updatedBy = userDetails.id;

    let res = await serverCall({ method: 'PATCH', data, url: `/mapping/plots/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating plot. Contact support.') // lklklk
  };

  const fromChild = async (data) => {
    const { type, name, value } = data;
    if(type==='autosuggest' && name==='typeId' && obj.typeId !== value) {
      setPlot(prev => {
        let currentValues = getValues();
        let newObj = {...prev, ...currentValues};
        newObj.typeId = value;
        return newObj;
      })
    } else if(type==='copied to phase') {
      await setPlots((prev) => {
        let arr = [...prev];
        // update the status for this one
        let idx = arr.findIndex(x => x.id === obj.id);
        arr[idx].publicStatus = 'Moved to Another Phase';

        // then merge the new ones in
        arr = [...arr, ...value.new];

        // and update the... updated ones
        for(const plot of value.updated) {
          let idx = arr.findIndex(x => x.id === plot.id);
          if(idx > -1) arr[idx] = {...arr[idx], ...plot};
        }
        return arr;
      })
    } else if(type==='toggle status') {
      setValue('status', value);
      handleSubmit(onSubmit)();
    }
  }

  if(!obj) return null;

  return (
    <div className="d-flex flex-column h-100 overflow-hidden" style={{borderLeft:`6px solid ${border}`}}>
      <Offcanvas.Header className="bg-body pb-0" closeButton>
        <Offcanvas.Title>Editing {obj?.display?.textLabel}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="bg-body flex-grow-1 pt-2 overflow-scroll">
        
        <Tabs defaultActiveKey="details" id="edit-tabs" className="mb-3">
          <Tab eventKey="details" title="Details">
            { phasePhotos.length > 0 && (
              <div className="horizontal-scroll mb-3">
                { phasePhotos.map(img => <Photo key={img.appId+img.id} img={img} mini />)}
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mb-3">
              <Generated id="plot-edit" schema={plotSchema} size="md" pieces={{ vars: { 'plot-actions': actions, 'plot-status': publicStatuses, types, typeVars, allGroups: assignGroups }, register, control, setValue, watch, errors, toParent: fromChild }} />
              <Generated id="plot-message" schema={typeSchema} size="md" pieces={{ vars: { 'plot-actions': actions, 'plot-status': publicStatuses, types, typeVars, symbols }, register, control, setValue, watch, errors, toParent: fromChild }} />
              <Generated id="plot-details" schema={detailSchema} size="md" pieces={{ vars: { 'plot-actions': actions, 'plot-status': publicStatuses, types, typeVars }, register, control, setValue, watch, errors, toParent: fromChild }} />
            </form>
          </Tab>
          <Tab eventKey="photos" title={`Photos ${phasePhotos.length > 0 ? ` (${phasePhotos.length})` : ''}`}>
            <Photos key={obj?.appId+obj?.phaseId} obj={obj} photos={plotPhotos} setPhotos={setPhotos} phases={phases} />
          </Tab>
          {/* <Tab eventKey="history" title="History">
            History here...?
            <PlotHistory plots={plotHistory} photos={plotPhotos} libraries={libraries} types={types} />
          </Tab> */}
        </Tabs>

        { hostname !== 'wgs.flysfo.io' && (
          <CopyToPhase key={obj?.appId+activePhase?.id} phase={activePhase} phases={phases} plots={plots} appIdStr={JSON.stringify([{ appId: obj.appId, id: obj.id }])} toParent={fromChild} />
        )}
        
      </Offcanvas.Body>
      <div className="bg-body p-3">
        <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'} float-end`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
          { !pending && !error && (formText.submit)}
          { pending === true && (
            <Fragment>
              <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              {formText.pending}
            </Fragment>
          )}
          { error && (`Error saving (${errorCode})`)}
          { pending ==='success' && formText.success}
        </button>

        <button className="btn btn-sm btn-outline-dark ms-auto me-1 float-end" type="button" onClick={handleClose}>
          {formText.cancel}
        </button>

        <ToggleStatusBtn toParent={fromChild} status={toggleStatus} wrapper="float-start" styles="btn btn-sm btn-outline-danger ms-2" textA="Delete" textD="Reactivate" />
        { userDetails.supe && <p className="float-start ms-2 mt-2 mb-0 small text-muted">{obj.appId} - {obj.id}</p>}
      </div>
    </div>
  )
}

export default View;
