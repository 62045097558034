
import { blobToBase64, nl2cols } from '../../../utils/functions';
import { getSvg, getRect, setRect, dimensions, fonts, repeatRows, cleanMsg } from './functions';

// future: maybe do icon sets if you can ...
// look for .st9{fill:none;stroke:#f00;} and the class...? then we 
// could automatically know which icon set they want? -- still need to figure out the innerHTML [[X]]

// if needed, fontfaceobserver:
// https://www.npmjs.com/package/fontfaceobserver

export const makeSvg = ({ template, message, symbols, type, plot, format }) => {
  return new Promise( async (resolve,reject)=>{
    // console.clear();
    let original = [...message]; // storing original message for splits below
    let content = [...message];
    let svgText = template;
    let svgTags = new DOMParser().parseFromString(template, "text/html").querySelector('svg');
    let { width, height } = await dimensions(svgTags);
    // console.log(svgTags);
    // console.log(svgText);
    // console.log(width, height);

    // file1 pulls from arr1
    // file2 pulls from arr2 ... etc.

    // console.log(content);
    // console.log(symbols);

    // get fonts
    if(format !== 'blob') {
      let styles = svgTags.querySelectorAll('style');
      await fonts({ styles, symbols });
    }

    // this works, saving just in case
    // styles[0].appendChild(document.createTextNode(".st1 { fill: #FF0000; }"));

    // split line breaks into additional columns/fields
    content = nl2cols(content);

    // get rid of any conditionals
    let conditionals = svgTags.querySelectorAll('[cond-index]');
    for(const tag of conditionals) {
      let condIdx = tag.getAttribute('cond-index');
      let condVal = tag.getAttribute('cond-value');
      if(condVal) {
        let condVals = condVal.split(',');
        if(!condVals.includes(content[condIdx])) {
          tag.parentNode.removeChild(tag);
        }
      }
    }

    // find any hardcoded fields
    let hardcoded = svgTags.querySelectorAll('[parent],[field]');
    for(const tag of hardcoded) {
      let parent  = tag.getAttribute('parent');
      let field = tag.getAttribute('field');
      let val;
      if(parent==='type' && type) {
        val = type[field];
      } else if(parent==='plot' && plot) {
        val = plot[field];
      }

      if(val) tag.innerHTML = val;
    }

    // check for any split/vertical text
    let alter = svgTags.querySelectorAll('[alter-msg]');
    for(const tag of alter) {
      let alterIdx  = tag.getAttribute('alter-msg');
      let alterType = tag.getAttribute('alter-type');
      if(alterType==='split') {
        let msg = content[Number(alterIdx-1)]?.split('');
        if(msg) {
          for(var i=0; i < msg.length; i++) {
            let text = tag.children[i];
            text.innerHTML = await cleanMsg(msg[i]);
          }
        }
      }
    }

    // check if there are any icon sets
    let iconGroups = svgTags.querySelectorAll('[icon-group]');
    for(const tag of iconGroups) {
      let iconIdx = tag.getAttribute('icon-index');
      let iconSet = tag.getAttribute('icon-set');

      for(var i=0; i < tag.children.length; i++) {
        let rect = tag.children[i];
        let { x, y, w, h } = await getRect(rect);

        let found = symbols.find(x => x.id === Number(content[iconIdx][i]));

        if(found && found[`url${iconSet}`]) {
          let icon = await getSvg(found[`url${iconSet}`], true);
          icon = await setRect({ icon, x, y, w, h });
          rect.replaceWith(icon);
        } else {
          // remove box outline
          rect.parentNode.removeChild(rect);
        }
      }
    }

    let rows = svgTags.querySelectorAll('[row-repeat]');
    await repeatRows({ svgTags, rows, content, symbols });

    // turn back into string for easier data parsing
    svgText = svgTags.outerHTML;

    // // // replace any instances of archroomno... automatically!
    // // let archRoomNo = '';
    // // if(item.archRoomNo) archRoomNo = item.archRoomNo;
    // // let regexp = /\[\[ARCHROOMNO\]\]/g;
    // // svg = svg.replace(regexp, archRoomNo);

    let regexp = /(<[^/^>]+>)[^+]?(\[\[[0-9]+\]\])[^+]?(<\/[^/^>]+>)/g;
    let placeholders = svgText.match(regexp);

    if(placeholders) {
      for(const placeholder of placeholders) {
        let tag = new DOMParser().parseFromString(placeholder, "text/html");
        let rect = tag.querySelector('rect');
        let text = tag.querySelector('text');
        let tspan = tag.querySelector('tspan');
  
        if(text || tspan) {
          if(tspan) text = tspan;
          let gutsexp = /(\[\[([0-9]+)\]\])/g;
          let guts = text.innerHTML.match(gutsexp);
  
          // determine message index based on number in double brackets
          for(const gut of guts) {
            let str = text.innerHTML;
            let num = gut.replace('[[','').replace(']]','');
            let idx = Number(num) - 1;
            let msg = content[idx] ? content[idx] : null;

            // check if we need to split this to allow for bottom aligning
            if(Boolean(text.getAttribute('split'))) {
              let splitNo = text.getAttribute('split');
              let breaks = original[idx] ? original[idx].split(/\r?\n/g) : [];
              let neededLines = splitNo - breaks.length;
              if(neededLines > 0) {
                for (var i = 0; i < neededLines; i++) {
                  content.splice(idx, 0, '');
                }
                msg = '';
              }
            }
  
            // check if we should pull text from the icon
            if(Boolean(text.getAttribute('iconText'))) {
              let found = symbols.find(x => x.id === Number(msg));
              if(found) msg = found.description;
            }
  
            // sanitize message content for svg, then replace
            // note, this may break if they have duplicate indexes
            msg = await cleanMsg(msg);
  
            let merged = str.replace(gut, msg);
            svgText = svgText.replace(text.innerHTML, merged);
          }
  
        } else if(rect) {
          let { x, y, w, h, iconSet } = await getRect(rect);
          let idx = rect.innerHTML.replace('[[','').replace(']]','');
          idx = Number(idx) - 1;
          let id = (content[idx]) ? content[idx] : null;
  
          let found = symbols.find(x => x.id === Number(id));
  
          if(found && found[`url${iconSet}`]) {
            let icon = await getSvg(found[`url${iconSet}`], true);
            icon = await setRect({ icon, x, y, w, h });
            svgText = svgText.replace(rect.outerHTML, icon.outerHTML);
          } else {
            // remove box outline
            svgText = svgText.replace(rect.outerHTML, '');
          }
        }
      }
    }

    // now create the png preview
    if(format === 'blob') {
      return resolve(svgText);
    } else {
      let img = document.createElement('img');
      let canvas = document.createElement('canvas');
      canvas.width = width/2;
      canvas.height = height/2;
      let ctx = canvas.getContext('2d');
  
      // let image = new Image();
      img.onload = (e) => {
        ctx.drawImage(e.target, 0, 0, width/2, height/2);
        var imgData = canvas.toDataURL();
        img.remove();
        canvas.remove();
        // resolve({svg: svg, img: imgData});
        // console.log(imgData);
        resolve(imgData);
      }
  
      img.src = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgText)))}`;
      // img.src = `data:image/svg+xml;base64,${btoa(svgText)}`;
  
      // documentation on above:
      // https://stackoverflow.com/questions/23223718/failed-to-execute-btoa-on-window-the-string-to-be-encoded-contains-characte
    }
  });
}