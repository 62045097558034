import React, { Fragment, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import DownloadPdf   from '../../../components/buttons/DownloadPdf';
import DownloadExcel from '../../../components/buttons/DownloadExcel';
import Table from '../../../components/system/TableLarge';
import MessageSchedule from '../../../components/reports/MessageSchedule';
import Photo from '../../system/photos/components/Photo';

const cols = [
  { Header: 'Plan', accessor: 'plan', width: 60 },
  { Header: 'ID', accessor: 'name', width: 40 },
  { Header: 'Type', accessor: 'type' },
  { Header: 'Action', accessor: 'action', width: 60 },
  { Header: 'Status', accessor: 'status', width: 60 },
  { Header: 'Photos', Cell: tableProps => {
    return (
      <div className="horizontal-scroll">
        { tableProps.row.original.photos?.map(img => <Photo key={img.appId+img.id} img={img} mini />)}
      </div>
    )
  }}
];

const View = () => {
  const { project, phases, activePhase, planGroups, plans, photos, symbols, types, typeVars, activePlots, activePhotos, activeQtys, projTasks } = useOutletContext();
  const [data, setData] = useState([]);

  useEffect(() => {
    let arr = [];
    for(const plot of activePlots) {
      let plan = plans.find(x => x.id === plot.planId);
      let pics = activePhotos.filter(x => x.plotAppId === plot.appId);
      arr.push({
        name: plot.name,
        type: plot.display.typeLabel,
        plan: plan?.name,
        action: plot.installMode ? plot.installMode : 'TBD',
        status: plot.publicStatus ? plot.publicStatus : 'Pending',
        photos: pics
      })
    }
    setData(arr);
  }, [activePlots, activePhotos, plans])

  return (
    <Fragment>
      <Helmet>
        <style>{` body { padding-top:4.3rem; } `}</style>
      </Helmet>
      <div className="row h-100 overflow-hidden">
        <div className="col-sm-9 pe-5 h-100 overflow-scroll">
          <div className="px-2 bg-white rounded h-100">
            <Table columns={cols} data={data} noDataMsg="No data yet." classes="table my-0" />
          </div>
        </div>
        <div className="col-sm-3 h-100 overflow-scroll">
          <h5 className="mb-2">Actions</h5>
          <MessageSchedule text="Application PDF" classes="shadow-sm btn btn-outline-dark mb-2 me-2" project={project} plots={activePlots} photos={photos} />
          <DownloadPdf text="PDF" project={project} planGroups={planGroups} plans={plans} phases={phases} phase={activePhase} types={types} typeVars={typeVars} tasks={projTasks} symbols={symbols} plots={activePlots} activeQtys={activeQtys} photos={activePhotos} wrapper="shadow-sm mb-2 me-2" />
          <DownloadExcel text="Excel" project={project} planGroups={planGroups} plans={plans} phases={phases} phase={activePhase} types={types} typeVars={typeVars} symbols={symbols} plots={activePlots} activeQtys={activeQtys} wrapper="shadow-sm mb-2 me-2" />
        </div>
      </div>
    </Fragment>
  )
}

export default View;
