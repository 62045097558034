import React from 'react';
import { useDroppable } from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { SortableItem } from './SortableItem';

const View = (props) => {
  const { id, arr, child, pieces, handle, toParent } = props;
  const caption = props.caption ? props.caption : 'sorted';
  const text = props.text ? props.text : 'Empty';
  const { setNodeRef } = useDroppable({ id });

  return (
    <SortableContext id={id} items={arr} strategy={rectSortingStrategy}>
      <div ref={setNodeRef}>
        { arr.length === 0 && <p className="p-3 mb-0 text-muted">{text}</p>}
        { arr.map((obj, idx) => (
          <SortableItem key={obj.id} sort={obj.planGroupId+obj.sortOrder} idx={idx} id={obj.id} obj={obj} pieces={pieces} length={arr.length} child={child} handle={handle} toParent={toParent} caption={caption} />          
        ))}
      </div>
    </SortableContext>
  );
}

export default View;