import React, { Fragment, useContext, useState } from 'react';
import dayjs from 'dayjs'

import { WorkerContext } from '../../context/WorkerContext';
import { Download } from 'react-bootstrap-icons';

import { getMarkup } from '../../components/system/markups/utils/render';

// letter: 792 pt x 612 pt
// tabloid: 1224 pt x 792 pt
// arch E: 48 in x 36 in, 3023 pt x 2160 pt

const View = (props) => {
  const { startWorker } = useContext(WorkerContext);
  const { project, plots, photos } = props;
  const classes = props.classes ? props.classes : 'btn btn-outline-primary';
  const text = props.text ? props.text : '';
  const [loading, setLoading] = useState(false);
  
  const download = async () => {
    console.clear();
    setLoading(true);

    let timestamp = dayjs().format('YYYY-MM-DD');
    let name = `${project.name} - (PDF)`;
    let filename = `${project.name} - Message Schedule ${timestamp}`;
    
    let fit = [850, 700];
    let body = [];
    let images = {};
    // let page = 1;
    plots.sort((a, b) => a.planId - b.planId || a.name.localeCompare(b.name));

    images['coverbg'] = 'https://eams-dev.s3.amazonaws.com/sfo-temp/fonts/red-bg.png';

    let coverBg = {
      image: 'coverbg',
      absolutePosition: {x: 0, y: 0},
      fit: [1224, 792]
    }
    body.push(coverBg);

    let cover = {
      layout: 'noBorders',
      unbreakable: true,
      table: {
        widths: [525,500],
        heights: [700, 30],
        body: [
          [
            [
              { text: `P${project.id} ${project.name}`, style: ['small', 'bold', 'white'] },
              { text: 'Messaging Schedule', style: ['small', 'white'] },
            ],
            [
              { text: `${project.name}`, style: 'headerWhite', margin: [0,96,0,0] },
              { text: `Messaging Schedule`, style: 'headerWhite', margin: [0,25,0,0] },
            ]
          ],
          [
            [
              { text: dayjs().format('MMMM YYYY'), style: ['small','white'] }
            ],
            []
          ]

        ]
      },
    };
    body.push(cover);

    for(const plot of plots) {
      let pics = photos.filter(x => x.plotAppId === plot.appId && x.projectId === plot.projectId && x.phaseId === plot.phaseId && x.status==='A');
      pics = pics.slice(Math.max(pics.length - 2, 0));

      let img = { text: '' };
      if(pics.length === 0) {
        let table = {
          layout: 'noBorders',
          unbreakable: true,
          table: {
            widths: [280,10,'*'],
            heights: [700, 30],
            body: [
              [
                [
                  { text: `P${project.id} ${project.name}`, style: 'smallBold' },
                  { text: 'Messaging Schedule', style: 'small' },
                  { text: `Terminal 3`, style: 'header', margin: [0, 75, 0, 0] },
                  { text: `Level ${plot.planId}`, style: 'header' },
                  { text: `Location ${plot.name}`, style: 'header', margin: [0, 0, 0, 100] },
                  { text: 'Application Notes', style: 'small' },
                  { text: plot.udf13 }
                ],
                [],
                []
              ],
              [
                [
                  { text: dayjs().format('MMMM YYYY'), style: 'small' }
                ],
                [],
                [
                  { text: 'Note: Visuals shown above are for message planning only and do not represent accurate sizing and/or spacing of information. Legacy design guidelines should be used for the final artworks.', style: 'small' }
                ]
              ]
  
            ]
          },
        };
        body.push(table);
      } else {
        for(const pic of pics) {
          let render = await getMarkup({ img: pic });
          images[`img${pic.id}`] = render ? render : pic.url;
          img = { image: `img${pic.id}`, fit };

          let table = {
            layout: 'noBorders',
            unbreakable: true,
            table: {
              widths: [280,10,'*'],
              heights: [700, 30],
              body: [
                [
                  [
                    { text: `P${project.id} ${project.name}`, style: 'smallBold' },
                    { text: 'Messaging Schedule', style: 'small' },
                    { text: `Terminal TBD`, style: 'header', margin: [0, 75, 0, 0] },
                    { text: `Level ${plot.planId}`, style: 'header' },
                    { text: `Location ${plot.name}`, style: 'header', margin: [0, 0, 0, 100] },
                    { text: 'Application Notes', style: 'small' },
                    { text: plot.udf13 },
                    { text: pic.notes },
                  ],
                  [],
                  [ img ]
                ],
                [
                  [
                    { text: dayjs().format('MMMM YYYY'), style: 'small' }
                  ],
                  [],
                  [
                    { text: 'Note: Visuals shown above are for message planning only and do not represent accurate sizing and/or spacing of information. Legacy design guidelines should be used for the final artworks.', style: 'small' }
                  ]
                ]
    
              ]
            },
          };
          body.push(table);
        }
      }
    }

    let content = {
      pageSize: {width: 1224, height: 792},
      pageMargins: [25,25,25,25],
      // background: {
      //   canvas: [{ 
      //     type: 'rect',
      //     x: 0,
      //     y: 0,
      //     w: 15,
      //     h: 150,
      //     color: 'black'
      //   }],
      //   absolutePosition: {x: 0, y: 90},
      // },
      content: [body],
      images: images,
      defaultStyle: {
        font: 'Helvetica'
      },
      styles: {
        small: { fontSize: 9 },
        bold: { bold: true },
        smallBold: { fontSize: 9, bold: true },
        header: { fontSize: 32, bold: true },
        headerWhite: { fontSize: 50, bold: true, color: 'white' },
        white: { color: 'white' }
      },
    }
    startWorker({ type: 'pdf', pieces: { name, filename, content } });
    setLoading(false);
  }

  return (
    <button type="button" className={classes} onClick={() => download()} disabled={loading}>
      { !loading && (
        <Fragment>
          <Download size={16} />
          { text && <span className="d-inline-block ms-2">{text}</span>}
        </Fragment>
      )}

      { loading && (
        <Fragment>
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </Fragment>
      )}        
    </button>
  )
}

export default View;
