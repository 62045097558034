import React, { forwardRef, useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form'

import '../../../static/article/css/article-editor.min.css';
import ArticleEditor from '../../../static/article/article-editor.js';
import '../../../static/article/plugins/reorder/reorder.js';

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  const app = useRef();
  const editorId = `${pieces.formId}-${item.field}`;

  useEffect(() => {
    if(app.current) {
      app.current.destroy();
      app.current = '';
    }

    app.current = ArticleEditor(`#${editorId}`, {
      // editor: { maxHeight: item.maxHeight },
      addbarHide: item.hide ? item.hide : [],
      css: ['/css/arx-frame.min.css'],
      placeholder: item.placeholder ? item.placeholder : '',
      custom: {
        css: ['/css/bootstrap.min.css']
      },
      classes: {
        img: 'img-fluid',
        table: 'table table-bordered'
      },
      embed: {
        responsive: 'embed-responsive embed-responsive-16by9'
      },
      plugins: ['reorder'],
      grid: {
        classname: 'row',
        columns: 12,
        gutter: '1px',
        offset: {
          left: '15px',
          right: '15px'
        },
        patterns: {
          '6|6': 'col-6|col-6',
          '4|4|4': 'col-4|col-4|col-4',
          '3|3|3|3': 'col-3|col-3|col-3|col-3',
          '2|2|2|2|2|2': 'col-2|col-2|col-2|col-2|col-2|col-2',
          '3|6|3': 'col-3|col-6|col-3',
          '2|8|2': 'col-2|col-8|col-2',
          '5|7': 'col-5|col-7',
          '7|5': 'col-7|col-5',
          '4|8': 'col-4|col-8',
          '8|4': 'col-8|col-4',
          '3|9': 'col-3|col-9',
          '9|3': 'col-9|col-3',
          '2|10': 'col-2|col-10',
          '10|2': 'col-10|col-2',
          '12': 'col-12'
        }
      },
      subscribe: {
        'editor.change': () => {
          let content = app.current.editor.getContent();
          if(content === '<p></p>') content = '';
          pieces.setValue(`${item.field}`, content);
          if(pieces.toParent) pieces.toParent({ type: 'article', name: item.field, value: content }); 
        },
        'editor.blur': () => {
          // need to put this in blur so it doesn't kick them out of the editor
          let content = app.current.editor?.getContent();
          if(content === '<p></p>') content = '';
          if(content && pieces?.trigger) pieces.trigger(`${item.field}`);
        }
      }
    });
  }, [editorId, item, pieces])

  useEffect(() => {
    return () => {
      app.current.destroy();
      app.current = '';
    }    
  }, [])

  return (
    <Controller
      name={item.field}
      control={pieces.control}
      rules={{ required: item.required }}
      render={({ field }) => {
        if(!field.value) field.value = '<p></p>';

        return (
          <div className="my-2">
            {item.label && (<label htmlFor={item.field}>{item.label}</label>)}
            {item.note && (<p className="mb-1 text-muted fst-italic small">{item.note}</p>)}
            <textarea {...field}
              ref={field.ref}
              id={editorId}
              name={item.field}
              disabled={item.disabled} />
            {pieces.errors[item.field] && <p className="mt-0 error">{item.errorMsg}</p>}
          </div>
        )
      }}
    />
  )
});

export default Field;
