import React, { Fragment } from 'react';

// NOTE: this is an uncontrolled object that returns the specified array
// this is NOT a component that is incorporated
// into dynaimcally generated forms...

const View = (props) => {
  const { arr, checked, setChecked, toParent } = props;

  const toggle = (e) => {
    e.target.blur();
    let items = [...checked];
    if(e.target.checked)
      items.push(e.target.value);
    else {
      let idx = items.indexOf(e.target.value);
      items.splice(idx, 1);
    }
    setChecked(items);
    toParent(items);
  }

  return (
    <Fragment>
      { arr.map((obj, index) => (
        <li key={'typeId-'+obj.value} className="dropdown-item">
          <div className="form-check">
            <input className="form-check-input" type="checkbox"
              id={'status-'+obj.value}
              value={obj.value}
              checked={checked.includes(obj.value)}
              onChange={toggle}
            />
            <label className="form-check-label" htmlFor={'status-'+obj.value}>
              {obj.label ? obj.label : obj.value}
            </label>
          </div>
        </li>
      ))}
    </Fragment>
  )
}

export default View;
