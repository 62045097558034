import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useCanvas } from '../../../hooks/useFabricCanvas';
import { emptyObj } from '../../../utils/functions';

import Text from './shapes/Text';
import Circle from './shapes/Circle';
import Rectangle from './shapes/Rectangle';

import Resize from './actions/Resize';
// import Drawing from './actions/Drawing';
import ColorPicker from './actions/ColorPicker';
import DeleteBtn from '../../buttons/DeleteBtn';

const View = ({ obj, toParent }) => {
  const canvasRef = useRef(null);
  const wrapperRef = useRef(null);

  // future: set elsewhere...
  // const [angle, setAngle] = useState(0);
  const angle = 0;

  const { canvas, setCanvas, bkgd, msg, selected } = useCanvas(canvasRef, wrapperRef, obj.url, angle);
  
  const [types, setTypes] = useState([]);
  const [fill, setFill] = useState();
  const [text, setText] = useState();
  const [border, setBorder] = useState();

  useEffect(() => {
    if(!canvas || emptyObj(obj)) return;

    // lklklk future here: add layers, etc.?

    if(!emptyObj(obj?.markups)) {
      canvas.loadFromJSON(obj.markups);
      canvas.getObjects().forEach((obj) => {
        if(obj.type==='i-text') {
          obj.hiddenTextareaContainer = canvas.lowerCanvasEl.parentNode;
        }
      });
    }
  }, [canvas, obj]);

  const updateMarkups = useCallback(() => {
    let markups = canvas.toObject(['id','appId','eamsLayer','padding','border','borderWidth','_controlsVisibility']);
    delete markups.backgroundImage;
    if(toParent) toParent({ type: 'markup', value: markups});
  }, [canvas, toParent])

  useEffect(() => {
    if(!msg) return;
    if(msg.type==='canvas') updateMarkups();
  }, [msg, updateMarkups])

  useEffect(() => {
    let fills = [];
    let texts = [];
    let types = [];
    let borders = [];

    for(const obj of selected) {
      types.push(obj.type);
      if(obj.type==='i-text') {
        fills.push(obj.backgroundColor);
        texts.push(obj.fill);
      } else {
        fills.push(obj.fill);
        borders.push(obj.stroke);
      }
    }

    let uniqueTypes = [...new Set([...types])];
    let uniqueFills = [...new Set([...fills])];
    let uniqueTexts = [...new Set([...texts])];
    let uniqueBorders = [...new Set([...borders])];

    setTypes(uniqueTypes);
    setFill(uniqueFills.length === 1 ? uniqueFills[0] : '#ccc');
    setText(uniqueTexts.length === 1 ? uniqueTexts[0] : '#000');
    setBorder(uniqueBorders.length === 1 ? uniqueBorders[0] : '#ccc');
  }, [selected])

  const fromChild = ({ type, name, value }) => {
    // console.log(type, name, value)
    if(type==='color-picker') {
      for(const obj of selected) {
        console.log(obj);
        if(obj.type==='i-text' && name==='fill') name = 'backgroundColor';
        if(obj.type==='i-text' && name==='text') name = 'fill';
        obj.set(name, value);
      }

    } else if(type==='delete confirmed') {
      for(const obj of selected) {
        canvas.remove(obj);
      }

      canvas.discardActiveObject();
    }

    canvas.requestRenderAll();
    updateMarkups();
  }
  

  return (
    <div className="h-100 position-relative">
      <div className="w-100 h-100" ref={wrapperRef}>
        <canvas ref={canvasRef} style={{border: '1px solid #ccc'}} />
      </div>

      <div className="position-absolute  bg-white rounded shadow-sm" style={{ left: 6, top: 6 }}>
        { selected.length===0 && (
          <Fragment>
            {/* <Drawing setCanvas={setCanvas} />
            <hr className="my-0" /> */}
            <Text canvas={canvas} update={updateMarkups} />
            <hr className="my-0" />
            <Rectangle canvas={canvas} update={updateMarkups} />
            <hr className="my-0" />
            <Circle canvas={canvas} update={updateMarkups} />
            <hr className="my-0" />
          </Fragment>
        )}
        { selected.length > 0 && (
          <Fragment>
            <ColorPicker name="fill" value={fill} toParent={fromChild} />
            { types.includes('i-text') && <ColorPicker name="text" value={text} toParent={fromChild} /> }
            { !types.includes('i-text') && <ColorPicker name="stroke" value={border} toParent={fromChild} /> }
            
            <hr className="my-0" />
            <DeleteBtn toParent={fromChild} wrapper="d-block border-bottom pt-1" styles="btn btn-sm mb-1" size="16" />
          </Fragment>
        )}
        
        <Resize wrapperRef={wrapperRef} canvas={canvas} bkgd={bkgd} />
      </div>
    </div>
  );
};

export default View;
