export const plans = [
  { id: 1, value: 1, label: 'Level 1', name: 'Level 1', appId: 'o4kxexc1jHH37a2OYmO5C', url: 'https://sfo-dcgis02.flysfo.com/arcgis/rest/services/CustomServices/MustardSquareLevel1/MapServer', status: 'A'},
  { id: 2, checked: true, value: 2, label: 'Level 2', name: 'Level 2', appId: 'kAMUxdCyHK2Fn9ePJeAcA', url: 'https://sfo-dcgis02.flysfo.com/arcgis/rest/services/CustomServices/MustardSquareLevel2/MapServer', status: 'A'},
  { id: 3, value: 3, label: 'Level 3', name: 'Level 3', appId: 'b2etzRevbXs16j76wFFMJ', url: 'https://sfo-dcgis02.flysfo.com/arcgis/rest/services/CustomServices/MustardSquareLevel3/MapServer', status: 'A'},
  // { id: 4, name: 'Level 1', appId: 'o4kxexc1jHH37a2OYmO5C', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_1/MapServer'},
  // { id: 5, name: 'Level 2', appId: 'kAMUxdCyHK2Fn9ePJeAcA', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_2/MapServer'},
  // { id: 6, name: 'Level 3', appId: 'b2etzRevbXs16j76wFFMJ', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_3/MapServer'},
  { id: 4, value: 4, label: 'Level 4', name: 'Level 4', appId: '59lKwmXfay90u4SBuB3mL', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_4/MapServer', status: 'A'},
  { id: 5, value: 5, label: 'Level 5', name: 'Level 5', appId: 'HcmjrVvztdzhisk3tNbPL', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_5/MapServer', status: 'A'},
  { id: 6, value: 6, label: 'Level 6', name: 'Level 6', appId: '0aZZdUQ6m5lDmBBE95bft', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_6/MapServer', status: 'A'},
  { id: 7, value: 7, label: 'Level 7', name: 'Level 7', appId: 'T5QevgsQfYbqQCp2RynEy', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_7/MapServer', status: 'A'},
  { id: 8, value: 8, label: 'Level 8', name: 'Level 8', appId: 'tqngYFnofaWMccNEKIWcy', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_8/MapServer', status: 'A'},
  { id: 9, value: 9, label: 'Level 9', name: 'Level 9', appId: 'hIbYH6IFuwv9DrEKlygmf', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_9/MapServer', status: 'A'},
  { id: 10, value: 10, label: 'Level 10', name: 'Level 10', appId: 'XUDkQBKcefpG7N2Ndk1Bp', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_10/MapServer', status: 'A'},
  { id: 11, value: 11, label: 'Level Mezzanine', name: 'Level Mezzanine', appId: 'jWTsjmAilC6vZvkUmWVhr', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_Mezzanine/MapServer', status: 'A'},
  { id: 12, value: 12, label: 'Basement', name: 'Basement', appId: '42EuoLBnkIvJl6WPPoINh', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Basement/MapServer', status: 'A'},
  { id: 13, value: 13, label: 'AirTrain', name: 'AirTrain', appId: 'H2lfIxWTU2wxtRuibI6IC', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/AirTrain/MapServer', status: 'A'},
  { id: 14, value: 14, label: 'Roadway', name: 'Roadway', appId: 'ztvrvJh67QHfHAUSVsIaY', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/SFO_Base_Map/MapServer/14', status: 'A'},
  { id: 15, value: 15, label: 'Fence', name: 'Fence', appId: 'fWghnV4xABBTWkiYmV8SS', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/SFO_Base_Map/MapServer/5', status: 'A'},
  { id: 16, value: 16, label: 'Taxiway Elements ', name: 'Taxiway Elements', appId: '2psDuS5aspKQUF9iPZwGm', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/SFO_Base_Map/MapServer/13', status: 'A'},
  { id: 17, value: 17, label: 'Runway ', name: 'Runway', appId: 'Y9zqw6xLQnwfeBvS4TtDw', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/SFO_Base_Map/MapServer/12', status: 'A'},
  { id: 18, value: 18, label: 'SFO Base Map ', name: 'SFO Base Map', appId: 'Twj6NY8IbeOm6zXXe7yrS', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/SFO_Base_Map/MapServer', status: 'A'},
  { id: 19, value: 19, label: 'Testing', name: 'Testing', appId: 'abcd1234', url: 'https://services.arcgisonline.com/arcgis/rest/services/ESRI_Imagery_World_2D/MapServer', status: 'A'},
]