import React, { Fragment, useEffect, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import RotatingCaret from '../../buttons/RotatingCaret';
import { filterExists, addFilter, removeFilter } from '../../../utils/filters'

// this one is specific for the Filters to toggle some/all on

const View = ({ open, appId, title, detail, arr, field, filters, setFilters, children }) => {
  const [show, setShow] = useState(open);
  const toggleShow  = () => setShow((s) => !s);

  const selectAll = (e) => {
    e.target.blur();
    for(const item of arr) {
      if(!e.target.checked) 
        removeFilter(setFilters, item.value, field);
      else if(!filterExists(filters, item.value, field))
        addFilter(setFilters, item.value, field, (x) => x[field] === item.value);
    }
  }

  useEffect(() => {
    if(!appId || arr?.length===0) return;
    let selectAll = document.getElementById(`selectAll-${appId}`);
    if(!selectAll) return;
    selectAll.indeterminate = false;

    let all = arr.map(x => x.id);
    let some = filters.filter(x => x.group===field && all.includes(x.name));

    if(some.length > 0 && all.length === some.length)
      selectAll.checked = true;
    else if(some.length > 0)
      selectAll.indeterminate = true;
    else
      selectAll.checked = false;
  }, [appId, filters, arr, field])

  return (
    <Fragment>
      <div className="d-flex align-items-center me-2 pointer">
        { appId && (
          <input
            type="checkbox"
            className="flex-shrink-0 form-check-input ms-3"
            id={`selectAll-${appId}`}
            disabled={children.length === 0}
            onChange={selectAll}
          />
        )}
        <h6 className="flex-grow-1 text-truncate dropdown-header mb-0" title={title ? title : ''} onClick={toggleShow}>{title ? title : 'View'}</h6>
        <h6 className="flex-shrink-0 dropdown-header mb-0" onClick={toggleShow}>{detail ? `(${detail})` : ''}</h6>
        <div className="flex-shrink-0" onClick={toggleShow}>
          <RotatingCaret rotated={show} />
        </div>
      </div>
      <Collapse in={show}>
        <div>
          {children}
        </div>
      </Collapse>
    </Fragment>
  )
}

export default View;
