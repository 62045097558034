import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';

import Modal from 'react-bootstrap/Modal';
import { SiteContext } from '../../context/SiteContext';
// import { WorkerContext } from '../../context/WorkerContext';
import Header from '../../components/system/Header';
import Sync from './components/Sync';

import db from '../../utils/dexie-provider.js'
import { useLiveQuery } from 'dexie-react-hooks'
import { ArrowDownUp } from 'react-bootstrap-icons';

const View = (props) => {
  const redirect = props.redirect ? props.redirect : '/';
  const params = useParams();
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
  const { config, syncing } = useContext(SiteContext);
  // const { startWorker } = useContext(WorkerContext);

  const [dirtyQty, setDirtyQty] = useState(null);
  const dirtyTasks  = useLiveQuery(() => db.tasks.where('dirty').equals(1).count());
  const dirtyPlots  = useLiveQuery(() => db.plots.where('dirty').equals(1).count());
  const dirtyPlotTasks = useLiveQuery(() => db.plotTasks.where('dirty').equals(1).count());
  const dirtyPhotos = useLiveQuery(() => db.photos.where('dirty').equals(1).count());

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow((s) => !s);
  const handleCancel = () => setShow(false);

  useEffect(() => {
    // if any of these are undefined, exit out so we don't
    // mark dirtyQty as zero when the value just hasn't loaded
    if(dirtyTasks===undefined || dirtyPlots===undefined || dirtyPlotTasks===undefined || dirtyPhotos===undefined) return;

    let qty = dirtyTasks + dirtyPlots + dirtyPlotTasks + dirtyPhotos;
    setDirtyQty(qty);

    // // auto-sync when dirtyQty is > 5
    // if(online && qty >= 5) {
    //   (async () => {
    //     let token = await getAccessTokenSilently();
    //     startWorker({ type: 'sync', pieces: { direction: 'upload', type: 'sync', token } })
    //   })();
    // }
  }, [dirtyTasks, dirtyPlots, dirtyPlotTasks, dirtyPhotos])

  // lklklk figure out how to route this differently...?
  if(!isLoading && !isAuthenticated) return (
    <div style={{top:0, left: 0, height: '100vh',width:'100vw',zIndex:1999}} className="position-fixed bg-light d-flex flex-column align-items-center justify-content-center">
      <button className="d-block btn btn-outline-primary" onClick={loginWithRedirect}>Login</button>
    </div>
  );

  return (
    <Fragment>
      <Helmet>
        <title>Site Survey | {config.title}</title>
      </Helmet>
      {!params.planId && <Header />}
      <Outlet context={{ dirtyQty }} />

      { syncing > 0 && (
        <div className="position-fixed bg-white border shadow-sm rounded p-2 pointer" style={{bottom:15,right:15,zIndex:102}} onClick={toggleModal}>
          <div className="spinner-border text-secondary" style={{width:20,height:20}} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      { syncing === 0 && (
        <div className="position-fixed pointer" style={{bottom:16,left:16,zIndex:102}} onClick={toggleModal}>
          { dirtyQty > 0 && (<span className="badge rounded-pill text-bg-warning shadow-sm" style={{fontSize:'190%'}}>{dirtyQty}</span>)}
          { dirtyQty===0 && (<button className="btn btn-outline-primary bg-white border shadow-sm rounded py-2"><ArrowDownUp size={18} /></button>)}
        </div>
      )}

      <Modal show={show} fullscreen={true} onHide={handleCancel} backdrop={false} keyboard={true} scrollable>
        <Sync handleCancel={handleCancel} redirect={redirect} dirtyQty={dirtyQty} dirtyTasks={dirtyTasks} dirtyPlots={dirtyPlots} dirtyPlotTasks={dirtyPlotTasks} dirtyPhotos={dirtyPhotos} />
      </Modal>
    </Fragment>
  )
}

export default View;
