import React, { Fragment, useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Tools as ToolsIcon } from 'react-bootstrap-icons'

import { emptyObj } from '../../../utils/functions';
import Collapsed from '../../../components/system/Collapsed';

// lklklk future:
// quick links to reset to original/zero?
// options to apply to multiple plans?
// ability for them to type values in? (contenteditable=true?)
// also need to fix the plan thing so a new phase will carry similar styling?

const View = (props) => {
  const { map, interaction, setPhasePlans, setMode, fadeBkgd, setFadeBkgd, offset, setOffset, sizing, setSizing, toParent } = props;
  const [show, setShow] = useState(false);
  const [sort, setSort] = useState('');
  const [start, setStart] = useState(1);
  const [skip, setSkip] = useState(0);
  const [reorder, setReorder] = useState([
    ['C',1,1,'table-danger'],
    ['A',1.1,2,'table-info'],
    ['C',1.2,3,'table-danger'],
    ['E',3,4,'table-secondary'],
    ['A',5,5,'table-info'],
    ['B',6,6,'table-warning'],
    ['A',6,7,'table-info'],
    ['D',7,8,'table-success'],
    ['A',13,9,'table-info'],
    ['C',22,10,'table-danger'],
  ])

  useEffect(() => {
    setReorder((prev) => {
      let arr = [...prev];

      if(sort==='name')
        arr.sort((a,b) => (a[1] > b[1]) ? 1 : ((b[1] > a[1]) ? -1 : 0))
      else
        arr.sort((a, b)=> (a[0].localeCompare(b[0], 'en', { numeric: true })))

      let type;
      let n = start;
      for (const line of arr) {
        if(sort==='type' && type!==line[0])
          n = start;

        line[2] = n;
        n = n+skip+1;
        type = line[0];
      }
      return arr;
    })
  }, [sort, start, skip])

  const startTool = () => {
    interaction.reorderStart = start;
    interaction.reorderSkip = skip;
    setMode(3);
  }
  
  const closePanel = () => {
    setFadeBkgd(map.styling?.fadeBkgd ? map.styling?.fadeBkgd : 1);
    setSizing({
      label: map.styling?.label ? map.styling?.label : 2,
      locator: map.styling?.locator ? map.styling?.locator : 2,
      line: map.styling?.line ? map.styling?.line : 2
    })
    setOffset({
      x: map.styling?.x ? map.styling.x : 0,
      y: map.styling?.y ? map.styling.y : 0,
      scale: map.styling?.scale ? map.styling.scale : 1
    })
    setSort('');
    setStart(1);
    setSkip(0);
    setShow(false);
  }

  const saveChanges = async () => {
    let updates = [];
    let styling = {...map.styling, ...sizing, ...offset};
    styling.fadeBkgd = fadeBkgd;

    // delete defaults so we aren't unnecessarily storing
    if(styling.fadeBkgd!==map.styling.fadeBkgd && styling.fadeBkgd === 1) delete styling.fadeBkgd;
    if(styling.label!==map.styling.label && styling.label === 2) delete styling.label;
    if(styling.locator!==map.styling.locator && styling.locator === 2) delete styling.locator;
    if(styling.line!==map.styling.line && styling.line === 2) delete styling.line;
    if(styling.x!==map.styling.x && styling.x === 0) delete styling.x;
    if(styling.y!==map.styling.y && styling.y === 0) delete styling.y;
    if(styling.scale!==map.styling.scale && styling.scale === 1) delete styling.scale;

    if(!emptyObj(styling)) {
      await setPhasePlans(prev => {
        let arr = [...prev];
        let idx = arr.findIndex(x => x.id===map.id);
        arr[idx].styling = styling;
        return arr;
      });

      updates.push({ type: 'map styled', value: { appId: map.appId, id: map.id, styling }});
    }

    if(sort!=='' || start > 1 || skip > 0) {
      updates.push({ type: 'bulk reorder', value: { planId: map.planId, sort, start, skip }});
    }

    if(updates.length > 0)
      toParent({ type: 'map tools', value: updates });

    closePanel();
  }

  return (
    <Fragment>
      <OverlayTrigger placement="top" overlay={<Tooltip id="map-tools">Change Map Tools/Settings</Tooltip>}>
        <button className="btn bg-white rounded border shadow-sm ms-1" type="button" onClick={() => setShow(true)}>
          <ToolsIcon size={20} />
        </button>
      </OverlayTrigger>

      <Offcanvas show={show} onHide={closePanel} placement="end" scroll={true} keyboard={true} backdrop={false} className="shadow">
        <div className="d-flex flex-column h-100 overflow-hidden">
          <Offcanvas.Header className="bg-body" closeButton>
            <Offcanvas.Title>Map Tools & Settings</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="bg-body pt-0 flex-grow-1 overflow-scroll">
            <Collapsed title="Display/Sizing">
              <div className="mt-2 mb-5">
                <div className="mb-3">
                  <label className="form-label">Locator Size {sizing.locator}</label>
                  <input type="range" defaultValue={sizing.locator} className="slider" min="0" max="8" step="0.01" onChange={(e) => setSizing(prev => ({...prev, locator: Number(e.target.value)}))} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Label Size {sizing.label}</label>
                  <input type="range" defaultValue={sizing.label} className="slider" min="0" max="8" step="0.01" onChange={(e) => setSizing(prev => ({...prev, label: Number(e.target.value)}))} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Line Size {sizing.line}</label>
                  <input type="range" defaultValue={sizing.line} className="slider" min="0" max="8" step="0.01" onChange={(e) => setSizing(prev => ({...prev, line: Number(e.target.value)}))} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Background Opacity {fadeBkgd}</label>
                  <input type="range" defaultValue={fadeBkgd} className="slider" min="0" max="1" step="0.01" onChange={(e) => setFadeBkgd(Number(e.target.value))} />
                </div>
              </div>
            </Collapsed>

            <Collapsed title="Placement/Bulk Move">
              <div className="mt-2 mb-5">
                <div className="mb-3">
                  <label className="form-label">Move left/right {offset.x}</label>
                  <input type="range" defaultValue={offset.x} className="slider" min="-2000" max="2000" step="1" onChange={(e) => setOffset(prev => ({...prev, x: Number(e.target.value)}))} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Move up/down {offset.y}</label>
                  <input type="range" defaultValue={offset.y} className="slider" min="-2000" max="2000" step="1" onChange={(e) => setOffset(prev => ({...prev, y: Number(e.target.value)}))} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Scale {offset.scale}</label>
                  <input type="range" defaultValue={offset.scale} className="slider" min="0" max="5" step="0.01" onChange={(e) => setOffset(prev => ({...prev, scale: Number(e.target.value)}))} />
                </div>
              </div>
            </Collapsed>

            <Collapsed title="Reordering/Renumbering">
              <div className="mt-2">
                <p className="mb-2">Reorder the plots sequentially using the options below.</p>
                <div className="row mb-3">
                  <div className="col-sm-6">
                    <label className="form-label mb-0">Sort By</label>
                    <select className="form-select" onChange={(e) => setSort(e.target.value)} aria-label="Sort">
                      <option value="">Select One</option>
                      <option value="name">Original Name</option>
                      <option value="type">Type</option>
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <label className="form-label mb-0">Start #</label>
                    <input type="number" className="form-control" defaultValue={start} onChange={(e) => setStart(Number(e.target.value))} />
                  </div>
                  <div className="col-sm-3">
                    <label className="form-label mb-0">Skip</label>
                    <input type="number" min="0" className="form-control" defaultValue={skip} onChange={(e) => setSkip(Number(e.target.value))} />
                  </div>
                </div>
                { sort==='type' && (
                  <p className="small text-danger"><b>Note:</b> If you bulk sort by type, you will reset the flow of the plot types across the plan. You will need to use the manual reorder function below to set the plots sequentially.</p>
                )}
                { sort!=='' && (
                  <div className="bg-white p-2 pb-1 mb-3">
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th width="33%">Type</th>
                          <th width="33%">Original</th>
                          <th width="33%">New</th>
                        </tr>
                      </thead>
                      <tbody>
                        { reorder.map((x, idx) => (
                          <tr key={idx}>
                            <td>{x[0]}</td>
                            <td>{x[1]}</td>
                            <td className={sort==='name' ? 'table-warning' : x[3]}>{x[2]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <p className="mb-2">If desired, you can also manually reorder/renumber plot names. This tool allows you to hover over plots to reorder them in a custom sequence.</p>
                <button className="btn btn-outline-dark" role="button" onClick={startTool}>Start Tool</button>
              </div>
            </Collapsed>
          </Offcanvas.Body>
          <div className="bg-body text-end p-3">
            <button className="btn btn-sm btn-outline-dark me-2" onClick={closePanel}>Cancel</button>
            <button className="btn btn-sm btn-success" onClick={saveChanges}>Save Changes</button>
          </div>
        </div>
      </Offcanvas>
    </Fragment>
  )
}

export default View;
