import React, { useEffect, useState } from 'react';
import DeleteBtn from '../../../buttons/DeleteBtn';

const View = (props) => {
  const { obj, options, parent, update, toggle } = props;
  const [value, setValue] = useState('viewer'); // single for dropdown
  const [selected, setSelected] = useState([]); // array for toggle/checks
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    let access = parent?.access;
    if(toggle) {
      let selected = access?.filter(x => x.type==='group' && x.id===obj?.id).map(x => x.role);
      setSelected(selected);

      if(selected?.length === options.length)
        setAllChecked(true);
      else
        setAllChecked(false);

      var checkbox = document.getElementById(`${obj.value}-toggleAll`);
      if(checkbox) {
        if(selected?.length > 0 && selected.length < options.length)
          checkbox.indeterminate = true;
        else
          checkbox.indeterminate = false;
      }
    } else {
      let found = access?.find(x => x.type==='group' && x.id===obj?.id);
      if(found) setValue(found.role ? found.role : '');
    }
  }, [obj, parent, options, toggle])

  const change = (e) => {
    e.target.blur();
    setValue(e.target.value);
    let arr = [...parent.access];
    let idx = arr.findIndex(x => x.type==='group' && x.id===obj.id);
    if(idx > -1) {
      arr[idx].role = e.target.value;
      update(arr);
    }
  }

  const toggleChecked = (e) => {
    e.target.blur();
    // remove all instances
    let arr = [...parent.access].filter(x => x.type!=='group' && x.id!==obj.id);
    console.log(arr);

    if(e.target.checked) {
      const roles = [...new Set(options.map(x => x.value))];
      setSelected(roles);
      for(const role of roles) {
        arr.push({ type: 'group', id: obj.value, role });
      }

    } else {
      setSelected([]);
    }

    update(arr);

  }

  const checked = (e) => {
    e.target.blur();
    console.log(e.target.value);
    let arr = [...parent.access];
    let items = selected ? [...selected] : [];
    if(e.target.checked) {
      items.push(e.target.value);
      arr.push({ type: 'group', id: obj.value, role: e.target.value });
    } else {
      let arrIdx = arr.findIndex(x => x.type==='group' && x.id===obj.value && x.role===e.target.value);
      if(arrIdx > -1) arr.splice(arrIdx, 1);      

      let idx = items.indexOf(e.target.value);
      items.splice(idx, 1);
    }

    setSelected(items);
    update(arr);
  }

  const fromChild = (data) => {
    const { type } = data;
    // console.clear();
    if(type === 'delete confirmed') {
      // parent setter can either be array OR object (where we don't need to search)
      let filtered = parent.access?.filter(x => !(x.type==='group' && x.id===obj.id));
      update(filtered);
    }
  }

  return (
    <div className={`d-flex ${toggle ? '' : 'mb-1'}`}>
      <p className="flex-grow-1 py-2 me-2 mb-0">{obj.label}</p>
      {!toggle && (
        <select
          style={{width: 150}}
          className="form-select form-select-sm me-2"
          value={value}
          onChange={change}>
          {options?.map((obj) => (
            <option key={`share-access-${obj.value}`} value={obj.value}>{obj.label}</option>
          ))}
        </select>
      )}

      { toggle && (
        <div className="d-flex mt-1">
          <div className="d-flex" style={{width:45}}>
            <div className="form-check ms-auto">
              <input
                type="checkbox"
                className="border form-check-input"
                id={`${obj.value}-toggleAll`}
                checked={allChecked}
                onChange={toggleChecked}
                />
            </div>
          </div>

          { options?.filter(x => x.toggle).map(opt => (
            <div key={`group-sel-${obj.value}-${opt.value}`} className="d-flex" style={{width:45}}>
              <div className="form-check ms-auto">
                <input 
                  type="checkbox"
                  value={opt.value}
                  id={`group-sel-${obj.value}-${opt.value}`}
                  checked={selected?.includes(opt.value) ? true : false}
                  className="border form-check-input"
                  onChange={checked}
                  />
              </div>
            </div>
          ))}
        </div>
      )}


      <DeleteBtn toParent={fromChild} wrapper="flex-shrink-0 pt-1" styles="btn btn-xs me-1" size="16" />
    </div>
  )
}

export default View;
