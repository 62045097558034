import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom'
import { UserContext } from '../../../context/UserContext';
import { useAxios } from '../../../hooks/useAxios';
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'

import  { nanoid } from 'nanoid';
import ConfirmBtn from '../../../components/buttons/ConfirmBtn';

// lklklk future:
// update table to be editable to show... options e.g. for floors or other data missing?
// modal to show which types would be added, and the ability to enter the descriptions too?
// possibly check to see if the tag/name already exists, and overwrite? or warn?
// same note but for exact X/Y coords?
// maybe check number of line breaks in arr1 to auto-create those fields so we can see them?

import Dropzone from '../../../components/system/dropzone/Dropzone'
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ExcelJS from 'exceljs';

import { emptyObj } from '../../../utils/functions'
import { checkHeaders, reviewPlots } from './utils/plots'
import { formatSA } from './utils/templates/sa';

const View = () => {
  const navigate = useNavigate();
  const { serverCall } = useAxios();
  const { project, setProject, phases, activePhase, plans, phasePlans, plots, setPlots, libraries, types, typeVars, setTypes } = useOutletContext();
  const { userDetails, setProjects } = useContext(UserContext);

  const [data, setData] = useState();
  const [importType, setImportType] = useState('sa');
  const importRef = useRef('sa');
  const [arrSplit, setArrSplit] = useState();
  const [qtys, setQtys] = useState({});
  const [summary, setSummary] = useState([]);
  const [error, setError] = useState(false);
  const closeModal = () => navigate(-1);

  useEffect(() => {
    importRef.current = importType;
  }, [importType])

  useEffect(() => {
    if(!data) return;
    (async () => {
      console.clear();
      try {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(data);

        let sheets = [];
        if(importRef.current==='sa') {
          sheets = await formatSA({ workbook, project, phases, activePhase, plans, phasePlans, types, typeVars, activePhase, userDetails });
          if(sheets.includes('error-')) throw sheets;
        } else {
          console.log('Not accpeted');
        }
        setSummary(sheets);

        let typeQty = sheets[0].newTypes.length;
        let plotQty = sheets[0].newPlots.length;
        setQtys({plotQty, typeQty});

      // let plotQty  = Object.values(summaries).reduce((idx, obj) => idx + obj.newPlots.filter(x => x.planId)?.length, 0);
      // let skipQty  = Object.values(summaries).reduce((idx, obj) => idx + obj.newPlots.filter(x => !x.planId)?.length, 0);
      // let typeQty  = Object.values(summaries).reduce((idx, obj) => idx + obj.newTypes.length, 0);
      // let foundQty = Object.values(summaries).reduce((idx, obj) => idx + obj.tagsFound, 0);
      // setQtys({plotQty, skipQty, typeQty, foundQty});
      // // console.log(plotQty, skipQty, typeQty);
      

      // setSummary(summaries);
      // // console.log(summaries);


      } catch(error) {
        console.log(error);
        if(error.includes('error-'))
          setError(error);
        else if(error.message?.includes('reading \'comments\''))
          setError('resave');
        else
          setError(true);
      }
    })();
  }, [data, arrSplit, activePhase, libraries, phasePlans, plans, plots, project, types, typeVars, userDetails])

  const fromChild = async ({ files }) => {
    if(files.length === 0) return;
    setError(false); // reset error messages

    var reader = new FileReader();
    reader.onload =  async () => setData(reader.result);
    reader.readAsArrayBuffer(files[0]);
  }

  const finish = async () => {
    console.clear();
    let allPlots = summary[0].newPlots;
    let allTypes = summary[0].newTypes;
    // for(const sheet of summary) {
    //   let newPlots = sheet.newPlots.filter(x => x.planId);
    //   allPlots = [...allPlots, ...newPlots];
    //   allTypes = [...allTypes, ...sheet.newTypes];
    // }

    // allPlots = allPlots.slice(0, 15);

    let data = {
      projectId: project.id,
      plots: allPlots,
      types: allTypes,
      updatedBy: userDetails.id,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss')
    }
    
    // console.log(data);
    // return;
    
    let res = await serverCall({ method: 'POST', data, url: `/mapping/plots/bulk-import`, eamsAuth0: true });
    if(res.status!==200) return alert('Error bulk importing. Contact support.');
    if(res.data.types) setTypes((prev) => [...prev, ...res.data.types]);
    if(res.data.plots) setPlots((prev) => [...prev, ...res.data.plots]);
    if(res.data.project) {
      setProjects((prev) => {
        let arr = [...prev];
        let idx = arr.findIndex(x => x.id===res.data.project.id);
        if(idx > -1) arr[idx] = res.data.project;
        return arr;
      })
    }

    closeModal();
  }

  return (
    <Fragment>
      <Helmet>
        <style>{`td.format { white-space: pre }`}</style>
      </Helmet>
      <Modal show={true} onHide={closeModal} fullscreen={true} backdrop={true} keyboard={true} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Bulk Import: Plots</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-4">
            <p>Use this tool to import and export data using Excel spreadsheets.</p>
            <div className="btn-group mt-0 mb-2" role="group" aria-label="Import Type">
              <input type="radio" className="btn-check" id="btnSimple" autoComplete="off" checked={!importType} onChange={() => setImportType(null) } />
              <label className="btn btn-outline-primary" htmlFor="btnSimple">Simple</label>
              <input type="radio" className="btn-check" id="btnSA" autoComplete="off" checked={importType==='sa'} onChange={() => setImportType('sa') } />
              <label className="btn btn-outline-primary" htmlFor="btnSA">SignAgent</label>
            </div>
            <Dropzone
              msg="Drag & drop your formatted Excel sheet or click to select file"
              excludeSave={true}
              fileTypes={{
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
              }}
              toParent={fromChild}
            />

            { (!error && summary.length > 0) && (
              <Fragment>
                <hr />
                <label>Split messages?</label><br />
                <div className="btn-group mt-1" role="group" aria-label="Array Delimiter">
                  <input type="radio" className="btn-check" id="btnNone" autoComplete="off" checked={!arrSplit} onChange={() => setArrSplit(null) } />
                  <label className="btn btn-outline-primary" htmlFor="btnNone">None</label>
                  <input type="radio" className="btn-check" id="btnSlash" autoComplete="off" checked={arrSplit===' / '} onChange={() => setArrSplit(' / ') } />
                  <label className="btn btn-outline-primary" htmlFor="btnSlash">/</label>
                </div>

                <h4 className="mt-5">Summary</h4>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Types to Add</td>
                      <td>{qtys?.typeQty ? qtys.typeQty : 0}</td>
                    </tr>
                    <tr>
                      <td>Plots to Add</td>
                      <td>{qtys?.plotQty ? qtys.plotQty : 0}</td>
                    </tr>
                    { qtys?.foundQty > 0 && (
                      <tr>
                        <td>Found Tags</td>
                        <td>{qtys.foundQty} ({Math.round(((qtys.foundQty) / qtys.plotQty) * 100)}%)</td>
                      </tr>
                    )}
                    { qtys?.skipQty > 0 && (
                      <tr className="text-danger fw-bold">
                        <td>Plots Skipped</td>
                        <td>{qtys.skipQty}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <ConfirmBtn styles="btn-outline-success" toParent={finish}
                  warning={ qtys?.skipQty > 0 ? `Are you sure you want to import these items? PLEASE NOTE: ${qtys.skipQty} rows will be skipped/not imported.` : 'Are you sure you want to import these items?'}>
                  Finish Import
                </ConfirmBtn>
              </Fragment>
            )}
          </div>
          <div className="col-sm-8">
            { error && (
              <Fragment>
                <h4 className="text-danger">Error importing</h4>
                { error!=='resave' && error!=='error-sa-overview' && error!=='error-missing-plan' && <p className="text-danger">There was an issue with your import.<br />Please check that the columns match the templates and try again.</p>}
                { error==='resave' && <p className="text-danger">We are unable to open your file. Please open and save-as in Excel and try to upload again.</p>}
                { error==='error-missing-plan' && <p className="text-danger">You are missing one or more plans. Please upload all plans and try again.</p>}
                { error==='error-sa-overview' && (
                  <Fragment>
                    <p className="text-danger">The Overview tab of this export is missing or has columns out of order.</p>
                    <p className="text-danger">Please upload the Excel file that comes direclty from SignAgent. If you're sure it matches, please <a href="mailto:support@wayfindit.com?subject=Wayfindit: Template Import Error (SA)">email support</a> and attach the file so we can review.</p>
                  </Fragment>
                )}
              </Fragment>
            )}
            { (!error && summary.length > 0) && (
              <Fragment>
{/* <Tabs defaultActiveKey={data[0].uid}>
                { summary.map(sheet => (
                  <Tab key={sheet.uid} eventKey={sheet.uid} title={sheet.name}> */}
                    <div className="table-responsive mb-5" style={{maxHeight:470}}>
                      <table className="table">
                        {/* <thead>
                          <tr>
                            <th>Row</th>
                            <th>Tag Found</th>
                            {sheet.headers.map((col, idx) => <th key={`th-${idx}`} style={{minWidth:100, maxWidth:200}}>{col}</th>)}
                          </tr>
                        </thead> */}
                        <tbody>
                          {summary[0].newPlots.map((col, idx) => (
                            <tr key={`row-${idx}`}>
                              <td>{idx+1}</td>
                              <td>{col.tagFound ? 'Yes' : 'No'}</td>
                              <td>{col.planId}</td>
                              <td>{col.name}</td>
                              <td>{col.typeCode}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  {/* </Tab>
                ))}
              </Tabs> */}
              </Fragment>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-dark ms-auto me-1" type="button" onClick={closeModal}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
    </Fragment>
  )
}

export default View;
