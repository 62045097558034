import React, { forwardRef } from 'react'
import { Controller } from 'react-hook-form'

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {

  return (
    <Controller
      name={item.field}
      control={pieces.control}
      rules={{ required: item.required }}
      render={({ field }) => {
        // this is the checkbox's value stored in db
        if(!item.value) item.value = true;

        // this is the value passed from setVals/reset
        if(!field.value) field.value = false;

        return (
          <div className="clearfix">
            <div className="my-2 form-check">
              <input {...field}
                ref={field.ref}
                type="checkbox"
                name={item.field}
                id={`checkbox-${item.field}`}
                checked={item.value === field.value}
                className="form-check-input"
                onChange={(e) => {
                  if(!item.value) item.value = true;
                  let val = e.target.checked ? item.value : null;
                  pieces.setValue(`${item.field}`, val);
                  if(pieces.toParent) pieces.toParent({ type: 'checkbox', name: item.field, value: val });
                }}
                disabled={item.disabled} />
              {item.label && (<label className="form-check-label" htmlFor={`checkbox-${item.field}`}>{item.label}</label>)}
              {item.note && (<p className="mb-1 text-muted fst-italic small">{item.note}</p>)}
              {pieces.errors[item.field] && <p className="mt-0 error">{item.errorMsg}</p>}
            </div>
          </div>
        )
      }}
    />
  )
});

export default Field;
