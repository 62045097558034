import React, { Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet'

import Admin from '../../dropdowns/Admin';
import { SiteContext } from '../../../context/SiteContext';

const View = (props) => {
  const navigate = useNavigate();
  const { hostname } = useContext(SiteContext);
  const { user, logout, isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <Fragment>
      <Helmet>
        <style>{` body { padding-top:4rem; } `}</style>
      </Helmet>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
        <div className="container-fluid">
          <div className="navbar-brand me-0">
            <img src="https://wayfindit.s3-us-west-2.amazonaws.com/styles/wayfindit-white.png" className="pointer" alt="Wayfindit"  onClick={() => navigate('/')} />
          </div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbar">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
              { isAuthenticated && (
                <Fragment>
                  <li className="nav-item dropdown ms-2">
                    <span className="nav-link dropdown-toggle pointer" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img alt={user?.name} src={user?.picture} className="avatar" />
                    </span>
                    <ul className="dropdown-menu dropdown-menu-md-end" aria-labelledby="navbarDropdown">
                      <Admin />
                      <li className="pointer dropdown-item" onClick={() => {
                        logout({ returnTo: window.location.origin })
                      }}>Logout</li>
                      <li><hr className="dropdown-divider" /></li>
                      <li><p className="dropdown-item small mb-0 text-muted">Version 7.10</p></li>
                    </ul>
                  </li>
                </Fragment>
              )}

              { !isAuthenticated && (
                <li className="nav-item ms-2 pointer" onClick={loginWithRedirect}>
                  <span className="nav-link">Login</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </Fragment>
  )
}

export default View;
