import React from 'react';
import { Fonts as Icon } from 'react-bootstrap-icons';
import { fabric } from 'fabric';

const View = ({ canvas, update }) => {

  const add = () => {
    const iText = new fabric.IText("Lorem ipsum\namet sit dolor", { 
      fontFamily: 'Helvetica', 
      backgroundColor: '#000000',
      fill: '#ffffff',
      lockSkewingX: true,
      lockSkewingY: true,
      lineHeight: 0.95,
      // border: 'purple',
      // borderWidth: 3,
      left: 300, 
      top: 300
    });

    iText.setControlsVisibility({ mb: false, ml: false, mr: false, mt: false });
    iText.hiddenTextareaContainer = canvas.lowerCanvasEl.parentNode;
    canvas.add(iText);
    canvas.setActiveObject(iText);

    // canvas.centerObject(iText);
    update();
  }

  return (
    <button className="btn btn-sm" onClick={add}>
      <Icon size={18} />
    </button>
  )
}

export default View;
