import React from 'react';
import { Circle as Icon } from 'react-bootstrap-icons';
import { fabric } from 'fabric';

const View = ({ canvas, update }) => {

  const add = () => {
    const shape = new fabric.Circle({ 
      fill: '#000',
      stroke: '#ccc',
      strokeWidth: 6,
      left: 300, 
      top: 300,
      radius: 50
    });

    canvas.add(shape);
    canvas.setActiveObject(shape);
    update();
  }

  return (
    <button className="btn btn-sm" onClick={add}>
      <Icon size={16} />
    </button>
  )
}

export default View;
