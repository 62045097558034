import React, { Fragment, useEffect, useState } from 'react';
import { makeSvg } from './_utils/generate-svg.js';

const View = (props) => {
  const { url, content, plot, type, symbols } = props;

  const [img, setImg] = useState();

  useEffect(() => {
    setImg(null);
    if(!url) return;

    (async () => {
      try {
        let blob = await fetch(url, { mode: 'cors' }).then(res => res.blob());
        let reader = new FileReader();
        reader.readAsText(blob);

        reader.onload = async() => {
          let lktest = await makeSvg({ template: reader.result, message: content, plot, type, symbols });
          setImg(lktest);
        }
      
        reader.onerror = function() {
          console.log(reader.error);
        };


      } catch (error) {
        console.log('There was an error', error);
      }
    })();

  }, [url, content])

  if(!url) return null;

  if(!img) return (
    <div className="text-center py-3">
      <div className="spinner-border spinner-border-sm me-2" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  return <img src={img} className="img-fluid border mb-2" alt="Generated Template Preview" />;
}

export default View;
