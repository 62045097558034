import React, { Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { UserContext } from '../../../context/UserContext';
import { useAxios } from '../../../hooks/useAxios';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';

import { styles } from './utils/styles';
import { formatFile } from './utils/format-data';
import { uploadFile } from './utils/upload-file';

import Accepted from './components/Accepted';
import Rejected from './components/Rejected';
import Complete from './components/Complete';

// how to use:
// we are pulling the bucket from env var instead of messing with it

// future:
// ability to update messages etc.

// <Dropzone
//   folder="upload/"
//   prefix="file-upload"
//   fileTypes={{
//     'image/jpg': [],
//     'image/jpeg': [],
//     'image/png': [],
//     'application/pdf': []
//   }}
//   toParent={fromChild}
// />

const View = (props) => {
  const { prefix, fileTypes, excludeSave, toParent } = props;
  const msg = props.msg ? props.msg : 'Drag & drop some files here, or click to select files';
  const { userDetails } = useContext(UserContext);
  const folder = props.folder ? props.folder : '/';
  const { serverCall } = useAxios();
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive, fileRejections } = useDropzone({
    accept: fileTypes,
    onDrop: useCallback(acceptedFiles => {
      if(excludeSave && toParent)
        toParent({ type: 'file captured', files: acceptedFiles})
      else
        handleFiles(userDetails.id, acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.id])
  });

  const [uploads, setUploads] = useState({});

  const [allFiles, setAllFiles] = useState([]);

  const style = useMemo(() => ({
    ...styles.base,
    ...(isFocused ? styles.focused : {}),
    ...(isDragAccept ? styles.accept : {}),
    ...(isDragReject ? styles.reject : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const handleFiles = async (userId, files) => {
    for (const file of files) {
      let appId = nanoid();
      let fileData = await formatFile(userDetails.id, appId, file, folder, prefix);

      fileData.createdBy = userId;
      fileData.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');

      setUploads((prev) => {
        const updated = { ...prev };
        updated[appId] = fileData;
        return updated;
      });

      let url  = await serverCall({ method: 'POST', data: fileData, url: '/system/files/presign/upload', eamsAuth0: true });
      let res = await uploadFile({ appId, url: url.data, file, setUploads });
      if(res.status === 'complete') {
        setAllFiles((prev) => {
          const updated = [...prev];
          updated.push(fileData);
          return updated;
        });

        setUploads((prev) => {
          const updated = { ...prev };
          delete updated[appId];
          return updated;
        });

        if(toParent) toParent({ type: 'file uploaded', value: fileData })
      }
    }
  }

  return (
    <Fragment>
      <div {...getRootProps({ style, className: 'dropzone pointer' })}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p className="mb-0 text-center">Drop the files here ...</p> :
            <p className="mb-0 text-center">{msg}</p>
        }
      </div>

      <Complete files={allFiles} />
      <Accepted files={uploads} />
      <Rejected files={fileRejections} />
    </Fragment>
  )
}

export default View;
