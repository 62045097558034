import db from '../../../utils/dexie-provider.js'

export const deleteDb = (redirect) => {
  db.delete().then(() => {
    console.log("Database successfully deleted");
    window.location.href = redirect;
  }).catch((err) => {
    alert("Could not delete database. Contact support.");
  });
}

export const deleteUnsynced = () => {
  return new Promise(async(resolve) => {
    // console.clear();
    let plots = await db.plots.where({ dirty: 1 }).toArray();
    let plotTasks = await db.plotTasks.where({ dirty: 1 }).toArray();
    let photos = await db.photos.where({ dirty: 1 }).toArray();
    // console.log(plots, plotTasks, photos);
  
    for(const plot of plots) {
      await db.plots.update([plot.appId, plot.phaseId], {dirty: null});
    }
  
    for(const plotTask of plotTasks) {
      await db.plotTasks.update([plotTask.plotAppId, plotTask.taskId], {dirty: null});
    }
  
    for(const photo of photos) {
      await db.photos.delete(photo.appId);
    }

    resolve(true);
  })
};