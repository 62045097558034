import rect from './shapes/rect';
import circle from './shapes/circle';
import triangle from './shapes/triangle';
import icon     from './shapes/icon';
import thumbnail from './shapes/thumbnail';

import archline from './shapes/archline';
import lozenge from './markers/lozenge';
import dot     from './markers/dot';
import abSides from './markers/abSides';
import directional from './markers/directional';

import { emptyObj, pickTextColor } from '../../../../utils/functions';

// arrows:
// https://stackoverflow.com/questions/53114152/draw-two-head-arrows-in-fabric-js

// simple vs arch comes from project
// but can be overriden at the type level
// LKLKLK HOWEVER!!

// we may want to add a third at project level
// that just lets it be set at type
// how else would we override so all are viewed as simple...?

// locator, label, and line scales are set at phasePlan
// but... can be multiplied I guess by the type?

export const handleDrawPlots = async ({ editable, sizing, offset, mode, statuses, canvas, map, plots, plotTasks, interaction }) => {
  if(!canvas) return;
  let promise = new Promise(async (resolve, reject) => {
    if(!sizing) {
      sizing = {
        label: map.styling?.label ? map.styling?.label : 2,
        locator: map.styling?.locator ? map.styling?.locator : 2,
        line: map.styling?.line ? map.styling?.line : 2
      };
    }
    
    if(!offset) {
      offset = {
        x: map.styling?.x ? map.styling.x : 0,
        y: map.styling?.y ? map.styling.y : 0,
        scale: map.styling?.scale ? map.styling.scale : 1
      };
    }

    // creating array so we don't see a flash
    // between deleting all objects + loading any thumbnails
    let newObjs = [];

    let fontFamily = '"Roboto","Segoe UI","Roboto","Helvetica Neue","Arial"';
    let fontSize = 10;
    let textPad = 6;
    let lineHeight = .85;

    // manually filter the plots based on planId
    // we need this because activePlots do NOT filter by planId
    // so that we can see quantities etc. even if we are in a plan
    // yes this is needed! if you remove this all plots will
    // show up on every single plan
    let filtered = plots.filter(x => x.planId === map.planId);

    // lklklk: should you map the plot icon/thumbs to see if you can preload as strings?
    // right now everything blinks while the image is being loaded...
    // wonder if you could have it load similar to the bg image so it doesn't blink?

    for (const plot of filtered) {
      let details = {
        id: plot.id,
        appId: plot.appId,
        phaseId: plot.phaseId,
        typeId: plot.typeId,
        status: plot.publicStatus,
        left: (plot.styling.locatorX+offset.x)*offset.scale,
        top: (plot.styling.locatorY+offset.y)*offset.scale,
        piece: 'locator'
      }

      let labelX = plot.styling.labelX ? (plot.styling.labelX+offset.x)*offset.scale : details.left + 50;
      let labelY = plot.styling.labelY ? (plot.styling.labelY+offset.y)*offset.scale : details.top + 50;
      let points = plot.styling.points ? plot.styling.points : [];
      let rotation = plot.styling.rotation ? plot.styling.rotation : 0;
      let textLabel = plot.display.textLabel;
      let borderColor = plot.display.borderColor;
      let icon = plot.display.typeIcon;
      let thumbA = plot.display.typeThumbA;
      let thumbB = plot.display.typeThumbB;

      // check to see if this plot has a plotTask with styling data
      if(plotTasks) {
        let found = plotTasks.find(x => x.plotAppId===plot.appId);
        if(found && !emptyObj(found.styling) && 
          (found.styling?.labelX !== plot.styling?.labelX || 
           found.styling?.labelY !== plot.styling?.labelY || 
           found.styling?.locatorX !== plot.styling?.locatorX || 
           found.styling?.locatorY !== plot.styling?.locatorY)) {

          details.eamsMoved = {
            ...details,
            piece: 'original location',
            opacity: 0,
            selectable: false,
            hoverCursor: 'auto'
          };

          if(found.styling.locatorX || found.styling.locatorY) {
            details.left = (found.styling.locatorX+offset.x)*offset.scale;
            details.top  = (found.styling.locatorY+offset.y)*offset.scale;
          }
          if (found.styling.labelX || found.styling.labelY) {
            labelX = (found.styling.labelX+offset.x)*offset.scale;
            labelY = (found.styling.labelY+offset.y)*offset.scale;
          }
        }
      }

      let textOpts = {
        fill: plot.display.textFill,
        fontSize: fontSize*sizing.locator,
        fontFamily: fontFamily,
        lineHeight: lineHeight*sizing.locator,
        padding: textPad*sizing.locator,
      }

      if(details.eamsMoved) {
        let ogLoc = buildMarker({ shapeId: 4, fillColor: '#f00', strokeColor: '#ff0', details: details.eamsMoved, scale: sizing.locator, rotation: 0 });
        newObjs.push(ogLoc);
      }

      if(plot.display.type === 'simple') {
        let shapeId = plot.display.locatorId;
        let fillColor = plot.display.locatorFill;

        // statusColor override
        if(mode===2 && statuses?.length > 0) {
          let status = statuses.find(x => x.value===plot.publicStatus);
          fillColor = status ? status.fillColor : '#ff0';
          textOpts.fill = pickTextColor(fillColor);
        } else if(mode===3) {
          fillColor = '#ff0';
          textOpts.fill = '#000';
          borderColor = '#000';
          textLabel = '---';
        }

        let shape = await buildShape({ editable, interaction, shapeId, textLabel, textOpts, fillColor, borderColor, scale: sizing.locator, details, points, icon, thumbA, thumbB })
        newObjs.push(shape);
          
      } else {
        let coords = [
          { x: details.left, y: details.top },
          // lklklk will want to add any plot line breaks here...
          { x: labelX, y: labelY },
        ]

        let labelDetails = {...details, piece: 'label', left: labelX, top: labelY }

        // override text options for label sizing
        textOpts.fontSize = fontSize*sizing.label;
        textOpts.lineHeight = lineHeight*sizing.label;
        textOpts.padding = textPad*sizing.label;

        // statusColor override
        let labelFill = plot.display.labelFill;
        let locatorFill = plot.display.locatorFill;
        let lineColor = plot.display.lineColor;

        if(mode===2 && statuses?.length > 0) {
          let status = statuses.find(x => x.value===plot.publicStatus);
          labelFill = status ? status.fillColor : '#ff0';
          textOpts.fill = pickTextColor(labelFill);
        } else if(mode===3) {
          labelFill = '#ffbf00';
          locatorFill = '#ffbf00';
          lineColor = '#ffbf00';
          textOpts.fill = '#000';
          borderColor = '#000';
          textLabel = '---';
        }

        // create line and append to details for moveLine
        let line = archline({ coords, color: lineColor, scale: sizing.line });
        labelDetails.eamsLine = line;
        details.eamsLine = line;

        let locator = buildMarker({ editable, shapeId: plot.display.locatorId, fillColor: locatorFill, details, textLabel, textOpts, scale: sizing.locator, rotation });
        let label = await buildShape({ editable, interaction, shapeId: plot.display.labelId, fillColor: labelFill, borderColor, details: labelDetails, textLabel, textOpts, scale: sizing.label, points, icon, thumbA, thumbB });

        newObjs.push(line);
        newObjs.push(locator);
        newObjs.push(label);
      }
    }

    // remove all
    canvas.getObjects().forEach((o) => {
      canvas.remove(o);
    });

    // then add all
    for(const obj of newObjs) {
      canvas.add(obj);
    }

    canvas.renderAll();
    resolve(canvas);
  })

  let result = await promise;
  return result;
}

const buildShape = async (pieces) => {
  // protect from empty icon/thumb urls
  if(pieces.shapeId===4 && !pieces.icon) pieces.shapeId = 1;
  if(pieces.shapeId===5 && !pieces.thumbA) pieces.shapeId = 1;

  switch (pieces.shapeId) {
    case 1: return circle(pieces);
    case 2: return rect(pieces);
    case 3: return triangle(pieces);
    case 4: return await icon(pieces);
    case 5: return await thumbnail(pieces);
    default: return circle(pieces);
  }
}

const buildMarker = (pieces) => {
  switch (pieces.shapeId) {
    case 1: return lozenge(pieces);
    case 2: return directional(pieces);
    case 3: return abSides(pieces);
    case 4: return dot(pieces);
    default: return lozenge(pieces);
  }
}

// saving for later...
// const animate = (canvas, obj, scale) => {
//   const duration = 750;
//   const opacity = 0.5;
//   const animateScale = 1.2;
//   var newOpacity = (obj.opacity == 1) ? opacity : 1;
//   var newScale = (obj.scaleX == scale) ? scale*animateScale : scale;

//   obj.animate({
//     scaleX: newScale,
//     scaleY: newScale,
//     opacity: newOpacity
//   }, {
//     duration: duration,
//     onChange: canvas.renderAll.bind(canvas),
//     onComplete: function() {
//       animate(canvas, obj, scale)
//     }
//   });
// }
