import React, { forwardRef } from 'react'
import { Controller } from 'react-hook-form'

// how to use:
// <Hidden key={item.id} {...register(item.field)} item={item} pieces={{ control }} />

// schema example:
// { id: '__NEW_ID__', field: '__DB_FIELD__', type: 'hidden' },

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  return (
    <Controller
      render={({ field }) => {
        if(!field.value) field.value = '';
        return <input {...field} ref={field.ref} type="hidden" value={field.value} />;
      }}
      name={item.field}
      control={pieces.control}
    />
  )
});

export default Field;
