import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';
import { UserContext } from '../../../../context/UserContext';
import Generated  from '../../../../components/system/Generated';
import { formObj } from '../../../../utils/schemas';
import Modal from 'react-bootstrap/Modal';
import { Pencil } from 'react-bootstrap-icons';

import Markups from '../../../../components/system/markups/Markups';


const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    // { id: '2', type: 'floating-input', field: 'title', label: 'title', required: 'Please enter a notification title/description'},
    { id: '3', type: 'checkboxes', label: 'Tags', field: 'tags', options: [
      { value: 'ZA0deGGvTQTpVG1fYTr8J', label: 'Public' },
      { value: 'BYiOv6kKnBCQv8H4X7BEg', label: 'Application'},
      { value: 'LApNuQDCJsyolKsH_berT', label: 'Install'},
    ]},
    { id: '5', type: 'floating-textarea', field: 'notes', label: 'Notes', rows: 5 },
    // { id: '4', type: 'access', field: 'access', label: 'Access', display: false },
    { id: '6', type: 'hidden', field: 'id' },
    { id: '7', type: 'hidden', field: 'markups' },
  ]}
]


const View = (props) => {
  const { obj, setPhotos } = props;
  const { userDetails } = useContext(UserContext);
  // const wrapper = (props.wrapper) ? props.wrapper : '';
  // const text = (props.text) ? props.text : '';
  // const styles = (props.styles) ? props.styles : "btn btn-sm btn-outline-danger";
  const size = (props.size) ? props.size : 16;
  const formText = { submit: 'Save', pending: 'Saving', success: 'Saved!', cancel: 'Cancel' };
  const errorCode = 'PH8482'

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  const [show, setShow] = useState(false);
  const toggle = () => setShow((s) => !s);

  // if you need to set the form value based on props.obj changing
  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [obj, reset])

  const onSubmit = async (data) => {
    let res = await serverCall({ method: 'PATCH', data, url: `/mapping/photos/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error editing photo. Contact support.') // lklklk

    setPhotos((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.id===data.id);
      if(idx > -1) arr[idx] = {...arr[idx], ...data};
      return arr;
    });

    setShow(false);
  };

  // const handleCancel = (e) => {
  //   e.target.blur();
  //   if(cancelType==='reset') return reset(props.obj);

  //   if (cancelType==='close') {
  //     // how do we trigger to close the modal or whatever? maybe just send to parent to handle?
  //     alert('close here');
  //   }
  // }

  const fromChild = ({ type, value }) => {
    // console.log(type, value);
    if(type==='markup') {
      setValue('markups', value);
    }
  }

  return (
    <Fragment>
      <button className="btn btn-sm bg-white me-2" onClick={toggle}>
        <Pencil className="text-dark" size={size} />
      </button>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Modal show={show} onHide={toggle} backdrop={true} keyboard={false} scrollable fullscreen>
          <Modal.Header closeButton>
            <Modal.Title>Edit/Markup Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid h-100">
              <div className="row h-100">
                <div className="col-sm-8 h-100">
                  <Markups obj={obj} toParent={fromChild} />
                </div>
                <div className="col-sm-4">
                  <Generated id="edit-photo" schema={schema} size="md" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
          { userDetails.supe && <p className="float-start ms-2 mt-2 mb-0 small text-muted">{obj.appId} - {obj.id}</p>}

            <button className="btn btn-sm btn-outline-dark ms-auto me-1 float-end" type="button" onClick={toggle}>
              {formText.cancel}
            </button>
            <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'} float-end`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
              { !pending && !error && (formText.submit)}
              { pending === true && (
                <Fragment>
                  <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  {formText.pending}
                </Fragment>
              )}
              { error && (`Error saving (${errorCode})`)}
              { pending ==='success' && formText.success}
            </button>
          </Modal.Footer>
        </Modal>
      </form>
    </Fragment>
  )
}

export default View;
