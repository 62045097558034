import React from 'react';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

// note: this assumes we are sorting by sortOrder

import { handleDragEndArray } from '../../../utils/dndkit'; // handleDragOver not implemented
import { SortableItem } from './SortableItem';

const View = (props) => {
  const { arr, setArr, child: SortItem, pieces, handle, toParent } = props;
  const caption = props.caption ? props.caption : 'sorted';

  const sensors = useSensors(useSensor(PointerSensor));
  // const dragOver = (e) => handleDragOver({ e, arr, setArr });
  const dragEnd = (e) => {
    document.activeElement.blur();
    handleDragEndArray({ e, arr, setArr, toParent, caption })
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={dragEnd}> {/* onDragOver={dragOver} */}
      <SortableContext items={arr} strategy={verticalListSortingStrategy}>
        { arr.map((obj, idx) => (
          <SortableItem key={obj.id} idx={idx} id={obj.id} obj={obj} pieces={pieces} length={arr.length} child={SortItem} handle={handle} toParent={toParent} />
        ))}
      </SortableContext>
    </DndContext>
  )
}

export default View;
