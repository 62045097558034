import React, { useContext } from 'react';
import { useAxios } from '../../../hooks/useAxios';
import { UserContext } from '../../../context/UserContext';

// import Collapsed from '../../../components/system/Collapsed';
import Photo from './components/Photo';
import Dropzone  from '../../../components/system/dropzone/Dropzone';
import dayjs from 'dayjs'

const View = (props) => {
  const { obj, photos, setPhotos, imgClasses, figClasses } = props;
  const { userDetails } = useContext(UserContext);
  const { serverCall } = useAxios();

  const wrapper = props.wrapper ? props.wrapper : '';
  const styles = props.styles ? props.styles : { paddingRight: 60 }; // { columns: 2 }

  const fromChild = async (data) => {
    const { type } = data;
    if(type==='file uploaded') {
      let pic = {...data.value, ...{
        projectId: obj.projectId,
        phaseId: obj.phaseId,
        planId: obj.planId,
        plotId: obj.id ? obj.id : null,
        plotAppId: obj.appId,
        createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        createdBy: userDetails.id,
        status: 'A'
      }};

      let res = await serverCall({ method: 'POST', data: pic, url: `/mapping/photos`, eamsAuth0: true });
      if(res.status!==200) return alert('Error adding photo. Contact support.') // lklklk

      setPhotos((prev) => {
        let arr = [...prev];
        arr.push(res.data);
        return arr;
      })
    }
    // if(type==='delete confirmed') {
    //   if(toParent) toParent({ type: 'remove types', value: [obj.id] });
    //   handleClose();
    // }
  }

  return (
    <div className={wrapper} style={styles}>
      <Dropzone
        folder="uploads/photos"
        prefix="photo-upload"
        toParent={fromChild}
      />
      <br />

      {/* { phases.filter(x => x.status==='A')
        .sort((a,b) => (b.sortOrder > a.sortOrder) ? 1 : ((a.sortOrder > b.sortOrder) ? -1 : 0))
        .map((phase, idx) => {
          let filtered = photos.filter(x => x.phaseId === phase.id);
          console.log(filtered);
          if(filtered.length===0) return null;
          return (
            <Collapsed key={`phase-${phase.appId}`} title={`${phase.name} ()`} open={idx===0}>
              <div>
                xx
              </div>
            </Collapsed>            
          )
        })} */}

      { photos.map(img => <Photo key={img.appId+img.id} img={img} imgClasses={imgClasses} figClasses={figClasses} setPhotos={setPhotos} />)}
    </div>
  )
}

export default View;
