import React, { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { fabric } from 'fabric';
import Alert from 'react-bootstrap/Alert';

import { emptyObj, isNumeric } from '../../utils/functions';

import StatusButtons from './components/StatusButtons';

import { init } from './utils/init';
import { wheelHandler } from './utils/interaction/wheel';
import { handleResize } from './utils/interaction/resize';
import { handleKeyDown, handleKeyUp } from './utils/interaction/keys';
import { handleObjModified, handleObjMoving, handleObjRotating } from './utils/interaction/objects';
import { handleSelectionCreated, handleSelectionUpdated, handleBeforeSelectionCleared, handleSelectionCleared } from './utils/interaction/selection';
import { handleMouseDown, handleMouseUp, handleDblClick } from './utils/interaction/mouse';
// import { handleHighlight } from './utils/interaction/highlight';
import { handleTouchDrag, handleLongpress, handleTouchGesture } from './utils/interaction/touch';
import { handleHover, handleOut } from './utils/interaction/hover';
import { viewportChanged } from './utils/interaction/viewport';

import { handleDrawPlots } from './utils/display/draw-plots';

const View = (props) => {
  const debug = false;
  const { map, setPhasePlans, touch, editable, publicStatuses, plots, plotTasks, selected, setSelected, zoomTo, toParent } = props;
  const [loading, setLoading] = useState(true);
  const [missing, setMissing] = useState(false);
  
  // 1 = type color, 2 = status, 3 = status border?
  const [mode, setMode] = useState(1);

  const interaction = useRef({
    touch, // determines if we send longpress etc back
    editable,
    mode: 1,
    panX: null,
    panY: null,
    hover: {},
    fadeBkgd: 1,
    bkgdLocked: false,
    plotsLocked: (touch || editable==='suggest') ? true : false,
    lockAfter: 5000,
    lockTimer: null,
    offset: { x: 0, y: 0, scale: 1 },
    selected: [],
    pauseSel: false, // needed, see notes in interaction/selection
    objMoved: false,
    nextPlot: 1
  });

  const wrapperRef = useRef(null);
  const canvasRef = useRef(null);
  const bkgdRef = useRef([]);

  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);

  // const [bgLoaded, setBgLoaded] = useState();
  const [bkgdLocked, setBkgdLocked] = useState(false);
  const [plotsLocked, setPlotsLocked] = useState((touch || editable==='suggest') ? true : false);

  const [fadeBkgd, setFadeBkgd] = useState(0);
  const [offset, setOffset] = useState({ x: 0, y: 0, scale: 1});
  const [sizing, setSizing] = useState({ label: 2, locator: 2, line: 2 });

  const wheel   = (e) => wheelHandler({ e, canvas: canvasRef.current, map });
  const keyDown = (e) => handleKeyDown({ e, debug, canvas: canvasRef.current, interaction: interaction.current, setSelected, toParent: fromChild });
  const keyUp   = (e) => handleKeyUp({ e, debug, canvas: canvasRef.current, interaction: interaction.current, setSelected, toParent: fromChild });
  const resize = useCallback(async () => {
    handleResize({ wrapper: wrapperRef.current, canvas: canvasRef.current, map, bkgd: bkgdRef.current });
  }, [map]);

  const selCreated     = (e) => handleSelectionCreated({ e, debug, canvas: canvasRef.current, interaction: interaction.current, setSelected });
  const selUpdated     = (e) => handleSelectionUpdated({ e, debug, canvas: canvasRef.current, interaction: interaction.current, setSelected });
  const selBeforeCleared = (e) => handleBeforeSelectionCleared({ e, debug, toParent: fromChild });
  const selCleared     = (e) => handleSelectionCleared({ e, debug, interaction: interaction.current, setSelected });
  const touchDrag      = (e) => handleTouchDrag({ e, debug, canvas: canvasRef.current, interaction: interaction.current, map });
  const touchGesture   = (e) => handleTouchGesture({ e, debug, canvas: canvasRef.current, interaction: interaction.current, map });
  const touchLongpress = (e) => handleLongpress({ e, debug, canvas: canvasRef.current, interaction: interaction.current, toParent: fromChild });
  const objMoving      = (e) => handleObjMoving({ e, debug, canvas: canvasRef.current, interaction: interaction.current });
  const objRotating    = (e) => handleObjRotating({ e, debug, canvas: canvasRef.current, interaction: interaction.current });
  const objModified    = (e) => handleObjModified({ e, interaction: interaction.current, setPlotsLocked, toParent: fromChild });
  const objHover       = (e) => handleHover({ e, debug, touch, canvas: canvasRef.current, interaction: interaction.current, toParent: fromChild });
  const objHoverOut    = (e) => handleOut({ e, debug, touch, canvas: canvasRef.current, interaction: interaction.current, toParent: fromChild });
  // const objCleared    = (e) => handleObjCleared({ e, canvas: canvasRef.current, fromMap });
  const mouseDown      = (e) => handleMouseDown({ e, debug, canvas: canvasRef.current, interaction: interaction.current, toParent: fromChild });
  const mouseUp        = (e) => handleMouseUp({ e, debug, canvas: canvasRef.current, interaction: interaction.current, setSelected, toParent: fromChild  });
  const mouseDblClick  = (e) => handleDblClick({ e, debug, canvas: canvasRef.current, interaction: interaction.current, toParent: fromChild });

  const drawPlots = () => handleDrawPlots({ editable, sizing, offset, mode, statuses: publicStatuses, canvas: canvasRef.current, map, plots, plotTasks, interaction: interaction.current });
  // const drawPlots = useCallback(async () => {
  //   handleDrawPlots({ editable, sizing, offset, mode, statuses: publicStatuses, canvas: canvasRef.current, map, plots, interaction: interaction.current });
  // }, [editable, map, mode, offset, plots, publicStatuses, sizing]);

  useLayoutEffect(() => {
    const update = () => {
      let width = wrapperRef.current.parentElement.offsetWidth;
      let height = wrapperRef.current.parentElement.offsetHeight;
      setWidth(width);
      setHeight(height);
    }
    update();
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, []);

  useEffect(() => {
    if(emptyObj(map)) return;
    setLoading(true);
    let canvas = init('map', editable, touch);
    let bkgd = new Image();

    if(map.base64) {
      bkgd.src = map.base64;
    } else if(map.url) {
      bkgd.crossOrigin = 'Anonymous';
      bkgd.src = map.url;
    } else {
      return setMissing(true);
    }
    
    bkgd.onload = () => {
      bkgdRef.current = [bkgd.width, bkgd.height];
      window.addEventListener('wheel', wheel);
      window.addEventListener('resize', resize);
      window.addEventListener('keydown', keyDown);
      window.addEventListener('keyup', keyUp);

      canvas.on('selection:created', selCreated );
      canvas.on('selection:updated', selUpdated );
      canvas.on('before:selection:cleared', selBeforeCleared );
      canvas.on('selection:cleared', selCleared );
      canvas.on('object:modified', objModified );
      canvas.on('object:moving', objMoving );
      canvas.on('object:rotating', objRotating );
      canvas.on('mouse:over', objHover );
      canvas.on('mouse:out', objHoverOut );
      canvas.on('mouse:dblclick', mouseDblClick );
      canvas.on('mouse:up', mouseUp );
      canvas.on('touch:gesture', touchGesture );
      // canvas.on('before:selection:cleared', objCleared );
      canvas.on('mouse:down', mouseDown );
      canvas.on('touch:drag', touchDrag );
      canvas.on('touch:longpress', touchLongpress );

      let opacity = map.styling?.fadeBkgd ? map.styling.fadeBkgd : 1;
      interaction.current.fadeBkgd = opacity;
      setFadeBkgd(opacity);

      setLoading(false);
      canvasRef.current = canvas;

      // reset interaction before drawing plots
      interaction.current.selected = [];
      resize();

      setSizing({
        label: map.styling?.label ? map.styling?.label : 2,
        locator: map.styling?.locator ? map.styling?.locator : 2,
        line: map.styling?.line ? map.styling?.line : 2
      })
      setOffset({
        x: map.styling?.x ? map.styling.x : 0,
        y: map.styling?.y ? map.styling.y : 0,
        scale: map.styling?.scale ? map.styling.scale : 1
      })
      interaction.current.offset = {
        x: map.styling?.x ? map.styling.x : 0,
        y: map.styling?.y ? map.styling.y : 0,
        scale: map.styling?.scale ? map.styling.scale : 1
      };
    }

    return () => {
      // console.log('unmounting, clear out canvas')
      canvas.wrapperEl.parentElement.innerHTML = '<canvas id="map" height="1" width="1" />';
      window.removeEventListener('wheel', wheel);
      window.removeEventListener('resize', resize);
      window.removeEventListener('keydown', keyDown);
      window.removeEventListener('keyup', keyUp);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  useEffect(() => {
    // determine next highest number
    let highestPlot = Math.max(...plots.map(o => isNumeric(o.name) ? o.name : 0), 0);
    interaction.current.nextPlot = String(highestPlot+1);

    // then continue to draw plots
    (async () => {
      let temp = [...interaction.current.selected];
      await drawPlots();
      if(setSelected) setSelected(temp);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plots])

  useEffect(() => {
    if(!canvasRef.current) return;
    canvasRef.current.selection = bkgdLocked;
  }, [bkgdLocked])

  useEffect(() => {
    if(emptyObj(map) || !canvasRef.current) return;
    let bkgd = new Image();
    if(map.base64) {
      bkgd.src = map.base64;
    } else if(map.url) {
      bkgd.crossOrigin = 'Anonymous';
      bkgd.src = map.url;
    } else {
      return setMissing(true);
    }

    bkgd.onload = () => {
      canvasRef.current.setBackgroundImage(bkgd.src, canvasRef.current.renderAll.bind(canvasRef.current), {
        opacity: fadeBkgd,
        backgroundColor: 'white',
        backgroundImageStretch: false,
        originX: 'center',
        originY: 'center',
        left: bkgd.width/2,
        top: bkgd.height/2,
        crossOrigin: 'Anonymous',
        // this angle works but the resize thing doesnt account for it
        // angle: 90
      });

      resize();
      canvasRef.current.renderAll();
    }
  }, [map, fadeBkgd, resize])

  useEffect(() => {
    if(!canvasRef.current) return;
    interaction.current.mode = mode;

    if(mode===3) {
      interaction.current.reorderNo = 1;
      interaction.current.reorder = [];
      setFadeBkgd(0.3);
    } else { 
      delete interaction.current.reorderNo;
      delete interaction.current.reorder;
      delete interaction.current.reorderStart;
      delete interaction.current.reorderSkip;
      setFadeBkgd(interaction.current.fadeBkgd);
    }

    (async () => {
      await drawPlots();
    })();    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, sizing, offset])

  useEffect(() => {
    if(!canvasRef.current) return;
    interaction.current.pauseSel = true;
    interaction.current.selected = selected;
    canvasRef.current.discardActiveObject();

    let objs = [];
    for(const plot of selected) {
      canvasRef.current.getObjects().forEach((obj) => {
        if(plot.appId===obj.appId && plot.phaseId===obj.phaseId && plot.piece === obj.piece)
          objs.push(obj);
      });
    }

    if(objs.length===1) {
      canvasRef.current.setActiveObject(objs[0]).requestRenderAll();
    } else {
      var sel = new fabric.ActiveSelection(objs, { canvas: canvasRef.current });
      canvasRef.current.setActiveObject(sel).requestRenderAll();
    }

    interaction.current.pauseSel = false;
  }, [selected])

  useEffect(() => {
    if(emptyObj(map) || !canvasRef.current || emptyObj(zoomTo)) return;
    let zoom = 1.3; // desired zoom
    let offset = interaction.current.offset;
    let x = -(((zoomTo.left+offset.x)*offset.scale)*zoom) + canvasRef.current.width/2;
    let y =  -(((zoomTo.top+offset.y)*offset.scale)*zoom) + canvasRef.current.height/2;
    canvasRef.current.setViewportTransform([zoom,0,0,zoom,x,y]);
    viewportChanged(canvasRef.current, map);
  }, [map, zoomTo])

  const saveRenumbering = () => {
    if(interaction.current.reorder?.length === 0) return;
    toParent({ type: 'reordered', value: interaction.current.reorder });
    setMode(1);
  }

  const fromChild = (data) => {
    const { type, value } = data;
    if(debug) console.log(data);
    if(type === 'shift key') {
      setBkgdLocked(value);
    } else {
      toParent(data);
    }    
  }

  return (
    <Fragment>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        <style>{`body { overflow: hidden; }`}</style>
      </Helmet>

      { loading && (
        <div style={{top:0, left: 0, height: '100vh',width:'100vw',zIndex:1040}} className="position-fixed bg-light d-flex align-items-center justify-content-center">
          <div className="spinner-border text-muted" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      { missing && (
        <div style={{top:0, left: 0, height: '100vh',width:'100vw',zIndex:1040}} className="position-fixed bg-light d-flex flex-column align-items-center justify-content-center">
          <h3 className="text-danger">Missing Background</h3>
          <p className="px-4 text-center text-danger">This map/background is missing. Please go back and refresh or download this plan to view.</p>
        </div>
      )}

      <div ref={wrapperRef} className="d-inline-block" style={{width:width, height:height}}>
        <canvas id="map" height="1" width="1" />
      </div>

      { mode===3 && (
        <Alert variant="warning" className="position-fixed w-100" style={{top:0, left:0, zIndex:1999}} onClose={() => setMode(1)} dismissible>
          <Alert.Heading>Renumbering Mode</Alert.Heading>
          <p className="mb-0">You are in renumbering mode. Press the <b>shift key</b> and hover over plots to reorder. Press <button className="btn btn-xs btn-outline-success" onClick={saveRenumbering}>Save</button> to make changes, or click X to cancel.</p>
        </Alert>
      )}

      <StatusButtons 
        map={map}
        setPhasePlans={setPhasePlans}
        touch={touch}
        editable={editable}
        classes="position-fixed" 
        styles={{bottom:16, right:16}} 
        interaction={interaction.current}
        bkgdLocked={bkgdLocked}
        setBkgdLocked={setBkgdLocked}
        plotsLocked={plotsLocked}
        setPlotsLocked={setPlotsLocked}
        resize={resize}
        mode={mode}
        setMode={setMode}
        fadeBkgd={fadeBkgd}
        setFadeBkgd={setFadeBkgd}
        offset={offset}
        setOffset={setOffset}
        sizing={sizing}
        setSizing={setSizing}
        toParent={toParent}
      />
    </Fragment>
  )
}

export default View;
