import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs'

import { UserContext } from '../../context/UserContext';
import { useAxios }    from '../../hooks/useAxios';
import Generated  from './Generated';
// import EmailError from './EmailError';

import { formObj } from '../../utils/schemas';

// note: this form assumes we are logged in/authenticated somehow
// if you need to submit an anonymous form, this script should be adjusted

// note 2:
// the response includes value = form values, manually entered
// and response = server-returned values, i.e. includes id of a new record

// how to use:
// <FormBasic debug={true} method="PATCH" url={`/system/schemas/${params.projectId}`} obj={active}
// <FormBasic debug={true} method="POST" url="/mapping/projects" schema={projSchema}
//   vals={{
//     createdBy: userDetails.id,
//     udf1: scale[0]?.value,
//     udf2: priority[0]?.value,
//     visible: 'Y',
//     publicStatus: projStatus[0]?.value
//   }}
//   vars={{ 'project-scale': scale, 'all-users': allUsers }}
//   updateForm={true} toParent={fromForm} schema={schema}
//   formText={{ submit: 'Save', pending: 'Saving', success: 'Saved!', cancel: 'Cancel' }}
// />

const View = (props) => {
  let { debug, obj, schema, vals, vars, method, url, createForm, updateForm, errorCode, formText, toParent, clearSubmit } = props;
  const { userDetails } = useContext(UserContext);
  const [formId, setFormId] = useState();
  formText = formText ? formText : { submit: 'Save', pending: 'Saving', success: 'Saved!', cancel: 'Cancel' };

  const handleCancel = () => {
    reset();
    if(toParent) toParent({type: 'cancelled'});
  }

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, trigger, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [schema])
  });

  useEffect(() => {
    let id = nanoid();
    setFormId(id);
  }, [])

  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [schema, obj, reset])

  const fromChild = (data) => {
    if(toParent) toParent(data);
  }

  const onSubmit = async (data) => {
    if(debug) console.log(data);
    let uuid = nanoid();

    if(createForm) {
      data.appId = uuid;
      data.createdAt = data.createdAt ? data.createdAt : dayjs().format('YYYY-MM-DD HH:mm:ss');
      data.createdByUser = userDetails.byUser;
      data.status = 'A';

      // if the form has already provided createdBy, do not override it
      // used in the cases where someone is filing a ticket for another person
      data.createdBy = data.createdBy ? data.createdBy : userDetails.id;
    }

    if(updateForm) {
      data.updatedBy = userDetails.id;
      data.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
      data.updatedByUser = userDetails.byUser;
    }

    if(debug) console.log(data);

    let res = await serverCall({
      method, data, url,
      headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId },
      eamsAuth0: true
    });
    if(debug) console.log(res);

    if(toParent) toParent({type: 'submitted', value: data, response: res.data});
    if(clearSubmit) reset();
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Generated id={`form-${formId}`} schema={schema} size="md" pieces={{ vals, vars, register, control, setValue, watch, errors, trigger, toParent: fromChild }} />
      </form>
      { method && url && (
        <Fragment>
          <button className={`btn btn-sm me-1 ${error ? 'btn-danger' : 'btn-success'}`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
            { !pending && !error && (formText.submit)}
            { pending === true && (
              <Fragment>
                <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                {formText.pending}
              </Fragment>
            )}
            { error && (`Error saving (${errorCode})`)}
            { pending ==='success' && formText.success}
          </button>

          <button className="btn btn-sm btn-outline-dark" type="button" onClick={handleCancel}>
            {formText.cancel}
          </button>
        </Fragment>
      )}
    </Fragment>
  )
}

export default View;
