import React, { Fragment, useEffect } from 'react';
import { fabric } from 'fabric';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ArrowsMove, AspectRatio, LockFill, UnlockFill, ToggleOff, ToggleOn, XLg as XIcon } from 'react-bootstrap-icons'

import Tools from './Tools';

// lklklk future:
// need to disable the unlocking if map is not editable...?


const View = (props) => {
  const { map, setPhasePlans, editable, touch, classes, styles, interaction, bkgdLocked, setBkgdLocked, plotsLocked, setPlotsLocked, resize, mode, setMode, fadeBkgd, setFadeBkgd, offset, setOffset, sizing, setSizing, toParent } = props;
  
  const toggleBkgd = () => {
    interaction.bkgdLocked = !interaction.bkgdLocked;
    setBkgdLocked((s) => !s);
  }

  const toggleMode = () => setMode((s) => {
    return (s===1) ? 2 : 1;
  });

  const toggleLock = () => {
    if(!editable) return;
    interaction.plotsLocked = !interaction.plotsLocked;
    setPlotsLocked((s) => !s);

    // start timer if we've unlocked
    if(!interaction.plotsLocked) {
      interaction.lockTimer = setTimeout(() => setPlotsLocked(true), interaction.lockAfter);
    }
  }

  const reset = () => {
    localStorage.removeItem('viewport');
    resize();
  }

  useEffect(() => {
    if(editable!=='suggest' && !touch) return;
    console.log('plotsLocked: ', plotsLocked);
    interaction.plotsLocked = plotsLocked;
    fabric.Object.prototype.lockMovementX = plotsLocked;
    fabric.Object.prototype.lockMovementY = plotsLocked;
    fabric.Object.prototype.lockRotation = plotsLocked;
  }, [editable, touch, plotsLocked])

  return (
    <div className={classes} style={styles}>
      { touch && (
        <Fragment>
          <button className="btn bg-white rounded border shadow-sm" type="button" onClick={toggleMode}>
            { mode===1 && <ToggleOff size={20} />}
            { mode!==1 && <ToggleOn size={20} />}
          </button>
          <button className="btn bg-white ms-2 rounded border shadow-sm" type="button" onClick={reset}>
            <AspectRatio size={20} />
          </button>
          { plotsLocked && (
            <button className="btn bg-danger ms-2 text-white rounded shadow-sm" type="button" onClick={toggleLock}>
              <LockFill size={20} />
            </button>
          )}
          {!plotsLocked && (
            <button className="btn bg-success ms-2 text-white rounded shadow-sm" type="button" onClick={toggleLock}>
              <UnlockFill size={20} />
            </button>
          )}
        </Fragment>
      )}
      {!touch && (
        <Fragment>
          { mode!==3 && (
            <OverlayTrigger placement="top" overlay={<Tooltip id="toggle-mode">
              { mode===1 && 'View by Status Color'}
              { mode!==1 && 'View by Type Color'}
              </Tooltip>}>
              <button className="btn bg-white rounded border shadow-sm me-1" type="button" onClick={toggleMode}>
                { mode===1 && <ToggleOff size={20} />}
                { mode!==1 && <ToggleOn size={20} />}
              </button>
            </OverlayTrigger>
          )}
          <OverlayTrigger placement="top" overlay={<Tooltip id="reset-viewport">Reset View</Tooltip>}>
            <button className="btn bg-white rounded border shadow-sm me-1" type="button" onClick={reset}>
              <AspectRatio size={20} />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id="bkgd-locked">{ bkgdLocked ? 'Unlock' : 'Lock'} Background</Tooltip>}>
            <button className="btn bg-white rounded border shadow-sm" type="button" onClick={toggleBkgd}>
              { bkgdLocked && <XIcon size={16} /> }
              { !bkgdLocked && <ArrowsMove size={16} /> }
            </button>
          </OverlayTrigger>

          { editable==='suggest' && (
            <OverlayTrigger placement="top" overlay={<Tooltip id="plots-locked">{ plotsLocked ? 'Unlock' : 'Lock'} Plots</Tooltip>}>
                <button className={`btn ms-1 text-white rounded shadow-sm ${plotsLocked ? 'bg-danger' : 'bg-success'}`} type="button" onClick={toggleLock}>
                  { plotsLocked && <LockFill size={20} /> }
                  { !plotsLocked && <UnlockFill size={20} /> }
                </button>
            </OverlayTrigger>
          )}

          { editable!=='suggest' && mode!==3 && (
            <Tools
              map={map}
              setMode={setMode}
              setPhasePlans={setPhasePlans}
              fadeBkgd={fadeBkgd}
              setFadeBkgd={setFadeBkgd}
              offset={offset}
              setOffset={setOffset}
              sizing={sizing}
              setSizing={setSizing}
              interaction={interaction}
              toParent={toParent}
            />
          )}
        </Fragment>
      )}
    </div>
  )
}

export default View;
