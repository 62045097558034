import React, { Fragment, useState } from 'react';
import { Trash } from 'react-bootstrap-icons';

// how to use:
// <ToggleStatusBtn toParent={fromChild} status={toggleStatus} textA="Delete Plan" textD="Reactivate Plan" />

// lklklk: maybe display as error if status is NULL? if it is, we don't see either button...

// future:
// consider adding a 'condition' that you pass, plus a message
// this would be like an extra warning, e.g. in DSM Presentations
// when we want to show a warning if active.children.length > 0
// it could show a modal to add an extra layer of protection

const Button = (props) => {
  const { status } = props;
  const [pending, setPending] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const wrapper = (props.wrapper) ? props.wrapper : '';
  const textA = (props.textA) ? props.textA : '';
  const textD = (props.textD) ? props.textD : 'Reactivate';
  const styles = (props.styles) ? props.styles : "btn btn-sm btn-outline-danger";
  const size = (props.size) ? props.size : 14;

  const toggleStatus = ({ type }) => {
    let newStatus = (type === 'activate') ? 'A' : 'D';

    props.toParent({type: 'toggle status', value: newStatus});
    if(newStatus === 'D') {
      setConfirmed(true);

      setTimeout(() => {
        setConfirmed(false);
        togglePending();
      }, 1000)
    }
  }
  const togglePending  = () => setPending((s) => !s);

  return (
    <Fragment>
      { pending && (
        <div className={wrapper}>
          { status === 'A' && (
            <button type="button" className={`${styles} btn-outline-danger`} onClick={togglePending}>
              { !textA && (
                <Trash className="text-danger" size={size} />
              )}
              { textA }
            </button>
          )}
          { status === 'D' && (
            <button type="button" className={`${styles} btn-outline-dark`} onClick={() => toggleStatus({type: 'activate'})}>
              { textD }
            </button>
          )}
        </div>
      )}

      { !pending && (
        <div className={wrapper}>
          <button type="button" className={`${styles} btn-outline-danger`} onClick={toggleStatus} disabled={confirmed}>
            {!confirmed && 'Confirm' }
            { confirmed && 'Please Wait...' }
          </button>
          <button type="button" className={`${styles} btn-outline-dark ms-1`} onClick={togglePending} disabled={confirmed}>
            Cancel
          </button>
        </div>
      )}
    </Fragment>
  )
}

export default Button;
