import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useAxios } from '../../../hooks/useAxios';
import dayjs from 'dayjs'

import { SiteContext } from '../../../context/SiteContext';
import { UserContext } from '../../../context/UserContext';

import FormBottomBar    from '../../../components/system/FormBottomBar';
import GeneratedDisplay from '../../../components/system/GeneratedDisplay';
import Collapsed from '../../../components/system/Collapsed';
import DeleteBtn from '../../../components/buttons/DeleteBtn';

import { userArray } from '../../../utils/functions';

import Phases from '../phases/Phases';
import Access from '../../../components/system/access/Access';

const View = () => {
  const debug = true;
  const navigate = useNavigate();
  const { serverCall } = useAxios();
  const { config } = useContext(SiteContext);
  const { projectRole, project, setProject, phases, setPhases, linkedTasks } = useOutletContext();
  const { userDetails, allUsers, projects, setProjects, schemas } = useContext(UserContext);

  const [schema, setSchema] = useState([]);
  const [scale, setScale] = useState([]);
  const [projStatus, setProjStatus] = useState([]);
  const [priority, setPriority] = useState([]);
  const [activePhases, setActivePhases] = useState([]);

  const [access, setAccess] = useState([]);
  const [accessUsers, setAccessUsers] = useState([]);
  const [accessTitle, setAccessTitle] = useState('Access');

  useEffect(() => {
    let found = schemas.find(x => x.name === 'project');
    if(found) setSchema(found.schema);

    // these are for filters
    // lklklk should these be moved to site or will they.. change based on the company/project?

    found = schemas.find(x => x.name === 'project-scale');
    if(found) setScale(found.schema);

    found = schemas.find(x => x.name === 'project-status');
    if(found) setProjStatus(found.schema);

    found = schemas.find(x => x.name === 'project-priority');
    if(found) setPriority(found.schema);
  }, [schemas])

  useEffect(() => {
    setActivePhases((prev) => {
      let arr = [...phases];
      arr = arr.filter(x => x.status === 'A');
      return arr;
    })
  }, [phases])

  useEffect(() => {
    if(!access) return;
    let userIds  = access.filter(x => x.type === 'user').map(x => x.id);
    let groupIds = access.filter(x => x.type === 'group').map(x => x.id);
    let filtered = allUsers.filter(x => {
      let groups = x.groupArr.filter(x => groupIds.includes(x.id));
      return (userIds.includes(x.id) || groups.length > 0) ? true : false;
    })
    setAccessUsers(filtered);
  }, [access, allUsers])

  const fromForm = (data) => {
    let { type, value, ownerQty, groupQty, userQty } = data;
    // value = form values, response = response returned from server

    if(type === 'access') {
      // lklklk: need to update/warn about people who are assigned things but that do not have access to the project...?
      setAccess(value);
      if(ownerQty > 1 || groupQty > 0 || userQty > 0) {
        let str = 'Access: ';
        if(ownerQty > 1) {
          str = str + ownerQty + ' Owners';
          if(groupQty > 0 || userQty > 0) str = str + ', ';
        }
        if(groupQty > 0)  {
          str = str + groupQty;
          str = groupQty > 1 ? str + ' Groups' : str + ' Group';
        }
        if(userQty > 0) {
          if(groupQty) str = str + ', ';
          str = str + userQty;
          str = userQty > 1 ? str + ' People' : str + ' Person';
        }
        setAccessTitle(str);
      } else {
        setAccessTitle('Access');
      }

    } else if(type === 'submitted') {
      if(!value) alert('Error.'); //lklklk

      // update managedByDisplay
      value.managedByDisplay = userArray(allUsers, value.managedBy);

      // now update the main project objects
      let projs = [...projects];
      let idx = projs.findIndex(x => x.id === value.id);
      projs[idx] = { ...projs[idx], ...value};
      setProjects(projs);

      // // check if response includes any log data
      // if(response.logs) {
      //   let arr = [...logs];
      //   for(const log of response.logs) {
      //     console.log(log);
      //     // let idx = arr.findIndex(x => x.id === log.dataValues.id);
      //     // if(idx > -1)
      //     //   arr[idx] = log.dataValues;
      //     // else
      //     //   arr.push(log.dataValues);
      //   }
      //
      //   console.log(arr);
      //   setLogs(arr);
      // }
    }
  }

  const toggleStatus = async ({ type }) => {
    // lklklk: consider moving this to the DeleteBtn component
    // then you can just pass the appropriate appId etc?
    let status = (type === 'activate') ? 'A' : 'D';

    let data = {
      id: project.id,
      status: status,
      updatedBy: userDetails.id,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedByUser: userDetails.byUser
    }

    let projs = [...projects];
    let idx = projs.findIndex(x => x.appId === project.appId);
    projs[idx].status = status;
    setProjects(projs);

    let res = await serverCall({
      method: 'PATCH', data, url: `/mapping/projects/${project.appId}`,
      headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId },
      eamsAuth0: true
    });
    if(debug) console.log(res);

    if(type === 'delete confirmed')
      navigate(`/`);
  }

  return (
    <div className="row h-100 overflow-hidden">
      <div className="col-md-6 pe-5 h-100 overflow-scroll">
        {(projectRole === 'contributor' || projectRole === 'viewer') && (
          <div className="p-3 bg-white rounded">
            <GeneratedDisplay schema={schema} data={project} tag="h6" />
          </div>
        )}

        {(userDetails.supe || projectRole === 'admin' || projectRole === 'editor') && (
          <FormBottomBar debug={true} method="PATCH" url={`/mapping/projects/${project.appId}`} obj={project}
            vars={{
              'project-scale': scale,
              'project-priority': priority,
              'project-status': projStatus,
              'all-users': allUsers,
              'access-users': accessUsers,
              'phases': activePhases
            }}
            updateForm={true} toParent={fromForm} schema={schema}
            formText={{ submit: 'Save', pending: 'Saving', success: 'Saved!', cancel: 'Cancel' }}
          />
        )}
      </div>
      <div className="col-md-6 pt-3 h-100 overflow-scroll">
        {(userDetails.supe || projectRole === 'admin' || projectRole === 'editor') && (
          <Fragment>
            <h5>Linked Tasks</h5>
            { linkedTasks.length === 0 && <p className="text-muted">No linked tasks.</p>}
            { linkedTasks.map(obj => (
              <p key={obj.appId} className="mb-0">{obj.udf2 ? `${obj.udf2}:` : ''} {obj.name}</p>
            ))}
            {/* <p key={obj.appId} onClick={() => navigate('/tbdtbdtbd')}><span className="link">{obj.name}</span></p> */}

            <hr />
            <Collapsed title={accessTitle}>
              <div>
                <Access type="project" url={`/mapping/projects/${project.appId}`} arr={project.access} parent={project} setParent={setProject} setParents={setProjects} toParent={fromForm} />
              </div>
            </Collapsed>
            <hr />
            <Collapsed title={config?.terms?.phase?.[3] ?? 'Phases'}>
              <div className="mt-2">
                <Phases project={project} phases={phases} setPhases={setPhases} />
              </div>
            </Collapsed>
            <hr />
            <Collapsed title="Advanced">
              <div className="mt-2">
                { project.status === 'A' && (
                  <DeleteBtn toParent={toggleStatus} text="Close Project" />
                )}
                { project.status === 'D' && (
                  <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => toggleStatus({type: 'activate'})}>
                    Re-open Project
                  </button>
                )}
              </div>
            </Collapsed>
            <hr />
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default View;
