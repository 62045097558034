import React from 'react';
import FormModal from '../../../../components/system/FormModal';

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'projectId' },
    { id: '3', type: 'floating-input', inputType: 'text', label: 'Group name', field: 'name', },
  ]},
]

const View = (props) => {
  const { projectId, setPlanGroups, toParent } = props;

  const fromForm = (data) => {
    let { type, status, response } = data; // value = form values, response = response from server

    if(type === 'submitted' && status === 200) {
      if(!response) alert('Error.'); //lklklk

      setPlanGroups((prev) => {
        let arr = [...prev];
        arr.push(response);
        return arr;
      })

      toParent({ type: 'group added', value: response });
    }
  }

  return (
    <FormModal debug={false} method="POST" url="/mapping/plan-groups" schema={schema}
      createForm={true} updateForm={false} toParent={fromForm}
      vals={{ projectId }}
      classes="btn btn-outline-dark  mt-2 me-2 mb-2"
      modalText={{ button: 'Add Plan Group', title: 'Create Plan Group' }}
      formText={{ submit: 'Create', pending: 'Creating', success: 'Created', cancel: 'Cancel' }}
      submitType={{ type: 'close-modal' }}
    />
  )
}

export default View;
