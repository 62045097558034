import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { useAxios } from '../../../../hooks/useAxios';
import { useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs'

import Modal from 'react-bootstrap/Modal';
import CreatableSelect from 'react-select/creatable';

import Generated  from '../../Generated';
import { formObj } from '../../../../utils/schemas';


const View = (props) => {
  const { groups, parent, update } = props;
  const { userDetails, setAllGroups, schemas } = useContext(UserContext);
  const [schema, setSchema] = useState([]);
  const [selected, setSelected] = useState();
  const [vals, setVals] = useState({});

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [schema])
  });

  const [show, setShow] = useState(false);
  const handleCancel = () => {
    setShow(false);
    setVals({});
    reset();
  }

  useEffect(() => {
    let found = schemas.find(x => x.name === 'group-add');
    if(found) setSchema(found.schema);
  }, [schemas])

  const handleChange = (e) => {
    // group already exists, add them
    setSelected(e);

    let access = [...parent.access];
    access = access.filter(x => !(x.type==='group' && x.id===e.id));
    access.push({ type: 'group', id: e.id, role: 'viewer' });
    update(access);

    setSelected(null);
  }

  const handleCreate = (val) => {
    setVals({ name: val, status: 'A' });
    setShow(true);
  }

  const onSubmit = async (data) => {
    // they are manually adding; send to server to create or update
    data.appId = nanoid();
    data.userBy = userDetails.id;
    data.userAt = dayjs().format('YYYY-MM-DD HH:mm:ss');

    let res = await serverCall({
      method: 'POST', data, url: '/system/user-groups',
      headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId }, eamsAuth0: true
    });
    if(!res.status===200) alert('Error adding user group. Contact support. (UA984)');

    // we should be receiving the returned user group record back, update or add
    setAllGroups(prev => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.id === res.data.id);
      if(idx > -1) 
        arr[idx] = res.data;
      else
        arr.push(res.data);

      return arr;
    });

    let access = [...parent.access];
    access = access.filter(x => !(x.type==='group' && x.id===res.data.id));
    access.push({ type: 'group', id: res.data.id, role: 'viewer' });
    update(access);
    handleCancel();
  }

  return (
    <Fragment>
      <div className="mb-2">
        <CreatableSelect
          isClearable
          blurInputOnSelect
          classNamePrefix="react-select"
          placeholder="Select or add group"
          value={selected}
          onChange={handleChange}
          onCreateOption={handleCreate}
          options={groups}
          isDisabled={pending}
        />
      </div>

      <Modal show={show} onHide={handleCancel} keyboard={true} centered={true} scrollable>
          <Modal.Header closeButton>
            <Modal.Title>Add New User Group</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Generated id="form-modal" schema={schema} size="md" pieces={{ vals, register, control, setValue, watch, errors }} />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline-danger me-1" type="button" onClick={handleCancel}>
              Cancel
            </button>

            <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'}`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
              { !pending && !error && 'Add'}
              { pending === true && (
                <Fragment>
                  <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  Adding...
                </Fragment>
              )}
              { error && (`Error adding user (SU992)`)}
              { pending ==='success' && 'Added!'}
            </button>
          </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

export default View;
