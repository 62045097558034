import React from 'react';
import { CaretDown } from 'react-bootstrap-icons'

// future:
// option to pass different icons? colors?
// do we need to pass size?

const View = (props) => {
  const rotated = props.rotated ? "rotate(180deg)" : "rotate(0)"
  const classes = props.classes ? props.classes : 'float-end mt-1 me-1';

  return <CaretDown className={classes} style={{ transform: rotated, transition: "all 0.25s ease-in-out" }} />
}

export default View;
