import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom'

import { SiteContext } from '../../context/SiteContext';
import { UserContext } from '../../context/UserContext';

const View = () => {
  const { hostname } = useContext(SiteContext);
  const { userDetails, highestRole } = useContext(UserContext);

  return (
    <Fragment>
      { highestRole === 'admin' && hostname !== 'wgs.flysfo.io' && hostname !=='admin.mstdsqr.com' && (
        <Fragment>
          <li><h6 className="dropdown-header">Admin</h6></li>
          <li className="pointer dropdown-item"><Link to="/libraries">Global Libraries</Link></li>
          { hostname==='icp.jblapp.com' && <li className="pointer dropdown-item"><Link to="/admin/editor">Edit Content</Link></li> }
          <li className="pointer dropdown-item"><Link to="/admin">Manage Users</Link></li>
          <li className="pointer dropdown-item"><Link to="/checklists">Review Checklists</Link></li>
          <li><hr className="dropdown-divider" /></li>
        </Fragment>
      )}

      { highestRole === 'admin' && hostname ==='admin.mstdsqr.com' && (
        <Fragment>
          <li><h6 className="dropdown-header">Admin</h6></li>
          <li className="pointer dropdown-item"><Link to="/admin">Manage Users</Link></li>
          <li><hr className="dropdown-divider" /></li>
        </Fragment>
      )}

      { highestRole === 'admin' && hostname === 'wgs.flysfo.io' && (
        <Fragment>
          <li><h6 className="dropdown-header">Admin</h6></li>
          <li className="pointer dropdown-item"><Link to="/admin">Manage Users</Link></li>
          <li className="pointer dropdown-item"><Link to="/admin/notifications">Manage Notifications</Link></li>
          <li className="pointer dropdown-item"><Link to="/admin/editor/FbMAvtzfmfuqQu6a823sg">Edit Guidance</Link></li>
          <li className="pointer dropdown-item"><Link to="/libraries">View Libraries</Link></li>
          <li className="pointer dropdown-item"><Link to="/admin/email">Send Email</Link></li>
          <li><hr className="dropdown-divider" /></li>
        </Fragment>
      )}

      { userDetails?.id===1 && (
        <Fragment>
          <li><h6 className="dropdown-header">Preview</h6></li>
          <li className="pointer dropdown-item"><Link to="/admin/reports">Report Editor</Link></li>
          <li><hr className="dropdown-divider" /></li>
        </Fragment>
      )}
    </Fragment>
  )
}

export default View;
