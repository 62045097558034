import { nanoid } from 'nanoid';

const init = ({ pieces, setSyncWorkers }) => {
  const worker = new Worker(new URL('./worker.js', import.meta.url));
  let newId = nanoid();

  worker.postMessage({ data: pieces });
  worker.onerror = (err) => err;
  worker.onmessage = (e) => {
    const { status } = e.data;

    // future: could get percentage here of plan ... downloads? or progress?

    if(status === 'finished') {
      worker.terminate();
      setSyncWorkers((prev) => {
        let arr = [...prev];
        let idx = arr.find(x => x.tempId === newId);
        arr.splice(idx, 1);
        return arr;
      })
    }
  };

  setSyncWorkers((prev) => {
    let arr = [...prev];
    arr.push({ 
      worker, 
      tempId: newId,
      direction: pieces.direction,
      type: pieces.type,
      id: pieces.id,
      data: { status: 'starting', progress: 0 }
    });
    return arr;
  });

}

export default init;