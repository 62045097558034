import React, { Fragment } from 'react';

const View = (props) => {
  const { files } = props;

  return (
    <Fragment>
      { files.length > 0 && (
        <Fragment>
          <h5 className="mt-2">Rejected</h5>
          { files.map((obj, idx) => (
            <div key={'rejected-'+idx} className="d-flex border-top px-2 py-3">
              <p className="mb-0 flex-grow-1">{obj.file.path}</p>
              <p className="mb-0 text-break">{obj.errors[0].message}</p>
            </div>
          ))}
          <hr />
        </Fragment>
      )}
    </Fragment>
  )
}

export default View;
