import React, { forwardRef, useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context/UserContext';

// lklklk future:
// probably need to update this so that we require that at least
// one of them are owners / have access...?

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  const { userDetails, allGroups } = useContext(UserContext);
  const [access, setAccess] = useState([]);
  const value = pieces.watch(item.field);
  const accessType = item.accessType ? item.accessType : 'content'; // content (could be project, task etc) or user

  let options = [
    { value: '', label: 'No access' },
    { value: 'admin', label: 'Tier 4 (Admin)' },
    { value: 'editor', label: 'Tier 3 (Editor)' },
    { value: 'contributor', label: 'Tier 2 (Contributor)' },
    { value: 'viewer', label: 'Tier 1 (Viewer)' },
  ]

  if(accessType !== 'user')
    options.splice(1, 0, { value: 'owner', label: 'Owner' });

  useEffect(() => {
    // if no value is set, this is probably a new record
    // spin through their userGroups where they can add/edit content
    if(!value) {
      let defaultVal = (accessType === 'user') ? 'admin' : 'owner';
      let admin = userDetails.groupArr.filter(x => x.role === 'owner' || x.role === 'admin' || x.role === 'editor');
      pieces.setValue(item.field, [{ type: 'group', id: admin[0]?.id, role: defaultVal }]);
    }

    // immediately send the current value back up to the parent
    // so we can filter the access-users variable for other fields
    if(pieces.toParent) pieces.toParent({ type: 'access', name: item.field, value });
  }, [accessType, value, item.field, pieces, userDetails])

  useEffect(() => {
    if(!userDetails || !value) return;

    let allowEditing = true;
    // lklklk this doesn't really work well, if they accidentally select
    // a non-admin role for one they aren't a group of it locks them out...
    if(accessType!=='user' && !userDetails.supe && value.length > 0) {
      // if user isn't a supe, find this item's owner, check if user is a member of that group
      // yes: the fields will be editable, no: disabled
      let owner = value.find(x => x.role === 'owner' || x.role === 'admin');
      let found = userDetails.groupArr.find(x => x.id === owner?.id);
      allowEditing = found ? true : false;
    }

    let list = [];
    for (const group of allGroups) {
      let admin = userDetails.groupArr.find(x => x.id === group.id && (x.role==='owner' || x.role==='admin'));
      if(!admin && userDetails.supe!=='Y') continue;
      let found = value?.find(x => x.type === 'group' && x.id === group.id);
      let role = found ? found.role : '';
      list.push({ id: group.id, label: group.label, value: role, active: allowEditing });
    }

    // SAVING THIS in case you need to replace the above and only
    // spin through the values that are already in the access field
    // for (const group of value) {
    //   if(group.type === 'user') continue;
    //   let found = allGroups.find(x => x.id === group.id);
    //   if(!found) continue;
    //   list.push({ id: found.id, label: found.label, value: group.role, active: allowEditing })
    // }

    list = list.sort((a, b) => ((a || {}).label || '').localeCompare((b || {}).label || '', undefined, { numeric: true, sensitivity: 'base' }));
    setAccess(list);
  }, [accessType, value, userDetails, allGroups])

  const changeAccess = ({ id, role }) => {
    let newAccess = [...access];
    let idx = newAccess.findIndex(x => x.id === id);
    newAccess[idx].value = role;
    setAccess(newAccess);

    // now prepare the actual access value for db
    // check to see if the one that was changed exists in the current value
    let arr = value ? [...value] : [];
    idx = arr.findIndex(x => x.id === id);
    if(idx > -1) {
      // this group exists in the current access
      // are changing the permissions or removing for no access
      if(role === '')
        arr.splice(idx, 1);
      else
        arr[idx].role = role;
    } else {
      // this group doesn't exist in the current access
      // only add it if the value is not empty
      if(role !== '')
        arr.push({ type: 'group', id, role: role });
    }

    pieces.setValue(item.field, arr);
  }

  if(accessType!=='user' && access.length < 2) return null;

  return (
    <div className="mt-3">
      {item.label && (<label htmlFor={item.field}>{item.label}</label>)}
      <div className="overflow-scroll" style={{maxHeight:250}}>
        { access.map((obj, idx) => (
          <div key={`access-${idx}`} className="row">
            <div className="col-sm-5 pt-2">
              {obj.label}
            </div>
            <div className="col-sm-7">
              <select
                value={obj.value}
                disabled={!obj.active}
                className={`form-select mb-2 ${(pieces.size) ? "form-select-"+pieces.size : ""}`}
                onChange={(e) => {
                  e.target.blur();
                  changeAccess({ id: obj.id, role: e.target.value });
                }}>
                { options.map((opt, idx) => {
                  if(!opt.label) opt.label = opt.value;
                  return (
                    <option key={`options-${idx}`} value={opt.value}>{opt.label}</option>
                  )
                })}
              </select>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
});

export default Field;
