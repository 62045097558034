export const formatFile = (userId, appId, file, folder, prefix) => {
  return new Promise(async(resolve) => {
    let data = {
      appId: appId,
      progress: 0
    };
    let lastDot = file.name.lastIndexOf('.');
    let fileExt = file.name.substring(lastDot + 1);
    let currTime = new Date().getTime();

    data.originalName = file.name.substring(0, lastDot);

    // use the original name or override if we are sent a prefix; then add timestamp
    let newFileName = prefix ? prefix : data.originalName;
    newFileName = newFileName+'-'+currTime+'-'+userId+'.'+fileExt;

    data.name = newFileName;
    data.source = file.name;
    data.type = file.type;
    data.size = file.size;

    // upload deatils
    data.bucket = process.env.REACT_APP_S3_BUCKET;
    data.folder = folder;

    resolve(data);
  })
};
