import React, { Fragment } from 'react';
import parse from 'html-react-parser';

import { checkConditions } from './forms/_utils/conditional';
import { nl2br, userArray } from '../../utils/functions';

// how to use:
// <Generated id="needed?" schema={schema} tag="sm" />

const View = (props) => {
  const { schema, data, pieces } = props;
  const formId = props.id ? props.id : 'form';
  const tag = props.tag ? props.tag : 'h5';

  return (
    <Fragment>
      { schema.map((item) => findType({ formId, tag, item, data, pieces }) )}
    </Fragment>
  )
}

const findType = ({ formId, tag, item, data, pieces }) => {
  if(!item || !data) return;
  if(item.type === 'hidden') return;
  if(item.display === false) return;

  let label = item.displayLabel ? item.displayLabel : item.label;
  let value = data[item.field] ? data[item.field] : null;

  if(value!=='-') {
    if(item.field==='createdBy' || item.field==='updatedBy') {
      let found = pieces.allUsers.find(x => x.id === value);
      if(found) value = found.label;
    } else if(item.field==='managedBy' || item.field==='assignedTo')
      value = userArray(pieces.allUsers, value);
  }

  let display = item.conditional ? checkConditions(item, null, value) : true;
  if(display === 'error') return <div key={formId+item.id} className="bg-danger text-white p-3">Conditional logic error.</div>
  if(!display) return;

  if(item.type.includes('col-'))
    return <Col key={formId+item.id} tag={tag} obj={item} data={data} pieces={pieces} />;
  else if(item.type==='type-selection')
    value = data.display.typeLabel;
  else if(item.parent) {
    if(item.parent==='type') {
      let found = pieces.types?.find(x => x.id===data.typeId);
      if(found) value = found[item.field] ? found[item.field] : null;
    }
  } else if(`${item.array}` in data && (data[item.array].length > 0 && data[item.array][0])) {
    value = parse(nl2br(data[item.array]));
  // } else {
  //   console.log(item);
  //   console.log(data);
  }

  // if the item is a checkbox/dropdown, but the value isn't one of the options, clear value
  if(item.options?.length > 0 && value) {
    let found = item.options.find(x => x.value===value);
    if(!found) value = null;
  } else if(item.type==='checkbox' && item.value!==value)
    value = null;

  // don't show if there's no associated value? should this be an option in the item config?
  if(!value) return;

  switch (item.type) {
    // case 'type-selection':
    //   return <Input key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;

    default:
      return (
        <div key={formId+item.id}>
          {parse(`<${tag} class="mb-1 text-muted">${label}</${tag}>`)}
          <p className="mb-2">{value}</p>
        </div>
      )
  }
}

const Col = (props) => {
  const { formId, tag, obj, data, pieces } = props;

  return (
    <div className="row g-2 mt-1">
      { obj.type === 'col-336' && (
        <Fragment>
          <div className="col-sm-3">
            { obj.col1?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
          <div className="col-sm-3">
            { obj.col2?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
          <div className="col-sm-6">
            { obj.col3?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
        </Fragment>
      )}

      { obj.type === 'col-363' && (
        <Fragment>
          <div className="col-sm-3">
            { obj.col1?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
          <div className="col-sm-6">
            { obj.col2?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
          <div className="col-sm-3">
            { obj.col3?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
        </Fragment>
      )}

      { obj.type === 'col-4' && (
        <Fragment>
          <div className="col-sm-4">
            { obj.col1?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
          <div className="col-sm-4">
            { obj.col2?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
          <div className="col-sm-4">
            { obj.col3?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
        </Fragment>
      )}

      { obj.type === 'col-48' && (
        <Fragment>
          <div className="col-sm-4">
            { obj.col1?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
          <div className="col-sm-8">
            { obj.col2?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
        </Fragment>
      )}

      { obj.type === 'col-84' && (
        <Fragment>
          <div className="col-sm-8">
            { obj.col1?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
          <div className="col-sm-4">
            { obj.col2?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
        </Fragment>
      )}

      { obj.type === 'col-6' && (
        <Fragment>
          <div className="col-sm-6">
            { obj.col1?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
          <div className="col-sm-6">
            { obj.col2?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
        </Fragment>
      )}

      { obj.type === 'col-633' && (
        <Fragment>
          <div className="col-sm-6">
            { obj.col1?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
          <div className="col-sm-3">
            { obj.col2?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
          <div className="col-sm-3">
            { obj.col3?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
        </Fragment>
      )}

      { obj.type === 'col-12' && (
        <div className="col">
          { obj.col?.map((item) => findType({ formId, tag, item, data, pieces }) )}
        </div>
      )}
    </div>
  )
}

// if you wanted to do columns:
// parent wrapper: style={{columns:2}}
// label value div: style={{breakInside:'avoid'}}

export default View;
