import getArtwork  from './types/artwork';
import getQuickUrl from './types/quick-url';

const getValues = ({ sources, arr, project, phase, types, typeVars, symbols, photos, taskIds }) => {
  return new Promise(async(resolve) => {
    let values = [];
    for(const obj of arr) {
      // console.log(obj);
      let item = [];
      for(const source of sources) {
        let value = '';
        if(source) {
          if(source.text) {
            value = source.text;
          } else if(source.type==='artwork name') {
            value = await getArtwork({ plot: obj, project, phase });
          } else if(source.type==='artwork filename') {
            value = await getArtwork({ plot: obj, project, phase, showExt: true });
          } else if(source.type==='quick url') {
            value = await getQuickUrl({ plot: obj, phase });
          } else if(source?.field && source?.parent) {
            // lklklk this should map to a function
            if(source.parent==='project' && project && project[source.field])
              value = project[source.field];
            else if(source.parent==='phase' && phase && phase[source.field])
              value = phase[source.field];
            else if(source.parent==='plan' && obj.plan && obj.plan[source.field])
              value = obj.plan[source.field];
            else if(source.parent==='plot.display' && obj.display[source.field])
              value = obj.display[source.field];
            else if(obj[source.field])
              value = obj[source.field];
  
  
            if(Array.isArray(value)) {
              value = value[0];
            }
          }
        }
        item.push(value);
      }

      values.push(item);
    }

    resolve(values);
  })
};

export default getValues;