import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { useAxios } from '../../../hooks/useAxios';

import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import QuickAdd from './components/QuickAdd';
import ChangePlan from './components/ChangePlan';
import ChangePhase from '../phases/components/ChangePhase';
import DownloadPlan from '../../../components/buttons/DownloadPlan';
import Map from '../../../components/mapping/MapV2';
import Hover from '../../../components/mapping/components/Hover';

import { Virtuoso } from 'react-virtuoso';
import PlotRow from '../plots/components/PlotRow';
import PlotEdit from '../plots/components/PlotEdit';
import BulkEdit from '../plots/components/BulkEdit';
import Filters from '../plots/components/Filters';

import { Back as BackIcon, Scissors, ChevronLeft, Clipboard, LayoutSplit, Pencil, Window } from 'react-bootstrap-icons';

import { emptyObj } from '../../../utils/functions';
import { findSchema } from '../../../utils/schemas';
import { findPhasePlan } from '../../../utils/phases';
import { getPlotDisplay } from '../../../utils/plots';
import { newPlot, copyPlot, addPlot, replacePlot, rotatePlot, updatePoints, movePlots, bulkReorderPlots, cutPlots, reorderPlots, toggleStatus } from '../plots/utils/plots';

const View = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { serverCall } = useAxios();
  const { userDetails, schemas } = useContext(UserContext);
  const { 
    loadingPlots,
    project,
    libraries, 
    phases, 
    planGroups,
    plans, 
    phasePlans, 
    setPhasePlans,
    activePlan, 
    setActivePlan, 
    activePhase, 
    setActivePhase, 
    types, 
    typeVars,
    symbols,
    activePlots,
    activeQtys,
    publicStatuses,
    plots, 
    setPlots, 
    activePhotos,
    setPhotos,
    search, 
    setSearch, 
    filters, 
    setFilters,
    sort,
    setSort
  } = useOutletContext();
  const [mapPlan, setMapPlan] = useState({});
  const planRef = useRef({});
  const listRef = useRef(null);
  const activePlotsRef = useRef([]);

  const hoverRef = useRef();
  const [hoverId, setHoverId] = useState();

  const [rowSchema, setRowSchema] = useState([]);
  const [bulkSchema, setBulkSchema] = useState([]);

  const [activeId, setActiveId] = useState();
  const [activePlot, setActivePlot] = useState({});
  const [zoomTo, setZoomTo] = useState({});

  const [selected, setSelected] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [copiedIds, setCopiedIds] = useState([]);

  const quickRef = useRef();
  const [quickAdd, setQuickAdd] = useState(false);
  const toggleQuick = () => setQuickAdd((s) => {
    quickRef.current = null;
    return !s
  });

  const [layout, setLayout] = useState(1);
  const [showPanel, setShowPanel] = useState(false);
  const closePanel = () => {
    setActiveId();
    setActivePlot({});
    setShowPanel(false);
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow();

  useEffect(() => {
    let found = schemas.filter(x => x.name==='plot-edit-row');
    found = findSchema(found, project); // this one could have multiple schemas
    if(found?.schema) setRowSchema(found.schema);

    found = schemas.find(x => x.name === 'plot-bulk-edit');
    if(found) setBulkSchema(found.schema);
  }, [schemas])

  useEffect(() => {
    if(emptyObj(activePhase)) return;
    setSelected([]);
    let plan = plans.find(x => x.appId === params.planId);
    if(!plan) return alert('Error finding plan. Contact support.'); // lklklk
    planRef.current = plan;
    setActivePlan(plan);

    let filtered = phasePlans.filter(x => x.planId === plan.id);
    let phasePlan = findPhasePlan(phases, activePhase, filtered);
    if(phasePlan) {
      // send planId, styling, and signed url to the map
      // planId is required so we filter the plots
      setMapPlan({ appId: phasePlan.appId, id: phasePlan.id, projectId: project.id, planId: plan.id, styling: phasePlan.styling, url: phasePlan.url });
    }
  }, [activePhase, phasePlans, phases, plans, setActivePlan, params])

  useEffect(() => {
    if(emptyObj(activePlan)) return;
    if(activePlan.appId !== params.planId)
      navigate(`/project/${params.projectId}/phase/${params.phaseId}/map/${activePlan.appId}`)

    return () =>  {
      setActivePlan({});
    }
  }, [activePlan, setActivePlan, params, navigate])

  useEffect(() => {
    if(selected.length!==1) {
      setActiveId();
      setActivePlot({});
      setShowPanel(false);
    } else {
      setActiveId(selected[0].id);
    }

    let ids = selected.map(x => x.id);
    let unique = selected.length > 0 ? [...new Set(ids)] : [];
    setSelectedIds(unique);
  }, [selected])

  useEffect(() => {
    activePlotsRef.current = activePlots;

    let found = activePlots.find(x => x.id === activeId);
    if(found) setActivePlot(found);
  }, [activePlots, activeId])

  const fromChild = async (data) => {
    const { type, value } = data;
    if(type==='view plot') {
      let index = activePlots.filter(x => x.planId === mapPlan.planId).findIndex(x => x.id === value);
      console.log(index);
      if(index > -1) listRef.current?.scrollToIndex({ index, align: 'center' });

      // could you also try to monitor when isScrolling?
      // https://virtuoso.dev/scroll-handling/
      setTimeout(() => setActiveId(value), 500);

    } else if(type==='view plot detail') {
      setShowPanel(true);
      setActiveId(value);

    } else if(type==='dblclick') {
      // add new and add to database
      let plot = newPlot({ typeId: quickRef.current, project, plans, types, planRef, activePhase, pieces: value, userDetails });
      await addPlot({ plotsRef: activePlotsRef.current, setPlots, plot });

      let idx = activePlotsRef.current.filter(x => x.planId === mapPlan.planId).findIndex(x => x.appId === plot.appId && x.phaseId === plot.phaseId);
      if(idx > -1) listRef.current?.scrollToIndex({ index: idx, align: 'center' });

      // push to server, and replace when we get it back with full id
      let res = await serverCall({ method: 'POST', data: plot, url: '/mapping/plots', eamsAuth0: true });
      if(res.status!==200) return alert('Error adding plot. Contact support.'); // lklklk
      let returned = getPlotDisplay({ project, plans, types, typeVars, plot: res.data });
      await replacePlot({ plotsRef: activePlotsRef.current, setPlots, plot: returned });

    } else if(type === 'plots moved') {
      let updates = await movePlots({ setPlots, plots: value, userDetails });
      let res = await serverCall({ method: 'PATCH', data: updates, url: '/mapping/plots/bulk', eamsAuth0: true });
      if(res.status!==200) return alert('Error moving plot(s). Contact support.'); // lklklk

    } else if(type === 'rotated' || type==='points moved') {
      let update = {};
      if(type==='rotated')
        update = await rotatePlot({ setPlots, plot: value, userDetails });
      else
        update = await updatePoints({ setPlots, plot: value, userDetails });

      let res = await serverCall({ method: 'PATCH', data: update, url: `/mapping/plots/${value.appId}`, eamsAuth0: true });
      if(res.status!==200) return alert('Error rotating plot. Contact support.'); // lklklk

      // if they were editing the polygon, reselect
      if(type==='points moved') {
        console.log('polygon edit selection NEEDS FIX')
        //   setSelected([value.id]);
      }

    } else if(type === 'map tools') {
      for(const update of value) {
        if(update.type==='map styled') {
          // temporarily doing this as v7 planPhases did not have appIds
          if(!update.value.appId) update.value.appId = 'temp';
          let res = await serverCall({ method: 'PATCH', data: update.value, url: `/mapping/phase-plans/${update.value.appId}`, eamsAuth0: true });
          if(res.status!==200) return alert('Error updating plan styles. Contact support.'); // lklklk
        } else if(update.type==='bulk reorder') {
          // switching the layout since the list doesn't auto-update
          // lklklk future: can you send a key to the list to force a refresh?
          let currentLayout = layout;
          setLayout(2);
          let updates = await bulkReorderPlots({ project, libraries, plans, types, activePlots, activeQtys, setPlots, reorder: update.value, userDetails });
          if(updates?.updates?.length > 0) {
            let res = await serverCall({ method: 'PATCH', data: updates, url: '/mapping/plots/bulk', eamsAuth0: true });
            if(res.status!==200) return alert('Error reordering plot(s). Contact support.'); // lklklk
          }
          setLayout(currentLayout);
        }
      }

    } else if(type === 'reordered') {
      // switching the layout since the list doesn't auto-update
      // lklklk future: can you send a key to the list to force a refresh?
      let currentLayout = layout;
      setLayout(2);
      let updates = await reorderPlots({ project, plans, types, setPlots, changed: value, userDetails });
      if(updates?.updates?.length > 0) {
        let res = await serverCall({ method: 'PATCH', data: updates, url: '/mapping/plots/bulk', eamsAuth0: true });
        if(res.status!==200) return alert('Error reordering plot(s). Contact support.'); // lklklk
      }
      setLayout(currentLayout);

    } else if(type === 'delete plots') {
      setShow('delete');
    } else if(type === 'hover') {
      if(!emptyObj(value)) {
        setHoverId(value.appId);
        hoverRef.current.style.display = 'block';
        hoverRef.current.style.top = value.top;
        hoverRef.current.style.left = value.left;
      } else {
        setHoverId(null);
        hoverRef.current.style.display = 'none';
      }
    }
  }

  const copyPlots = () => {
    setCopiedIds(selectedIds);
  }

  const clearCopy = () => {
    setCopiedIds([]);
  }

  const confirmCut = async () => {
    let plotIds = [...copiedIds];
    setCopiedIds([]);
    
    let updates = await cutPlots({ plotIds, setPlots, phaseId: activePhase.id, planId: activePlan.id, userDetails });
    // lklklk updates.existing includes any that we aren't moving/cutting, 
    // should show an error message/update for these...
    let res = await serverCall({ method: 'PATCH', data: updates, url: '/mapping/plots/bulk', eamsAuth0: true });
    if(res.status!==200) return alert('Error cutting plot(s). Contact support.'); // lklklk
  }

  const pastePlots = async () => {
    if(copiedIds.length === 0) return;

    // first grab the plots and copy them
    let arr = [];
    for(const id of copiedIds) {
      let ogPlot = plots.find(x => x.id === id);
      let obj = copyPlot({ project, plans, types, planRef, activePhase, ogPlot, userDetails });
      arr.push(obj);
    }

    // then sort by name 
    arr.sort((a, b) => ((a || {}).name || '').localeCompare((b || {}).name || '', undefined, { numeric: true, sensitivity: 'base' }));

    // push to server, and replace when we get it back with full id
    let res = await serverCall({ method: 'POST', data: arr, url: '/mapping/plots/bulk', eamsAuth0: true });
    if(res.status!==200) return alert('Error copying plot(s). Contact support.'); // lklklk

    let newSelect = [];
    await setPlots((prev) => {
      let arr = [...prev];
      for (const returned of res.data) {
        let plot = getPlotDisplay({ project, plans, types, typeVars, plot: returned });
        arr.push(plot);
        
        // add to selection
        newSelect.push({ appId: plot.appId, id: plot.id, phaseId: plot.phaseId, piece: 'locator' });
        if(plot.display.type === 'arch')
          newSelect.push({ appId: plot.appId, id: plot.id, phaseId: plot.phaseId, piece: 'label' });
      }
      return arr;
    })

    setTimeout(() => setSelected(newSelect), 500);
  }

  const deletePlots = async () => {
    let statuses = await toggleStatus({ setPlots, plotIds: selectedIds, userDetails, status: 'D' });
    let res = await serverCall({ method: 'PATCH', data: statuses, url: '/mapping/plots/bulk', eamsAuth0: true });
    if(res.status!==200) return alert('Error deleting plot(s). Contact support.'); // lklklk
    setSelected([]);
    setCopiedIds([]);
    setShow();
  }

  return (
    <Fragment>
      { layout===1 && (
        <div className="row h-100 overflow-hidden">
          <div className="col-sm-5 d-flex flex-column h-100 overflow-hidden">
            <div className="row" style={{height:65}}></div>
            <div className="row overflow-scroll h-100">
              <div className="col p-0">
                { loadingPlots && (
                  <div className="d-flex justify-content-center text-muted mt-3 p-3">
                    <div className="spinner-border spinner-border-sm text-muted me-2" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    Loading plots...
                  </div>
                )}
                { !loadingPlots && activePlots.length === 0 && <p className="text-muted mt-3 p-3">No plots yet.</p>}
                <Virtuoso
                  ref={listRef}
                  style={{width: '100%', height: '100%'}}
                  data={activePlots.filter(x => x.planId === mapPlan.planId)}
                  itemContent={(idx, obj) => (
                    <PlotRow key={idx+obj.id} schema={rowSchema} obj={obj} project={project} plans={plans} types={types} typeVars={typeVars} setPlots={setPlots} selected={selected} setSelected={setSelected} setZoomTo={setZoomTo} toParent={fromChild} />
                  )}
                />
                <div style={{height:80}} />
              </div>
            </div>
          </div>
          <div className="col-sm-7 h-100 overflow-hidden">
            { !emptyObj(activePlan) && (
              <Map map={mapPlan} setPhasePlans={setPhasePlans} editable={true} plots={activePlots} publicStatuses={publicStatuses} selected={selected} setSelected={setSelected} zoomTo={zoomTo} toParent={fromChild} />
            )}
          </div>
        </div>
      )}

      { layout===2 && (
        <div className="row h-100 overflow-hidden">
          <div className="col-sm-12 h-100 overflow-hidden">
            { !emptyObj(activePlan) && (
              <Map map={mapPlan} setPhasePlans={setPhasePlans} editable={true} plots={activePlots} publicStatuses={publicStatuses} selected={selected} setSelected={setSelected} zoomTo={zoomTo} toParent={fromChild} />
            )}
          </div>
        </div>
      )}

      <div ref={hoverRef} style={{position:'fixed', display:'none'}}>
        <Hover appId={hoverId} activePhase={activePhase} types={types} typeVars={typeVars} symbols={symbols} plots={activePlots} />
      </div>

      <div className="position-fixed" style={{ top: 12, left: 12, zIndex: 1045 }}>
        {(copiedIds.length > 0 || selected.length > 0) && (
          <div className="mb-2 float-end">
            <div className="dropdown">
              <button className="btn bg-white border rounded shadow-sm me-2 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <BackIcon size={16} className="me-1" /> <Scissors size={16} className="me-1" /> {copiedIds.length > 0 && copiedIds.length}
              </button>
              <ul className="dropdown-menu">
                { selected.length > 0 && (
                  <li className="dropdown-item pointer" onClick={copyPlots}>Copy {selected.length} Plots</li>
                )}
                { copiedIds.length > 0 && selected.length > 0 && <li><hr className="dropdown-divider" /></li>}
                { copiedIds.length > 0 && (
                  <Fragment>
                    <li className="dropdown-item pointer" onClick={pastePlots}>Paste {copiedIds.length} Plots</li>
                    <li className="dropdown-item pointer" onClick={confirmCut}>Cut/Move {copiedIds.length} Plots</li>
                  </Fragment>
                )}
                { copiedIds.length > 0 && selected.length===0 && (
                  <Fragment>
                    <li><hr className="dropdown-divider" /></li>
                    <li className="dropdown-item pointer" onClick={clearCopy}>Clear Current</li>
                  </Fragment>
                )}
              </ul>
            </div>
          </div>
        )}            
        { selected.length > 1 && (
          <div className="mb-2 float-end">
            <OverlayTrigger placement="bottom" overlay={<Tooltip id={`bulk-edit-plots`}>Bulk Edit {selectedIds.length} Plots</Tooltip>}>
              <button className="btn bg-white border rounded shadow-sm me-2" onClick={() => setShow('bulk-edit')}><Pencil size={16} className="me-1" /> {selectedIds.length}</button>
            </OverlayTrigger>
          </div>
        )}
        <ChangePhase phases={phases} activePhase={activePhase} setActivePhase={setActivePhase} />
        <ChangePlan planGroups={planGroups} plans={plans} activePlan={activePlan} setActivePlan={setActivePlan} plots={activePlots} />
        <button className="btn bg-white border rounded shadow-sm me-2" onClick={() => navigate(`../phase/${activePhase.appId}`)}>
          <ChevronLeft />
        </button>

        <div className="btn-group bg-white shadow-sm me-2" role="group" aria-label="Map View">
          <button type="button" className={`btn rounded btn-outline-primary border ${layout===1 ? 'active' : ''}`} onClick={() => setLayout(1)}><LayoutSplit size={16} /></button>
          <button type="button" className={`btn rounded btn-outline-primary border ${layout===2 ? 'active' : ''}`} onClick={() => setLayout(2)}><Window size={16} /></button>
        </div>     
      </div>
      <div className="position-fixed" style={{top:12, right:12}}>
        <Filters project={project} publicStatuses={publicStatuses} activeQtys={activeQtys} libraries={libraries} planGroups={planGroups} plans={plans} types={types} search={search} setSearch={setSearch} filters={filters} setFilters={setFilters} sort={sort} setSort={setSort} />
        <QuickAdd quickAdd={quickAdd} quickRef={quickRef} toggle={toggleQuick} types={types} />
        <DownloadPlan classes="btn bg-white border rounded" project={project} planGroups={planGroups} plans={[activePlan]} phasePlans={phasePlans} phases={phases} phase={activePhase} publicStatuses={publicStatuses} plots={activePlots} />
      </div>

      <Offcanvas show={!emptyObj(activePlot) && (showPanel || layout===2)} style={{width:500}} onHide={closePanel} placement="start" scroll={true} keyboard={false} backdrop={false} className="shadow">
        {!emptyObj(activePlot) && (
          <PlotEdit project={project} planGroups={planGroups} plans={plans} phases={phases} activePhase={activePhase} plots={plots} activePhotos={activePhotos} setPhotos={setPhotos} publicStatuses={publicStatuses} obj={activePlot} types={types} typeVars={typeVars} symbols={symbols} setPlots={setPlots} handleClose={closePanel} toParent={fromChild} />
        )}
      </Offcanvas>

      <Modal show={show==='bulk-edit'} onHide={handleClose} centered>
        <BulkEdit key={JSON.stringify(selectedIds)+activePhase?.id} userDetails={userDetails} schema={bulkSchema} selected={selected} selectedIds={selectedIds} phase={activePhase} phases={phases} plots={plots} setPlots={setPlots} deletePlots={deletePlots} handleClose={handleClose} toParent={fromChild} />
      </Modal>

      <Modal size="sm" show={show==='delete'} onHide={handleClose} centered>
        <Modal.Body className="bg-body">
          <h5 className="text-danger">Delete Plots</h5>
          <p className="text-danger">Are you sure you want to delete {selectedIds.length} plots?</p>
          <div className="text-center">
            <button className="btn btn-outline-danger mx-1 px-3" onClick={deletePlots}>Confirm</button>
            <button className="btn btn-outline-dark mx-1 px-3" onClick={handleClose}>Cancel</button>
          </div>
          <p className="small text-muted fst-italic mt-3 mb-0">Note: you can undo this by filtering for "inactive" plots and re-activating.</p>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default View;
