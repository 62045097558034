import React, { Fragment, useContext, useState } from 'react';
import { WorkerContext } from '../../context/WorkerContext';
import { Download } from 'react-bootstrap-icons';
import dayjs from 'dayjs'

const View = (props) => {
  const { startWorker } = useContext(WorkerContext);
  const { project, planGroups, plans, phase, plots, photos } = props;
  const wrapper = props.wrapper ? props.wrapper : 'shadow-sm me-2';
  const classes = props.classes ? props.classes : 'btn btn-outline-primary';
  const text = props.text ? props.text : '';
  const [loading, setLoading] = useState(false);

  const download = async () => {
    console.clear();
    setLoading(true);
    let files = [];

    // get plot appIds for these photos
    let plotAppIds = photos.map(x => x.plotAppId);
    plotAppIds = [...new Set([...plotAppIds])];

    // then filter the plots list to only include those with photos
    let filtered = plots.filter(x => plotAppIds.includes(x.appId));

    for(const plot of filtered) {
      let prefix = project.number ? project.number : project.name;
      let plan = plans.find(x => x.id === plot.planId);
      if(plan.planGroupId) {
        let planGroup = planGroups.find(x => x.id === plan.planGroupId);
        prefix = prefix + ' - ' + planGroup.name;
      }
      prefix = prefix + ' - ' + plan.name;
      prefix = prefix + ' - Loc ' + plot.name;

      let plotPics = photos.filter(x => x.plotAppId === plot.appId).sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));

      for (const [i, v] of plotPics.entries()) {
        let filename = prefix + '-' + Number(i+1);
        let ext = v.name.split('.').pop();
        if(ext === filename) ext = '';

        files.push({
          filename: filename+'.'+ext,
          url: v.url
        })
      }
    }

    let timestamp = dayjs().format('YYYY-MM-DD');
    let name = `${project.name} - ${phase.name} - Photos (ZIP)`;
    let filename = `${project.name} - ${phase.name} - Photos ${timestamp}`;

    startWorker({ type: 'zip', pieces: { name, filename, files } });
    setLoading(false);
  }

  return (
    <div className={`btn-group position-static ${wrapper}`}>
      <button type="button" className={classes} onClick={download} disabled={loading}>
        { !loading && (
          <Fragment>
            <Download size={16} />
            { text && <span className="d-inline-block ms-2">{text}</span>}
          </Fragment>
        )}

        { loading && (
          <Fragment>
            <div className="spinner-border spinner-border-sm me-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            Generating
          </Fragment>
        )}        
      </button>
      {/* <button type="button" className={`dropdown-toggle dropdown-toggle-split ${classes}`} data-bs-toggle="dropdown" aria-expanded="false" disabled={loading}>
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className="dropdown-menu dropdown-menu-end">
        <li className="dropdown-item pointer">Text Only</li>
        <li className="dropdown-item pointer">Thumbnails</li>
        <li className="dropdown-item pointer">Install Status</li>
        <li className="dropdown-item pointer">Install Photos</li>
      </ul> */}
    </div>
  )
}

export default View;
