import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { matchSorter } from 'match-sorter';

import { UserContext } from '../../../context/UserContext';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import FilterCheckboxes from '../../../components/system/filters/Checkboxes';

// future:
// consider passing the sort function in case
// we need to differentiate for products

export const Group = {
  STATUS: 'status',
  TYPE: 'taskType',
  PUBLICSTATUS: 'publicStatus'
}

const isShownByStatus = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.STATUS);
  if (!filtered.length) return false;
  return filtered.some((filter) => filter.fnc(item));
}

const isShownByType = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.TYPE);
  if (!filtered.length) return true;
  return filtered.some((filter) => filter.fnc(item));
}

const isShownByPublicStatus = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.PUBLICSTATUS);
  if (!filtered.length) return true;
  return filtered.some((filter) => filter.fnc(item));
}

export const applyFilters = (data, filters, search) => {
  if(!data) data = [];

  // apply filters
  let active = data.filter((plot) => {
    const showByStatus  = isShownByStatus(plot, filters);
    const showByType  = isShownByType(plot, filters);
    const showByPublicStatus  = isShownByPublicStatus(plot, filters);
    return showByStatus && showByType && showByPublicStatus;
  });

  // then apply any search terms
  if(search) {
    active = matchSorter(active, search, {keys: [
      {threshold: matchSorter.rankings.CONTAINS, key: 'taskType'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'name'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf1'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf2'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf3'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf4'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf5'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'assignedToDisplay'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'publicStatus'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'linkedProject'},
    ]});
  }

  // then sort
  // active.sort((a, b)=> (a.name.localeCompare(b.name, 'en', { numeric: true })))
  // active.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
  active.sort((a,b) => (b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0))
  // console.log('Filtered qty:', active.length);
  // console.log(active);
  return active;
}

const View = (props) => {
  const { filters, setFilters, setSearch } = props;
  const { schemas } = useContext(UserContext);

  const defaultsRef = useRef([]);
  const [statuses, setStatuses] = useState([]);
  const [taskStatus, setTaskStatus] = useState([])
  const [taskTypes, setTaskTypes] = useState([])

  const resetFilters = () => {
    setFilters(defaultsRef.current);
  }

  useEffect(() => {
    defaultsRef.current = [];
    let found = schemas.find(x => x.name === 'statuses');
    if(found?.schema) {
      setStatuses(found.schema);
      for(const obj of found.schema) {
        if(obj.active)
          defaultsRef.current.push({name: obj.value, group: 'status', fnc: (x) => x.status === obj.value})
      }
    }

    found = schemas.find(x => x.name === 'task-status');
    if(found?.schema) {
      setTaskStatus(found.schema);
      for(const obj of found.schema) {
        if(obj.active)
          defaultsRef.current.push({name: obj.value, group: 'publicStatus', fnc: (x) => x.publicStatus === obj.value})
      }
    }

    found = schemas.find(x => x.name === 'task-types');
    if(found) setTaskTypes(found.schema);

    setFilters(defaultsRef.current);
  }, [schemas, setFilters])

  return (
    <Fragment>
      <div className="dropdown d-inline-block float-end">
        <button className="btn btn-sm btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
          Filters { JSON.stringify(filters) !== JSON.stringify(defaultsRef.current) && (`(${filters.length})`)}
        </button>

        <ul className="dropdown-menu dropdown-menu-end" style={{maxHeight:415, overflow: 'scroll'}}>

          { JSON.stringify(filters) !== JSON.stringify(defaultsRef.current) && (
            <Fragment>
              <li className="text-end">
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-reset`}>Clear filters</Tooltip>}>
                  <button className="btn btn-sm btn-outline-dark mx-2" type="button" onClick={resetFilters}>
                    Clear Filters
                  </button>
                </OverlayTrigger>
              </li>
              <li><hr className="dropdown-divider" /></li>
            </Fragment>
          )}

          <FilterCheckboxes arr={taskTypes} group={Group.TYPE} field="taskType" title="Task Type" filters={filters} setFilters={setFilters} />
          <li><hr className="dropdown-divider" /></li>
          <FilterCheckboxes arr={taskStatus} group={Group.PUBLICSTATUS} field="publicStatus" title="Status" filters={filters} setFilters={setFilters} />
          <li><hr className="dropdown-divider" /></li>
          <FilterCheckboxes arr={statuses} group={Group.STATUS} field="status" title="Active" filters={filters} setFilters={setFilters} />
        </ul>
      </div>

      <form className="d-inline-block mb-2 me-2 float-end" style={{width:150}} onSubmit={e => { e.preventDefault() }}>
        <input style={{height:28}} className="form-control form-control-sm" type="search" placeholder="Search" aria-label="Search" onChange={(e) => setSearch(e.target.value)} />
      </form>
    </Fragment>
  )
}

export default View;
