import React from 'react';
import FormModal from '../../../../components/system/FormModal';

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'floating-input', inputType: 'text', label: 'Presentation name', field: 'name', },
  ]},
]

const View = (props) => {
  const { setPresos } = props;

  const fromForm = (data) => {
    let { type, status, response } = data; // value = form values, response = response from server

    if(type === 'submitted' && status === 200) {
      if(!response) alert('Error.'); //lklklk
      console.log(response);
      console.log(setPresos);

      // setPresos((prev) => {
      //   let arr = [...prev];
      //   arr.push(response);
      //   return arr;
      // })
    }
  }

  return (
    <FormModal debug={false} method="POST" url="/dsm/presentation" schema={schema}
      createForm={true} updateForm={false} toParent={fromForm}
      modalText={{ button: 'Create New', title: 'Create New Presentation' }}
      formText={{ submit: 'Create', pending: 'Creating', success: 'Created', cancel: 'Cancel' }}
      submitType={{ type: 'close-modal' }}
    />
  )
}

export default View;
