import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusLg, ToggleOn, ToggleOff, XLg } from 'react-bootstrap-icons';

// future: add lock icon?
// re-center? maybe project... quick zooms?

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
}

const View = ({ mapRef, mode, toggleMode, toParent }) => {
  const navigate = useNavigate();

  const add = () => {
    let latLng = mapRef.current.getCenter();
    toParent({ type: 'gis survey add', value: {latLng, nextPlot: 'TBD'} })
  }

  return (
    <Fragment>
      <div className={POSITION_CLASSES.topright} style={{marginTop:1}}>
        <div className="leaflet-control">
          <button className="btn bg-white rounded shadow-sm" onClick={() => navigate('../')}>
            <XLg size={26} />
          </button>
        </div>
      </div>

      <div className={POSITION_CLASSES.bottomleft}>
        <div className="leaflet-control" style={{marginLeft:70, marginBottom:16}}>
          <button className="btn bg-white rounded shadow-sm me-2" type="button" onClick={toggleMode}>
            { mode===1 && <ToggleOff size={20} />}
            { mode!==1 && <ToggleOn size={20} />}
          </button>
          <button className="btn bg-white rounded shadow-sm" onClick={add}>
            <PlusLg size={20} />
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default View;
