import React, { forwardRef } from 'react'
import { useFieldArray } from 'react-hook-form'
import Generated from '../Generated';
import DeleteBtn from '../../buttons/DeleteBtn';
import DirectionBtns from '../../buttons/DirectionBtns';
import { emptyObj } from '../../../utils/functions';
import { formatRepeats } from '../../../utils/schemas';

// let test2 =  [
//   {
//     "type": "col-6",
//     "col1": [
//       {
//         "type": "floating-select",
//         "label": "Icon",
//         "libraryId": 3,
//         "category": "icon"
//       }
//     ],
//     "col2": [
//       {
//         "type": "floating-input",
//         "label": "Line [X]"
//       }
//     ]
//   }
// ]

const Field = forwardRef(({ item, pieces }, ref) => {
  let parent = item.prefix ? `${item.field}.${item.prefix}` : `${item.field}`;
  const { fields, append, swap, remove } = useFieldArray({ control: pieces.control, name: `${parent}` });
  

  const fromChild = ({ type, value, idx }) => {
    if(type==='direction' && value==='up') swap(idx,idx-1);
    if(type==='direction' && value==='down') swap(idx,idx+1);
  }

  // React.useEffect(() => {
  //   console.log(item);
  // }, [item])

  // React.useEffect(() => {
  //   if(emptyObj(pieces) || !pieces.getValues) return;
  //   console.log(pieces?.errors);
  // }, [pieces, item?.newRow])

  return (
    <div className={ pieces.size === 'sm' ? 'my-0' : 'my-2'}>
      {item.label && (<label className="d-block mb-1" htmlFor={item.field}>{item.label}</label>)}
      {item.note && (<p className="mb-1 text-muted fst-italic small">{item.note}</p>)}
      { fields.map((field, idx) => {
        let offset = idx*Object.keys(item.newRow).length;
        if(item?.startIdx) offset = offset + item.startIdx;
        let parent = item.prefix ? `${item.field}.${item.prefix}` : `${item.field}`;
        let parentName = item.prefix ? `${item.field}.${item.prefix}.${idx}` : `${item.field}.${idx}`;
        let schema = formatRepeats(item.schema, 1, offset, parentName);
        // console.log(parentName);
        // console.log(parent, idx);

        return (
          <div key={field.id} className="d-flex">
            <DirectionBtns idx={idx} count={fields.length} toParent={fromChild} styles={{paddingTop:13}} />
            <div className="flex-grow-1">
              <Generated key={field.id} id={field.id} repeat={{ parent, idx }} schema={schema} size="md" pieces={pieces} />
            </div>
            <DeleteBtn toParent={() => remove(idx)} wrapper="d-flex align-items-center" styles="btn btn-sm ms-1" size="16" />
          </div>
        )
      })}
      {!emptyObj(item?.newRow) && <button type="button" className="mt-2 btn btn-sm btn-outline-dark" onClick={() => append(item.newRow)}>Add New</button>}
    </div>
  )
});

// prepend({ firstName: "prependFirstName", lastName: "prependLastName" })
// insert(2, { firstName: "insertFirstName", lastName: "insertLastName" })
// move(1, 2)
// replace([{ firstName: "test1", lastName: "test1" }, { firstName: "test2", lastName: "test2" }]);
// reset({ test: [{ firstName: "Bill", lastName: "Luo" }] })

export default Field;
