import { useCallback, useContext, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'

import { SiteContext } from '../context/SiteContext';

// at the moment this doesn't actually directly upload files like from input
// files/the dropzones. you may want to figure out how to integrate that later

// setSyncing increases the sync count globally;
// pending, data, error are all component-specific
// you do want both!

// to use:
// import { useAxios } from '../data/useAxios';
// const { serverCall, data, pending, error } = useAxios();
// (async () => {
//   let res = await serverCall({
//     method: 'POST',
//     url: "/test",
//     params: { title: 'lktest' },
//     data: { title: 'lktest' },
//     eams: { type: 'create' },
//     headers: {'Content-Type': 'image/jpeg', 'Content-Encoding': 'base64'}
//   });
// })();


export const useAxios = () => {
  const debug = false;
  const delay = (ms=2000) => new Promise(r=>setTimeout(r, ms));
  axios.defaults.baseURL = "/api/v1";

  const { user, getAccessTokenSilently } = useAuth0();
  const { setSyncing } = useContext(SiteContext);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState();

  const serverCall = useCallback((request) => {
    return new Promise( async (resolve) => {
      setPending(true);
      setSyncing(c => c+1);

      if(debug) await delay();
      if(debug) console.log(request);

      if(request.data === undefined) request.data = {};
      if(request.headers === undefined) request.headers = {};

      // get auth token -- may want to make this optional for non-logged in users?
      if(request.eamsAuth0) {
        const accessToken = await getAccessTokenSilently();
        request.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      // if('blob' in params) {
      //   // if we've received a blob we are most likely doing a PUT
      //   // replace fetch the blob and replace data with it
      //   params.data = await fetch(params.blob).then(res => res.blob());
      //   delete params.blob;
      //
      //   // aws rejects the authorization header, delete it
      //   delete params.headers['Authorization'];
      // }

      try {
        const res = await axios.request(request);
        resolve(res);
        setPending('success');
        setSyncing(c => c-1);

        setTimeout(() => setPending(false), 1000);

      } catch (error) {
        console.log(error);

        let newError = `Error ${error.response.status}: ${error.response.data}`;
        setError(newError);

        setPending(false);
        setSyncing(c => c-1);
        resolve(error);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return { serverCall, pending, error };
};

// backup manual blob example just incase you need it!
// let blob = await fetch(photo.base64).then(res => res.blob());
// let awsDone = await axios.put( awsUrl.data, blob, { headers: {'Content-Type': photo.fileType, 'Content-Encoding': 'base64'}});
